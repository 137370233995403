.values {
    &__section {
        position: relative;
        margin-bottom: 56px;
    }
    
    &__grid {
        display: flex;
        margin: 0 -16px 56px;
        > div {
            padding: 0 16px;
            flex: 1 1 auto;
        }
        @media (max-width: $breakpoint-medium-max) {
            margin: 0 -12px 56px;
            > div {
                padding: 0 12px;
            }
        }
        @media (max-width: $breakpoint-small-max) {
            margin: 0;
            display: block;
            > div {
                padding: 0 0 40px;
            }
        }
    }
    
    &__title {
        font-size: 16px;
        max-width: 488px;
        margin-bottom: 32px;
        > h5 {
            display: inline-flex;
            align-items: center;
        }
        .MuiIconButton-root.MuiButtonBase-root {
            width: 16px;
            height: 16px;
            margin-left: 8px;
            .MuiSvgIcon-root {
                font-size: 1.2rem;
            }
        }
    }
    
    &__table-holder {
        background: $gray-300;
        border-radius: 8px;
        padding: 24px;
        @media (max-width: $breakpoint-small-max) {
            padding: 24px 16px;
        }
    }
    
    &__table {
        font-size: 14px;
        margin-bottom: 24px;
    }
    
    &__table-head {
        padding: 0 0 27px;
        display: flex;
        align-items: center;
        font-weight: 700;
        border-bottom: 1px dashed $gray-600;
        @media (max-width: $breakpoint-small-max) {
            padding-bottom: 20px;
        }
        
        .values__table--edit & {
            @media (min-width: $breakpoint-small) {
                margin-bottom: 10px;
            }
        }
        > div {
            width: 33.33%;
            &:not(:first-child) {
                padding-left: 12px;
            }
        }
    
        .values__table--goals & {
            border: 0;
            padding: 0;
            @media (max-width: $breakpoint-xsmall-max) {
                display: none;
            }
            > div {
                @media (min-width: $breakpoint-xsmall) {
                    padding: 8px 12px;
                    border-left: 1px dashed $gray-600;
                }
                @media (min-width: $breakpoint-medium) {
                    padding: 8px 42px;
                }
            
                &:first-child {
                    border: 0;
                    padding-left: 0;
                }
                &:last-child {
                    padding-right: 0;
                }
            }
        }
    }
    
    &__table-row {
        padding: 17px 0;
        display: flex;
        //align-items: center;
        border-bottom: 1px dashed $gray-600;
        
        .values__table--edit & {
            padding: 12px 0;
            
            @media (min-width: $breakpoint-small) {
                border: 0;
            }
    
            @media (max-width: $breakpoint-small-max) {
                padding: 8px 0;
            }
        }
        > div {
            width: 33.33%;
            &:not(:first-child) {
                padding-left: 12px;
            }
        }
        
        .values__table--goals & {
            border: 0;
            padding: 0;
            @media (max-width: $breakpoint-xsmall-max) {
                display: block;
                padding-top: 21px;
                padding-bottom: 10px;
                border-bottom: 1px dashed $gray-600;;
                > div {
                    padding: 0 0 13px;
                }
            }
            
            > div {
                @media (max-width: $breakpoint-xsmall-max) {
                    width: auto;
                }
                @media (min-width: $breakpoint-xsmall) {
                    padding: 8px 12px;
                    border-left: 1px dashed $gray-600;
                }
                @media (min-width: $breakpoint-medium) {
                    padding: 8px 42px;
                }
            
                &:first-child {
                    border: 0;
                    padding-left: 0;
                }
                &:last-child {
                    padding-right: 0;
                }
            }
            .form-group {
                margin: 0;
                @media (max-width: $breakpoint-xsmall-max) {
                    &__label {
                        font-weight: 700;
                        margin-bottom: 9px;
                    }
                }
            }
        }
    }
    
    &__list {
        @media (min-width: 641px) {
            display: flex;
            flex-wrap: wrap;
            margin: 0 -8px -16px;
            > div {
                display: flex;
                width: 33.33%;
                padding: 0 8px 16px;
            }
        }
        @media (min-width: $breakpoint-medium) {
            margin: 0 -16px -32px;
            > div {
                padding: 0 16px 32px;
            }
        }
        @media (max-width: 641px) {
            margin: 0 -8px;
            width: auto;
        }
    }
    
    &__disabled {
         > * {
             pointer-events: none;
             opacity: .4;
         }
    }
}