.reminder-card {
    position: relative;
    border-radius: 12px;
    background: $gray-400;
    transition: background-color .2s, box-shadow .1s;
    display: flex;
    flex-direction: column;
    width: 100%;
    font-size: 14px;
    &:hover {
        cursor: pointer;
        background: #fff;
        box-shadow: $box-shadow;
        @media (min-width: $breakpoint-medium) {
            .task-card__priority-text {
                opacity: 1;
            }
            .MuiRating-root {
                display: none;
            }
        }
    }
    
    &__inner {
        padding: 36px 16px 24px;
        display: flex;
        flex-direction: column;
        color: $base-color;
        @media (min-width: $breakpoint-medium) {
            padding: 32px 24px;
            min-height: 224px;
        }
        &:hover {
            text-decoration: none;
        }
    }
    
    &__menu {
        position: absolute;
        top: 10px;
        right: 20px;
        z-index: 2;
        @media (max-width: $breakpoint-medium-max) {
            right: 16px;
            top: 10px;
        }
        button {
            cursor: pointer;
            border: 0;
            background: none;
            padding: 0;
            color: $gray-800;
            outline: none;
            path {
                stroke: currentColor;
            }
            &:focus {
                color: $base-color;
            }
        }
    }
    
    &__menu-drop {
        position: absolute;
        right: -12px;
        top: 100%;
        width: 120px;
        background: $gray-200;
        padding: 2px 8px;
        transition: opacity .2s;
        border-radius: 6px;
        opacity: 0;
        visibility: hidden;
        box-shadow: $box-shadow;
        &::after {
            position: absolute;
            top: -16px;
            right: 16px;
            content:'';
            border: 8px solid transparent;
            border-bottom-color: $gray-200;
        }
        &--open {
            opacity: 1;
            visibility: visible;
        }
        ul {
            li {
                padding: 5px 0;
                &:not(:last-child) {
                    border-bottom: 1px solid $gray-600;
                }
            }
        }
        button {
            display: block;
            width: 100%;
            font-size: 14px;
            text-align: left;
            color: $base-color;
            padding: 4px 8px;
            &:hover {
                text-decoration: underline;
            }
        }
    }
    
    &__options {
        li {
            display: flex;
            align-items: center;
            &:not(:last-child) {
                margin-bottom: 12px;
            }
            
            .icon-svg {
                margin-right: 5px;
                flex: 0 0 24px;
            }
        }
    }
}