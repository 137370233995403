.shortheader {
    padding: 0 32px;
    border-bottom: 1px solid $gray-600;
    display: flex;
    align-items: center;
    position: relative;
    min-height: 80px;
    z-index: 5;
    background: #fff;
    
    @media (max-width: $breakpoint-medium-max) {
        padding: 26px 16px;
    }
    
    &__logo-box {
        display: flex;
        align-items: center;
        margin-right: 48px;
        @media (max-width: $breakpoint-large-max) {
            margin-right: 20px;
        }
        @media (max-width: $breakpoint-xsmall-max) {
            margin: 0;
        }
    }
    
    &__holder {
        flex: 1 1 auto;
        display: flex;
        align-items: center;
        @media (max-width: $breakpoint-medium-max) {
            justify-content: flex-end;
        }
    }
    
    &__content {
        @media (min-width: $breakpoint-medium) {
            flex: 1 1 auto;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding: 23px;
        }
        @media (max-width: $breakpoint-medium-max) {
            text-align: center;
            position: fixed;
            left: 0;
            right: 0;
            bottom: 0;
            top: 81px;
            background: #fff url('../../images/decor.png') no-repeat top 0 right -858px;
            transform: translateX(-100%);
            background-size: 1008px 210px;
            transition: transform .2s;
            padding: 48px 24px 14px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            
            .shortheader--open & {
                transform: translateX(0);
            }
        }
        > button {
            margin-left: 20px;
            @media (max-width: $breakpoint-medium-max) {
                margin: 0 0 30px 0;
                max-width: 350px;
                width: 100%;
            }
        }
    }
    
    &__toggle {
        position: relative;
        padding: 0;
        border: 0;
        background: none;
        width: 24px;
        height: 16px;
        margin-right: 20px;
        outline: none;
        cursor: pointer;
        flex: 0 0 24px;
        span,
        &::before,
        &::after {
            overflow: hidden;
            text-indent: -9999px;
            position: absolute;
            top: 0;
            display: block;
            content:'';
            height: 2px;
            width: 24px;
            background: $base-color;
            transition: transform .2s, opacity .1s;
        }
        &::before {
            top: 6px;
            width: 16px;
        }
        &::after {
            top: 12px;
            width: 8px;
        }
        
        .shortheader--open & {
            span {
                opacity: 0;
            }
            &::before,
            &::after {
                top: 50%;
                border-radius: 4px;
                width: 16px;
                transform: rotate(45deg);
            }
            &::after {
                transform: rotate(-45deg);
            }
        }
    }
    
    &--open {
        @media (max-width: $breakpoint-medium-max) {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
        }
    }
    .MuiButton-label {
        white-space: nowrap;
    }
}