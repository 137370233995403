.MuiTooltip-popper {
    .MuiRating-root {
        margin-bottom: 10px;
    }
}

.tooltip {
    &__line {
        margin: 15px 0;
        border: solid $gray-500;
        border-width: 1px 0 0;
    }

    &-align{
      display: flex;
      align-items: center;
    } 
}

.MuiTooltip-tooltip {
  color: #000 !important;
  border: 1px solid #F9F9F9 !important;
  padding: 16px !important;
  font-size: 12px !important;
  max-width: 340px !important;
  min-width: 240px !important;
  box-shadow: 0 16px 20px rgba(0, 0, 0, .1) !important;
  text-align: left !important;
  border-radius: 8px !important;
  background-color: #fff !important;
}

.MuiTooltip-arrow {
  color: #fff !important;
}