//clip path generator url https://www.cssportal.com/css-clip-path-generator/

@media (min-width: $breakpoint-medium) {
    //first
    .tree-item--protection {
        -webkit-clip-path: polygon(16% 10%, 24% 10%, 32% 12%, 39% 12%, 47% 11%, 56% 6%, 63% 2%, 70% 1%, 77% 3%, 81% 6%, 83% 10%, 83% 15%, 86% 16%, 92% 19%, 96% 25%, 99% 33%, 99% 40%, 97% 48%, 93% 53%, 86% 55%, 82% 55%, 78% 53%, 78% 59%, 76% 62%, 72% 62%, 70% 62%, 70% 68%, 68% 75%, 63% 77%, 59% 78%, 54% 77%, 53% 81%, 51% 83%, 52% 88%, 51% 93%, 47% 98%, 40% 100%, 33% 99%, 25% 95%, 19% 89%, 14% 82%, 13% 77%, 8% 74%, 4% 68%, 1% 59%, 0 47%, 1% 40%, 0 31%, 2% 22%, 7% 15%);
        clip-path: polygon(16% 10%, 24% 10%, 32% 12%, 39% 12%, 47% 11%, 56% 6%, 63% 2%, 70% 1%, 77% 3%, 81% 6%, 83% 10%, 83% 15%, 86% 16%, 92% 19%, 96% 25%, 99% 33%, 99% 40%, 97% 48%, 93% 53%, 86% 55%, 82% 55%, 78% 53%, 78% 59%, 76% 62%, 72% 62%, 70% 62%, 70% 68%, 68% 75%, 63% 77%, 59% 78%, 54% 77%, 53% 81%, 51% 83%, 52% 88%, 51% 93%, 47% 98%, 40% 100%, 33% 99%, 25% 95%, 19% 89%, 14% 82%, 13% 77%, 8% 74%, 4% 68%, 1% 59%, 0 47%, 1% 40%, 0 31%, 2% 22%, 7% 15%);
    }
    .tree-sub-item--insurance {
        -webkit-clip-path: polygon(40% 5%, 49% 5%, 58% 8%, 67% 11%, 77% 18%, 84% 26%, 91% 37%, 95% 48%, 97% 60%, 97% 69%, 95% 78%, 91% 87%, 85% 92%, 78% 93%, 71% 90%, 64% 84%, 60% 78%, 58% 88%, 54% 94%, 48% 97%, 41% 94%, 35% 89%, 30% 80%, 27% 72%, 25% 80%, 21% 86%, 14% 86%, 9% 82%, 5% 73%, 3% 59%, 5% 44%, 8% 32%, 15% 20%, 22% 13%, 31% 8%);
        clip-path: polygon(40% 5%, 49% 5%, 58% 8%, 67% 11%, 77% 18%, 84% 26%, 91% 37%, 95% 48%, 97% 60%, 97% 69%, 95% 78%, 91% 87%, 85% 92%, 78% 93%, 71% 90%, 64% 84%, 60% 78%, 58% 88%, 54% 94%, 48% 97%, 41% 94%, 35% 89%, 30% 80%, 27% 72%, 25% 80%, 21% 86%, 14% 86%, 9% 82%, 5% 73%, 3% 59%, 5% 44%, 8% 32%, 15% 20%, 22% 13%, 31% 8%);
    }
    .tree-sub-item--life {
        -webkit-clip-path: polygon(2% 33%, 9% 34%, 19% 33%, 30% 28%, 41% 22%, 51% 13%, 61% 7%, 71% 5%, 79% 7%, 86% 9%, 92% 14%, 95% 21%, 97% 30%, 97% 39%, 95% 48%, 91% 55%, 85% 59%, 80% 62%, 81% 70%, 79% 79%, 75% 87%, 69% 91%, 61% 92%, 52% 89%, 44% 82%, 39% 90%, 32% 92%, 25% 88%, 18% 81%, 12% 70%, 7% 57%, 3% 43%);
        clip-path: polygon(2% 33%, 9% 34%, 19% 33%, 30% 28%, 41% 22%, 51% 13%, 61% 7%, 71% 5%, 79% 7%, 86% 9%, 92% 14%, 95% 21%, 97% 30%, 97% 39%, 95% 48%, 91% 55%, 85% 59%, 80% 62%, 81% 70%, 79% 79%, 75% 87%, 69% 91%, 61% 92%, 52% 89%, 44% 82%, 39% 90%, 32% 92%, 25% 88%, 18% 81%, 12% 70%, 7% 57%, 3% 43%);
    }
    .tree-sub-item--disability {
        -webkit-clip-path: polygon(67% 3%, 74% 5%, 81% 10%, 88% 17%, 93% 26%, 97% 36%, 99% 44%, 99% 56%, 95% 67%, 91% 73%, 84% 79%, 76% 82%, 67% 82%, 61% 79%, 58% 77%, 58% 83%, 57% 90%, 52% 95%, 46% 96%, 39% 93%, 35% 89%, 31% 84%, 29% 79%, 23% 80%, 16% 78%, 9% 73%, 4% 66%, 1% 58%, 1% 48%, 3% 41%, 7% 35%, 12% 31%, 18% 29%, 25% 29%, 33% 27%, 40% 24%, 45% 17%, 51% 10%, 56% 6%, 61% 4%);
        clip-path: polygon(67% 3%, 74% 5%, 81% 10%, 88% 17%, 93% 26%, 97% 36%, 99% 44%, 99% 56%, 95% 67%, 91% 73%, 84% 79%, 76% 82%, 67% 82%, 61% 79%, 58% 77%, 58% 83%, 57% 90%, 52% 95%, 46% 96%, 39% 93%, 35% 89%, 31% 84%, 29% 79%, 23% 80%, 16% 78%, 9% 73%, 4% 66%, 1% 58%, 1% 48%, 3% 41%, 7% 35%, 12% 31%, 18% 29%, 25% 29%, 33% 27%, 40% 24%, 45% 17%, 51% 10%, 56% 6%, 61% 4%);
    }
    .tree-sub-item--estate {
        -webkit-clip-path: polygon(24% 3%, 32% 3%, 39% 7%, 46% 14%, 52% 18%, 57% 21%, 64% 20%, 70% 17%, 76% 13%, 81% 14%, 87% 17%, 91% 22%, 94% 28%, 96% 36%, 96% 44%, 95% 54%, 91% 62%, 86% 68%, 79% 69%, 79% 76%, 76% 84%, 71% 90%, 64% 93%, 55% 93%, 47% 90%, 44% 85%, 39% 93%, 31% 96%, 23% 93%, 16% 85%, 9% 72%, 4% 55%, 3% 40%, 5% 25%, 9% 14%, 15% 7%, 19% 4%);
        clip-path: polygon(24% 3%, 32% 3%, 39% 7%, 46% 14%, 52% 18%, 57% 21%, 64% 20%, 70% 17%, 76% 13%, 81% 14%, 87% 17%, 91% 22%, 94% 28%, 96% 36%, 96% 44%, 95% 54%, 91% 62%, 86% 68%, 79% 69%, 79% 76%, 76% 84%, 71% 90%, 64% 93%, 55% 93%, 47% 90%, 44% 85%, 39% 93%, 31% 96%, 23% 93%, 16% 85%, 9% 72%, 4% 55%, 3% 40%, 5% 25%, 9% 14%, 15% 7%, 19% 4%);
    }
    .tree-sub-item--unemployment {
        -webkit-clip-path: polygon(42% 8%, 50% 8%, 61% 11%, 71% 17%, 78% 24%, 86% 34%, 92% 45%, 95% 58%, 96% 70%, 93% 81%, 86% 89%, 76% 93%, 63% 92%, 52% 88%, 47% 82%, 39% 89%, 28% 90%, 19% 85%, 11% 77%, 5% 65%, 3% 53%, 5% 39%, 10% 30%, 15% 22%, 22% 16%, 30% 11%);
        clip-path: polygon(42% 8%, 50% 8%, 61% 11%, 71% 17%, 78% 24%, 86% 34%, 92% 45%, 95% 58%, 96% 70%, 93% 81%, 86% 89%, 76% 93%, 63% 92%, 52% 88%, 47% 82%, 39% 89%, 28% 90%, 19% 85%, 11% 77%, 5% 65%, 3% 53%, 5% 39%, 10% 30%, 15% 22%, 22% 16%, 30% 11%);
    }
    .tree-sub-item--bequeath {
        -webkit-clip-path: polygon(13% 8%, 20% 6%, 26% 7%, 33% 9%, 40% 11%, 47% 12%, 56% 13%, 65% 13%, 74% 11%, 80% 11%, 86% 14%, 91% 18%, 94% 24%, 97% 31%, 97% 37%, 97% 43%, 93% 50%, 89% 54%, 91% 59%, 91% 67%, 90% 77%, 85% 86%, 77% 93%, 68% 96%, 56% 95%, 44% 91%, 32% 84%, 22% 74%, 12% 61%, 6% 46%, 3% 34%, 4% 24%, 7% 15%);
        clip-path: polygon(13% 8%, 20% 6%, 26% 7%, 33% 9%, 40% 11%, 47% 12%, 56% 13%, 65% 13%, 74% 11%, 80% 11%, 86% 14%, 91% 18%, 94% 24%, 97% 31%, 97% 37%, 97% 43%, 93% 50%, 89% 54%, 91% 59%, 91% 67%, 90% 77%, 85% 86%, 77% 93%, 68% 96%, 56% 95%, 44% 91%, 32% 84%, 22% 74%, 12% 61%, 6% 46%, 3% 34%, 4% 24%, 7% 15%);
    }
    //first end

    //second
    .tree-item--about {
        -webkit-clip-path: polygon(55% 0%, 61% 2%, 67% 4%, 72% 9%, 77% 13%, 83% 15%, 88% 16%, 93% 18%, 96% 22%, 97% 27%, 97% 31%, 95% 34%, 96% 37%, 97% 41%, 97% 46%, 97% 50%, 94% 53%, 92% 53%, 94% 57%, 97% 62%, 99% 67%, 100% 72%, 100% 78%, 98% 81%, 96% 83%, 94% 83%, 95% 88%, 94% 93%, 92% 97%, 89% 99%, 85% 99%, 82% 98%, 77% 94%, 72% 87%, 71% 92%, 68% 96%, 64% 97%, 60% 95%, 54% 89%, 53% 94%, 50% 98%, 44% 97%, 40% 93%, 37% 88%, 37% 95%, 35% 97%, 31% 97%, 28% 94%, 26% 90%, 25% 97%, 23% 99%, 18% 99%, 12% 97%, 5% 90%, 1% 80%, 0 67%, 1% 56%, 4% 48%, 8% 41%, 15% 35%, 22% 30%, 27% 21%, 31% 13%, 37% 7%, 43% 3%, 48% 0%);
        clip-path: polygon(55% 0%, 61% 2%, 67% 4%, 72% 9%, 77% 13%, 83% 15%, 88% 16%, 93% 18%, 96% 22%, 97% 27%, 97% 31%, 95% 34%, 96% 37%, 97% 41%, 97% 46%, 97% 50%, 94% 53%, 92% 53%, 94% 57%, 97% 62%, 99% 67%, 100% 72%, 100% 78%, 98% 81%, 96% 83%, 94% 83%, 95% 88%, 94% 93%, 92% 97%, 89% 99%, 85% 99%, 82% 98%, 77% 94%, 72% 87%, 71% 92%, 68% 96%, 64% 97%, 60% 95%, 54% 89%, 53% 94%, 50% 98%, 44% 97%, 40% 93%, 37% 88%, 37% 95%, 35% 97%, 31% 97%, 28% 94%, 26% 90%, 25% 97%, 23% 99%, 18% 99%, 12% 97%, 5% 90%, 1% 80%, 0 67%, 1% 56%, 4% 48%, 8% 41%, 15% 35%, 22% 30%, 27% 21%, 31% 13%, 37% 7%, 43% 3%, 48% 0%);
    }
    .tree-sub-item--risk {
        -webkit-clip-path: polygon(5% 29%, 8% 24%, 13% 18%, 16% 14%, 21% 11%, 26% 8%, 32% 7%, 37% 6%, 43% 7%, 49% 9%, 53% 12%, 57% 15%, 60% 19%, 63% 23%, 65% 26%, 68% 29%, 70% 32%, 72% 34%, 75% 36%, 78% 38%, 80% 41%, 81% 45%, 79% 49%, 77% 52%, 72% 55%, 68% 56%, 62% 56%, 57% 56%, 50% 53%, 46% 51%, 44% 52%, 43% 55%, 40% 57%, 36% 60%, 32% 62%, 28% 62%, 24% 63%, 19% 63%, 14% 62%, 10% 61%, 7% 59%, 3% 56%, 1% 52%, 0% 49%, 0% 44%, 2% 37%, 3% 33%);
        clip-path: polygon(5% 29%, 8% 24%, 13% 18%, 16% 14%, 21% 11%, 26% 8%, 32% 7%, 37% 6%, 43% 7%, 49% 9%, 53% 12%, 57% 15%, 60% 19%, 63% 23%, 65% 26%, 68% 29%, 70% 32%, 72% 34%, 75% 36%, 78% 38%, 80% 41%, 81% 45%, 79% 49%, 77% 52%, 72% 55%, 68% 56%, 62% 56%, 57% 56%, 50% 53%, 46% 51%, 44% 52%, 43% 55%, 40% 57%, 36% 60%, 32% 62%, 28% 62%, 24% 63%, 19% 63%, 14% 62%, 10% 61%, 7% 59%, 3% 56%, 1% 52%, 0% 49%, 0% 44%, 2% 37%, 3% 33%);
    }
    .tree-sub-item--companies {
        -webkit-clip-path: polygon(67% 38%, 72% 38%, 77% 39%, 80% 40%, 83% 42%, 84% 44%, 86% 45%, 88% 47%, 90% 49%, 92% 52%, 94% 55%, 97% 61%, 99% 66%, 100% 74%, 100% 83%, 96% 90%, 92% 92%, 89% 92%, 83% 91%, 79% 89%, 74% 86%, 69% 81%, 70% 86%, 68% 89%, 64% 92%, 60% 91%, 56% 90%, 52% 87%, 49% 84%, 48% 88%, 46% 91%, 43% 93%, 39% 94%, 35% 95%, 30% 94%, 26% 93%, 23% 91%, 19% 89%, 15% 86%, 12% 83%, 9% 80%, 6% 76%, 4% 72%, 3% 70%, 1% 65%, 0% 60%, 0% 53%, 0% 47%, 1% 44%, 4% 42%, 8% 42%, 12% 43%, 15% 45%, 19% 49%, 21% 52%, 24% 55%, 27% 55%, 32% 56%, 35% 54%, 38% 52%, 42% 48%, 46% 44%, 50% 42%, 54% 40%, 59% 39%);
        clip-path: polygon(67% 38%, 72% 38%, 77% 39%, 80% 40%, 83% 42%, 84% 44%, 86% 45%, 88% 47%, 90% 49%, 92% 52%, 94% 55%, 97% 61%, 99% 66%, 100% 74%, 100% 83%, 96% 90%, 92% 92%, 89% 92%, 83% 91%, 79% 89%, 74% 86%, 69% 81%, 70% 86%, 68% 89%, 64% 92%, 60% 91%, 56% 90%, 52% 87%, 49% 84%, 48% 88%, 46% 91%, 43% 93%, 39% 94%, 35% 95%, 30% 94%, 26% 93%, 23% 91%, 19% 89%, 15% 86%, 12% 83%, 9% 80%, 6% 76%, 4% 72%, 3% 70%, 1% 65%, 0% 60%, 0% 53%, 0% 47%, 1% 44%, 4% 42%, 8% 42%, 12% 43%, 15% 45%, 19% 49%, 21% 52%, 24% 55%, 27% 55%, 32% 56%, 35% 54%, 38% 52%, 42% 48%, 46% 44%, 50% 42%, 54% 40%, 59% 39%);
    }
    .tree-sub-item--values {
        -webkit-clip-path: polygon(26% 2%, 29% 6%, 33% 10%, 37% 14%, 45% 18%, 52% 21%, 61% 23%, 70% 23%, 75% 23%, 81% 25%, 86% 29%, 90% 33%, 94% 38%, 96% 45%, 97% 53%, 95% 60%, 92% 64%, 87% 66%, 84% 66%, 81% 64%, 82% 71%, 82% 78%, 82% 85%, 80% 91%, 76% 95%, 70% 97%, 64% 96%, 58% 93%, 52% 88%, 47% 82%, 43% 75%, 40% 68%, 37% 72%, 32% 75%, 26% 75%, 19% 72%, 12% 66%, 7% 58%, 3% 49%, 3% 39%, 5% 32%, 11% 27%, 19% 16%);
        clip-path: polygon(26% 2%, 29% 6%, 33% 10%, 37% 14%, 45% 18%, 52% 21%, 61% 23%, 70% 23%, 75% 23%, 81% 25%, 86% 29%, 90% 33%, 94% 38%, 96% 45%, 97% 53%, 95% 60%, 92% 64%, 87% 66%, 84% 66%, 81% 64%, 82% 71%, 82% 78%, 82% 85%, 80% 91%, 76% 95%, 70% 97%, 64% 96%, 58% 93%, 52% 88%, 47% 82%, 43% 75%, 40% 68%, 37% 72%, 32% 75%, 26% 75%, 19% 72%, 12% 66%, 7% 58%, 3% 49%, 3% 39%, 5% 32%, 11% 27%, 19% 16%);
    }
    .tree-sub-item--education {
        -webkit-clip-path: polygon(48% 1%, 58% 3%, 67% 6%, 76% 12%, 82% 18%, 88% 26%, 93% 36%, 96% 45%, 97% 54%, 96% 62%, 94% 67%, 90% 69%, 87% 70%, 87% 78%, 86% 85%, 84% 92%, 79% 98%, 75% 99%, 69% 99%, 62% 96%, 54% 90%, 48% 83%, 44% 75%, 42% 81%, 40% 88%, 35% 92%, 27% 93%, 20% 90%, 13% 84%, 7% 75%, 4% 62%, 3% 53%, 4% 42%, 6% 33%, 9% 23%, 15% 16%, 23% 9%, 31% 4%, 40% 2%);
        clip-path: polygon(48% 1%, 58% 3%, 67% 6%, 76% 12%, 82% 18%, 88% 26%, 93% 36%, 96% 45%, 97% 54%, 96% 62%, 94% 67%, 90% 69%, 87% 70%, 87% 78%, 86% 85%, 84% 92%, 79% 98%, 75% 99%, 69% 99%, 62% 96%, 54% 90%, 48% 83%, 44% 75%, 42% 81%, 40% 88%, 35% 92%, 27% 93%, 20% 90%, 13% 84%, 7% 75%, 4% 62%, 3% 53%, 4% 42%, 6% 33%, 9% 23%, 15% 16%, 23% 9%, 31% 4%, 40% 2%);
    }
    .tree-sub-item--profile {
        -webkit-clip-path: polygon(52% 2%, 63% 3%, 72% 6%, 81% 11%, 90% 18%, 95% 26%, 97% 35%, 96% 44%, 93% 49%, 86% 52%, 78% 52%, 83% 60%, 84% 71%, 83% 81%, 79% 89%, 71% 95%, 63% 95%, 55% 92%, 49% 86%, 45% 79%, 41% 87%, 35% 90%, 28% 89%, 20% 85%, 13% 77%, 6% 65%, 2% 54%, 1% 48%, 6% 37%, 12% 28%, 19% 19%, 26% 12%, 35% 7%, 43% 4%);
        clip-path: polygon(52% 2%, 63% 3%, 72% 6%, 81% 11%, 90% 18%, 95% 26%, 97% 35%, 96% 44%, 93% 49%, 86% 52%, 78% 52%, 83% 60%, 84% 71%, 83% 81%, 79% 89%, 71% 95%, 63% 95%, 55% 92%, 49% 86%, 45% 79%, 41% 87%, 35% 90%, 28% 89%, 20% 85%, 13% 77%, 6% 65%, 2% 54%, 1% 48%, 6% 37%, 12% 28%, 19% 19%, 26% 12%, 35% 7%, 43% 4%);
    }
    .tree-sub-item--children {
        -webkit-clip-path: polygon(23% 6%, 30% 6%, 37% 10%, 46% 15%, 55% 18%, 64% 20%, 73% 21%, 79% 22%, 85% 25%, 91% 31%, 94% 37%, 96% 44%, 95% 50%, 93% 54%, 90% 56%, 93% 61%, 96% 67%, 96% 75%, 95% 83%, 90% 88%, 83% 90%, 75% 90%, 68% 88%, 59% 83%, 57% 87%, 55% 92%, 50% 95%, 43% 95%, 34% 92%, 25% 86%, 17% 78%, 11% 69%, 7% 59%, 4% 48%, 3% 39%, 4% 29%, 6% 20%, 10% 13%, 16% 8%);
        clip-path: polygon(23% 6%, 30% 6%, 37% 10%, 46% 15%, 55% 18%, 64% 20%, 73% 21%, 79% 22%, 85% 25%, 91% 31%, 94% 37%, 96% 44%, 95% 50%, 93% 54%, 90% 56%, 93% 61%, 96% 67%, 96% 75%, 95% 83%, 90% 88%, 83% 90%, 75% 90%, 68% 88%, 59% 83%, 57% 87%, 55% 92%, 50% 95%, 43% 95%, 34% 92%, 25% 86%, 17% 78%, 11% 69%, 7% 59%, 4% 48%, 3% 39%, 4% 29%, 6% 20%, 10% 13%, 16% 8%);
    }
    /*.tree-sub-item--profile {
        -webkit-clip-path: polygon(48% 5%, 56% 5%, 67% 7%, 75% 10%, 84% 15%, 90% 21%, 95% 28%, 98% 35%, 98% 41%, 97% 46%, 94% 51%, 88% 54%, 83% 55%, 79% 53%, 82% 58%, 84% 65%, 84% 75%, 83% 83%, 79% 89%, 75% 94%, 68% 96%, 62% 95%, 56% 92%, 50% 87%, 46% 80%, 43% 86%, 38% 90%, 32% 90%, 26% 89%, 18% 82%, 11% 74%, 7% 66%, 4% 57%, 2% 50%, 5% 42%, 9% 35%, 14% 28%, 19% 22%, 24% 17%, 32% 12%, 39% 7%); clip-path: polygon(48% 5%, 56% 5%, 67% 7%, 75% 10%, 84% 15%, 90% 21%, 95% 28%, 98% 35%, 98% 41%, 97% 46%, 94% 51%, 88% 54%, 83% 55%, 79% 53%, 82% 58%, 84% 65%, 84% 75%, 83% 83%, 79% 89%, 75% 94%, 68% 96%, 62% 95%, 56% 92%, 50% 87%, 46% 80%, 43% 86%, 38% 90%, 32% 90%, 26% 89%, 18% 82%, 11% 74%, 7% 66%, 4% 57%, 2% 50%, 5% 42%, 9% 35%, 14% 28%, 19% 22%, 24% 17%, 32% 12%, 39% 7%);
    }*/
    //second end

    //third
    .tree-item--future {
        -webkit-clip-path: polygon(18% 2%, 24% 0%, 31% 0%, 38% 2%, 44% 5%, 48% 11%, 52% 16%, 53% 21%, 53% 24%, 58% 25%, 64% 25%, 67% 26%, 70% 28%, 71% 31%, 71% 35%, 76% 37%, 81% 38%, 86% 40%, 89% 42%, 91% 46%, 92% 50%, 91% 54%, 93% 58%, 96% 63%, 99% 70%, 100% 78%, 99% 84%, 97% 88%, 93% 89%, 93% 93%, 91% 98%, 85% 100%, 80% 98%, 76% 94%, 73% 88%, 72% 83%, 70% 86%, 67% 90%, 62% 90%, 56% 87%, 52% 82%, 48% 73%, 47% 77%, 43% 79%, 38% 78%, 34% 73%, 30% 67%, 27% 61%, 26% 56%, 25% 62%, 23% 66%, 20% 67%, 16% 67%, 11% 64%, 6% 58%, 3% 51%, 1% 42%, 0% 32%, 2% 22%, 5% 14%, 9% 9%, 13% 5%);
        clip-path: polygon(18% 2%, 24% 0%, 31% 0%, 38% 2%, 44% 5%, 48% 11%, 52% 16%, 53% 21%, 53% 24%, 58% 25%, 64% 25%, 67% 26%, 70% 28%, 71% 31%, 71% 35%, 76% 37%, 81% 38%, 86% 40%, 89% 42%, 91% 46%, 92% 50%, 91% 54%, 93% 58%, 96% 63%, 99% 70%, 100% 78%, 99% 84%, 97% 88%, 93% 89%, 93% 93%, 91% 98%, 85% 100%, 80% 98%, 76% 94%, 73% 88%, 72% 83%, 70% 86%, 67% 90%, 62% 90%, 56% 87%, 52% 82%, 48% 73%, 47% 77%, 43% 79%, 38% 78%, 34% 73%, 30% 67%, 27% 61%, 26% 56%, 25% 62%, 23% 66%, 20% 67%, 16% 67%, 11% 64%, 6% 58%, 3% 51%, 1% 42%, 0% 32%, 2% 22%, 5% 14%, 9% 9%, 13% 5%);
    }
    .tree-sub-item--retirement {
        -webkit-clip-path: polygon(49% 7%, 55% 7%, 63% 9%, 71% 12%, 77% 18%, 83% 25%, 88% 34%, 92% 44%, 96% 57%, 97% 66%, 95% 73%, 93% 78%, 88% 78%, 82% 76%, 79% 71%, 77% 67%, 75% 81%, 71% 89%, 66% 94%, 61% 96%, 55% 95%, 49% 91%, 44% 84%, 42% 76%, 39% 81%, 36% 84%, 31% 84%, 27% 81%, 22% 75%, 18% 74%, 12% 73%, 7% 74%, 2% 78%, 4% 67%, 7% 55%, 11% 44%, 15% 35%, 20% 26%, 26% 19%, 33% 13%, 41% 9%);
        clip-path: polygon(49% 7%, 55% 7%, 63% 9%, 71% 12%, 77% 18%, 83% 25%, 88% 34%, 92% 44%, 96% 57%, 97% 66%, 95% 73%, 93% 78%, 88% 78%, 82% 76%, 79% 71%, 77% 67%, 75% 81%, 71% 89%, 66% 94%, 61% 96%, 55% 95%, 49% 91%, 44% 84%, 42% 76%, 39% 81%, 36% 84%, 31% 84%, 27% 81%, 22% 75%, 18% 74%, 12% 73%, 7% 74%, 2% 78%, 4% 67%, 7% 55%, 11% 44%, 15% 35%, 20% 26%, 26% 19%, 33% 13%, 41% 9%);
    }
    .tree-sub-item--objectives {
        -webkit-clip-path: polygon(18% 7%, 25% 7%, 32% 10%, 38% 14%, 45% 17%, 53% 21%, 61% 23%, 71% 24%, 78% 24%, 83% 25%, 86% 28%, 89% 33%, 89% 38%, 89% 45%, 87% 49%, 84% 53%, 89% 54%, 92% 57%, 95% 61%, 97% 66%, 97% 73%, 97% 79%, 94% 86%, 89% 92%, 81% 96%, 72% 96%, 63% 93%, 54% 87%, 47% 81%, 41% 75%, 39% 81%, 33% 84%, 25% 83%, 17% 77%, 10% 66%, 5% 53%, 3% 42%, 3% 29%, 4% 22%, 7% 15%, 12% 10%);
        clip-path: polygon(18% 7%, 25% 7%, 32% 10%, 38% 14%, 45% 17%, 53% 21%, 61% 23%, 71% 24%, 78% 24%, 83% 25%, 86% 28%, 89% 33%, 89% 38%, 89% 45%, 87% 49%, 84% 53%, 89% 54%, 92% 57%, 95% 61%, 97% 66%, 97% 73%, 97% 79%, 94% 86%, 89% 92%, 81% 96%, 72% 96%, 63% 93%, 54% 87%, 47% 81%, 41% 75%, 39% 81%, 33% 84%, 25% 83%, 17% 77%, 10% 66%, 5% 53%, 3% 42%, 3% 29%, 4% 22%, 7% 15%, 12% 10%);
    }
    .tree-sub-item--sabbatical {
        -webkit-clip-path: polygon(16% 3%, 24% 2%, 32% 4%, 40% 5%, 50% 5%, 59% 7%, 68% 9%, 74% 12%, 78% 18%, 80% 23%, 80% 27%, 77% 31%, 83% 36%, 88% 41%, 92% 47%, 96% 55%, 98% 63%, 97% 70%, 94% 77%, 89% 80%, 83% 81%, 83% 87%, 78% 93%, 70% 98%, 61% 98%, 52% 96%, 43% 90%, 35% 81%, 32% 73%, 31% 65%, 31% 56%, 28% 49%, 23% 42%, 16% 35%, 9% 29%, 3% 23%, 2% 17%, 3% 13%, 7% 8%, 11% 5%);
        clip-path: polygon(16% 3%, 24% 2%, 32% 4%, 40% 5%, 50% 5%, 59% 7%, 68% 9%, 74% 12%, 78% 18%, 80% 23%, 80% 27%, 77% 31%, 83% 36%, 88% 41%, 92% 47%, 96% 55%, 98% 63%, 97% 70%, 94% 77%, 89% 80%, 83% 81%, 83% 87%, 78% 93%, 70% 98%, 61% 98%, 52% 96%, 43% 90%, 35% 81%, 32% 73%, 31% 65%, 31% 56%, 28% 49%, 23% 42%, 16% 35%, 9% 29%, 3% 23%, 2% 17%, 3% 13%, 7% 8%, 11% 5%);
    }
    .tree-sub-item--future-education {
        -webkit-clip-path: polygon(19% 2%, 25% 2%, 32% 6%, 40% 9%, 48% 13%, 57% 16%, 66% 19%, 73% 22%, 80% 28%, 86% 35%, 91% 44%, 95% 53%, 98% 64%, 98% 76%, 96% 88%, 90% 95%, 81% 99%, 71% 97%, 62% 89%, 58% 84%, 53% 76%, 49% 66%, 46% 73%, 42% 77%, 34% 77%, 28% 73%, 20% 67%, 13% 56%, 7% 45%, 3% 34%, 2% 22%, 4% 14%, 8% 8%, 12% 4%);
        clip-path: polygon(19% 2%, 25% 2%, 32% 6%, 40% 9%, 48% 13%, 57% 16%, 66% 19%, 73% 22%, 80% 28%, 86% 35%, 91% 44%, 95% 53%, 98% 64%, 98% 76%, 96% 88%, 90% 95%, 81% 99%, 71% 97%, 62% 89%, 58% 84%, 53% 76%, 49% 66%, 46% 73%, 42% 77%, 34% 77%, 28% 73%, 20% 67%, 13% 56%, 7% 45%, 3% 34%, 2% 22%, 4% 14%, 8% 8%, 12% 4%);
    }
    .tree-sub-item--future-children {
        -webkit-clip-path: polygon(6% 8%, 16% 5%, 26% 3%, 34% 3%, 44% 3%, 51% 4%, 59% 6%, 67% 8%, 76% 13%, 82% 17%, 88% 23%, 91% 30%, 92% 36%, 91% 40%, 89% 43%, 86% 44%, 90% 47%, 92% 53%, 94% 60%, 93% 67%, 91% 72%, 86% 76%, 80% 76%, 75% 75%, 69% 72%, 69% 80%, 67% 88%, 63% 93%, 58% 95%, 51% 97%, 43% 94%, 34% 90%, 26% 83%, 18% 75%, 12% 65%, 8% 56%, 5% 46%, 4% 36%, 3% 26%, 4% 17%);
        clip-path: polygon(6% 8%, 16% 5%, 26% 3%, 34% 3%, 44% 3%, 51% 4%, 59% 6%, 67% 8%, 76% 13%, 82% 17%, 88% 23%, 91% 30%, 92% 36%, 91% 40%, 89% 43%, 86% 44%, 90% 47%, 92% 53%, 94% 60%, 93% 67%, 91% 72%, 86% 76%, 80% 76%, 75% 75%, 69% 72%, 69% 80%, 67% 88%, 63% 93%, 58% 95%, 51% 97%, 43% 94%, 34% 90%, 26% 83%, 18% 75%, 12% 65%, 8% 56%, 5% 46%, 4% 36%, 3% 26%, 4% 17%);
    }
    //third end

    //fourth
    .tree-item--finance {
        -webkit-clip-path: polygon(98% 62%, 100% 69%, 98% 76%, 91% 78%, 90% 84%, 84% 89%, 80% 89%, 75% 87%, 74% 94%, 71% 98%, 66% 100%, 59% 96%, 56% 92%, 54% 87%, 52% 91%, 46% 92%, 42% 90%, 39% 86%, 35% 79%, 32% 72%, 30% 62%, 26% 67%, 18% 69%, 11% 67%, 5% 60%, 2% 51%, 0 40%, 1% 31%, 2% 26%, 4% 20%, 7% 14%, 13% 7%, 20% 3%, 28% 1%, 35% 1%, 41% 3%, 45% 6%, 50% 13%, 50% 18%, 50% 22%, 55% 25%, 59% 27%, 62% 28%, 67% 28%, 72% 28%, 75% 34%, 78% 36%, 83% 38%, 87% 40%, 91% 44%, 94% 50%, 95% 55%);
        clip-path: polygon(98% 62%, 100% 69%, 98% 76%, 91% 78%, 90% 84%, 84% 89%, 80% 89%, 75% 87%, 74% 94%, 71% 98%, 66% 100%, 59% 96%, 56% 92%, 54% 87%, 52% 91%, 46% 92%, 42% 90%, 39% 86%, 35% 79%, 32% 72%, 30% 62%, 26% 67%, 18% 69%, 11% 67%, 5% 60%, 2% 51%, 0 40%, 1% 31%, 2% 26%, 4% 20%, 7% 14%, 13% 7%, 20% 3%, 28% 1%, 35% 1%, 41% 3%, 45% 6%, 50% 13%, 50% 18%, 50% 22%, 55% 25%, 59% 27%, 62% 28%, 67% 28%, 72% 28%, 75% 34%, 78% 36%, 83% 38%, 87% 40%, 91% 44%, 94% 50%, 95% 55%);
    }
    .tree-sub-item--expenses {
        -webkit-clip-path: polygon(31% 2%, 26% 6%, 19% 12%, 14% 19%, 9% 27%, 6% 36%, 4% 44%, 4% 52%, 5% 60%, 7% 69%, 10% 76%, 15% 84%, 21% 90%, 27% 94%, 34% 97%, 41% 98%, 49% 98%, 56% 96%, 61% 92%, 65% 88%, 68% 85%, 73% 86%, 78% 85%, 83% 82%, 87% 78%, 88% 74%, 88% 69%, 87% 65%, 85% 63%, 89% 62%, 92% 60%, 94% 56%, 97% 51%, 97% 47%, 96% 42%, 93% 37%, 89% 33%, 84% 30%, 77% 28%, 71% 28%, 64% 28%, 57% 26%, 50% 24%, 44% 21%, 39% 16%, 34% 10%);
        clip-path: polygon(31% 2%, 26% 6%, 19% 12%, 14% 19%, 9% 27%, 6% 36%, 4% 44%, 4% 52%, 5% 60%, 7% 69%, 10% 76%, 15% 84%, 21% 90%, 27% 94%, 34% 97%, 41% 98%, 49% 98%, 56% 96%, 61% 92%, 65% 88%, 68% 85%, 73% 86%, 78% 85%, 83% 82%, 87% 78%, 88% 74%, 88% 69%, 87% 65%, 85% 63%, 89% 62%, 92% 60%, 94% 56%, 97% 51%, 97% 47%, 96% 42%, 93% 37%, 89% 33%, 84% 30%, 77% 28%, 71% 28%, 64% 28%, 57% 26%, 50% 24%, 44% 21%, 39% 16%, 34% 10%);
    }
    .tree-sub-item--income {
        -webkit-clip-path: polygon(39% 4%, 48% 4%, 56% 5%, 64% 6%, 72% 10%, 80% 16%, 87% 22%, 93% 32%, 97% 43%, 98% 52%, 97% 60%, 95% 64%, 91% 66%, 89% 74%, 86% 84%, 80% 92%, 72% 97%, 65% 98%, 56% 94%, 50% 87%, 44% 79%, 38% 85%, 32% 87%, 24% 83%, 17% 75%, 11% 64%, 6% 51%, 3% 38%, 3% 27%, 7% 22%, 13% 17%, 20% 11%, 28% 8%);
        clip-path: polygon(39% 4%, 48% 4%, 56% 5%, 64% 6%, 72% 10%, 80% 16%, 87% 22%, 93% 32%, 97% 43%, 98% 52%, 97% 60%, 95% 64%, 91% 66%, 89% 74%, 86% 84%, 80% 92%, 72% 97%, 65% 98%, 56% 94%, 50% 87%, 44% 79%, 38% 85%, 32% 87%, 24% 83%, 17% 75%, 11% 64%, 6% 51%, 3% 38%, 3% 27%, 7% 22%, 13% 17%, 20% 11%, 28% 8%);
    }
    .tree-sub-item--worth {
        -webkit-clip-path: polygon(19% 3%, 25% 3%, 31% 6%, 36% 9%, 43% 14%, 49% 18%, 56% 21%, 63% 23%, 70% 23%, 77% 23%, 85% 23%, 91% 26%, 95% 30%, 97% 38%, 97% 46%, 95% 53%, 90% 58%, 84% 60%, 80% 60%, 81% 68%, 79% 77%, 75% 87%, 69% 93%, 60% 95%, 51% 94%, 42% 90%, 35% 84%, 29% 76%, 25% 68%, 20% 70%, 14% 70%, 8% 65%, 5% 56%, 2% 45%, 2% 33%, 4% 21%, 7% 13%, 12% 6%);
        clip-path: polygon(19% 3%, 25% 3%, 31% 6%, 36% 9%, 43% 14%, 49% 18%, 56% 21%, 63% 23%, 70% 23%, 77% 23%, 85% 23%, 91% 26%, 95% 30%, 97% 38%, 97% 46%, 95% 53%, 90% 58%, 84% 60%, 80% 60%, 81% 68%, 79% 77%, 75% 87%, 69% 93%, 60% 95%, 51% 94%, 42% 90%, 35% 84%, 29% 76%, 25% 68%, 20% 70%, 14% 70%, 8% 65%, 5% 56%, 2% 45%, 2% 33%, 4% 21%, 7% 13%, 12% 6%);
    }
    .tree-sub-item--stocks {
        -webkit-clip-path: polygon(19% 6%, 26% 4%, 35% 4%, 43% 5%, 51% 7%, 58% 10%, 67% 15%, 76% 21%, 84% 27%, 90% 34%, 95% 41%, 97% 49%, 98% 54%, 96% 60%, 92% 64%, 87% 65%, 82% 63%, 84% 70%, 85% 76%, 84% 84%, 80% 90%, 75% 95%, 68% 97%, 60% 97%, 53% 95%, 45% 90%, 38% 84%, 31% 76%, 28% 81%, 23% 82%, 17% 79%, 12% 72%, 8% 64%, 5% 55%, 3% 45%, 3% 35%, 4% 25%, 7% 18%, 12% 11%);
        clip-path: polygon(19% 6%, 26% 4%, 35% 4%, 43% 5%, 51% 7%, 58% 10%, 67% 15%, 76% 21%, 84% 27%, 90% 34%, 95% 41%, 97% 49%, 98% 54%, 96% 60%, 92% 64%, 87% 65%, 82% 63%, 84% 70%, 85% 76%, 84% 84%, 80% 90%, 75% 95%, 68% 97%, 60% 97%, 53% 95%, 45% 90%, 38% 84%, 31% 76%, 28% 81%, 23% 82%, 17% 79%, 12% 72%, 8% 64%, 5% 55%, 3% 45%, 3% 35%, 4% 25%, 7% 18%, 12% 11%);
    }
    .tree-sub-item--incremental-income {
        -webkit-clip-path: polygon(7% 13%, 11% 10%, 14% 7%, 17% 5%, 22% 3%, 26% 2%, 31% 1%, 36% 1%, 41% 2%, 47% 3%, 51% 4%, 58% 6%, 64% 9%, 68% 11%, 73% 14%, 77% 17%, 80% 20%, 83% 23%, 86% 27%, 88% 30%, 89% 33%, 89% 37%, 88% 40%, 90% 42%, 92% 45%, 94% 47%, 96% 50%, 98% 55%, 99% 60%, 99% 64%, 98% 69%, 96% 73%, 93% 76%, 90% 77%, 86% 78%, 82% 78%, 79% 78%, 79% 82%, 78% 85%, 76% 89%, 73% 93%, 70% 95%, 66% 97%, 61% 98%, 56% 98%, 50% 96%, 45% 94%, 42% 91%, 40% 90%, 37% 91%, 32% 92%, 27% 91%, 22% 88%, 19% 86%, 15% 81%, 12% 75%, 9% 70%, 7% 64%, 5% 59%, 3% 53%, 2% 47%, 1% 41%, 1% 34%, 2% 27%, 3% 22%, 5% 17%);
        clip-path: polygon(7% 13%, 11% 10%, 14% 7%, 17% 5%, 22% 3%, 26% 2%, 31% 1%, 36% 1%, 41% 2%, 47% 3%, 51% 4%, 58% 6%, 64% 9%, 68% 11%, 73% 14%, 77% 17%, 80% 20%, 83% 23%, 86% 27%, 88% 30%, 89% 33%, 89% 37%, 88% 40%, 90% 42%, 92% 45%, 94% 47%, 96% 50%, 98% 55%, 99% 60%, 99% 64%, 98% 69%, 96% 73%, 93% 76%, 90% 77%, 86% 78%, 82% 78%, 79% 78%, 79% 82%, 78% 85%, 76% 89%, 73% 93%, 70% 95%, 66% 97%, 61% 98%, 56% 98%, 50% 96%, 45% 94%, 42% 91%, 40% 90%, 37% 91%, 32% 92%, 27% 91%, 22% 88%, 19% 86%, 15% 81%, 12% 75%, 9% 70%, 7% 64%, 5% 59%, 3% 53%, 2% 47%, 1% 41%, 1% 34%, 2% 27%, 3% 22%, 5% 17%);
    }
    .tree-sub-item--assets {
        -webkit-clip-path: polygon(14% 6%, 21% 4%, 27% 4%, 34% 5%, 39% 8%, 44% 12%, 49% 15%, 56% 18%, 63% 21%, 71% 22%, 78% 25%, 85% 29%, 90% 33%, 95% 39%, 97% 45%, 99% 51%, 98% 57%, 95% 62%, 92% 65%, 94% 70%, 96% 77%, 95% 85%, 92% 91%, 87% 95%, 81% 97%, 74% 96%, 66% 93%, 60% 89%, 56% 83%, 53% 79%, 53% 79%, 51% 74%, 50% 79%, 46% 83%, 41% 85%, 35% 84%, 27% 81%, 20% 74%, 14% 66%, 9% 57%, 6% 48%, 3% 39%, 2% 29%, 3% 20%, 5% 14%, 9% 9%);
        clip-path: polygon(14% 6%, 21% 4%, 27% 4%, 34% 5%, 39% 8%, 44% 12%, 49% 15%, 56% 18%, 63% 21%, 71% 22%, 78% 25%, 85% 29%, 90% 33%, 95% 39%, 97% 45%, 99% 51%, 98% 57%, 95% 62%, 92% 65%, 94% 70%, 96% 77%, 95% 85%, 92% 91%, 87% 95%, 81% 97%, 74% 96%, 66% 93%, 60% 89%, 56% 83%, 53% 79%, 53% 79%, 51% 74%, 50% 79%, 46% 83%, 41% 85%, 35% 84%, 27% 81%, 20% 74%, 14% 66%, 9% 57%, 6% 48%, 3% 39%, 2% 29%, 3% 20%, 5% 14%, 9% 9%);
    }
    //fourth end


    //sub-items
    /*.tree__title--insurance {
        -webkit-clip-path: polygon(17% 11%, 20% 8%, 23% 6%, 27% 4%, 31% 2%, 34% 1%, 38% 0%, 42% 0%, 46% 0%, 50% 1%, 54% 2%, 59% 4%, 62% 6%, 66% 9%, 70% 12%, 73% 16%, 76% 20%, 80% 24%, 83% 29%, 85% 33%, 88% 39%, 90% 44%, 91% 49%, 91% 54%, 91% 60%, 90% 66%, 88% 70%, 86% 75%, 84% 78%, 81% 80%, 77% 81%, 73% 81%, 69% 80%, 65% 78%, 61% 74%, 58% 69%, 55% 65%, 55% 69%, 54% 74%, 52% 79%, 50% 83%, 48% 85%, 44% 85%, 40% 84%, 37% 82%, 34% 79%, 30% 74%, 28% 69%, 26% 65%, 24% 60%, 24% 65%, 23% 69%, 20% 73%, 17% 76%, 13% 76%, 9% 74%, 6% 69%, 4% 64%, 2% 58%, 2% 50%, 2% 43%, 3% 37%, 4% 31%, 7% 25%, 10% 19%, 13% 14%);
        clip-path: polygon(17% 11%, 20% 8%, 23% 6%, 27% 4%, 31% 2%, 34% 1%, 38% 0%, 42% 0%, 46% 0%, 50% 1%, 54% 2%, 59% 4%, 62% 6%, 66% 9%, 70% 12%, 73% 16%, 76% 20%, 80% 24%, 83% 29%, 85% 33%, 88% 39%, 90% 44%, 91% 49%, 91% 54%, 91% 60%, 90% 66%, 88% 70%, 86% 75%, 84% 78%, 81% 80%, 77% 81%, 73% 81%, 69% 80%, 65% 78%, 61% 74%, 58% 69%, 55% 65%, 55% 69%, 54% 74%, 52% 79%, 50% 83%, 48% 85%, 44% 85%, 40% 84%, 37% 82%, 34% 79%, 30% 74%, 28% 69%, 26% 65%, 24% 60%, 24% 65%, 23% 69%, 20% 73%, 17% 76%, 13% 76%, 9% 74%, 6% 69%, 4% 64%, 2% 58%, 2% 50%, 2% 43%, 3% 37%, 4% 31%, 7% 25%, 10% 19%, 13% 14%);
    }*/
    .tree-sub-item--home {
        -webkit-clip-path: polygon(0% 27%, 5% 29%, 11% 29%, 17% 28%, 23% 27%, 29% 25%, 36% 22%, 41% 18%, 45% 14%, 48% 11%, 52% 8%, 56% 6%, 60% 3%, 64% 1%, 71% 0%, 77% 0%, 81% 0%, 85% 1%, 88% 4%, 92% 7%, 95% 11%, 97% 15%, 99% 21%, 100% 26%, 100% 30%, 100% 35%, 99% 40%, 97% 44%, 95% 49%, 93% 53%, 90% 55%, 86% 58%, 83% 59%, 84% 64%, 85% 70%, 84% 76%, 82% 83%, 79% 89%, 75% 93%, 70% 95%, 65% 96%, 60% 95%, 56% 93%, 53% 92%, 51% 90%, 50% 95%, 47% 98%, 44% 100%, 41% 100%, 38% 100%, 34% 98%, 30% 95%, 26% 92%, 22% 87%, 18% 81%, 14% 73%, 10% 66%, 8% 59%, 5% 52%, 3% 43%, 1% 37%);
        clip-path: polygon(0% 27%, 5% 29%, 11% 29%, 17% 28%, 23% 27%, 29% 25%, 36% 22%, 41% 18%, 45% 14%, 48% 11%, 52% 8%, 56% 6%, 60% 3%, 64% 1%, 71% 0%, 77% 0%, 81% 0%, 85% 1%, 88% 4%, 92% 7%, 95% 11%, 97% 15%, 99% 21%, 100% 26%, 100% 30%, 100% 35%, 99% 40%, 97% 44%, 95% 49%, 93% 53%, 90% 55%, 86% 58%, 83% 59%, 84% 64%, 85% 70%, 84% 76%, 82% 83%, 79% 89%, 75% 93%, 70% 95%, 65% 96%, 60% 95%, 56% 93%, 53% 92%, 51% 90%, 50% 95%, 47% 98%, 44% 100%, 41% 100%, 38% 100%, 34% 98%, 30% 95%, 26% 92%, 22% 87%, 18% 81%, 14% 73%, 10% 66%, 8% 59%, 5% 52%, 3% 43%, 1% 37%);
    }
    .tree-sub-item--health {
        -webkit-clip-path: polygon(45% 2%, 54% 2%, 64% 5%, 73% 10%, 81% 16%, 88% 23%, 93% 30%, 96% 37%, 95% 45%, 93% 51%, 95% 55%, 98% 62%, 99% 72%, 96% 77%, 90% 80%, 84% 79%, 79% 77%, 78% 84%, 74% 90%, 70% 93%, 62% 94%, 54% 93%, 48% 91%, 40% 86%, 38% 92%, 35% 97%, 30% 99%, 24% 99%, 16% 97%, 10% 90%, 6% 82%, 3% 74%, 1% 65%, 2% 60%, 2% 53%, 3% 47%, 3% 39%, 3% 29%, 6% 22%, 9% 15%, 16% 10%, 24% 6%, 33% 2%);
        clip-path: polygon(45% 2%, 54% 2%, 64% 5%, 73% 10%, 81% 16%, 88% 23%, 93% 30%, 96% 37%, 95% 45%, 93% 51%, 95% 55%, 98% 62%, 99% 72%, 96% 77%, 90% 80%, 84% 79%, 79% 77%, 78% 84%, 74% 90%, 70% 93%, 62% 94%, 54% 93%, 48% 91%, 40% 86%, 38% 92%, 35% 97%, 30% 99%, 24% 99%, 16% 97%, 10% 90%, 6% 82%, 3% 74%, 1% 65%, 2% 60%, 2% 53%, 3% 47%, 3% 39%, 3% 29%, 6% 22%, 9% 15%, 16% 10%, 24% 6%, 33% 2%);
    }
    .tree-sub-item--auto {
        -webkit-clip-path: polygon(3% 38%, 5% 33%, 10% 29%, 13% 28%, 18% 28%, 22% 29%, 27% 30%, 32% 29%, 38% 26%, 43% 22%, 49% 17%, 53% 11%, 59% 7%, 64% 3%, 69% 0%, 74% 0%, 79% 0%, 85% 2%, 88% 4%, 93% 9%, 96% 14%, 99% 20%, 100% 27%, 100% 33%, 100% 39%, 98% 46%, 95% 52%, 90% 55%, 85% 58%, 80% 57%, 76% 56%, 72% 55%, 72% 61%, 69% 65%, 65% 68%, 67% 71%, 67% 77%, 66% 83%, 65% 88%, 61% 94%, 57% 97%, 53% 99%, 47% 100%, 44% 100%, 39% 100%, 33% 98%, 27% 95%, 21% 91%, 16% 86%, 10% 79%, 5% 72%, 2% 63%, 0% 55%, 0% 50%, 0% 44%);
        clip-path: polygon(3% 38%, 5% 33%, 10% 29%, 13% 28%, 18% 28%, 22% 29%, 27% 30%, 32% 29%, 38% 26%, 43% 22%, 49% 17%, 53% 11%, 59% 7%, 64% 3%, 69% 0%, 74% 0%, 79% 0%, 85% 2%, 88% 4%, 93% 9%, 96% 14%, 99% 20%, 100% 27%, 100% 33%, 100% 39%, 98% 46%, 95% 52%, 90% 55%, 85% 58%, 80% 57%, 76% 56%, 72% 55%, 72% 61%, 69% 65%, 65% 68%, 67% 71%, 67% 77%, 66% 83%, 65% 88%, 61% 94%, 57% 97%, 53% 99%, 47% 100%, 44% 100%, 39% 100%, 33% 98%, 27% 95%, 21% 91%, 16% 86%, 10% 79%, 5% 72%, 2% 63%, 0% 55%, 0% 50%, 0% 44%);
    }
    .tree-sub-item--umbrella {
        -webkit-clip-path: polygon(1% 53%, 2% 49%, 6% 45%, 9% 43%, 13% 42%, 17% 41%, 21% 39%, 25% 37%, 29% 34%, 32% 30%, 36% 26%, 40% 20%, 43% 15%, 47% 11%, 51% 7%, 57% 4%, 62% 1%, 67% 0%, 72% 0%, 76% 0%, 80% 3%, 84% 6%, 88% 11%, 92% 17%, 95% 23%, 98% 31%, 99% 38%, 100% 45%, 100% 52%, 99% 60%, 97% 68%, 94% 75%, 89% 80%, 84% 83%, 79% 85%, 73% 86%, 67% 84%, 63% 83%, 60% 80%, 61% 86%, 60% 92%, 58% 97%, 55% 100%, 51% 100%, 47% 100%, 44% 99%, 40% 96%, 37% 91%, 34% 86%, 32% 82%, 29% 86%, 27% 89%, 23% 92%, 18% 92%, 13% 90%, 8% 87%, 4% 81%, 2% 74%, 0% 68%, 0% 63%, 0% 58%);
        clip-path: polygon(1% 53%, 2% 49%, 6% 45%, 9% 43%, 13% 42%, 17% 41%, 21% 39%, 25% 37%, 29% 34%, 32% 30%, 36% 26%, 40% 20%, 43% 15%, 47% 11%, 51% 7%, 57% 4%, 62% 1%, 67% 0%, 72% 0%, 76% 0%, 80% 3%, 84% 6%, 88% 11%, 92% 17%, 95% 23%, 98% 31%, 99% 38%, 100% 45%, 100% 52%, 99% 60%, 97% 68%, 94% 75%, 89% 80%, 84% 83%, 79% 85%, 73% 86%, 67% 84%, 63% 83%, 60% 80%, 61% 86%, 60% 92%, 58% 97%, 55% 100%, 51% 100%, 47% 100%, 44% 99%, 40% 96%, 37% 91%, 34% 86%, 32% 82%, 29% 86%, 27% 89%, 23% 92%, 18% 92%, 13% 90%, 8% 87%, 4% 81%, 2% 74%, 0% 68%, 0% 63%, 0% 58%);
    }


    /*.tree__title--retirement {
        -webkit-clip-path: polygon(0% 77%, 2% 67%, 4% 56%, 7% 47%, 11% 39%, 14% 32%, 18% 25%, 23% 18%, 27% 12%, 32% 7%, 38% 4%, 44% 1%, 50% 0%, 56% 1%, 62% 2%, 67% 4%, 73% 7%, 79% 14%, 85% 21%, 90% 31%, 95% 42%, 97% 51%, 99% 61%, 99% 67%, 99% 73%, 97% 77%, 93% 81%, 89% 81%, 85% 79%, 82% 75%, 80% 72%, 78% 66%, 78% 71%, 77% 76%, 75% 84%, 73% 90%, 69% 95%, 66% 98%, 62% 99%, 58% 99%, 54% 97%, 50% 94%, 47% 90%, 44% 85%, 43% 81%, 42% 77%, 41% 81%, 38% 85%, 34% 86%, 30% 85%, 27% 83%, 24% 79%, 20% 76%, 16% 74%, 12% 73%, 7% 74%, 4% 77%, 0 80%);
        clip-path: polygon(0% 77%, 2% 67%, 4% 56%, 7% 47%, 11% 39%, 14% 32%, 18% 25%, 23% 18%, 27% 12%, 32% 7%, 38% 4%, 44% 1%, 50% 0%, 56% 1%, 62% 2%, 67% 4%, 73% 7%, 79% 14%, 85% 21%, 90% 31%, 95% 42%, 97% 51%, 99% 61%, 99% 67%, 99% 73%, 97% 77%, 93% 81%, 89% 81%, 85% 79%, 82% 75%, 80% 72%, 78% 66%, 78% 71%, 77% 76%, 75% 84%, 73% 90%, 69% 95%, 66% 98%, 62% 99%, 58% 99%, 54% 97%, 50% 94%, 47% 90%, 44% 85%, 43% 81%, 42% 77%, 41% 81%, 38% 85%, 34% 86%, 30% 85%, 27% 83%, 24% 79%, 20% 76%, 16% 74%, 12% 73%, 7% 74%, 4% 77%, 0 80%);
    }*/
    .tree-sub-item--retirement-your {
        -webkit-clip-path: polygon(38% 0%, 44% 0, 49% 1%, 54% 3%, 60% 6%, 64% 10%, 68% 14%, 70% 19%, 72% 23%, 73% 27%, 73% 30%, 77% 32%, 82% 32%, 86% 32%, 91% 32%, 94% 32%, 97% 34%, 98% 37%, 99% 42%, 99% 46%, 97% 49%, 94% 52%, 91% 55%, 92% 59%, 93% 65%, 92% 71%, 90% 76%, 86% 79%, 81% 80%, 76% 79%, 73% 78%, 69% 75%, 69% 81%, 68% 88%, 68% 93%, 65% 97%, 61% 99%, 56% 99%, 51% 97%, 47% 93%, 43% 88%, 40% 83%, 37% 80%, 36% 78%, 34% 82%, 31% 85%, 27% 86%, 23% 85%, 19% 83%, 15% 80%, 12% 77%, 10% 74%, 7% 69%, 4% 63%, 3% 59%, 1% 52%, 0 45%, 0 38%, 1% 32%, 4% 24%, 7% 19%, 9% 16%, 13% 11%, 18% 7%, 25% 3%, 32% 1%);
clip-path: polygon(38% 0%, 44% 0, 49% 1%, 54% 3%, 60% 6%, 64% 10%, 68% 14%, 70% 19%, 72% 23%, 73% 27%, 73% 30%, 77% 32%, 82% 32%, 86% 32%, 91% 32%, 94% 32%, 97% 34%, 98% 37%, 99% 42%, 99% 46%, 97% 49%, 94% 52%, 91% 55%, 92% 59%, 93% 65%, 92% 71%, 90% 76%, 86% 79%, 81% 80%, 76% 79%, 73% 78%, 69% 75%, 69% 81%, 68% 88%, 68% 93%, 65% 97%, 61% 99%, 56% 99%, 51% 97%, 47% 93%, 43% 88%, 40% 83%, 37% 80%, 36% 78%, 34% 82%, 31% 85%, 27% 86%, 23% 85%, 19% 83%, 15% 80%, 12% 77%, 10% 74%, 7% 69%, 4% 63%, 3% 59%, 1% 52%, 0 45%, 0 38%, 1% 32%, 4% 24%, 7% 19%, 9% 16%, 13% 11%, 18% 7%, 25% 3%, 32% 1%);
    }
    .tree-sub-item--retirement-spouse {
        -webkit-clip-path: polygon(4% 17%, 8% 12%, 13% 7%, 18% 4%, 25% 1%, 33% 0%, 42% 1%, 48% 2%, 54% 4%, 60% 4%, 67% 4%, 72% 5%, 78% 7%, 82% 9%, 85% 12%, 87% 17%, 87% 21%, 86% 26%, 85% 29%, 82% 31%, 87% 34%, 91% 39%, 95% 45%, 98% 53%, 100% 59%, 100% 66%, 100% 72%, 98% 76%, 94% 81%, 90% 82%, 87% 82%, 87% 87%, 86% 92%, 83% 96%, 79% 99%, 75% 100%, 70% 100%, 64% 99%, 58% 95%, 54% 90%, 50% 85%, 48% 80%, 46% 75%, 44% 79%, 40% 83%, 35% 85%, 27% 84%, 20% 81%, 13% 76%, 8% 70%, 4% 62%, 2% 55%, 0% 47%, 0% 41%, 0% 34%, 0% 29%);
        clip-path: polygon(4% 17%, 8% 12%, 13% 7%, 18% 4%, 25% 1%, 33% 0%, 42% 1%, 48% 2%, 54% 4%, 60% 4%, 67% 4%, 72% 5%, 78% 7%, 82% 9%, 85% 12%, 87% 17%, 87% 21%, 86% 26%, 85% 29%, 82% 31%, 87% 34%, 91% 39%, 95% 45%, 98% 53%, 100% 59%, 100% 66%, 100% 72%, 98% 76%, 94% 81%, 90% 82%, 87% 82%, 87% 87%, 86% 92%, 83% 96%, 79% 99%, 75% 100%, 70% 100%, 64% 99%, 58% 95%, 54% 90%, 50% 85%, 48% 80%, 46% 75%, 44% 79%, 40% 83%, 35% 85%, 27% 84%, 20% 81%, 13% 76%, 8% 70%, 4% 62%, 2% 55%, 0% 47%, 0% 41%, 0% 34%, 0% 29%);
    }


    /*.tree__title--worth {
        -webkit-clip-path: polygon(16% 17%, 21% 14%, 25% 13%, 29% 13%, 32% 14%, 35% 16%, 39% 19%, 43% 22%, 48% 24%, 53% 26%, 59% 27%, 66% 28%, 71% 28%, 76% 28%, 80% 28%, 83% 31%, 86% 34%, 88% 38%, 88% 41%, 87% 45%, 86% 48%, 85% 51%, 82% 54%, 80% 55%, 77% 56%, 74% 56%, 74% 60%, 74% 63%, 74% 67%, 73% 70%, 71% 74%, 68% 78%, 65% 81%, 62% 82%, 58% 84%, 54% 84%, 50% 83%, 46% 82%, 42% 79%, 38% 76%, 34% 72%, 31% 68%, 29% 63%, 27% 65%, 24% 66%, 21% 66%, 17% 64%, 14% 60%, 11% 54%, 9% 47%, 9% 40%, 10% 34%, 11% 29%, 12% 24%);
        clip-path: polygon(16% 17%, 21% 14%, 25% 13%, 29% 13%, 32% 14%, 35% 16%, 39% 19%, 43% 22%, 48% 24%, 53% 26%, 59% 27%, 66% 28%, 71% 28%, 76% 28%, 80% 28%, 83% 31%, 86% 34%, 88% 38%, 88% 41%, 87% 45%, 86% 48%, 85% 51%, 82% 54%, 80% 55%, 77% 56%, 74% 56%, 74% 60%, 74% 63%, 74% 67%, 73% 70%, 71% 74%, 68% 78%, 65% 81%, 62% 82%, 58% 84%, 54% 84%, 50% 83%, 46% 82%, 42% 79%, 38% 76%, 34% 72%, 31% 68%, 29% 63%, 27% 65%, 24% 66%, 21% 66%, 17% 64%, 14% 60%, 11% 54%, 9% 47%, 9% 40%, 10% 34%, 11% 29%, 12% 24%);
    }*/
    .tree-sub-item--your-assets {
        -webkit-clip-path: polygon(4% 23%, 7% 17%, 13% 12%, 19% 7%, 26% 4%, 33% 1%, 40% 0%, 48% 0%, 56% 0%, 63% 1%, 71% 4%, 77% 7%, 81% 12%, 82% 16%, 81% 19%, 79% 22%, 77% 26%, 76% 29%, 76% 33%, 78% 38%, 81% 43%, 84% 47%, 88% 51%, 92% 55%, 97% 58%, 99% 62%, 100% 66%, 100% 70%, 99% 75%, 96% 78%, 93% 80%, 88% 80%, 85% 78%, 87% 83%, 88% 88%, 88% 93%, 87% 96%, 85% 99%, 81% 100%, 77% 100%, 71% 99%, 66% 96%, 62% 93%, 58% 88%, 54% 83%, 52% 78%, 51% 74%, 49% 70%, 49% 65%, 46% 69%, 42% 72%, 36% 74%, 30% 75%, 22% 74%, 17% 71%, 11% 68%, 6% 63%, 3% 57%, 0% 50%, 0% 44%, 0% 37%, 0% 33%, 0% 30%);
        clip-path: polygon(4% 23%, 7% 17%, 13% 12%, 19% 7%, 26% 4%, 33% 1%, 40% 0%, 48% 0%, 56% 0%, 63% 1%, 71% 4%, 77% 7%, 81% 12%, 82% 16%, 81% 19%, 79% 22%, 77% 26%, 76% 29%, 76% 33%, 78% 38%, 81% 43%, 84% 47%, 88% 51%, 92% 55%, 97% 58%, 99% 62%, 100% 66%, 100% 70%, 99% 75%, 96% 78%, 93% 80%, 88% 80%, 85% 78%, 87% 83%, 88% 88%, 88% 93%, 87% 96%, 85% 99%, 81% 100%, 77% 100%, 71% 99%, 66% 96%, 62% 93%, 58% 88%, 54% 83%, 52% 78%, 51% 74%, 49% 70%, 49% 65%, 46% 69%, 42% 72%, 36% 74%, 30% 75%, 22% 74%, 17% 71%, 11% 68%, 6% 63%, 3% 57%, 0% 50%, 0% 44%, 0% 37%, 0% 33%, 0% 30%);
    }
    .tree-sub-item--your-liabilities {
        -webkit-clip-path: polygon(14% 2%, 20% 5%, 26% 9%, 33% 12%, 40% 13%, 48% 12%, 53% 13%, 58% 15%, 60% 19%, 61% 21%, 66% 22%, 72% 24%, 80% 28%, 87% 34%, 91% 39%, 93% 43%, 93% 47%, 98% 53%, 100% 59%, 99% 66%, 97% 71%, 93% 73%, 87% 74%, 85% 79%, 80% 85%, 74% 87%, 68% 87%, 60% 84%, 59% 91%, 55% 97%, 49% 100%, 40% 99%, 28% 90%, 19% 77%, 10% 62%, 6% 52%, 2% 38%, 1% 29%, 1% 19%, 3% 7%, 9% 3%);
        clip-path: polygon(14% 2%, 20% 5%, 26% 9%, 33% 12%, 40% 13%, 48% 12%, 53% 13%, 58% 15%, 60% 19%, 61% 21%, 66% 22%, 72% 24%, 80% 28%, 87% 34%, 91% 39%, 93% 43%, 93% 47%, 98% 53%, 100% 59%, 99% 66%, 97% 71%, 93% 73%, 87% 74%, 85% 79%, 80% 85%, 74% 87%, 68% 87%, 60% 84%, 59% 91%, 55% 97%, 49% 100%, 40% 99%, 28% 90%, 19% 77%, 10% 62%, 6% 52%, 2% 38%, 1% 29%, 1% 19%, 3% 7%, 9% 3%);
    }


    /*.tree__title--profile-summary {
        -webkit-clip-path: polygon(0% 51%, 3% 44%, 7% 35%, 12% 25%, 17% 18%, 22% 12%, 26% 8%, 31% 5%, 36% 3%, 41% 1%, 47% 0%, 55% 0%, 61% 1%, 66% 3%, 73% 6%, 79% 10%, 85% 15%, 90% 20%, 96% 25%, 99% 28%, 100% 32%, 100% 36%, 100% 41%, 98% 45%, 95% 50%, 91% 52%, 88% 54%, 83% 53%, 79% 52%, 83% 58%, 85% 63%, 86% 69%, 86% 76%, 85% 82%, 83% 88%, 81% 93%, 77% 98%, 72% 100%, 67% 100%, 64% 100%, 59% 98%, 54% 95%, 50% 89%, 45% 83%, 43% 87%, 40% 92%, 36% 94%, 31% 95%, 26% 94%, 20% 90%, 15% 85%, 10% 79%, 6% 71%, 3% 64%, 1% 57%, 0% 52%);
        clip-path: polygon(0% 51%, 3% 44%, 7% 35%, 12% 25%, 17% 18%, 22% 12%, 26% 8%, 31% 5%, 36% 3%, 41% 1%, 47% 0%, 55% 0%, 61% 1%, 66% 3%, 73% 6%, 79% 10%, 85% 15%, 90% 20%, 96% 25%, 99% 28%, 100% 32%, 100% 36%, 100% 41%, 98% 45%, 95% 50%, 91% 52%, 88% 54%, 83% 53%, 79% 52%, 83% 58%, 85% 63%, 86% 69%, 86% 76%, 85% 82%, 83% 88%, 81% 93%, 77% 98%, 72% 100%, 67% 100%, 64% 100%, 59% 98%, 54% 95%, 50% 89%, 45% 83%, 43% 87%, 40% 92%, 36% 94%, 31% 95%, 26% 94%, 20% 90%, 15% 85%, 10% 79%, 6% 71%, 3% 64%, 1% 57%, 0% 52%);
    }
    .tree-sub-item--about-your-spouse {
        -webkit-clip-path: polygon(0% 34%, 4% 31%, 10% 27%, 15% 22%, 20% 16%, 24% 10%, 28% 6%, 33% 3%, 37% 0%, 50% 0%, 55% 0%, 59% 3%, 64% 5%, 71% 8%, 77% 9%, 84% 12%, 90% 16%, 95% 22%, 98% 28%, 99% 33%, 98% 38%, 97% 42%, 95% 44%, 98% 49%, 100% 54%, 100% 60%, 100% 65%, 98% 70%, 96% 74%, 92% 77%, 87% 78%, 88% 85%, 86% 91%, 84% 95%, 82% 98%, 76% 100%, 71% 100%, 67% 100%, 61% 98%, 56% 94%, 51% 89%, 48% 84%, 45% 80%, 43% 75%, 41% 80%, 38% 84%, 34% 85%, 29% 85%, 25% 84%, 20% 80%, 14% 74%, 10% 68%, 6% 61%, 4% 55%, 1% 46%, 0% 39%);
        clip-path: polygon(0% 34%, 4% 31%, 10% 27%, 15% 22%, 20% 16%, 24% 10%, 28% 6%, 33% 3%, 37% 0%, 50% 0%, 55% 0%, 59% 3%, 64% 5%, 71% 8%, 77% 9%, 84% 12%, 90% 16%, 95% 22%, 98% 28%, 99% 33%, 98% 38%, 97% 42%, 95% 44%, 98% 49%, 100% 54%, 100% 60%, 100% 65%, 98% 70%, 96% 74%, 92% 77%, 87% 78%, 88% 85%, 86% 91%, 84% 95%, 82% 98%, 76% 100%, 71% 100%, 67% 100%, 61% 98%, 56% 94%, 51% 89%, 48% 84%, 45% 80%, 43% 75%, 41% 80%, 38% 84%, 34% 85%, 29% 85%, 25% 84%, 20% 80%, 14% 74%, 10% 68%, 6% 61%, 4% 55%, 1% 46%, 0% 39%);
    }
    .tree-sub-item--about-your-family {
        -webkit-clip-path: polygon(1% 25%, 3% 18%, 7% 12%, 12% 7%, 17% 4%, 23% 1%, 30% 0%, 38% 0%, 46% 0%, 54% 2%, 61% 5%, 68% 9%, 76% 13%, 83% 19%, 89% 25%, 94% 31%, 97% 39%, 100% 48%, 100% 55%, 100% 60%, 98% 65%, 96% 68%, 93% 70%, 89% 70%, 90% 74%, 91% 80%, 90% 86%, 88% 91%, 86% 95%, 83% 98%, 78% 100%, 73% 100%, 67% 100%, 61% 97%, 56% 94%, 52% 89%, 47% 81%, 45% 86%, 43% 91%, 39% 95%, 34% 96%, 26% 94%, 19% 89%, 13% 81%, 8% 72%, 4% 63%, 2% 54%, 0% 45%, 0% 35%);
        clip-path: polygon(1% 25%, 3% 18%, 7% 12%, 12% 7%, 17% 4%, 23% 1%, 30% 0%, 38% 0%, 46% 0%, 54% 2%, 61% 5%, 68% 9%, 76% 13%, 83% 19%, 89% 25%, 94% 31%, 97% 39%, 100% 48%, 100% 55%, 100% 60%, 98% 65%, 96% 68%, 93% 70%, 89% 70%, 90% 74%, 91% 80%, 90% 86%, 88% 91%, 86% 95%, 83% 98%, 78% 100%, 73% 100%, 67% 100%, 61% 97%, 56% 94%, 52% 89%, 47% 81%, 45% 86%, 43% 91%, 39% 95%, 34% 96%, 26% 94%, 19% 89%, 13% 81%, 8% 72%, 4% 63%, 2% 54%, 0% 45%, 0% 35%);
    }
    */
    .tree-sub-item--about-your-spouse {
        -webkit-clip-path: polygon(0% 51%, 3% 44%, 7% 35%, 12% 25%, 17% 18%, 22% 12%, 26% 8%, 31% 5%, 36% 3%, 41% 1%, 47% 0%, 55% 0%, 61% 1%, 66% 3%, 73% 6%, 79% 10%, 85% 15%, 90% 20%, 96% 25%, 99% 28%, 100% 32%, 100% 36%, 100% 41%, 98% 45%, 95% 50%, 91% 52%, 88% 54%, 83% 53%, 79% 52%, 83% 58%, 85% 63%, 86% 69%, 86% 76%, 85% 82%, 83% 88%, 81% 93%, 77% 98%, 72% 100%, 67% 100%, 64% 100%, 59% 98%, 54% 95%, 50% 89%, 45% 83%, 43% 87%, 40% 92%, 36% 94%, 31% 95%, 26% 94%, 20% 90%, 15% 85%, 10% 79%, 6% 71%, 3% 64%, 1% 57%, 0% 52%);
        clip-path: polygon(0% 51%, 3% 44%, 7% 35%, 12% 25%, 17% 18%, 22% 12%, 26% 8%, 31% 5%, 36% 3%, 41% 1%, 47% 0%, 55% 0%, 61% 1%, 66% 3%, 73% 6%, 79% 10%, 85% 15%, 90% 20%, 96% 25%, 99% 28%, 100% 32%, 100% 36%, 100% 41%, 98% 45%, 95% 50%, 91% 52%, 88% 54%, 83% 53%, 79% 52%, 83% 58%, 85% 63%, 86% 69%, 86% 76%, 85% 82%, 83% 88%, 81% 93%, 77% 98%, 72% 100%, 67% 100%, 64% 100%, 59% 98%, 54% 95%, 50% 89%, 45% 83%, 43% 87%, 40% 92%, 36% 94%, 31% 95%, 26% 94%, 20% 90%, 15% 85%, 10% 79%, 6% 71%, 3% 64%, 1% 57%, 0% 52%);
    }
    
    .tree-sub-item--about-your-family {
        -webkit-clip-path: polygon(40% 0%, 50% 1%, 58% 3%, 68% 5%, 81% 8%, 87% 11%, 93% 16%, 96% 23%, 92% 27%, 96% 32%, 97% 39%, 95% 44%, 89% 49%, 95% 56%, 98% 61%, 100% 71%, 98% 79%, 92% 81%, 92% 87%, 89% 95%, 81% 99%, 69% 100%, 59% 96%, 51% 90%, 45% 96%, 36% 97%, 26% 94%, 18% 85%, 10% 73%, 6% 63%, 2% 54%, 0% 45%, 0% 36%, 0% 26%, 4% 18%, 11% 10%, 23% 3%);
        clip-path: polygon(40% 0%, 50% 1%, 58% 3%, 68% 5%, 81% 8%, 87% 11%, 93% 16%, 96% 23%, 92% 27%, 96% 32%, 97% 39%, 95% 44%, 89% 49%, 95% 56%, 98% 61%, 100% 71%, 98% 79%, 92% 81%, 92% 87%, 89% 95%, 81% 99%, 69% 100%, 59% 96%, 51% 90%, 45% 96%, 36% 97%, 26% 94%, 18% 85%, 10% 73%, 6% 63%, 2% 54%, 0% 45%, 0% 36%, 0% 26%, 4% 18%, 11% 10%, 23% 3%);
    }
    .tree-sub-item--about-you {
        -webkit-clip-path: polygon(53% 2%, 60% 5%, 67% 10%, 73% 16%, 78% 21%, 82% 27%, 87% 33%, 90% 40%, 94% 47%, 96% 55%, 99% 64%, 100% 73%, 100% 80%, 100% 88%, 98% 93%, 94% 98%, 90% 99%, 85% 98%, 80% 96%, 75% 93%, 71% 90%, 68% 87%, 68% 91%, 67% 94%, 65% 96%, 62% 97%, 57% 96%, 53% 95%, 50% 92%, 48% 90%, 48% 94%, 46% 97%, 42% 100%, 37% 100%, 33% 100%, 26% 98%, 20% 96%, 15% 93%, 11% 88%, 5% 82%, 2% 75%, 0% 66%, 0% 58%, 0% 50%, 2% 44%, 4% 39%, 7% 34%, 10% 30%, 14% 26%, 19% 22%, 24% 18%, 30% 14%, 35% 10%, 38% 6%, 41% 3%, 44% 0%, 49% 0%);
        clip-path: polygon(53% 2%, 60% 5%, 67% 10%, 73% 16%, 78% 21%, 82% 27%, 87% 33%, 90% 40%, 94% 47%, 96% 55%, 99% 64%, 100% 73%, 100% 80%, 100% 88%, 98% 93%, 94% 98%, 90% 99%, 85% 98%, 80% 96%, 75% 93%, 71% 90%, 68% 87%, 68% 91%, 67% 94%, 65% 96%, 62% 97%, 57% 96%, 53% 95%, 50% 92%, 48% 90%, 48% 94%, 46% 97%, 42% 100%, 37% 100%, 33% 100%, 26% 98%, 20% 96%, 15% 93%, 11% 88%, 5% 82%, 2% 75%, 0% 66%, 0% 58%, 0% 50%, 2% 44%, 4% 39%, 7% 34%, 10% 30%, 14% 26%, 19% 22%, 24% 18%, 30% 14%, 35% 10%, 38% 6%, 41% 3%, 44% 0%, 49% 0%);
    }
    .tree-sub-item--assets-item-1 {
        -webkit-clip-path: polygon(0% 73%, 2% 62%, 5% 51%, 11% 39%, 17% 27%, 23% 19%, 29% 13%, 36% 7%, 44% 3%, 53% 0%, 61% 0%, 68% 0%, 74% 2%, 81% 6%, 87% 10%, 93% 17%, 98% 25%, 100% 34%, 100% 40%, 100% 45%, 99% 50%, 97% 55%, 93% 57%, 90% 59%, 86% 58%, 87% 64%, 88% 71%, 88% 77%, 86% 85%, 83% 92%, 80% 96%, 75% 98%, 71% 98%, 65% 97%, 61% 93%, 55% 87%, 51% 81%, 49% 77%, 49% 83%, 48% 90%, 46% 95%, 43% 99%, 39% 100%, 34% 100%, 30% 97%, 26% 93%, 22% 89%, 19% 85%, 15% 80%, 9% 77%, 0 76%);
        clip-path: polygon(0% 73%, 2% 62%, 5% 51%, 11% 39%, 17% 27%, 23% 19%, 29% 13%, 36% 7%, 44% 3%, 53% 0%, 61% 0%, 68% 0%, 74% 2%, 81% 6%, 87% 10%, 93% 17%, 98% 25%, 100% 34%, 100% 40%, 100% 45%, 99% 50%, 97% 55%, 93% 57%, 90% 59%, 86% 58%, 87% 64%, 88% 71%, 88% 77%, 86% 85%, 83% 92%, 80% 96%, 75% 98%, 71% 98%, 65% 97%, 61% 93%, 55% 87%, 51% 81%, 49% 77%, 49% 83%, 48% 90%, 46% 95%, 43% 99%, 39% 100%, 34% 100%, 30% 97%, 26% 93%, 22% 89%, 19% 85%, 15% 80%, 9% 77%, 0 76%);
    }
    .tree-sub-item--assets-item-3 {
        -webkit-clip-path: polygon(2% 14%, 6% 8%, 10% 3%, 15% 0%, 20% 0%, 26% 1%, 30% 3%, 34% 7%, 40% 13%, 47% 18%, 54% 22%, 60% 24%, 66% 26%, 73% 26%, 78% 25%, 84% 24%, 89% 25%, 94% 28%, 98% 34%, 100% 38%, 100% 43%, 100% 48%, 100% 53%, 97% 59%, 94% 63%, 91% 65%, 87% 66%, 84% 65%, 87% 71%, 88% 75%, 88% 81%, 88% 87%, 86% 92%, 84% 97%, 77% 100%, 73% 100%, 67% 100%, 63% 99%, 57% 97%, 49% 93%, 42% 89%, 34% 84%, 27% 79%, 20% 73%, 14% 66%, 8% 58%, 4% 50%, 1% 42%, 0% 36%, 0% 28%, 0% 22%);
        clip-path: polygon(2% 14%, 6% 8%, 10% 3%, 15% 0%, 20% 0%, 26% 1%, 30% 3%, 34% 7%, 40% 13%, 47% 18%, 54% 22%, 60% 24%, 66% 26%, 73% 26%, 78% 25%, 84% 24%, 89% 25%, 94% 28%, 98% 34%, 100% 38%, 100% 43%, 100% 48%, 100% 53%, 97% 59%, 94% 63%, 91% 65%, 87% 66%, 84% 65%, 87% 71%, 88% 75%, 88% 81%, 88% 87%, 86% 92%, 84% 97%, 77% 100%, 73% 100%, 67% 100%, 63% 99%, 57% 97%, 49% 93%, 42% 89%, 34% 84%, 27% 79%, 20% 73%, 14% 66%, 8% 58%, 4% 50%, 1% 42%, 0% 36%, 0% 28%, 0% 22%);
    }
    .tree-sub-item--assets-item-4 {
        -webkit-clip-path: polygon(21% 0%, 29% 1%, 37% 4%, 43% 9%, 50% 15%, 57% 18%, 68% 21%, 78% 23%, 89% 30%, 97% 39%, 100% 49%, 99% 57%, 93% 66%, 97% 76%, 95% 89%, 91% 96%, 83% 100%, 72% 100%, 66% 97%, 57% 88%, 51% 79%, 47% 86%, 38% 89%, 29% 86%, 17% 76%, 9% 64%, 2% 46%, 0 33%, 0 23%, 2% 16%, 5% 9%, 13% 3%);
        clip-path: polygon(21% 0%, 29% 1%, 37% 4%, 43% 9%, 50% 15%, 57% 18%, 68% 21%, 78% 23%, 89% 30%, 97% 39%, 100% 49%, 99% 57%, 93% 66%, 97% 76%, 95% 89%, 91% 96%, 83% 100%, 72% 100%, 66% 97%, 57% 88%, 51% 79%, 47% 86%, 38% 89%, 29% 86%, 17% 76%, 9% 64%, 2% 46%, 0 33%, 0 23%, 2% 16%, 5% 9%, 13% 3%);
    }
    .tree-sub-item--assets-item-5 {
        -webkit-clip-path: polygon(42% 1%, 47% 2%, 52% 4%, 57% 5%, 63% 8%, 71% 12%, 78% 17%, 84% 22%, 88% 28%, 90% 33%, 89% 39%, 94% 45%, 98% 53%, 100% 60%, 100% 68%, 96% 75%, 91% 78%, 85% 79%, 79% 78%, 79% 84%, 76% 90%, 72% 95%, 65% 98%, 59% 99%, 53% 98%, 45% 95%, 40% 91%, 34% 93%, 28% 93%, 21% 89%, 17% 84%, 11% 77%, 7% 68%, 4% 60%, 1% 50%, 0% 42%, 0% 30%, 2% 22%, 5% 16%, 9% 10%, 14% 6%, 20% 3%, 29% 1%, 34% 0%);
clip-path: polygon(42% 1%, 47% 2%, 52% 4%, 57% 5%, 63% 8%, 71% 12%, 78% 17%, 84% 22%, 88% 28%, 90% 33%, 89% 39%, 94% 45%, 98% 53%, 100% 60%, 100% 68%, 96% 75%, 91% 78%, 85% 79%, 79% 78%, 79% 84%, 76% 90%, 72% 95%, 65% 98%, 59% 99%, 53% 98%, 45% 95%, 40% 91%, 34% 93%, 28% 93%, 21% 89%, 17% 84%, 11% 77%, 7% 68%, 4% 60%, 1% 50%, 0% 42%, 0% 30%, 2% 22%, 5% 16%, 9% 10%, 14% 6%, 20% 3%, 29% 1%, 34% 0%);
    }
    /*.tree__title--assets {
        -webkit-clip-path: polygon(17% 18%, 20% 16%, 23% 15%, 27% 14%, 31% 14%, 35% 15%, 39% 17%, 43% 21%, 45% 23%, 49% 25%, 52% 27%, 57% 29%, 61% 31%, 67% 32%, 71% 33%, 75% 35%, 79% 39%, 83% 44%, 86% 48%, 87% 53%, 88% 58%, 87% 63%, 85% 67%, 82% 69%, 84% 72%, 85% 76%, 85% 82%, 84% 87%, 83% 91%, 81% 94%, 78% 96%, 74% 98%, 69% 98%, 64% 96%, 60% 93%, 56% 89%, 53% 85%, 51% 81%, 50% 77%, 49% 81%, 47% 85%, 44% 87%, 40% 87%, 36% 87%, 32% 85%, 28% 83%, 25% 78%, 21% 73%, 17% 67%, 14% 59%, 12% 53%, 11% 46%, 10% 39%, 11% 31%, 12% 26%, 14% 21%);
        clip-path: polygon(17% 18%, 20% 16%, 23% 15%, 27% 14%, 31% 14%, 35% 15%, 39% 17%, 43% 21%, 45% 23%, 49% 25%, 52% 27%, 57% 29%, 61% 31%, 67% 32%, 71% 33%, 75% 35%, 79% 39%, 83% 44%, 86% 48%, 87% 53%, 88% 58%, 87% 63%, 85% 67%, 82% 69%, 84% 72%, 85% 76%, 85% 82%, 84% 87%, 83% 91%, 81% 94%, 78% 96%, 74% 98%, 69% 98%, 64% 96%, 60% 93%, 56% 89%, 53% 85%, 51% 81%, 50% 77%, 49% 81%, 47% 85%, 44% 87%, 40% 87%, 36% 87%, 32% 85%, 28% 83%, 25% 78%, 21% 73%, 17% 67%, 14% 59%, 12% 53%, 11% 46%, 10% 39%, 11% 31%, 12% 26%, 14% 21%);
    }*/
    .tree-sub-item--assets-item-2 {
        -webkit-clip-path: polygon(3% 12%, 6% 9%, 12% 6%, 18% 4%, 25% 2%, 31% 0%, 41% 0%, 49% 0%, 57% 1%, 64% 2%, 72% 3%, 79% 6%, 85% 9%, 92% 15%, 97% 20%, 100% 26%, 100% 31%, 100% 37%, 97% 42%, 93% 46%, 90% 48%, 85% 50%, 79% 52%, 78% 58%, 76% 65%, 73% 73%, 69% 80%, 65% 86%, 61% 91%, 56% 95%, 50% 99%, 44% 100%, 37% 100%, 30% 98%, 24% 95%, 18% 89%, 13% 81%, 8% 73%, 4% 61%, 2% 53%, 1% 47%, 0% 39%, 0% 24%, 1% 17%);
        clip-path: polygon(3% 12%, 6% 9%, 12% 6%, 18% 4%, 25% 2%, 31% 0%, 41% 0%, 49% 0%, 57% 1%, 64% 2%, 72% 3%, 79% 6%, 85% 9%, 92% 15%, 97% 20%, 100% 26%, 100% 31%, 100% 37%, 97% 42%, 93% 46%, 90% 48%, 85% 50%, 79% 52%, 78% 58%, 76% 65%, 73% 73%, 69% 80%, 65% 86%, 61% 91%, 56% 95%, 50% 99%, 44% 100%, 37% 100%, 30% 98%, 24% 95%, 18% 89%, 13% 81%, 8% 73%, 4% 61%, 2% 53%, 1% 47%, 0% 39%, 0% 24%, 1% 17%);
    }


    /*.tree__title--objectives {
        -webkit-clip-path: polygon(12% 12%, 17% 9%, 23% 8%, 30% 8%, 36% 12%, 43% 16%, 50% 20%, 57% 22%, 63% 23%, 69% 23%, 73% 22%, 77% 22%, 81% 24%, 83% 27%, 85% 32%, 85% 36%, 85% 44%, 83% 48%, 81% 51%, 84% 52%, 88% 55%, 90% 60%, 92% 65%, 92% 71%, 91% 77%, 88% 83%, 85% 87%, 81% 90%, 76% 92%, 71% 92%, 65% 91%, 60% 89%, 54% 85%, 49% 81%, 44% 77%, 41% 73%, 40% 77%, 37% 80%, 33% 82%, 29% 81%, 24% 79%, 19% 75%, 15% 69%, 11% 59%, 8% 52%, 6% 45%, 5% 36%, 5% 28%, 7% 21%);
        clip-path: polygon(12% 12%, 17% 9%, 23% 8%, 30% 8%, 36% 12%, 43% 16%, 50% 20%, 57% 22%, 63% 23%, 69% 23%, 73% 22%, 77% 22%, 81% 24%, 83% 27%, 85% 32%, 85% 36%, 85% 44%, 83% 48%, 81% 51%, 84% 52%, 88% 55%, 90% 60%, 92% 65%, 92% 71%, 91% 77%, 88% 83%, 85% 87%, 81% 90%, 76% 92%, 71% 92%, 65% 91%, 60% 89%, 54% 85%, 49% 81%, 44% 77%, 41% 73%, 40% 77%, 37% 80%, 33% 82%, 29% 81%, 24% 79%, 19% 75%, 15% 69%, 11% 59%, 8% 52%, 6% 45%, 5% 36%, 5% 28%, 7% 21%);
    }*/
    .tree-sub-item--objective-1 {
        -webkit-clip-path: polygon(2% 93%, 2% 87%, 3% 78%, 4% 70%, 6% 63%, 8% 54%, 10% 48%, 13% 41%, 15% 35%, 18% 29%, 22% 23%, 26% 18%, 30% 14%, 34% 10%, 39% 7%, 44% 4%, 50% 2%, 55% 2%, 60% 3%, 65% 5%, 70% 8%, 75% 13%, 79% 17%, 84% 23%, 87% 30%, 90% 34%, 92% 39%, 95% 48%, 97% 56%, 99% 67%, 99% 79%, 96% 86%, 92% 87%, 89% 85%, 86% 80%, 85% 76%, 83% 80%, 79% 82%, 76% 79%, 74% 75%, 72% 69%, 71% 75%, 67% 80%, 64% 80%, 60% 79%, 55% 72%, 55% 80%, 52% 85%, 47% 88%, 44% 90%, 37% 89%, 31% 88%, 26% 87%, 18% 87%, 11% 89%);
        clip-path: polygon(2% 93%, 2% 87%, 3% 78%, 4% 70%, 6% 63%, 8% 54%, 10% 48%, 13% 41%, 15% 35%, 18% 29%, 22% 23%, 26% 18%, 30% 14%, 34% 10%, 39% 7%, 44% 4%, 50% 2%, 55% 2%, 60% 3%, 65% 5%, 70% 8%, 75% 13%, 79% 17%, 84% 23%, 87% 30%, 90% 34%, 92% 39%, 95% 48%, 97% 56%, 99% 67%, 99% 79%, 96% 86%, 92% 87%, 89% 85%, 86% 80%, 85% 76%, 83% 80%, 79% 82%, 76% 79%, 74% 75%, 72% 69%, 71% 75%, 67% 80%, 64% 80%, 60% 79%, 55% 72%, 55% 80%, 52% 85%, 47% 88%, 44% 90%, 37% 89%, 31% 88%, 26% 87%, 18% 87%, 11% 89%);
    }
    .tree-sub-item--objective-2 {
        -webkit-clip-path: polygon(7% 19%, 10% 15%, 14% 11%, 19% 7%, 23% 4%, 29% 2%, 35% 1%, 41% 1%, 47% 2%, 52% 3%, 58% 5%, 63% 8%, 69% 12%, 72% 15%, 78% 21%, 83% 27%, 88% 33%, 92% 39%, 96% 48%, 99% 55%, 100% 66%, 99% 74%, 96% 81%, 93% 84%, 87% 86%, 81% 84%, 75% 81%, 66% 72%, 66% 80%, 65% 89%, 62% 95%, 58% 99%, 54% 100%, 50% 99%, 47% 96%, 43% 92%, 38% 81%, 37% 87%, 36% 92%, 33% 97%, 30% 98%, 26% 98%, 20% 94%, 16% 89%, 13% 86%, 10% 80%, 8% 76%, 6% 71%, 3% 67%, 2% 61%, 0% 56%, 0 46%, 1% 39%, 3% 30%);
        clip-path: polygon(7% 19%, 10% 15%, 14% 11%, 19% 7%, 23% 4%, 29% 2%, 35% 1%, 41% 1%, 47% 2%, 52% 3%, 58% 5%, 63% 8%, 69% 12%, 72% 15%, 78% 21%, 83% 27%, 88% 33%, 92% 39%, 96% 48%, 99% 55%, 100% 66%, 99% 74%, 96% 81%, 93% 84%, 87% 86%, 81% 84%, 75% 81%, 66% 72%, 66% 80%, 65% 89%, 62% 95%, 58% 99%, 54% 100%, 50% 99%, 47% 96%, 43% 92%, 38% 81%, 37% 87%, 36% 92%, 33% 97%, 30% 98%, 26% 98%, 20% 94%, 16% 89%, 13% 86%, 10% 80%, 8% 76%, 6% 71%, 3% 67%, 2% 61%, 0% 56%, 0 46%, 1% 39%, 3% 30%);
    }
    .tree-sub-item--objective-3 {
        -webkit-clip-path: polygon(7% 15%, 10% 12%, 15% 8%, 21% 5%, 28% 3%, 33% 2%, 39% 1%, 44% 2%, 51% 4%, 56% 5%, 61% 8%, 66% 12%, 69% 14%, 74% 17%, 79% 18%, 83% 17%, 87% 17%, 91% 18%, 93% 21%, 95% 27%, 95% 34%, 93% 41%, 95% 44%, 98% 48%, 100% 55%, 99% 63%, 98% 69%, 94% 74%, 88% 77%, 84% 77%, 78% 76%, 72% 72%, 68% 67%, 68% 73%, 68% 79%, 66% 84%, 62% 86%, 58% 86%, 55% 83%, 53% 79%, 52% 86%, 51% 90%, 47% 94%, 44% 94%, 41% 92%, 38% 88%, 36% 83%, 34% 90%, 32% 94%, 29% 98%, 25% 99%, 21% 98%, 17% 94%, 12% 86%, 9% 79%, 6% 71%, 4% 62%, 2% 53%, 1% 45%, 1% 35%, 1% 27%, 3% 21%);
        clip-path: polygon(7% 15%, 10% 12%, 15% 8%, 21% 5%, 28% 3%, 33% 2%, 39% 1%, 44% 2%, 51% 4%, 56% 5%, 61% 8%, 66% 12%, 69% 14%, 74% 17%, 79% 18%, 83% 17%, 87% 17%, 91% 18%, 93% 21%, 95% 27%, 95% 34%, 93% 41%, 95% 44%, 98% 48%, 100% 55%, 99% 63%, 98% 69%, 94% 74%, 88% 77%, 84% 77%, 78% 76%, 72% 72%, 68% 67%, 68% 73%, 68% 79%, 66% 84%, 62% 86%, 58% 86%, 55% 83%, 53% 79%, 52% 86%, 51% 90%, 47% 94%, 44% 94%, 41% 92%, 38% 88%, 36% 83%, 34% 90%, 32% 94%, 29% 98%, 25% 99%, 21% 98%, 17% 94%, 12% 86%, 9% 79%, 6% 71%, 4% 62%, 2% 53%, 1% 45%, 1% 35%, 1% 27%, 3% 21%);
    }
    .tree-sub-item--objective-4 {
        -webkit-clip-path: polygon(0% 18%, 5% 19%, 10% 19%, 17% 17%, 25% 13%, 32% 9%, 39% 5%, 45% 1%, 53% 0%, 62% 0%, 68% 1%, 75% 4%, 81% 7%, 87% 12%, 92% 19%, 97% 28%, 100% 40%, 100% 50%, 100% 55%, 98% 62%, 94% 65%, 90% 67%, 85% 63%, 82% 60%, 83% 68%, 82% 77%, 80% 83%, 76% 90%, 72% 93%, 69% 93%, 65% 91%, 61% 88%, 59% 82%, 57% 88%, 55% 93%, 52% 97%, 47% 100%, 43% 100%, 38% 99%, 32% 96%, 27% 89%, 21% 81%, 16% 72%, 11% 61%, 7% 49%, 3% 37%, 0% 26%);
        clip-path: polygon(0% 18%, 5% 19%, 10% 19%, 17% 17%, 25% 13%, 32% 9%, 39% 5%, 45% 1%, 53% 0%, 62% 0%, 68% 1%, 75% 4%, 81% 7%, 87% 12%, 92% 19%, 97% 28%, 100% 40%, 100% 50%, 100% 55%, 98% 62%, 94% 65%, 90% 67%, 85% 63%, 82% 60%, 83% 68%, 82% 77%, 80% 83%, 76% 90%, 72% 93%, 69% 93%, 65% 91%, 61% 88%, 59% 82%, 57% 88%, 55% 93%, 52% 97%, 47% 100%, 43% 100%, 38% 99%, 32% 96%, 27% 89%, 21% 81%, 16% 72%, 11% 61%, 7% 49%, 3% 37%, 0% 26%);
    }
    .tree-sub-item--objective-6 {
        -webkit-clip-path: polygon(8% 31%, 17% 27%, 27% 24%, 36% 21%, 45% 17%, 52% 12%, 59% 6%, 67% 2%, 77% 0%, 85% 0%, 91% 2%, 95% 6%, 98% 12%, 100% 19%, 100% 26%, 100% 32%, 98% 40%, 94% 45%, 90% 48%, 85% 46%, 81% 43%, 82% 51%, 81% 60%, 80% 68%, 77% 75%, 74% 80%, 69% 82%, 63% 79%, 59% 75%, 57% 71%, 55% 78%, 53% 86%, 49% 93%, 45% 97%, 38% 100%, 33% 100%, 27% 99%, 21% 95%, 14% 87%, 8% 76%, 3% 65%, 0% 54%, 0% 45%, 2% 37%);
        clip-path: polygon(8% 31%, 17% 27%, 27% 24%, 36% 21%, 45% 17%, 52% 12%, 59% 6%, 67% 2%, 77% 0%, 85% 0%, 91% 2%, 95% 6%, 98% 12%, 100% 19%, 100% 26%, 100% 32%, 98% 40%, 94% 45%, 90% 48%, 85% 46%, 81% 43%, 82% 51%, 81% 60%, 80% 68%, 77% 75%, 74% 80%, 69% 82%, 63% 79%, 59% 75%, 57% 71%, 55% 78%, 53% 86%, 49% 93%, 45% 97%, 38% 100%, 33% 100%, 27% 99%, 21% 95%, 14% 87%, 8% 76%, 3% 65%, 0% 54%, 0% 45%, 2% 37%);
    }
    .tree-sub-item--objective-5 {
        -webkit-clip-path: polygon(37% 3%, 45% 7%, 51% 10%, 58% 11%, 65% 12%, 73% 12%, 80% 13%, 87% 16%, 93% 23%, 97% 29%, 100% 38%, 100% 44%, 100% 51%, 99% 57%, 95% 62%, 90% 65%, 85% 65%, 80% 63%, 81% 68%, 81% 75%, 79% 81%, 75% 85%, 70% 87%, 64% 86%, 59% 83%, 55% 77%, 55% 84%, 54% 90%, 51% 96%, 47% 100%, 43% 100%, 37% 100%, 34% 98%, 30% 94%, 26% 88%, 22% 80%, 20% 74%, 16% 68%, 11% 63%, 6% 60%, 2% 54%, 0% 47%, 0% 38%, 0% 32%, 2% 25%, 5% 18%, 8% 12%, 12% 8%, 16% 4%, 20% 1%, 26% 0%, 32% 0%);
        clip-path: polygon(37% 3%, 45% 7%, 51% 10%, 58% 11%, 65% 12%, 73% 12%, 80% 13%, 87% 16%, 93% 23%, 97% 29%, 100% 38%, 100% 44%, 100% 51%, 99% 57%, 95% 62%, 90% 65%, 85% 65%, 80% 63%, 81% 68%, 81% 75%, 79% 81%, 75% 85%, 70% 87%, 64% 86%, 59% 83%, 55% 77%, 55% 84%, 54% 90%, 51% 96%, 47% 100%, 43% 100%, 37% 100%, 34% 98%, 30% 94%, 26% 88%, 22% 80%, 20% 74%, 16% 68%, 11% 63%, 6% 60%, 2% 54%, 0% 47%, 0% 38%, 0% 32%, 2% 25%, 5% 18%, 8% 12%, 12% 8%, 16% 4%, 20% 1%, 26% 0%, 32% 0%);
    }
    .tree-sub-item--objective-7 {
        -webkit-clip-path: polygon(59% 0, 68% 5%, 75% 9%, 82% 14%, 88% 22%, 93% 28%, 97% 35%, 99% 43%, 100% 51%, 100% 58%, 99% 62%, 96% 68%, 91% 73%, 85% 76%, 80% 77%, 77% 76%, 76% 81%, 75% 87%, 72% 93%, 65% 97%, 58% 100%, 52% 100%, 46% 100%, 38% 99%, 30% 96%, 22% 92%, 15% 87%, 9% 79%, 5% 73%, 2% 65%, 0% 56%, 0% 43%, 1% 37%, 4% 30%, 7% 24%, 12% 18%, 17% 13%, 23% 8%, 31% 4%, 39% 1%, 49% 0%);
        clip-path: polygon(59% 0, 68% 5%, 75% 9%, 82% 14%, 88% 22%, 93% 28%, 97% 35%, 99% 43%, 100% 51%, 100% 58%, 99% 62%, 96% 68%, 91% 73%, 85% 76%, 80% 77%, 77% 76%, 76% 81%, 75% 87%, 72% 93%, 65% 97%, 58% 100%, 52% 100%, 46% 100%, 38% 99%, 30% 96%, 22% 92%, 15% 87%, 9% 79%, 5% 73%, 2% 65%, 0% 56%, 0% 43%, 1% 37%, 4% 30%, 7% 24%, 12% 18%, 17% 13%, 23% 8%, 31% 4%, 39% 1%, 49% 0%);
    }


    .tree-sub-item--children-1 {
        -webkit-clip-path: polygon(0% 72%, 2% 64%, 5% 54%, 8% 44%, 13% 33%, 18% 23%, 24% 15%, 29% 9%, 34% 4%, 41% 1%, 50% 0%, 58% 0%, 64% 2%, 71% 6%, 78% 12%, 84% 18%, 89% 26%, 96% 36%, 100% 45%, 99% 54%, 95% 62%, 92% 67%, 88% 70%, 84% 71%, 79% 67%, 76% 63%, 74% 57%, 72% 65%, 69% 73%, 66% 79%, 62% 81%, 57% 79%, 53% 73%, 50% 69%, 49% 64%, 48% 73%, 47% 83%, 45% 90%, 42% 95%, 38% 100%, 34% 100%, 29% 100%, 26% 98%, 22% 92%, 18% 86%, 14% 80%, 9% 75%, 3% 74%);
        clip-path: polygon(0% 72%, 2% 64%, 5% 54%, 8% 44%, 13% 33%, 18% 23%, 24% 15%, 29% 9%, 34% 4%, 41% 1%, 50% 0%, 58% 0%, 64% 2%, 71% 6%, 78% 12%, 84% 18%, 89% 26%, 96% 36%, 100% 45%, 99% 54%, 95% 62%, 92% 67%, 88% 70%, 84% 71%, 79% 67%, 76% 63%, 74% 57%, 72% 65%, 69% 73%, 66% 79%, 62% 81%, 57% 79%, 53% 73%, 50% 69%, 49% 64%, 48% 73%, 47% 83%, 45% 90%, 42% 95%, 38% 100%, 34% 100%, 29% 100%, 26% 98%, 22% 92%, 18% 86%, 14% 80%, 9% 75%, 3% 74%);
    }
    .tree-sub-item--children-3 {
        -webkit-clip-path: polygon(34% 4%, 55% 23%, 66% 29%, 82% 35%, 89% 41%, 93% 47%, 97% 55%, 100% 65%, 99% 77%, 94% 87%, 86% 92%, 79% 91%, 71% 85%, 69% 93%, 64% 99%, 58% 99%, 50% 96%, 44% 86%, 40% 77%, 36% 83%, 28% 88%, 21% 87%, 15% 80%, 10% 72%, 5% 61%, 2% 50%, 0 38%, 1% 27%, 3% 18%, 6% 12%, 10% 5%, 15% 2%, 23% 1%);
        clip-path: polygon(34% 4%, 55% 23%, 66% 29%, 82% 35%, 89% 41%, 93% 47%, 97% 55%, 100% 65%, 99% 77%, 94% 87%, 86% 92%, 79% 91%, 71% 85%, 69% 93%, 64% 99%, 58% 99%, 50% 96%, 44% 86%, 40% 77%, 36% 83%, 28% 88%, 21% 87%, 15% 80%, 10% 72%, 5% 61%, 2% 50%, 0 38%, 1% 27%, 3% 18%, 6% 12%, 10% 5%, 15% 2%, 23% 1%);
    }
    .tree-sub-item--children-5 {
        -webkit-clip-path: polygon(42% 0%, 51% 2%, 64% 6%, 72% 10%, 81% 19%, 90% 31%, 96% 43%, 99% 57%, 99% 68%, 95% 81%, 89% 89%, 82% 91%, 75% 88%, 66% 76%, 64% 85%, 61% 91%, 54% 93%, 47% 90%, 43% 86%, 38% 79%, 35% 75%, 32% 83%, 28% 90%, 21% 93%, 13% 89%, 7% 82%, 3% 73%, 2% 65%, 1% 55%, 2% 45%, 4% 35%, 6% 27%, 10% 20%, 14% 14%, 19% 9%, 25% 5%, 33% 2%);
        clip-path: polygon(42% 0%, 51% 2%, 64% 6%, 72% 10%, 81% 19%, 90% 31%, 96% 43%, 99% 57%, 99% 68%, 95% 81%, 89% 89%, 82% 91%, 75% 88%, 66% 76%, 64% 85%, 61% 91%, 54% 93%, 47% 90%, 43% 86%, 38% 79%, 35% 75%, 32% 83%, 28% 90%, 21% 93%, 13% 89%, 7% 82%, 3% 73%, 2% 65%, 1% 55%, 2% 45%, 4% 35%, 6% 27%, 10% 20%, 14% 14%, 19% 9%, 25% 5%, 33% 2%);
    }
    .tree-sub-item--children-7 {
        -webkit-clip-path: polygon(3% 17%, 7% 11%, 11% 5%, 15% 3%, 19% 2%, 24% 3%, 30% 5%, 36% 5%, 41% 5%, 47% 5%, 53% 4%, 58% 3%, 64% 0%, 73% 0%, 78% 0%, 82% 4%, 87% 9%, 90% 14%, 94% 22%, 97% 30%, 99% 39%, 100% 47%, 100% 56%, 99% 63%, 96% 70%, 94% 74%, 90% 76%, 87% 77%, 83% 76%, 81% 74%, 81% 82%, 80% 88%, 78% 94%, 76% 98%, 73% 100%, 68% 100%, 63% 100%, 59% 97%, 54% 93%, 50% 87%, 47% 81%, 46% 77%, 44% 84%, 42% 89%, 39% 94%, 35% 97%, 32% 98%, 27% 98%, 22% 96%, 17% 93%, 12% 87%, 8% 79%, 5% 73%, 3% 64%, 1% 56%, 0% 47%, 0% 37%, 0% 29%);
        clip-path: polygon(3% 17%, 7% 11%, 11% 5%, 15% 3%, 19% 2%, 24% 3%, 30% 5%, 36% 5%, 41% 5%, 47% 5%, 53% 4%, 58% 3%, 64% 0%, 73% 0%, 78% 0%, 82% 4%, 87% 9%, 90% 14%, 94% 22%, 97% 30%, 99% 39%, 100% 47%, 100% 56%, 99% 63%, 96% 70%, 94% 74%, 90% 76%, 87% 77%, 83% 76%, 81% 74%, 81% 82%, 80% 88%, 78% 94%, 76% 98%, 73% 100%, 68% 100%, 63% 100%, 59% 97%, 54% 93%, 50% 87%, 47% 81%, 46% 77%, 44% 84%, 42% 89%, 39% 94%, 35% 97%, 32% 98%, 27% 98%, 22% 96%, 17% 93%, 12% 87%, 8% 79%, 5% 73%, 3% 64%, 1% 56%, 0% 47%, 0% 37%, 0% 29%);
    }
    .tree-sub-item--children-9 {
        -webkit-clip-path: polygon(25% 9%, 33% 9%, 40% 8%, 47% 7%, 53% 5%, 58% 2%, 64% 0%, 74% 0%, 84% 1%, 89% 5%, 94% 12%, 98% 20%, 100% 28%, 100% 37%, 99% 44%, 97% 49%, 94% 52%, 90% 54%, 85% 54%, 81% 52%, 83% 59%, 84% 68%, 84% 76%, 83% 83%, 82% 89%, 79% 95%, 75% 99%, 69% 100%, 60% 100%, 55% 98%, 50% 94%, 46% 89%, 43% 92%, 39% 94%, 35% 94%, 31% 93%, 24% 90%, 18% 84%, 11% 77%, 7% 70%, 3% 61%, 0% 54%, 0% 50%, 0% 41%, 1% 35%, 3% 29%, 5% 25%, 7% 21%, 10% 17%, 17% 12%);
        clip-path: polygon(25% 9%, 33% 9%, 40% 8%, 47% 7%, 53% 5%, 58% 2%, 64% 0%, 74% 0%, 84% 1%, 89% 5%, 94% 12%, 98% 20%, 100% 28%, 100% 37%, 99% 44%, 97% 49%, 94% 52%, 90% 54%, 85% 54%, 81% 52%, 83% 59%, 84% 68%, 84% 76%, 83% 83%, 82% 89%, 79% 95%, 75% 99%, 69% 100%, 60% 100%, 55% 98%, 50% 94%, 46% 89%, 43% 92%, 39% 94%, 35% 94%, 31% 93%, 24% 90%, 18% 84%, 11% 77%, 7% 70%, 3% 61%, 0% 54%, 0% 50%, 0% 41%, 1% 35%, 3% 29%, 5% 25%, 7% 21%, 10% 17%, 17% 12%);
    }
    .tree-sub-item--children-10 {
        -webkit-clip-path: polygon(56% 2%, 65% 5%, 73% 9%, 80% 15%, 87% 20%, 93% 27%, 97% 34%, 99% 41%, 100% 46%, 100% 52%, 99% 56%, 97% 61%, 92% 64%, 88% 66%, 82% 66%, 83% 71%, 84% 76%, 83% 82%, 82% 88%, 79% 92%, 74% 96%, 69% 99%, 61% 100%, 54% 100%, 46% 100%, 38% 97%, 29% 93%, 22% 88%, 14% 82%, 9% 74%, 4% 67%, 2% 60%, 0% 52%, 0% 42%, 1% 35%, 2% 28%, 5% 22%, 9% 15%, 13% 11%, 18% 6%, 25% 3%, 31% 0%, 44% 0%, 51% 0%);
        clip-path: polygon(56% 2%, 65% 5%, 73% 9%, 80% 15%, 87% 20%, 93% 27%, 97% 34%, 99% 41%, 100% 46%, 100% 52%, 99% 56%, 97% 61%, 92% 64%, 88% 66%, 82% 66%, 83% 71%, 84% 76%, 83% 82%, 82% 88%, 79% 92%, 74% 96%, 69% 99%, 61% 100%, 54% 100%, 46% 100%, 38% 97%, 29% 93%, 22% 88%, 14% 82%, 9% 74%, 4% 67%, 2% 60%, 0% 52%, 0% 42%, 1% 35%, 2% 28%, 5% 22%, 9% 15%, 13% 11%, 18% 6%, 25% 3%, 31% 0%, 44% 0%, 51% 0%);
    }
    .tree-sub-item--children-2 {
        -webkit-clip-path: polygon(13% 33%, 18% 24%, 23% 15%, 28% 7%, 33% 3%, 40% 0%, 54% 0%, 63% 4%, 74% 10%, 81% 15%, 87% 22%, 94% 32%, 98% 40%, 100% 49%, 100% 60%, 99% 66%, 96% 73%, 90% 78%, 84% 80%, 79% 79%, 76% 77%, 76% 83%, 75% 90%, 72% 96%, 68% 99%, 62% 100%, 56% 99%, 52% 96%, 47% 93%, 41% 83%, 40% 88%, 38% 93%, 35% 97%, 31% 100%, 26% 100%, 22% 100%, 17% 98%, 10% 93%, 5% 86%, 2% 79%, 0% 71%, 0% 64%, 0% 58%, 2% 51%);
        clip-path: polygon(13% 33%, 18% 24%, 23% 15%, 28% 7%, 33% 3%, 40% 0%, 54% 0%, 63% 4%, 74% 10%, 81% 15%, 87% 22%, 94% 32%, 98% 40%, 100% 49%, 100% 60%, 99% 66%, 96% 73%, 90% 78%, 84% 80%, 79% 79%, 76% 77%, 76% 83%, 75% 90%, 72% 96%, 68% 99%, 62% 100%, 56% 99%, 52% 96%, 47% 93%, 41% 83%, 40% 88%, 38% 93%, 35% 97%, 31% 100%, 26% 100%, 22% 100%, 17% 98%, 10% 93%, 5% 86%, 2% 79%, 0% 71%, 0% 64%, 0% 58%, 2% 51%);
    }
    .tree-sub-item--children-4 {
        -webkit-clip-path: polygon(11% 23%, 17% 16%, 23% 10%, 29% 3%, 35% 0%, 46% 0%, 53% 2%, 62% 6%, 72% 12%, 79% 18%, 85% 26%, 92% 36%, 98% 49%, 100% 58%, 100% 66%, 99% 74%, 95% 81%, 90% 84%, 85% 84%, 81% 82%, 80% 79%, 80% 85%, 78% 90%, 76% 95%, 72% 99%, 67% 100%, 62% 100%, 57% 100%, 51% 96%, 45% 90%, 40% 83%, 36% 74%, 34% 66%, 32% 72%, 29% 77%, 25% 80%, 21% 81%, 15% 80%, 9% 76%, 5% 69%, 2% 63%, 0% 55%, 0% 47%, 0% 39%, 4% 32%, 6% 28%);
        clip-path: polygon(11% 23%, 17% 16%, 23% 10%, 29% 3%, 35% 0%, 46% 0%, 53% 2%, 62% 6%, 72% 12%, 79% 18%, 85% 26%, 92% 36%, 98% 49%, 100% 58%, 100% 66%, 99% 74%, 95% 81%, 90% 84%, 85% 84%, 81% 82%, 80% 79%, 80% 85%, 78% 90%, 76% 95%, 72% 99%, 67% 100%, 62% 100%, 57% 100%, 51% 96%, 45% 90%, 40% 83%, 36% 74%, 34% 66%, 32% 72%, 29% 77%, 25% 80%, 21% 81%, 15% 80%, 9% 76%, 5% 69%, 2% 63%, 0% 55%, 0% 47%, 0% 39%, 4% 32%, 6% 28%);
    }
    .tree-sub-item--children-6 {
        -webkit-clip-path: polygon(37% 3%, 41% 8%, 47% 12%, 53% 16%, 63% 21%, 70% 24%, 78% 28%, 86% 34%, 92% 41%, 97% 48%, 100% 55%, 100% 62%, 100% 69%, 97% 76%, 92% 82%, 84% 84%, 78% 83%, 74% 80%, 74% 86%, 73% 91%, 71% 96%, 68% 99%, 63% 100%, 56% 100%, 49% 96%, 44% 91%, 41% 84%, 39% 90%, 36% 94%, 32% 97%, 27% 98%, 20% 97%, 14% 93%, 8% 85%, 2% 76%, 0% 66%, 0% 55%, 1% 47%, 5% 37%, 8% 27%, 14% 20%, 18% 13%, 24% 7%, 30% 1%, 34% 0%);
        clip-path: polygon(37% 3%, 41% 8%, 47% 12%, 53% 16%, 63% 21%, 70% 24%, 78% 28%, 86% 34%, 92% 41%, 97% 48%, 100% 55%, 100% 62%, 100% 69%, 97% 76%, 92% 82%, 84% 84%, 78% 83%, 74% 80%, 74% 86%, 73% 91%, 71% 96%, 68% 99%, 63% 100%, 56% 100%, 49% 96%, 44% 91%, 41% 84%, 39% 90%, 36% 94%, 32% 97%, 27% 98%, 20% 97%, 14% 93%, 8% 85%, 2% 76%, 0% 66%, 0% 55%, 1% 47%, 5% 37%, 8% 27%, 14% 20%, 18% 13%, 24% 7%, 30% 1%, 34% 0%);
    }
    .tree-sub-item--children-8 {
        -webkit-clip-path: polygon(10% 8%, 17% 13%, 26% 17%, 36% 18%, 48% 17%, 59% 14%, 68% 11%, 77% 10%, 84% 12%, 90% 15%, 95% 20%, 99% 28%, 100% 36%, 100% 42%, 100% 51%, 97% 56%, 94% 61%, 90% 62%, 87% 62%, 90% 70%, 90% 78%, 90% 85%, 88% 91%, 84% 95%, 78% 95%, 73% 93%, 68% 88%, 64% 81%, 63% 87%, 61% 93%, 58% 97%, 53% 100%, 48% 100%, 42% 100%, 33% 97%, 24% 91%, 15% 80%, 10% 71%, 6% 61%, 2% 50%, 0% 37%, 0% 23%, 0% 14%, 0% 6%, 2% 0%, 5% 2%);
        clip-path: polygon(10% 8%, 17% 13%, 26% 17%, 36% 18%, 48% 17%, 59% 14%, 68% 11%, 77% 10%, 84% 12%, 90% 15%, 95% 20%, 99% 28%, 100% 36%, 100% 42%, 100% 51%, 97% 56%, 94% 61%, 90% 62%, 87% 62%, 90% 70%, 90% 78%, 90% 85%, 88% 91%, 84% 95%, 78% 95%, 73% 93%, 68% 88%, 64% 81%, 63% 87%, 61% 93%, 58% 97%, 53% 100%, 48% 100%, 42% 100%, 33% 97%, 24% 91%, 15% 80%, 10% 71%, 6% 61%, 2% 50%, 0% 37%, 0% 23%, 0% 14%, 0% 6%, 2% 0%, 5% 2%);
    }


    .tree-sub-item--education-item-1 {
        -webkit-clip-path: polygon(1% 58%, 5% 46%, 11% 33%, 19% 22%, 27% 12%, 35% 7%, 44% 2%, 52% 0%, 64% 1%, 74% 5%, 84% 11%, 93% 19%, 100% 29%, 100% 39%, 98% 46%, 92% 52%, 86% 54%, 82% 53%, 78% 50%, 78% 58%, 77% 67%, 74% 76%, 70% 82%, 66% 85%, 60% 84%, 55% 81%, 51% 77%, 48% 73%, 48% 80%, 47% 88%, 45% 95%, 42% 100%, 36% 100%, 31% 100%, 26% 96%, 20% 89%, 14% 80%, 9% 69%, 0 62%);
        clip-path: polygon(1% 58%, 5% 46%, 11% 33%, 19% 22%, 27% 12%, 35% 7%, 44% 2%, 52% 0%, 64% 1%, 74% 5%, 84% 11%, 93% 19%, 100% 29%, 100% 39%, 98% 46%, 92% 52%, 86% 54%, 82% 53%, 78% 50%, 78% 58%, 77% 67%, 74% 76%, 70% 82%, 66% 85%, 60% 84%, 55% 81%, 51% 77%, 48% 73%, 48% 80%, 47% 88%, 45% 95%, 42% 100%, 36% 100%, 31% 100%, 26% 96%, 20% 89%, 14% 80%, 9% 69%, 0 62%);
    }
    .tree-sub-item--education-item-3 {
        -webkit-clip-path: polygon(64% 7%, 73% 16%, 80% 24%, 89% 27%, 96% 31%, 100% 36%, 100% 44%, 100% 54%, 98% 61%, 92% 66%, 86% 68%, 82% 66%, 79% 64%, 80% 70%, 79% 76%, 77% 82%, 73% 87%, 68% 89%, 62% 89%, 58% 88%, 52% 84%, 47% 78%, 47% 85%, 46% 91%, 43% 96%, 39% 99%, 33% 100%, 26% 100%, 20% 98%, 13% 93%, 7% 87%, 2% 77%, 0% 64%, 0% 51%, 1% 41%, 4% 32%, 7% 25%, 11% 18%, 16% 12%, 21% 7%, 28% 3%, 34% 0%, 47% 0%, 55% 1%);
        clip-path: polygon(64% 7%, 73% 16%, 80% 24%, 89% 27%, 96% 31%, 100% 36%, 100% 44%, 100% 54%, 98% 61%, 92% 66%, 86% 68%, 82% 66%, 79% 64%, 80% 70%, 79% 76%, 77% 82%, 73% 87%, 68% 89%, 62% 89%, 58% 88%, 52% 84%, 47% 78%, 47% 85%, 46% 91%, 43% 96%, 39% 99%, 33% 100%, 26% 100%, 20% 98%, 13% 93%, 7% 87%, 2% 77%, 0% 64%, 0% 51%, 1% 41%, 4% 32%, 7% 25%, 11% 18%, 16% 12%, 21% 7%, 28% 3%, 34% 0%, 47% 0%, 55% 1%);
    }
    .tree-sub-item--education-item-5 {
        -webkit-clip-path: polygon(45% 6%, 54% 7%, 64% 11%, 70% 13%, 79% 19%, 87% 26%, 93% 35%, 98% 45%, 100% 54%, 100% 61%, 100% 67%, 98% 75%, 95% 83%, 89% 87%, 81% 87%, 74% 83%, 72% 90%, 70% 95%, 63% 100%, 56% 100%, 49% 98%, 41% 93%, 35% 85%, 31% 80%, 29% 85%, 24% 89%, 19% 89%, 15% 85%, 9% 77%, 6% 68%, 2% 58%, 0% 43%, 0% 25%, 1% 17%, 3% 10%, 7% 6%, 12% 1%, 19% 0%, 26% 0%, 32% 1%);
        clip-path: polygon(45% 6%, 54% 7%, 64% 11%, 70% 13%, 79% 19%, 87% 26%, 93% 35%, 98% 45%, 100% 54%, 100% 61%, 100% 67%, 98% 75%, 95% 83%, 89% 87%, 81% 87%, 74% 83%, 72% 90%, 70% 95%, 63% 100%, 56% 100%, 49% 98%, 41% 93%, 35% 85%, 31% 80%, 29% 85%, 24% 89%, 19% 89%, 15% 85%, 9% 77%, 6% 68%, 2% 58%, 0% 43%, 0% 25%, 1% 17%, 3% 10%, 7% 6%, 12% 1%, 19% 0%, 26% 0%, 32% 1%);
    }
    .tree-sub-item--education-item-8 {
        -webkit-clip-path: polygon(59% 30%, 67% 31%, 76% 31%, 82% 32%, 87% 34%, 92% 40%, 96% 45%, 100% 56%, 100% 66%, 100% 73%, 98% 81%, 95% 88%, 89% 95%, 82% 99%, 73% 100%, 64% 100%, 54% 99%, 42% 94%, 35% 88%, 32% 83%, 27% 84%, 20% 81%, 14% 76%, 9% 66%, 4% 55%, 1% 38%, 0% 28%, 0% 18%, 2% 11%, 5% 5%, 8% 2%, 13% 0%, 18% 0%, 23% 0%, 28% 4%, 32% 9%, 36% 15%, 46% 23%);
        clip-path: polygon(59% 30%, 67% 31%, 76% 31%, 82% 32%, 87% 34%, 92% 40%, 96% 45%, 100% 56%, 100% 66%, 100% 73%, 98% 81%, 95% 88%, 89% 95%, 82% 99%, 73% 100%, 64% 100%, 54% 99%, 42% 94%, 35% 88%, 32% 83%, 27% 84%, 20% 81%, 14% 76%, 9% 66%, 4% 55%, 1% 38%, 0% 28%, 0% 18%, 2% 11%, 5% 5%, 8% 2%, 13% 0%, 18% 0%, 23% 0%, 28% 4%, 32% 9%, 36% 15%, 46% 23%);
    }
    .tree-sub-item--education-item-2 {
        -webkit-clip-path: polygon(84% 7%, 90% 14%, 97% 25%, 99% 33%, 100% 41%, 100% 50%, 98% 56%, 94% 60%, 90% 61%, 86% 59%, 86% 66%, 85% 77%, 81% 83%, 75% 86%, 68% 84%, 62% 80%, 58% 74%, 58% 82%, 56% 88%, 51% 95%, 43% 99%, 34% 100%, 27% 100%, 18% 98%, 9% 94%, 0% 86%, 5% 79%, 13% 68%, 18% 58%, 24% 45%, 30% 29%, 35% 19%, 41% 9%, 48% 3%, 57% 0%, 68% 0%, 75% 2%);
        clip-path: polygon(84% 7%, 90% 14%, 97% 25%, 99% 33%, 100% 41%, 100% 50%, 98% 56%, 94% 60%, 90% 61%, 86% 59%, 86% 66%, 85% 77%, 81% 83%, 75% 86%, 68% 84%, 62% 80%, 58% 74%, 58% 82%, 56% 88%, 51% 95%, 43% 99%, 34% 100%, 27% 100%, 18% 98%, 9% 94%, 0% 86%, 5% 79%, 13% 68%, 18% 58%, 24% 45%, 30% 29%, 35% 19%, 41% 9%, 48% 3%, 57% 0%, 68% 0%, 75% 2%);
    }
    .tree-sub-item--education-item-6 {
        -webkit-clip-path: polygon(79% 10%, 88% 20%, 95% 33%, 99% 45%, 100% 53%, 100% 61%, 97% 68%, 92% 73%, 85% 73%, 80% 70%, 75% 63%, 75% 71%, 73% 80%, 70% 86%, 64% 91%, 58% 90%, 50% 84%, 43% 71%, 43% 81%, 41% 89%, 36% 97%, 32% 100%, 25% 100%, 17% 97%, 10% 88%, 5% 79%, 0% 66%, 0% 61%, 2% 53%, 5% 42%, 9% 32%, 15% 24%, 20% 16%, 26% 10%, 33% 5%, 40% 1%, 50% 0%, 61% 0%, 69% 4%);
        clip-path: polygon(79% 10%, 88% 20%, 95% 33%, 99% 45%, 100% 53%, 100% 61%, 97% 68%, 92% 73%, 85% 73%, 80% 70%, 75% 63%, 75% 71%, 73% 80%, 70% 86%, 64% 91%, 58% 90%, 50% 84%, 43% 71%, 43% 81%, 41% 89%, 36% 97%, 32% 100%, 25% 100%, 17% 97%, 10% 88%, 5% 79%, 0% 66%, 0% 61%, 2% 53%, 5% 42%, 9% 32%, 15% 24%, 20% 16%, 26% 10%, 33% 5%, 40% 1%, 50% 0%, 61% 0%, 69% 4%);
    }
    .tree-sub-item--education-item-9 {
        -webkit-clip-path: polygon(70% 5%, 80% 11%, 89% 17%, 96% 25%, 100% 34%, 100% 41%, 99% 46%, 96% 52%, 90% 55%, 85% 55%, 80% 52%, 81% 58%, 80% 65%, 78% 71%, 75% 73%, 70% 74%, 64% 72%, 66% 79%, 65% 84%, 65% 89%, 62% 95%, 58% 98%, 53% 100%, 46% 100%, 39% 100%, 31% 97%, 23% 93%, 15% 85%, 9% 77%, 4% 66%, 2% 59%, 0% 48%, 0% 37%, 0% 31%, 2% 22%, 5% 14%, 11% 8%, 20% 3%, 30% 0%, 40% 0%, 53% 0%);
        clip-path: polygon(70% 5%, 80% 11%, 89% 17%, 96% 25%, 100% 34%, 100% 41%, 99% 46%, 96% 52%, 90% 55%, 85% 55%, 80% 52%, 81% 58%, 80% 65%, 78% 71%, 75% 73%, 70% 74%, 64% 72%, 66% 79%, 65% 84%, 65% 89%, 62% 95%, 58% 98%, 53% 100%, 46% 100%, 39% 100%, 31% 97%, 23% 93%, 15% 85%, 9% 77%, 4% 66%, 2% 59%, 0% 48%, 0% 37%, 0% 31%, 2% 22%, 5% 14%, 11% 8%, 20% 3%, 30% 0%, 40% 0%, 53% 0%);
    }
    .tree-sub-item--education-item-10 {
        -webkit-clip-path: polygon(78% 11%, 88% 21%, 94% 31%, 98% 41%, 100% 49%, 100% 59%, 100% 70%, 98% 80%, 94% 90%, 88% 98%, 79% 100%, 70% 100%, 60% 95%, 51% 88%, 43% 77%, 42% 85%, 39% 92%, 34% 97%, 27% 98%, 22% 96%, 15% 91%, 8% 83%, 3% 73%, 0% 62%, 0% 52%, 0% 41%, 2% 30%, 6% 21%, 13% 13%, 20% 7%, 28% 2%, 36% 0%, 47% 0%, 56% 0%, 68% 4%);
        clip-path: polygon(78% 11%, 88% 21%, 94% 31%, 98% 41%, 100% 49%, 100% 59%, 100% 70%, 98% 80%, 94% 90%, 88% 98%, 79% 100%, 70% 100%, 60% 95%, 51% 88%, 43% 77%, 42% 85%, 39% 92%, 34% 97%, 27% 98%, 22% 96%, 15% 91%, 8% 83%, 3% 73%, 0% 62%, 0% 52%, 0% 41%, 2% 30%, 6% 21%, 13% 13%, 20% 7%, 28% 2%, 36% 0%, 47% 0%, 56% 0%, 68% 4%);
    }
    .tree-sub-item--education-item-7 {
        -webkit-clip-path: polygon(80% 9%, 88% 17%, 94% 26%, 98% 35%, 100% 43%, 100% 52%, 100% 59%, 96% 69%, 91% 75%, 85% 76%, 79% 76%, 73% 73%, 72% 81%, 70% 91%, 65% 96%, 59% 100%, 49% 100%, 44% 97%, 38% 90%, 33% 80%, 31% 85%, 25% 89%, 19% 89%, 13% 83%, 7% 76%, 3% 66%, 0% 51%, 0% 42%, 1% 35%, 4% 25%, 8% 18%, 15% 11%, 20% 7%, 28% 3%, 36% 0%, 46% 0%, 55% 0%, 65% 2%);
        clip-path: polygon(80% 9%, 88% 17%, 94% 26%, 98% 35%, 100% 43%, 100% 52%, 100% 59%, 96% 69%, 91% 75%, 85% 76%, 79% 76%, 73% 73%, 72% 81%, 70% 91%, 65% 96%, 59% 100%, 49% 100%, 44% 97%, 38% 90%, 33% 80%, 31% 85%, 25% 89%, 19% 89%, 13% 83%, 7% 76%, 3% 66%, 0% 51%, 0% 42%, 1% 35%, 4% 25%, 8% 18%, 15% 11%, 20% 7%, 28% 3%, 36% 0%, 46% 0%, 55% 0%, 65% 2%);
    }
    /*.tree__title--education {
        -webkit-clip-path: polygon(86% 23%, 94% 32%, 98% 43%, 100% 51%, 100% 60%, 98% 66%, 94% 69%, 89% 69%, 90% 76%, 90% 83%, 88% 91%, 85% 96%, 81% 99%, 75% 100%, 69% 100%, 60% 96%, 52% 90%, 46% 81%, 45% 86%, 42% 91%, 38% 95%, 33% 95%, 25% 93%, 20% 90%, 17% 86%, 14% 82%, 12% 76%, 11% 71%, 10% 65%, 10% 60%, 10% 54%, 11% 50%, 12% 45%, 13% 40%, 15% 36%, 18% 31%, 20% 28%, 23% 24%, 26% 21%, 29% 19%, 33% 17%, 36% 15%, 40% 13%, 44% 12%, 48% 11%, 52% 11%, 57% 11%, 61% 12%, 65% 13%, 72% 15%, 77% 17%, 82% 20%);
        clip-path: polygon(86% 23%, 94% 32%, 98% 43%, 100% 51%, 100% 60%, 98% 66%, 94% 69%, 89% 69%, 90% 76%, 90% 83%, 88% 91%, 85% 96%, 81% 99%, 75% 100%, 69% 100%, 60% 96%, 52% 90%, 46% 81%, 45% 86%, 42% 91%, 38% 95%, 33% 95%, 25% 93%, 20% 90%, 17% 86%, 14% 82%, 12% 76%, 11% 71%, 10% 65%, 10% 60%, 10% 54%, 11% 50%, 12% 45%, 13% 40%, 15% 36%, 18% 31%, 20% 28%, 23% 24%, 26% 21%, 29% 19%, 33% 17%, 36% 15%, 40% 13%, 44% 12%, 48% 11%, 52% 11%, 57% 11%, 61% 12%, 65% 13%, 72% 15%, 77% 17%, 82% 20%);
    }*/


    .tree-sub-item--future-child-1 {
        -webkit-clip-path: polygon(52% 2%, 58% 3%, 66% 6%, 71% 10%, 76% 14%, 81% 19%, 86% 27%, 92% 39%, 96% 52%, 99% 66%, 99% 80%, 96% 87%, 92% 88%, 88% 85%, 85% 77%, 82% 81%, 78% 81%, 75% 77%, 72% 69%, 70% 76%, 66% 80%, 62% 80%, 58% 77%, 55% 72%, 54% 79%, 52% 85%, 47% 89%, 41% 89%, 36% 89%, 31% 87%, 27% 87%, 23% 87%, 18% 87%, 11% 89%, 4% 93%, 1% 94%, 2% 87%, 3% 78%, 4% 69%, 7% 60%, 10% 50%, 13% 40%, 16% 32%, 21% 24%, 25% 18%, 33% 11%, 40% 6%);
        clip-path: polygon(52% 2%, 58% 3%, 66% 6%, 71% 10%, 76% 14%, 81% 19%, 86% 27%, 92% 39%, 96% 52%, 99% 66%, 99% 80%, 96% 87%, 92% 88%, 88% 85%, 85% 77%, 82% 81%, 78% 81%, 75% 77%, 72% 69%, 70% 76%, 66% 80%, 62% 80%, 58% 77%, 55% 72%, 54% 79%, 52% 85%, 47% 89%, 41% 89%, 36% 89%, 31% 87%, 27% 87%, 23% 87%, 18% 87%, 11% 89%, 4% 93%, 1% 94%, 2% 87%, 3% 78%, 4% 69%, 7% 60%, 10% 50%, 13% 40%, 16% 32%, 21% 24%, 25% 18%, 33% 11%, 40% 6%);
    }
    .tree-sub-item--future-child-2 {
        -webkit-clip-path: polygon(39% 0%, 51% 1%, 61% 2%, 71% 5%, 80% 9%, 86% 14%, 93% 21%, 96% 27%, 97% 34%, 95% 39%, 92% 42%, 95% 46%, 97% 51%, 99% 57%, 99% 64%, 98% 70%, 95% 75%, 91% 77%, 84% 78%, 76% 75%, 76% 80%, 76% 86%, 72% 92%, 69% 96%, 63% 99%, 56% 100%, 48% 99%, 42% 97%, 33% 92%, 28% 87%, 23% 82%, 19% 76%, 14% 69%, 10% 61%, 5% 52%, 3% 46%, 1% 36%, 0% 28%, 0 20%, 1% 12%, 5% 7%, 9% 5%, 18% 3%, 25% 1%);
        clip-path: polygon(39% 0%, 51% 1%, 61% 2%, 71% 5%, 80% 9%, 86% 14%, 93% 21%, 96% 27%, 97% 34%, 95% 39%, 92% 42%, 95% 46%, 97% 51%, 99% 57%, 99% 64%, 98% 70%, 95% 75%, 91% 77%, 84% 78%, 76% 75%, 76% 80%, 76% 86%, 72% 92%, 69% 96%, 63% 99%, 56% 100%, 48% 99%, 42% 97%, 33% 92%, 28% 87%, 23% 82%, 19% 76%, 14% 69%, 10% 61%, 5% 52%, 3% 46%, 1% 36%, 0% 28%, 0 20%, 1% 12%, 5% 7%, 9% 5%, 18% 3%, 25% 1%);
    }
    .tree-sub-item--future-child-3 {
        -webkit-clip-path: polygon(66% 10%, 75% 22%, 82% 35%, 91% 43%, 100% 47%, 100% 57%, 99% 66%, 96% 77%, 92% 82%, 87% 81%, 81% 78%, 81% 86%, 79% 96%, 73% 100%, 68% 100%, 64% 96%, 61% 89%, 59% 83%, 57% 93%, 54% 98%, 49% 100%, 44% 100%, 37% 93%, 32% 82%, 27% 71%, 20% 60%, 12% 50%, 0% 41%, 2% 33%, 10% 17%, 19% 8%, 28% 0%, 44% 0%, 54% 2%);
        clip-path: polygon(66% 10%, 75% 22%, 82% 35%, 91% 43%, 100% 47%, 100% 57%, 99% 66%, 96% 77%, 92% 82%, 87% 81%, 81% 78%, 81% 86%, 79% 96%, 73% 100%, 68% 100%, 64% 96%, 61% 89%, 59% 83%, 57% 93%, 54% 98%, 49% 100%, 44% 100%, 37% 93%, 32% 82%, 27% 71%, 20% 60%, 12% 50%, 0% 41%, 2% 33%, 10% 17%, 19% 8%, 28% 0%, 44% 0%, 54% 2%);
    }
    .tree-sub-item--future-child-4 {
        -webkit-clip-path: polygon(46% 10%, 54% 12%, 64% 11%, 73% 9%, 81% 9%, 88% 11%, 94% 17%, 98% 26%, 100% 35%, 100% 42%, 100% 52%, 98% 59%, 93% 65%, 88% 70%, 82% 69%, 78% 67%, 80% 76%, 79% 83%, 77% 90%, 75% 96%, 69% 100%, 64% 100%, 59% 100%, 52% 97%, 45% 90%, 40% 83%, 39% 90%, 37% 96%, 33% 97%, 27% 98%, 22% 97%, 15% 92%, 9% 85%, 4% 76%, 1% 65%, 0% 54%, 0% 42%, 0% 34%, 2% 26%, 5% 15%, 9% 7%, 14% 0%, 30% 7%);
        clip-path: polygon(46% 10%, 54% 12%, 64% 11%, 73% 9%, 81% 9%, 88% 11%, 94% 17%, 98% 26%, 100% 35%, 100% 42%, 100% 52%, 98% 59%, 93% 65%, 88% 70%, 82% 69%, 78% 67%, 80% 76%, 79% 83%, 77% 90%, 75% 96%, 69% 100%, 64% 100%, 59% 100%, 52% 97%, 45% 90%, 40% 83%, 39% 90%, 37% 96%, 33% 97%, 27% 98%, 22% 97%, 15% 92%, 9% 85%, 4% 76%, 1% 65%, 0% 54%, 0% 42%, 0% 34%, 2% 26%, 5% 15%, 9% 7%, 14% 0%, 30% 7%);
    }
    .tree-sub-item--future-child-6 {
        -webkit-clip-path: polygon(49% 13%, 58% 13%, 68% 13%, 77% 17%, 85% 22%, 91% 30%, 95% 38%, 98% 49%, 100% 61%, 100% 69%, 100% 78%, 97% 87%, 92% 95%, 87% 98%, 81% 97%, 76% 92%, 73% 86%, 72% 93%, 69% 98%, 64% 100%, 57% 100%, 51% 95%, 45% 87%, 43% 80%, 41% 73%, 39% 81%, 35% 86%, 29% 89%, 23% 87%, 16% 82%, 10% 70%, 5% 57%, 2% 46%, 0% 28%, 0% 16%, 2% 8%, 6% 4%, 10% 0%, 14% 0%, 20% 2%, 25% 7%, 35% 11%);
        clip-path: polygon(49% 13%, 58% 13%, 68% 13%, 77% 17%, 85% 22%, 91% 30%, 95% 38%, 98% 49%, 100% 61%, 100% 69%, 100% 78%, 97% 87%, 92% 95%, 87% 98%, 81% 97%, 76% 92%, 73% 86%, 72% 93%, 69% 98%, 64% 100%, 57% 100%, 51% 95%, 45% 87%, 43% 80%, 41% 73%, 39% 81%, 35% 86%, 29% 89%, 23% 87%, 16% 82%, 10% 70%, 5% 57%, 2% 46%, 0% 28%, 0% 16%, 2% 8%, 6% 4%, 10% 0%, 14% 0%, 20% 2%, 25% 7%, 35% 11%);
    }
    .tree-sub-item--future-child-9 {
        -webkit-clip-path: polygon(49% 3%, 60% 8%, 73% 17%, 85% 28%, 92% 40%, 98% 52%, 100% 62%, 100% 70%, 100% 82%, 95% 93%, 85% 99%, 76% 100%, 66% 99%, 57% 91%, 53% 85%, 52% 80%, 48% 85%, 42% 88%, 33% 86%, 28% 80%, 25% 74%, 24% 67%, 24% 61%, 19% 64%, 14% 64%, 9% 62%, 4% 55%, 0% 45%, 0% 31%, 0% 23%, 4% 13%, 9% 6%, 18% 1%, 27% 0, 40% 1%);
        clip-path: polygon(49% 3%, 60% 8%, 73% 17%, 85% 28%, 92% 40%, 98% 52%, 100% 62%, 100% 70%, 100% 82%, 95% 93%, 85% 99%, 76% 100%, 66% 99%, 57% 91%, 53% 85%, 52% 80%, 48% 85%, 42% 88%, 33% 86%, 28% 80%, 25% 74%, 24% 67%, 24% 61%, 19% 64%, 14% 64%, 9% 62%, 4% 55%, 0% 45%, 0% 31%, 0% 23%, 4% 13%, 9% 6%, 18% 1%, 27% 0, 40% 1%);
    }
    .tree-sub-item--future-child-10 {
        -webkit-clip-path: polygon(60% 22%, 73% 27%, 86% 35%, 93% 44%, 97% 52%, 100% 60%, 100% 67%, 100% 75%, 99% 83%, 96% 91%, 92% 97%, 86% 100%, 80% 100%, 70% 97%, 63% 90%, 58% 80%, 54% 71%, 53% 62%, 50% 68%, 44% 74%, 37% 76%, 29% 74%, 20% 68%, 13% 59%, 5% 44%, 2% 34%, 0% 23%, 0% 13%, 1% 6%, 5% 2%, 11% 0%, 15% 0%, 20% 0%, 30% 3%, 40% 9%, 49% 17%);
        clip-path: polygon(60% 22%, 73% 27%, 86% 35%, 93% 44%, 97% 52%, 100% 60%, 100% 67%, 100% 75%, 99% 83%, 96% 91%, 92% 97%, 86% 100%, 80% 100%, 70% 97%, 63% 90%, 58% 80%, 54% 71%, 53% 62%, 50% 68%, 44% 74%, 37% 76%, 29% 74%, 20% 68%, 13% 59%, 5% 44%, 2% 34%, 0% 23%, 0% 13%, 1% 6%, 5% 2%, 11% 0%, 15% 0%, 20% 0%, 30% 3%, 40% 9%, 49% 17%);
    }
    .tree-sub-item--future-child-8 {
        -webkit-clip-path: polygon(68% 7%, 79% 14%, 89% 24%, 96% 34%, 100% 42%, 100% 49%, 100% 57%, 96% 63%, 90% 67%, 83% 68%, 77% 65%, 73% 60%, 74% 68%, 74% 77%, 70% 85%, 65% 90%, 57% 89%, 51% 86%, 47% 81%, 47% 87%, 45% 95%, 41% 99%, 37% 100%, 31% 100%, 26% 98%, 20% 94%, 13% 86%, 6% 72%, 0% 55%, 0% 46%, 0% 36%, 0% 27%, 3% 18%, 9% 10%, 14% 5%, 27% 0%, 42% 0%, 52% 1%);
        clip-path: polygon(68% 7%, 79% 14%, 89% 24%, 96% 34%, 100% 42%, 100% 49%, 100% 57%, 96% 63%, 90% 67%, 83% 68%, 77% 65%, 73% 60%, 74% 68%, 74% 77%, 70% 85%, 65% 90%, 57% 89%, 51% 86%, 47% 81%, 47% 87%, 45% 95%, 41% 99%, 37% 100%, 31% 100%, 26% 98%, 20% 94%, 13% 86%, 6% 72%, 0% 55%, 0% 46%, 0% 36%, 0% 27%, 3% 18%, 9% 10%, 14% 5%, 27% 0%, 42% 0%, 52% 1%);
    }
    .tree-sub-item--future-child-7 {
        -webkit-clip-path: polygon(78% 12%, 85% 17%, 91% 24%, 97% 33%, 100% 42%, 100% 48%, 100% 56%, 98% 61%, 95% 67%, 91% 70%, 86% 70%, 82% 68%, 79% 66%, 80% 74%, 79% 82%, 77% 90%, 71% 96%, 65% 100%, 60% 100%, 54% 100%, 45% 96%, 35% 90%, 27% 82%, 19% 74%, 12% 64%, 6% 52%, 2% 42%, 0% 35%, 1% 26%, 4% 19%, 8% 12%, 13% 7%, 20% 2%, 29% 0%, 42% 0%, 51% 1%, 63% 5%);
        clip-path: polygon(78% 12%, 85% 17%, 91% 24%, 97% 33%, 100% 42%, 100% 48%, 100% 56%, 98% 61%, 95% 67%, 91% 70%, 86% 70%, 82% 68%, 79% 66%, 80% 74%, 79% 82%, 77% 90%, 71% 96%, 65% 100%, 60% 100%, 54% 100%, 45% 96%, 35% 90%, 27% 82%, 19% 74%, 12% 64%, 6% 52%, 2% 42%, 0% 35%, 1% 26%, 4% 19%, 8% 12%, 13% 7%, 20% 2%, 29% 0%, 42% 0%, 51% 1%, 63% 5%);
    }
    .tree-sub-item--future-child-5 {
        -webkit-clip-path: polygon(69% 10%, 79% 19%, 88% 29%, 96% 41%, 100% 56%, 100% 65%, 99% 73%, 94% 79%, 90% 81%, 85% 78%, 83% 75%, 83% 82%, 82% 89%, 79% 96%, 76% 98%, 70% 99%, 64% 98%, 58% 93%, 54% 88%, 51% 82%, 51% 89%, 49% 95%, 45% 99%, 41% 100%, 37% 100%, 30% 97%, 22% 90%, 16% 80%, 10% 71%, 5% 61%, 3% 52%, 0% 40%, 0% 28%, 0% 20%, 3% 14%, 7% 8%, 12% 4%, 19% 1%, 26% 0%, 37% 0%, 43% 0%, 54% 3%);
        clip-path: polygon(69% 10%, 79% 19%, 88% 29%, 96% 41%, 100% 56%, 100% 65%, 99% 73%, 94% 79%, 90% 81%, 85% 78%, 83% 75%, 83% 82%, 82% 89%, 79% 96%, 76% 98%, 70% 99%, 64% 98%, 58% 93%, 54% 88%, 51% 82%, 51% 89%, 49% 95%, 45% 99%, 41% 100%, 37% 100%, 30% 97%, 22% 90%, 16% 80%, 10% 71%, 5% 61%, 3% 52%, 0% 40%, 0% 28%, 0% 20%, 3% 14%, 7% 8%, 12% 4%, 19% 1%, 26% 0%, 37% 0%, 43% 0%, 54% 3%);
    }
    /*.tree__title--future-children {
        -webkit-clip-path: polygon(4% 12%, 9% 11%, 14% 9%, 20% 8%, 26% 7%, 32% 6%, 38% 6%, 44% 7%, 50% 8%, 55% 9%, 60% 10%, 66% 12%, 72% 15%, 77% 18%, 81% 22%, 84% 26%, 86% 31%, 88% 36%, 87% 40%, 86% 43%, 84% 44%, 82% 45%, 84% 48%, 87% 51%, 88% 55%, 89% 59%, 90% 64%, 89% 68%, 87% 72%, 85% 74%, 82% 76%, 78% 77%, 74% 77%, 69% 75%, 67% 74%, 67% 78%, 67% 81%, 67% 86%, 64% 91%, 60% 95%, 53% 98%, 46% 98%, 39% 96%, 32% 92%, 24% 85%, 19% 79%, 14% 72%, 9% 63%, 5% 52%, 3% 44%, 2% 38%, 1% 31%, 1% 21%);
        clip-path: polygon(4% 12%, 9% 11%, 14% 9%, 20% 8%, 26% 7%, 32% 6%, 38% 6%, 44% 7%, 50% 8%, 55% 9%, 60% 10%, 66% 12%, 72% 15%, 77% 18%, 81% 22%, 84% 26%, 86% 31%, 88% 36%, 87% 40%, 86% 43%, 84% 44%, 82% 45%, 84% 48%, 87% 51%, 88% 55%, 89% 59%, 90% 64%, 89% 68%, 87% 72%, 85% 74%, 82% 76%, 78% 77%, 74% 77%, 69% 75%, 67% 74%, 67% 78%, 67% 81%, 67% 86%, 64% 91%, 60% 95%, 53% 98%, 46% 98%, 39% 96%, 32% 92%, 24% 85%, 19% 79%, 14% 72%, 9% 63%, 5% 52%, 3% 44%, 2% 38%, 1% 31%, 1% 21%);
    }*/


    .tree-sub-item--future-education-item-1 {
        -webkit-clip-path: polygon(83% 7%, 90% 15%, 95% 22%, 98% 29%, 100% 38%, 100% 45%, 100% 54%, 99% 62%, 97% 69%, 93% 75%, 89% 77%, 84% 77%, 81% 75%, 77% 70%, 77% 79%, 76% 89%, 73% 97%, 68% 100%, 63% 100%, 58% 98%, 53% 90%, 50% 83%, 47% 74%, 46% 82%, 44% 90%, 40% 94%, 35% 96%, 30% 95%, 26% 92%, 23% 86%, 19% 78%, 15% 69%, 8% 63%, 0% 60%, 0% 54%, 3% 47%, 8% 38%, 13% 30%, 19% 23%, 23% 17%, 29% 11%, 35% 6%, 41% 2%, 48% 0%, 57% 0%, 65% 0%, 72% 2%);
        clip-path: polygon(83% 7%, 90% 15%, 95% 22%, 98% 29%, 100% 38%, 100% 45%, 100% 54%, 99% 62%, 97% 69%, 93% 75%, 89% 77%, 84% 77%, 81% 75%, 77% 70%, 77% 79%, 76% 89%, 73% 97%, 68% 100%, 63% 100%, 58% 98%, 53% 90%, 50% 83%, 47% 74%, 46% 82%, 44% 90%, 40% 94%, 35% 96%, 30% 95%, 26% 92%, 23% 86%, 19% 78%, 15% 69%, 8% 63%, 0% 60%, 0% 54%, 3% 47%, 8% 38%, 13% 30%, 19% 23%, 23% 17%, 29% 11%, 35% 6%, 41% 2%, 48% 0%, 57% 0%, 65% 0%, 72% 2%);
    }
    .tree-sub-item--future-education-item-3 {
        -webkit-clip-path: polygon(68% 5%, 75% 10%, 81% 17%, 86% 24%, 91% 32%, 95% 40%, 99% 48%, 100% 56%, 100% 62%, 98% 66%, 96% 69%, 92% 72%, 85% 73%, 85% 77%, 85% 82%, 82% 88%, 78% 91%, 72% 93%, 66% 92%, 60% 90%, 55% 86%, 53% 84%, 52% 89%, 51% 94%, 47% 98%, 43% 100%, 38% 100%, 34% 100%, 26% 98%, 20% 95%, 14% 90%, 8% 84%, 0% 75%, 1% 73%, 4% 72%, 7% 69%, 13% 62%, 16% 54%, 19% 46%, 21% 39%, 23% 33%, 26% 26%, 29% 19%, 34% 13%, 38% 8%, 43% 4%, 49% 0%, 57% 0%, 63% 0%);
        clip-path: polygon(68% 5%, 75% 10%, 81% 17%, 86% 24%, 91% 32%, 95% 40%, 99% 48%, 100% 56%, 100% 62%, 98% 66%, 96% 69%, 92% 72%, 85% 73%, 85% 77%, 85% 82%, 82% 88%, 78% 91%, 72% 93%, 66% 92%, 60% 90%, 55% 86%, 53% 84%, 52% 89%, 51% 94%, 47% 98%, 43% 100%, 38% 100%, 34% 100%, 26% 98%, 20% 95%, 14% 90%, 8% 84%, 0% 75%, 1% 73%, 4% 72%, 7% 69%, 13% 62%, 16% 54%, 19% 46%, 21% 39%, 23% 33%, 26% 26%, 29% 19%, 34% 13%, 38% 8%, 43% 4%, 49% 0%, 57% 0%, 63% 0%);
    }
    .tree-sub-item--future-education-item-6 {
        -webkit-clip-path: polygon(49% 17%, 56% 18%, 69% 15%, 79% 13%, 86% 15%, 92% 19%, 95% 25%, 100% 38%, 100% 47%, 100% 53%, 99% 60%, 96% 68%, 92% 74%, 87% 77%, 80% 77%, 79% 85%, 75% 93%, 69% 99%, 63% 100%, 56% 100%, 49% 96%, 42% 89%, 37% 80%, 33% 69%, 30% 76%, 26% 80%, 20% 81%, 15% 77%, 8% 67%, 4% 58%, 1% 43%, 0% 33%, 0% 18%, 1% 12%, 4% 4%, 9% 0%, 14% 0%, 19% 3%, 25% 8%, 34% 15%);
        clip-path: polygon(49% 17%, 56% 18%, 69% 15%, 79% 13%, 86% 15%, 92% 19%, 95% 25%, 100% 38%, 100% 47%, 100% 53%, 99% 60%, 96% 68%, 92% 74%, 87% 77%, 80% 77%, 79% 85%, 75% 93%, 69% 99%, 63% 100%, 56% 100%, 49% 96%, 42% 89%, 37% 80%, 33% 69%, 30% 76%, 26% 80%, 20% 81%, 15% 77%, 8% 67%, 4% 58%, 1% 43%, 0% 33%, 0% 18%, 1% 12%, 4% 4%, 9% 0%, 14% 0%, 19% 3%, 25% 8%, 34% 15%);
    }
    .tree-sub-item--future-education-item-5 {
        -webkit-clip-path: polygon(65% 7%, 75% 14%, 86% 25%, 92% 34%, 98% 49%, 99% 59%, 100% 71%, 99% 81%, 97% 91%, 92% 97%, 85% 100%, 80% 100%, 74% 100%, 68% 96%, 60% 90%, 54% 80%, 46% 70%, 39% 62%, 30% 56%, 22% 55%, 12% 54%, 5% 54%, 1% 47%, 0% 41%, 0% 32%, 2% 25%, 4% 19%, 9% 14%, 13% 10%, 18% 6%, 25% 3%, 32% 0%, 39% 0%, 46% 0%, 56% 2%);
        clip-path: polygon(65% 7%, 75% 14%, 86% 25%, 92% 34%, 98% 49%, 99% 59%, 100% 71%, 99% 81%, 97% 91%, 92% 97%, 85% 100%, 80% 100%, 74% 100%, 68% 96%, 60% 90%, 54% 80%, 46% 70%, 39% 62%, 30% 56%, 22% 55%, 12% 54%, 5% 54%, 1% 47%, 0% 41%, 0% 32%, 2% 25%, 4% 19%, 9% 14%, 13% 10%, 18% 6%, 25% 3%, 32% 0%, 39% 0%, 46% 0%, 56% 2%);
    }
    .tree-sub-item--future-education-item-4 {
        -webkit-clip-path: polygon(69% 4%, 77% 7%, 85% 12%, 91% 16%, 96% 22%, 99% 28%, 100% 32%, 100% 37%, 99% 41%, 97% 45%, 94% 50%, 88% 53%, 83% 55%, 84% 61%, 84% 68%, 84% 75%, 82% 84%, 79% 92%, 73% 97%, 65% 100%, 59% 100%, 52% 100%, 41% 96%, 31% 89%, 21% 80%, 14% 70%, 8% 60%, 4% 50%, 2% 42%, 0% 34%, 0% 24%, 1% 18%, 4% 12%, 9% 7%, 16% 3%, 28% 0%, 37% 0%, 51% 0%);
        clip-path: polygon(69% 4%, 77% 7%, 85% 12%, 91% 16%, 96% 22%, 99% 28%, 100% 32%, 100% 37%, 99% 41%, 97% 45%, 94% 50%, 88% 53%, 83% 55%, 84% 61%, 84% 68%, 84% 75%, 82% 84%, 79% 92%, 73% 97%, 65% 100%, 59% 100%, 52% 100%, 41% 96%, 31% 89%, 21% 80%, 14% 70%, 8% 60%, 4% 50%, 2% 42%, 0% 34%, 0% 24%, 1% 18%, 4% 12%, 9% 7%, 16% 3%, 28% 0%, 37% 0%, 51% 0%);
    }
    .tree-sub-item--future-education-item-2 {
        -webkit-clip-path: polygon(71% 5%, 80% 10%, 88% 17%, 93% 23%, 97% 31%, 100% 38%, 100% 45%, 100% 52%, 96% 57%, 91% 61%, 86% 61%, 80% 59%, 81% 63%, 81% 69%, 79% 74%, 75% 81%, 68% 84%, 60% 84%, 54% 81%, 49% 77%, 49% 81%, 48% 88%, 46% 93%, 42% 98%, 37% 100%, 32% 100%, 26% 100%, 19% 97%, 12% 93%, 7% 87%, 2% 79%, 0% 70%, 0% 60%, 0% 52%, 1% 45%, 2% 39%, 5% 29%, 10% 19%, 16% 11%, 22% 5%, 29% 2%, 38% 0%, 47% 0%, 56% 0%);
        clip-path: polygon(71% 5%, 80% 10%, 88% 17%, 93% 23%, 97% 31%, 100% 38%, 100% 45%, 100% 52%, 96% 57%, 91% 61%, 86% 61%, 80% 59%, 81% 63%, 81% 69%, 79% 74%, 75% 81%, 68% 84%, 60% 84%, 54% 81%, 49% 77%, 49% 81%, 48% 88%, 46% 93%, 42% 98%, 37% 100%, 32% 100%, 26% 100%, 19% 97%, 12% 93%, 7% 87%, 2% 79%, 0% 70%, 0% 60%, 0% 52%, 1% 45%, 2% 39%, 5% 29%, 10% 19%, 16% 11%, 22% 5%, 29% 2%, 38% 0%, 47% 0%, 56% 0%);
    }

    /*.tree__title--future-education {
        -webkit-clip-path: polygon(16% 9%, 22% 8%, 26% 8%, 30% 10%, 33% 12%, 37% 15%, 41% 17%, 46% 19%, 50% 20%, 55% 21%, 60% 23%, 64% 25%, 68% 28%, 72% 31%, 77% 36%, 81% 41%, 84% 46%, 87% 51%, 89% 57%, 90% 61%, 91% 66%, 91% 74%, 91% 79%, 91% 83%, 90% 87%, 88% 92%, 85% 95%, 82% 97%, 79% 99%, 75% 99%, 69% 99%, 64% 96%, 60% 93%, 55% 87%, 52% 82%, 49% 76%, 48% 70%, 46% 74%, 43% 77%, 40% 79%, 34% 79%, 29% 78%, 23% 73%, 18% 68%, 14% 62%, 10% 53%, 7% 44%, 6% 39%, 5% 34%, 5% 29%, 6% 24%, 7% 19%, 9% 15%, 12% 12%);
        clip-path: polygon(16% 9%, 22% 8%, 26% 8%, 30% 10%, 33% 12%, 37% 15%, 41% 17%, 46% 19%, 50% 20%, 55% 21%, 60% 23%, 64% 25%, 68% 28%, 72% 31%, 77% 36%, 81% 41%, 84% 46%, 87% 51%, 89% 57%, 90% 61%, 91% 66%, 91% 74%, 91% 79%, 91% 83%, 90% 87%, 88% 92%, 85% 95%, 82% 97%, 79% 99%, 75% 99%, 69% 99%, 64% 96%, 60% 93%, 55% 87%, 52% 82%, 49% 76%, 48% 70%, 46% 74%, 43% 77%, 40% 79%, 34% 79%, 29% 78%, 23% 73%, 18% 68%, 14% 62%, 10% 53%, 7% 44%, 6% 39%, 5% 34%, 5% 29%, 6% 24%, 7% 19%, 9% 15%, 12% 12%);
    }*/
    .tree-sub-item--future-education-item-7 {
        -webkit-clip-path: polygon(56% 15%, 63% 14%, 71% 13%, 78% 14%, 85% 18%, 91% 23%, 95% 30%, 98% 38%, 100% 47%, 100% 54%, 100% 62%, 100% 70%, 99% 77%, 96% 84%, 92% 89%, 86% 92%, 81% 91%, 76% 88%, 72% 84%, 70% 79%, 69% 86%, 66% 91%, 62% 95%, 58% 96%, 53% 94%, 48% 89%, 43% 83%, 39% 73%, 38% 79%, 36% 87%, 32% 96%, 27% 99%, 19% 98%, 14% 95%, 9% 88%, 5% 79%, 2% 69%, 0% 57%, 0% 50%, 0% 43%, 0% 37%, 1% 29%, 3% 22%, 6% 15%, 10% 9%, 14% 4%, 19% 0%, 23% 0%, 28% 0%, 32% 0%, 39% 5%, 46% 12%);
        clip-path: polygon(56% 15%, 63% 14%, 71% 13%, 78% 14%, 85% 18%, 91% 23%, 95% 30%, 98% 38%, 100% 47%, 100% 54%, 100% 62%, 100% 70%, 99% 77%, 96% 84%, 92% 89%, 86% 92%, 81% 91%, 76% 88%, 72% 84%, 70% 79%, 69% 86%, 66% 91%, 62% 95%, 58% 96%, 53% 94%, 48% 89%, 43% 83%, 39% 73%, 38% 79%, 36% 87%, 32% 96%, 27% 99%, 19% 98%, 14% 95%, 9% 88%, 5% 79%, 2% 69%, 0% 57%, 0% 50%, 0% 43%, 0% 37%, 1% 29%, 3% 22%, 6% 15%, 10% 9%, 14% 4%, 19% 0%, 23% 0%, 28% 0%, 32% 0%, 39% 5%, 46% 12%);
    }
    .tree-sub-item--future-education-item-8 {
        -webkit-clip-path: polygon(53% 8%, 67% 16%, 80% 28%, 91% 38%, 98% 49%, 100% 58%, 100% 65%, 99% 73%, 96% 78%, 91% 80%, 87% 79%, 82% 75%, 82% 82%, 81% 91%, 78% 97%, 73% 100%, 66% 100%, 60% 98%, 54% 93%, 49% 86%, 46% 79%, 43% 89%, 38% 93%, 31% 94%, 23% 90%, 15% 81%, 9% 69%, 3% 55%, 0% 42%, 0% 32%, 0% 21%, 2% 13%, 6% 7%, 10% 3%, 17% 0%, 24% 0%, 34% 1%);
        clip-path: polygon(53% 8%, 67% 16%, 80% 28%, 91% 38%, 98% 49%, 100% 58%, 100% 65%, 99% 73%, 96% 78%, 91% 80%, 87% 79%, 82% 75%, 82% 82%, 81% 91%, 78% 97%, 73% 100%, 66% 100%, 60% 98%, 54% 93%, 49% 86%, 46% 79%, 43% 89%, 38% 93%, 31% 94%, 23% 90%, 15% 81%, 9% 69%, 3% 55%, 0% 42%, 0% 32%, 0% 21%, 2% 13%, 6% 7%, 10% 3%, 17% 0%, 24% 0%, 34% 1%);
    }
    .tree-sub-item--future-education-item-9 {
        -webkit-clip-path: polygon(61% 3%, 69% 6%, 79% 13%, 88% 22%, 93% 31%, 97% 40%, 99% 50%, 100% 60%, 100% 72%, 99% 78%, 96% 90%, 91% 95%, 84% 100%, 79% 100%, 72% 99%, 68% 96%, 65% 89%, 62% 92%, 57% 95%, 51% 95%, 46% 93%, 40% 88%, 36% 81%, 34% 76%, 33% 71%, 29% 76%, 24% 77%, 18% 77%, 13% 74%, 8% 69%, 4% 62%, 1% 55%, 0% 46%, 0% 37%, 1% 29%, 4% 23%, 8% 16%, 14% 10%, 21% 5%, 28% 1%, 38% 0%, 50% 0%);
        clip-path: polygon(61% 3%, 69% 6%, 79% 13%, 88% 22%, 93% 31%, 97% 40%, 99% 50%, 100% 60%, 100% 72%, 99% 78%, 96% 90%, 91% 95%, 84% 100%, 79% 100%, 72% 99%, 68% 96%, 65% 89%, 62% 92%, 57% 95%, 51% 95%, 46% 93%, 40% 88%, 36% 81%, 34% 76%, 33% 71%, 29% 76%, 24% 77%, 18% 77%, 13% 74%, 8% 69%, 4% 62%, 1% 55%, 0% 46%, 0% 37%, 1% 29%, 4% 23%, 8% 16%, 14% 10%, 21% 5%, 28% 1%, 38% 0%, 50% 0%);
    }
    .tree-sub-item--future-education-item-10 {
        -webkit-clip-path: polygon(43% 8%, 62% 18%, 78% 29%, 87% 39%, 96% 51%, 100% 64%, 100% 73%, 98% 84%, 89% 94%, 73% 100%, 64% 100%, 54% 100%, 36% 92%, 22% 80%, 11% 65%, 4% 51%, 0% 34%, 0% 16%, 2% 0%, 21% 1%);
        clip-path: polygon(43% 8%, 62% 18%, 78% 29%, 87% 39%, 96% 51%, 100% 64%, 100% 73%, 98% 84%, 89% 94%, 73% 100%, 64% 100%, 54% 100%, 36% 92%, 22% 80%, 11% 65%, 4% 51%, 0% 34%, 0% 16%, 2% 0%, 21% 1%);
    }


    .tree-sub-item--bequeath-item-1 {
        -webkit-clip-path: polygon(83% 9%, 95% 26%, 98% 39%, 100% 49%, 100% 61%, 99% 70%, 96% 77%, 89% 82%, 83% 84%, 77% 81%, 71% 76%, 71% 82%, 70% 89%, 67% 96%, 62% 100%, 56% 100%, 47% 99%, 41% 95%, 36% 88%, 33% 81%, 30% 74%, 28% 81%, 26% 85%, 21% 89%, 15% 90%, 9% 87%, 5% 81%, 2% 76%, 0% 67%, 0% 57%, 0% 47%, 3% 35%, 8% 23%, 16% 14%, 24% 8%, 35% 3%, 47% 0%, 60% 0%, 71% 2%);
        clip-path: polygon(83% 9%, 95% 26%, 98% 39%, 100% 49%, 100% 61%, 99% 70%, 96% 77%, 89% 82%, 83% 84%, 77% 81%, 71% 76%, 71% 82%, 70% 89%, 67% 96%, 62% 100%, 56% 100%, 47% 99%, 41% 95%, 36% 88%, 33% 81%, 30% 74%, 28% 81%, 26% 85%, 21% 89%, 15% 90%, 9% 87%, 5% 81%, 2% 76%, 0% 67%, 0% 57%, 0% 47%, 3% 35%, 8% 23%, 16% 14%, 24% 8%, 35% 3%, 47% 0%, 60% 0%, 71% 2%);
    }
    .tree-sub-item--bequeath-item-2 {
        -webkit-clip-path: polygon(88% 3%, 94% 10%, 99% 18%, 100% 27%, 100% 35%, 99% 45%, 96% 54%, 91% 60%, 86% 64%, 83% 64%, 84% 72%, 83% 81%, 81% 87%, 77% 94%, 72% 98%, 66% 100%, 58% 98%, 51% 94%, 45% 87%, 41% 93%, 37% 98%, 33% 100%, 29% 100%, 26% 100%, 20% 96%, 15% 90%, 11% 81%, 6% 71%, 3% 57%, 0% 41%, 0% 31%, 4% 30%, 10% 31%, 20% 30%, 31% 25%, 41% 17%, 50% 8%, 55% 4%, 64% 0%, 73% 0%, 82% 0%);
        clip-path: polygon(88% 3%, 94% 10%, 99% 18%, 100% 27%, 100% 35%, 99% 45%, 96% 54%, 91% 60%, 86% 64%, 83% 64%, 84% 72%, 83% 81%, 81% 87%, 77% 94%, 72% 98%, 66% 100%, 58% 98%, 51% 94%, 45% 87%, 41% 93%, 37% 98%, 33% 100%, 29% 100%, 26% 100%, 20% 96%, 15% 90%, 11% 81%, 6% 71%, 3% 57%, 0% 41%, 0% 31%, 4% 30%, 10% 31%, 20% 30%, 31% 25%, 41% 17%, 50% 8%, 55% 4%, 64% 0%, 73% 0%, 82% 0%);
    }
    .tree-sub-item--bequeath-item-3 {
        -webkit-clip-path: polygon(83% 5%, 89% 14%, 94% 22%, 98% 30%, 100% 41%, 100% 48%, 100% 57%, 98% 67%, 93% 77%, 87% 81%, 80% 85%, 73% 86%, 65% 84%, 58% 81%, 58% 88%, 57% 94%, 52% 100%, 47% 100%, 43% 100%, 38% 97%, 34% 93%, 30% 86%, 27% 79%, 22% 81%, 16% 79%, 11% 75%, 5% 68%, 2% 58%, 0% 48%, 0% 40%, 1% 35%, 3% 30%, 6% 27%, 11% 27%, 18% 27%, 27% 25%, 36% 20%, 43% 12%, 50% 8%, 56% 3%, 65% 0%, 74% 1%);
        clip-path: polygon(83% 5%, 89% 14%, 94% 22%, 98% 30%, 100% 41%, 100% 48%, 100% 57%, 98% 67%, 93% 77%, 87% 81%, 80% 85%, 73% 86%, 65% 84%, 58% 81%, 58% 88%, 57% 94%, 52% 100%, 47% 100%, 43% 100%, 38% 97%, 34% 93%, 30% 86%, 27% 79%, 22% 81%, 16% 79%, 11% 75%, 5% 68%, 2% 58%, 0% 48%, 0% 40%, 1% 35%, 3% 30%, 6% 27%, 11% 27%, 18% 27%, 27% 25%, 36% 20%, 43% 12%, 50% 8%, 56% 3%, 65% 0%, 74% 1%);
    }
    .tree-sub-item--bequeath-item-5 {
        -webkit-clip-path: polygon(48% 0%, 56% 0%, 66% 3%, 74% 7%, 83% 13%, 89% 21%, 94% 28%, 99% 40%, 99% 52%, 95% 63%, 85% 67%, 77% 68%, 73% 67%, 71% 73%, 68% 76%, 68% 84%, 65% 91%, 58% 98%, 46% 100%, 34% 98%, 22% 92%, 11% 83%, 4% 73%, 1% 63%, 0% 49%, 1% 37%, 6% 26%, 13% 16%, 21% 9%, 33% 1%);
        clip-path: polygon(48% 0%, 56% 0%, 66% 3%, 74% 7%, 83% 13%, 89% 21%, 94% 28%, 99% 40%, 99% 52%, 95% 63%, 85% 67%, 77% 68%, 73% 67%, 71% 73%, 68% 76%, 68% 84%, 65% 91%, 58% 98%, 46% 100%, 34% 98%, 22% 92%, 11% 83%, 4% 73%, 1% 63%, 0% 49%, 1% 37%, 6% 26%, 13% 16%, 21% 9%, 33% 1%);
    }
    .tree-sub-item--bequeath-item-4 {
        -webkit-clip-path: polygon(80% 8%, 88% 15%, 95% 22%, 99% 30%, 100% 39%, 100% 46%, 98% 52%, 93% 58%, 87% 59%, 87% 65%, 87% 72%, 83% 79%, 79% 83%, 73% 86%, 66% 86%, 60% 84%, 54% 80%, 50% 88%, 45% 96%, 38% 100%, 32% 100%, 24% 98%, 16% 92%, 10% 84%, 4% 73%, 1% 60%, 0% 50%, 0% 42%, 0% 30%, 3% 23%, 5% 16%, 11% 9%, 18% 4%, 27% 0%, 38% 0%, 51% 0%, 63% 2%);
        clip-path: polygon(80% 8%, 88% 15%, 95% 22%, 99% 30%, 100% 39%, 100% 46%, 98% 52%, 93% 58%, 87% 59%, 87% 65%, 87% 72%, 83% 79%, 79% 83%, 73% 86%, 66% 86%, 60% 84%, 54% 80%, 50% 88%, 45% 96%, 38% 100%, 32% 100%, 24% 98%, 16% 92%, 10% 84%, 4% 73%, 1% 60%, 0% 50%, 0% 42%, 0% 30%, 3% 23%, 5% 16%, 11% 9%, 18% 4%, 27% 0%, 38% 0%, 51% 0%, 63% 2%);
    }
    /*.tree__title--bequeath {
        -webkit-clip-path: polygon(66% 12%, 76% 10%, 87% 9%, 93% 13%, 97% 20%, 99% 26%, 100% 34%, 99% 46%, 96% 52%, 91% 55%, 93% 62%, 93% 69%, 93% 76%, 90% 84%, 85% 92%, 81% 96%, 69% 100%, 62% 100%, 55% 100%, 42% 94%, 29% 87%, 18% 76%, 9% 63%, 3% 50%, 0% 39%, 0% 24%, 1% 16%, 4% 9%, 9% 3%, 14% 0%, 21% 0%, 27% 2%, 35% 7%, 49% 11%);
        clip-path: polygon(66% 12%, 76% 10%, 87% 9%, 93% 13%, 97% 20%, 99% 26%, 100% 34%, 99% 46%, 96% 52%, 91% 55%, 93% 62%, 93% 69%, 93% 76%, 90% 84%, 85% 92%, 81% 96%, 69% 100%, 62% 100%, 55% 100%, 42% 94%, 29% 87%, 18% 76%, 9% 63%, 3% 50%, 0% 39%, 0% 24%, 1% 16%, 4% 9%, 9% 3%, 14% 0%, 21% 0%, 27% 2%, 35% 7%, 49% 11%);
    }*/


    .tree-sub-item--stock-item-1 {
        -webkit-clip-path: polygon(71% 5%, 81% 12%, 91% 21%, 97% 35%, 100% 45%, 100% 53%, 100% 60%, 98% 66%, 95% 70%, 91% 69%, 92% 76%, 91% 83%, 88% 93%, 84% 98%, 79% 100%, 73% 100%, 65% 96%, 58% 89%, 54% 83%, 51% 76%, 50% 81%, 46% 85%, 41% 85%, 36% 83%, 31% 77%, 25% 69%, 22% 61%, 18% 50%, 12% 41%, 6% 32%, 0% 27%, 2% 23%, 7% 18%, 13% 12%, 20% 7%, 27% 3%, 36% 0%, 46% 0%, 55% 0%, 63% 1%);
        clip-path: polygon(71% 5%, 81% 12%, 91% 21%, 97% 35%, 100% 45%, 100% 53%, 100% 60%, 98% 66%, 95% 70%, 91% 69%, 92% 76%, 91% 83%, 88% 93%, 84% 98%, 79% 100%, 73% 100%, 65% 96%, 58% 89%, 54% 83%, 51% 76%, 50% 81%, 46% 85%, 41% 85%, 36% 83%, 31% 77%, 25% 69%, 22% 61%, 18% 50%, 12% 41%, 6% 32%, 0% 27%, 2% 23%, 7% 18%, 13% 12%, 20% 7%, 27% 3%, 36% 0%, 46% 0%, 55% 0%, 63% 1%);
    }
    .tree-sub-item--stock-item-3 {
        -webkit-clip-path: polygon(59% 19%, 68% 22%, 78% 21%, 86% 21%, 92% 23%, 97% 28%, 100% 34%, 100% 43%, 99% 49%, 97% 57%, 94% 67%, 91% 75%, 87% 84%, 83% 90%, 77% 97%, 69% 100%, 60% 100%, 54% 100%, 46% 95%, 38% 87%, 32% 79%, 30% 72%, 28% 65%, 25% 70%, 19% 74%, 15% 73%, 8% 68%, 3% 62%, 1% 54%, 0% 45%, 0% 35%, 2% 25%, 5% 16%, 8% 9%, 13% 4%, 18% 0%, 24% 0%, 31% 0%, 35% 4%, 40% 9%, 47% 14%);
        clip-path: polygon(59% 19%, 68% 22%, 78% 21%, 86% 21%, 92% 23%, 97% 28%, 100% 34%, 100% 43%, 99% 49%, 97% 57%, 94% 67%, 91% 75%, 87% 84%, 83% 90%, 77% 97%, 69% 100%, 60% 100%, 54% 100%, 46% 95%, 38% 87%, 32% 79%, 30% 72%, 28% 65%, 25% 70%, 19% 74%, 15% 73%, 8% 68%, 3% 62%, 1% 54%, 0% 45%, 0% 35%, 2% 25%, 5% 16%, 8% 9%, 13% 4%, 18% 0%, 24% 0%, 31% 0%, 35% 4%, 40% 9%, 47% 14%);
    }
    /*.tree__title--stock {
        -webkit-clip-path: polygon(36% 16%, 42% 16%, 51% 18%, 60% 20%, 68% 24%, 76% 29%, 82% 34%, 88% 40%, 92% 47%, 93% 52%, 91% 58%, 88% 60%, 84% 61%, 81% 61%, 78% 61%, 79% 64%, 80% 69%, 80% 74%, 79% 79%, 76% 83%, 73% 86%, 68% 88%, 63% 88%, 58% 87%, 53% 85%, 46% 82%, 41% 78%, 37% 74%, 35% 71%, 33% 74%, 30% 76%, 26% 76%, 23% 75%, 20% 72%, 18% 69%, 16% 65%, 14% 60%, 12% 55%, 11% 49%, 11% 42%, 11% 37%, 13% 31%, 16% 25%, 22% 20%, 29% 17%); clip-path: polygon(36% 16%, 42% 16%, 51% 18%, 60% 20%, 68% 24%, 76% 29%, 82% 34%, 88% 40%, 92% 47%, 93% 52%, 91% 58%, 88% 60%, 84% 61%, 81% 61%, 78% 61%, 79% 64%, 80% 69%, 80% 74%, 79% 79%, 76% 83%, 73% 86%, 68% 88%, 63% 88%, 58% 87%, 53% 85%, 46% 82%, 41% 78%, 37% 74%, 35% 71%, 33% 74%, 30% 76%, 26% 76%, 23% 75%, 20% 72%, 18% 69%, 16% 65%, 14% 60%, 12% 55%, 11% 49%, 11% 42%, 11% 37%, 13% 31%, 16% 25%, 22% 20%, 29% 17%);
    }*/
    .tree-sub-item--stock-item-6 {
        -webkit-clip-path: polygon(85% 7%, 93% 17%, 97% 26%, 99% 34%, 100% 43%, 100% 50%, 99% 57%, 98% 64%, 92% 70%, 87% 74%, 79% 75%, 79% 79%, 78% 85%, 74% 90%, 72% 94%, 65% 97%, 55% 100%, 49% 100%, 41% 100%, 30% 96%, 19% 90%, 11% 83%, 6% 74%, 1% 62%, 0% 52%, 0% 35%, 2% 29%, 6% 20%, 12% 14%, 19% 8%, 30% 3%, 41% 0%, 57% 0%, 69% 1%);
        clip-path: polygon(85% 7%, 93% 17%, 97% 26%, 99% 34%, 100% 43%, 100% 50%, 99% 57%, 98% 64%, 92% 70%, 87% 74%, 79% 75%, 79% 79%, 78% 85%, 74% 90%, 72% 94%, 65% 97%, 55% 100%, 49% 100%, 41% 100%, 30% 96%, 19% 90%, 11% 83%, 6% 74%, 1% 62%, 0% 52%, 0% 35%, 2% 29%, 6% 20%, 12% 14%, 19% 8%, 30% 3%, 41% 0%, 57% 0%, 69% 1%);
    }
    .tree-sub-item--stock-item-5 {
        -webkit-clip-path: polygon(48% 11%, 55% 19%, 65% 24%, 74% 28%, 83% 31%, 90% 36%, 97% 43%, 100% 50%, 100% 56%, 100% 61%, 98% 65%, 95% 67%, 90% 68%, 93% 71%, 94% 75%, 94% 81%, 94% 87%, 93% 93%, 89% 97%, 83% 100%, 77% 100%, 73% 100%, 65% 97%, 59% 93%, 55% 89%, 53% 84%, 50% 77%, 49% 82%, 44% 87%, 39% 88%, 32% 88%, 24% 84%, 17% 77%, 10% 68%, 4% 56%, 1% 48%, 0% 38%, 0% 31%, 1% 23%, 4% 15%, 8% 8%, 13% 4%, 20% 0%, 26% 0%, 33% 1%, 40% 5%);
        clip-path: polygon(48% 11%, 55% 19%, 65% 24%, 74% 28%, 83% 31%, 90% 36%, 97% 43%, 100% 50%, 100% 56%, 100% 61%, 98% 65%, 95% 67%, 90% 68%, 93% 71%, 94% 75%, 94% 81%, 94% 87%, 93% 93%, 89% 97%, 83% 100%, 77% 100%, 73% 100%, 65% 97%, 59% 93%, 55% 89%, 53% 84%, 50% 77%, 49% 82%, 44% 87%, 39% 88%, 32% 88%, 24% 84%, 17% 77%, 10% 68%, 4% 56%, 1% 48%, 0% 38%, 0% 31%, 1% 23%, 4% 15%, 8% 8%, 13% 4%, 20% 0%, 26% 0%, 33% 1%, 40% 5%);
    }
    .tree-sub-item--stock-item-4 {
        -webkit-clip-path: polygon(67% 8%, 75% 13%, 83% 20%, 90% 28%, 95% 38%, 99% 48%, 100% 57%, 100% 67%, 97% 76%, 93% 81%, 87% 83%, 81% 82%, 78% 80%, 74% 87%, 68% 92%, 60% 98%, 52% 100%, 43% 100%, 37% 100%, 28% 96%, 21% 91%, 14% 82%, 8% 70%, 4% 61%, 1% 49%, 0% 42%, 0% 24%, 0% 15%, 2% 9%, 5% 7%, 11% 4%, 18% 1%, 26% 0%, 33% 0%, 45% 0%, 54% 2%);
        clip-path: polygon(67% 8%, 75% 13%, 83% 20%, 90% 28%, 95% 38%, 99% 48%, 100% 57%, 100% 67%, 97% 76%, 93% 81%, 87% 83%, 81% 82%, 78% 80%, 74% 87%, 68% 92%, 60% 98%, 52% 100%, 43% 100%, 37% 100%, 28% 96%, 21% 91%, 14% 82%, 8% 70%, 4% 61%, 1% 49%, 0% 42%, 0% 24%, 0% 15%, 2% 9%, 5% 7%, 11% 4%, 18% 1%, 26% 0%, 33% 0%, 45% 0%, 54% 2%);
    }
    .tree-sub-item--stock-item-2 {
        -webkit-clip-path: polygon(34% 0, 52% 15%, 63% 22%, 75% 27%, 85% 33%, 93% 41%, 99% 49%, 100% 58%, 100% 67%, 97% 74%, 91% 79%, 86% 79%, 79% 76%, 79% 82%, 76% 89%, 72% 96%, 65% 100%, 58% 100%, 51% 99%, 42% 90%, 36% 82%, 32% 72%, 31% 78%, 29% 82%, 22% 86%, 14% 86%, 5% 78%, 0% 68%, 0% 59%, 3% 44%, 11% 25%, 20% 13%, 32% 0);
        clip-path: polygon(34% 0, 52% 15%, 63% 22%, 75% 27%, 85% 33%, 93% 41%, 99% 49%, 100% 58%, 100% 67%, 97% 74%, 91% 79%, 86% 79%, 79% 76%, 79% 82%, 76% 89%, 72% 96%, 65% 100%, 58% 100%, 51% 99%, 42% 90%, 36% 82%, 32% 72%, 31% 78%, 29% 82%, 22% 86%, 14% 86%, 5% 78%, 0% 68%, 0% 59%, 3% 44%, 11% 25%, 20% 13%, 32% 0);
    }


    .tree-sub-item--incremental-item-6 {
        -webkit-clip-path: polygon(75% 6%, 84% 13%, 93% 22%, 97% 30%, 100% 39%, 100% 48%, 99% 56%, 97% 62%, 93% 65%, 93% 71%, 92% 77%, 90% 84%, 87% 92%, 83% 98%, 78% 100%, 71% 100%, 65% 100%, 59% 97%, 53% 93%, 49% 85%, 43% 76%, 38% 83%, 32% 86%, 24% 85%, 17% 79%, 12% 70%, 7% 59%, 3% 47%, 1% 34%, 0% 25%, 1% 21%, 5% 17%, 11% 11%, 18% 7%, 26% 2%, 35% 0%, 47% 0%, 58% 0%, 65% 1%);
        clip-path: polygon(75% 6%, 84% 13%, 93% 22%, 97% 30%, 100% 39%, 100% 48%, 99% 56%, 97% 62%, 93% 65%, 93% 71%, 92% 77%, 90% 84%, 87% 92%, 83% 98%, 78% 100%, 71% 100%, 65% 100%, 59% 97%, 53% 93%, 49% 85%, 43% 76%, 38% 83%, 32% 86%, 24% 85%, 17% 79%, 12% 70%, 7% 59%, 3% 47%, 1% 34%, 0% 25%, 1% 21%, 5% 17%, 11% 11%, 18% 7%, 26% 2%, 35% 0%, 47% 0%, 58% 0%, 65% 1%);
    }
    .tree-sub-item--incremental-item-3 {
        -webkit-clip-path: polygon(41% 10%, 51% 18%, 61% 22%, 72% 24%, 82% 24%, 91% 25%, 97% 31%, 100% 39%, 100% 46%, 99% 54%, 96% 61%, 92% 64%, 87% 65%, 83% 64%, 84% 73%, 83% 82%, 80% 91%, 77% 96%, 69% 100%, 61% 100%, 55% 100%, 46% 96%, 41% 90%, 34% 81%, 29% 73%, 27% 68%, 25% 73%, 21% 76%, 16% 75%, 11% 74%, 7% 68%, 4% 63%, 1% 55%, 0% 47%, 0% 35%, 0% 26%, 2% 18%, 5% 12%, 8% 7%, 12% 2%, 16% 0%, 22% 0%, 28% 0%, 35% 6%);
        clip-path: polygon(41% 10%, 51% 18%, 61% 22%, 72% 24%, 82% 24%, 91% 25%, 97% 31%, 100% 39%, 100% 46%, 99% 54%, 96% 61%, 92% 64%, 87% 65%, 83% 64%, 84% 73%, 83% 82%, 80% 91%, 77% 96%, 69% 100%, 61% 100%, 55% 100%, 46% 96%, 41% 90%, 34% 81%, 29% 73%, 27% 68%, 25% 73%, 21% 76%, 16% 75%, 11% 74%, 7% 68%, 4% 63%, 1% 55%, 0% 47%, 0% 35%, 0% 26%, 2% 18%, 5% 12%, 8% 7%, 12% 2%, 16% 0%, 22% 0%, 28% 0%, 35% 6%);
    }
    .tree-sub-item--incremental-item-2 {
        -webkit-clip-path: polygon(42% 1%, 47% 2%, 52% 4%, 57% 5%, 63% 8%, 71% 12%, 78% 17%, 84% 22%, 88% 28%, 90% 33%, 89% 39%, 94% 45%, 98% 53%, 100% 60%, 100% 68%, 96% 75%, 91% 78%, 85% 79%, 79% 78%, 79% 84%, 76% 90%, 72% 95%, 65% 98%, 59% 99%, 53% 98%, 45% 95%, 40% 91%, 34% 93%, 28% 93%, 21% 89%, 17% 84%, 11% 77%, 7% 68%, 4% 60%, 1% 50%, 0% 42%, 0% 30%, 2% 22%, 5% 16%, 9% 10%, 14% 6%, 20% 3%, 29% 1%, 34% 0%);
        clip-path: polygon(42% 1%, 47% 2%, 52% 4%, 57% 5%, 63% 8%, 71% 12%, 78% 17%, 84% 22%, 88% 28%, 90% 33%, 89% 39%, 94% 45%, 98% 53%, 100% 60%, 100% 68%, 96% 75%, 91% 78%, 85% 79%, 79% 78%, 79% 84%, 76% 90%, 72% 95%, 65% 98%, 59% 99%, 53% 98%, 45% 95%, 40% 91%, 34% 93%, 28% 93%, 21% 89%, 17% 84%, 11% 77%, 7% 68%, 4% 60%, 1% 50%, 0% 42%, 0% 30%, 2% 22%, 5% 16%, 9% 10%, 14% 6%, 20% 3%, 29% 1%, 34% 0%);
    }
    /*
    .tree-sub-item--incremental-item-2 {
        -webkit-clip-path: polygon(71% 8%, 81% 17%, 90% 28%, 97% 38%, 100% 50%, 100% 59%, 99% 65%, 95% 74%, 88% 81%, 81% 87%, 70% 90%, 60% 91%, 51% 88%, 42% 85%, 36% 85%, 32% 89%, 29% 95%, 25% 100%, 20% 100%, 14% 100%, 10% 96%, 7% 89%, 4% 80%, 1% 69%, 0% 61%, 0% 52%, 1% 41%, 4% 31%, 9% 23%, 14% 15%, 21% 8%, 31% 3%, 41% 0%, 51% 0%, 61% 1%);
        clip-path: polygon(71% 8%, 81% 17%, 90% 28%, 97% 38%, 100% 50%, 100% 59%, 99% 65%, 95% 74%, 88% 81%, 81% 87%, 70% 90%, 60% 91%, 51% 88%, 42% 85%, 36% 85%, 32% 89%, 29% 95%, 25% 100%, 20% 100%, 14% 100%, 10% 96%, 7% 89%, 4% 80%, 1% 69%, 0% 61%, 0% 52%, 1% 41%, 4% 31%, 9% 23%, 14% 15%, 21% 8%, 31% 3%, 41% 0%, 51% 0%, 61% 1%);
    }
    .tree__title--incremental {
        -webkit-clip-path: polygon(87% 20%, 90% 24%, 93% 28%, 95% 33%, 98% 39%, 99% 45%, 100% 52%, 100% 59%, 99% 64%, 97% 71%, 93% 76%, 88% 79%, 83% 79%, 83% 83%, 83% 88%, 79% 94%, 73% 98%, 67% 100%, 60% 100%, 53% 100%, 45% 96%, 38% 92%, 31% 86%, 30% 84%, 27% 78%, 23% 72%, 21% 68%, 19% 63%, 17% 55%, 16% 48%, 16% 42%, 17% 35%, 20% 30%, 24% 27%, 30% 25%, 36% 26%, 42% 28%, 47% 27%, 55% 26%, 61% 22%, 66% 18%, 72% 16%, 78% 16%);
        clip-path: polygon(87% 20%, 90% 24%, 93% 28%, 95% 33%, 98% 39%, 99% 45%, 100% 52%, 100% 59%, 99% 64%, 97% 71%, 93% 76%, 88% 79%, 83% 79%, 83% 83%, 83% 88%, 79% 94%, 73% 98%, 67% 100%, 60% 100%, 53% 100%, 45% 96%, 38% 92%, 31% 86%, 30% 84%, 27% 78%, 23% 72%, 21% 68%, 19% 63%, 17% 55%, 16% 48%, 16% 42%, 17% 35%, 20% 30%, 24% 27%, 30% 25%, 36% 26%, 42% 28%, 47% 27%, 55% 26%, 61% 22%, 66% 18%, 72% 16%, 78% 16%);
    }*/
    .tree-sub-item--incremental-item-1 {
        -webkit-clip-path: polygon(61% 10%, 71% 16%, 81% 26%, 86% 33%, 93% 43%, 96% 53%, 99% 63%, 100% 73%, 100% 85%, 100% 91%, 97% 95%, 93% 99%, 87% 100%, 81% 100%, 75% 100%, 68% 96%, 61% 89%, 56% 82%, 54% 75%, 53% 81%, 50% 84%, 45% 87%, 39% 88%, 33% 87%, 27% 83%, 20% 79%, 15% 71%, 8% 62%, 3% 50%, 1% 43%, 0% 35%, 0% 26%, 2% 20%, 5% 15%, 8% 9%, 13% 5%, 19% 0%, 27% 0%, 36% 0%, 46% 3%);
        clip-path: polygon(61% 10%, 71% 16%, 81% 26%, 86% 33%, 93% 43%, 96% 53%, 99% 63%, 100% 73%, 100% 85%, 100% 91%, 97% 95%, 93% 99%, 87% 100%, 81% 100%, 75% 100%, 68% 96%, 61% 89%, 56% 82%, 54% 75%, 53% 81%, 50% 84%, 45% 87%, 39% 88%, 33% 87%, 27% 83%, 20% 79%, 15% 71%, 8% 62%, 3% 50%, 1% 43%, 0% 35%, 0% 26%, 2% 20%, 5% 15%, 8% 9%, 13% 5%, 19% 0%, 27% 0%, 36% 0%, 46% 3%);
    }
    .tree-sub-item--incremental-item-4 {
        -webkit-clip-path: polygon(13% 8%, 20% 15%, 31% 22%, 40% 25%, 51% 27%, 60% 26%, 69% 22%, 78% 19%, 86% 21%, 91% 25%, 95% 31%, 98% 38%, 100% 45%, 100% 52%, 100% 58%, 99% 65%, 96% 72%, 94% 77%, 88% 82%, 82% 82%, 77% 80%, 75% 83%, 70% 89%, 64% 95%, 59% 98%, 53% 100%, 47% 100%, 40% 100%, 33% 99%, 26% 96%, 20% 89%, 13% 80%, 8% 69%, 3% 57%, 1% 45%, 0% 32%, 0% 23%, 1% 10%, 4% 0%);
        clip-path: polygon(13% 8%, 20% 15%, 31% 22%, 40% 25%, 51% 27%, 60% 26%, 69% 22%, 78% 19%, 86% 21%, 91% 25%, 95% 31%, 98% 38%, 100% 45%, 100% 52%, 100% 58%, 99% 65%, 96% 72%, 94% 77%, 88% 82%, 82% 82%, 77% 80%, 75% 83%, 70% 89%, 64% 95%, 59% 98%, 53% 100%, 47% 100%, 40% 100%, 33% 99%, 26% 96%, 20% 89%, 13% 80%, 8% 69%, 3% 57%, 1% 45%, 0% 32%, 0% 23%, 1% 10%, 4% 0%);
    }
    .tree-sub-item--incremental-item-5 {
        -webkit-clip-path: polygon(34% 5%, 39% 15%, 54% 28%, 64% 32%, 74% 33%, 82% 34%, 88% 38%, 94% 45%, 99% 52%, 100% 59%, 100% 73%, 99% 79%, 97% 84%, 94% 89%, 89% 91%, 81% 90%, 74% 87%, 70% 82%, 70% 88%, 68% 93%, 64% 98%, 60% 100%, 54% 100%, 49% 100%, 42% 95%, 37% 90%, 33% 83%, 31% 76%, 28% 81%, 24% 83%, 18% 85%, 12% 83%, 6% 77%, 3% 70%, 0% 58%, 0% 49%, 2% 38%, 7% 26%, 14% 16%, 19% 8%, 29% 0);
        clip-path: polygon(34% 5%, 39% 15%, 54% 28%, 64% 32%, 74% 33%, 82% 34%, 88% 38%, 94% 45%, 99% 52%, 100% 59%, 100% 73%, 99% 79%, 97% 84%, 94% 89%, 89% 91%, 81% 90%, 74% 87%, 70% 82%, 70% 88%, 68% 93%, 64% 98%, 60% 100%, 54% 100%, 49% 100%, 42% 95%, 37% 90%, 33% 83%, 31% 76%, 28% 81%, 24% 83%, 18% 85%, 12% 83%, 6% 77%, 3% 70%, 0% 58%, 0% 49%, 2% 38%, 7% 26%, 14% 16%, 19% 8%, 29% 0);
    }


    .tree-sub-item--sabbatical-your-spouse{
        -webkit-clip-path: polygon(39% 0%, 46% 1%, 53% 3%, 60% 6%, 66% 12%, 70% 19%, 73% 24%, 73% 30%, 77% 31%, 83% 32%, 90% 31%, 95% 33%, 99% 38%, 99% 44%, 97% 49%, 94% 53%, 90% 55%, 93% 61%, 94% 67%, 92% 74%, 88% 79%, 82% 81%, 76% 80%, 69% 76%, 69% 83%, 69% 90%, 64% 99%, 57% 100%, 49% 96%, 44% 90%, 40% 85%, 36% 78%, 34% 82%, 30% 86%, 24% 86%, 19% 84%, 14% 79%, 10% 74%, 6% 67%, 2% 58%, 0% 48%, 0% 42%, 1% 33%, 3% 26%, 5% 20%, 10% 14%, 16% 8%, 21% 4%, 29% 1%);
        clip-path: polygon(39% 0%, 46% 1%, 53% 3%, 60% 6%, 66% 12%, 70% 19%, 73% 24%, 73% 30%, 77% 31%, 83% 32%, 90% 31%, 95% 33%, 99% 38%, 99% 44%, 97% 49%, 94% 53%, 90% 55%, 93% 61%, 94% 67%, 92% 74%, 88% 79%, 82% 81%, 76% 80%, 69% 76%, 69% 83%, 69% 90%, 64% 99%, 57% 100%, 49% 96%, 44% 90%, 40% 85%, 36% 78%, 34% 82%, 30% 86%, 24% 86%, 19% 84%, 14% 79%, 10% 74%, 6% 67%, 2% 58%, 0% 48%, 0% 42%, 1% 33%, 3% 26%, 5% 20%, 10% 14%, 16% 8%, 21% 4%, 29% 1%);
    }
    .tree-sub-item--sabbatical-your{
        -webkit-clip-path: polygon(25% 0%, 32% 1%, 39% 3%, 44% 5%, 49% 7%, 56% 9%, 65% 9%, 73% 9%, 80% 13%, 86% 20%, 87% 29%, 84% 35%, 90% 42%, 95% 50%, 98% 63%, 98% 73%, 95% 81%, 87% 84%, 86% 89%, 82% 96%, 74% 100%, 67% 99%, 61% 97%, 55% 92%, 50% 86%, 47% 78%, 43% 82%, 35% 85%, 28% 84%, 22% 82%, 16% 76%, 10% 69%, 6% 60%, 3% 52%, 1% 45%, 1% 36%, 1% 25%, 3% 18%, 7% 11%, 14% 4%);
        clip-path: polygon(25% 0%, 32% 1%, 39% 3%, 44% 5%, 49% 7%, 56% 9%, 65% 9%, 73% 9%, 80% 13%, 86% 20%, 87% 29%, 84% 35%, 90% 42%, 95% 50%, 98% 63%, 98% 73%, 95% 81%, 87% 84%, 86% 89%, 82% 96%, 74% 100%, 67% 99%, 61% 97%, 55% 92%, 50% 86%, 47% 78%, 43% 82%, 35% 85%, 28% 84%, 22% 82%, 16% 76%, 10% 69%, 6% 60%, 3% 52%, 1% 45%, 1% 36%, 1% 25%, 3% 18%, 7% 11%, 14% 4%);
    }

    /*.tree__title--sabbatical{
        -webkit-clip-path: polygon(1% 33%, 4% 49%, 5% 60%, 6% 71%, 6% 82%, 4% 93%, 1% 99%, 100% 100%, 100% 0%, 0% 0%); 
        clip-path: polygon(1% 33%, 4% 49%, 5% 60%, 6% 71%, 6% 82%, 4% 93%, 1% 99%, 100% 100%, 100% 0%, 0% 0%);
    }*/
    //sub-items end
}



.tree {
    margin: 0 auto;
    position: relative;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.286;
    font-family: $font-family-heading;
    color: $success-color;
    transform-origin: 50% 0;
    transition: transform .6s;
    transition-delay: 0.2s;
    will-change: transform;
    
    @media (min-width: $breakpoint-medium) {
        max-height: 830px;
        width: 912px;
        text-align: center;
        
        &--scale {
            transform: scale(0.89);
        }
        
        &--sub-page {
            margin-bottom: -40px;
        }
    }
    @media (max-width: $breakpoint-medium-max) {
        max-width: 600px;
        margin: 0 auto;
        width: 100%;
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
    }
    
    &__title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 30px;
        h2 {
            margin: 0;
            @media (max-width: $breakpoint-medium-max) {
                font-size: 22px;
            }
        }

        &-main {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-right: auto;
            color: $success-color;

            @media (max-width: $breakpoint-medium-max) {
                width: 100%;
                max-width: 600px;
                margin-left: auto;
            }
        }
        
        @media (min-width: $breakpoint-medium) {
            position: absolute;
            flex-direction: column;
            align-items: flex-start;
            z-index: 11;
            
            h2 {
                font-size: 28px;
                font-family: $font-family-heading;
                margin: 0 12px 0 0;
                color: $base-color;
            }

            &-main {
                display: flex;
                align-items: flex-start;  
                margin-bottom: 10px;
            }

            &-other {
                font-size: 14px;
                color: $gray-800;
                margin: 0 0 20px;
            }
            
            &--progress-bar {
                left: 32px;
                top: 90px;
            }
        }
        @media (max-width: $breakpoint-medium-max) {
            &--progress-bar {
                flex-direction: column;
            }
        }
    }
    
    &__base {
        width: 100%;
        img {
            width: 100%;
        }
        @media (max-width: $breakpoint-large) {
            margin-left: 100px;
        }
    }
    
    &__task {
        margin: 0 auto;
        width: 89px;
        height: 131px;
        background: url('../../../images/tree/Acorn.png') no-repeat;
        background-size: 100% 100%;
        font-size: 14px;
        font-family: $font-family;
        display: flex;
        align-items: flex-end;
        padding: 0 0 13px 16px;
        order: 99;
        @media (min-width: $breakpoint-medium) {
            top: 453px;
            right: 56px;
            position: absolute;
            width: 100px;
            height: 146px;
            padding: 0 0 17px 18px;
        }
        > a {
            display: inline-flex;
            width: 27px;
            height: 22px;
            justify-content: center;
            align-items: center;
            border-radius: 8px;
            background: #fff;
            color: $success-color;
            &:hover {
                text-decoration: none;
            }
        }
    }
    
    &__label {
        @media (min-width: $breakpoint-medium) {
            transform: scale(1.11);
            transition: transform .2s ease-in-out;
        }
    }
    
    &__count {
        position: relative;
        height: 32px;
        margin-top: 6px;
        padding: 0 4px;
        min-width: 35px;
        align-items: center;
        //display: inline-flex;
        display: inline-block;
        text-align: center;
        line-height: 32px;
        justify-content: center;
        border-radius: 8px;
        font-family: $font-family;
        font-size: 14px;
        color: #fff;
        //transition: background-color .2s;
        background-color: #FAC63F;
        &--big{
            display: flex;
            height: 40px;
            margin-top: 0;
            min-width: 45px;
            font-family: $font-family-heading;
            font-weight: 600;
        }
        &--small {
            font-size: 12px;
            line-height: 22px;
            height: 22px;
            padding: 0 3px;
            margin-top: 3px;
            min-width: 24px;
        }
        &--base {
            background-color: #FF665C;
    
            .tree-item--disabled & {
                background-color: #FFB1AC;
                .tree--tour:not(.tree--tour-details) & {
                    background-color: #FF665C;
                }
            }
        }
        
        &--middle,
        &--large {
            background-color: #FAC63F;
    
            .tree-item--disabled & {
                background-color: #FFDE89;
            }
        }
        
        &--full {
            background-color: #215E59;
    
            .tree-item--disabled & {
                background-color: #7AA29E;
            }
        }
        &--loading {
            background: #D1D1D1;
        }
    }
    
    &__sticky-holder {
        flex: 1 1 auto;
        @media (max-width: $breakpoint-medium-max) {
            display: flex;
            flex-direction: column;
        }
    }
    
    &__add-button {
        @media (min-width: $breakpoint-medium) {
            &--bequeath {
                position: absolute;
                top: 500px;
                left: 407px;
            }
        }
        
        @media (max-width: $breakpoint-medium-max) {
            position: sticky;
            bottom: 16px;
            right: 16px;
            text-align: right;
            .MuiButtonBase-root {
                width: 48px;
                height: 48px;
                min-width: 48px;
                padding: 0;
                margin: 0;
                border-radius: 12px;
                &::before,
                &::after {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    width: 16px;
                    height: 2px;
                    border-radius: 2px;
                    content:'';
                    background: #fff;
                    transform: translate(-50%,-50%);
                }
                &::after {
                    transform: translate(-50%,-50%) rotate(90deg);
                }
                .MuiButton-label {
                    display: none;
                }
            }
        }
    }
    
    &--active {
        @media (min-width: $breakpoint-medium) {
            transform: scale(1);
            transition: transform .5s;
    
            .tree__label {
                transform: scale(1);
            }
        }
    
        .tree-item {
            &:not(.tree-item--hover),
            &.tree-item--disabled {
                .tree__count {
                    &--base {
                        background-color: #FFB1AC;
                    }
    
                    &--middle,
                    &--large {
                        background-color: #FFDE89;
                    }
    
                    &--full {
                        background-color: #7AA29E;
                    }
                }
            }
        }
    }
    &__switcher{
        font-size: 12px;
        color: $gray-800;
        margin-top: 10px;
        @media (max-width: $breakpoint-medium-max){
            margin: 0 auto;
            max-width: 600px;
            width: 100%;
        }
    }
}