.tree-sub-item {
    position: relative;
    font-size: 14px;
    color: $success-color;
    display: flex;
    align-items: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    transition: opacity .2s ease-in-out;
    
    @media (min-width: $breakpoint-medium) {
        //opacity: .8;
        position: absolute;
        justify-content: center;
        &--disabled {
            color: #7AA29E;
        }
        
        &--children {
            top: 13px;
            right: 3px;
            width: 132px;
            height: 121px;
            background-image: url('../../../images/tree/about/sections/Profile-min.png');
            &.tree-sub-item--base {
                background-image: url('../../../images/tree/about/sections/Profile-0-min.png');
            }
            &.tree-sub-item--middle {
                background-image: url('../../../images/tree/about/sections/Profile-10-min.png');
            }
            &.tree-sub-item--large {
                background-image: url('../../../images/tree/about/sections/Profile-50-min.png');
            }
            &.tree-sub-item--disabled,
            &.tree-sub-item--blocked {
                background-image: url('../../../images/tree/about/sections/Profile-not-active-min.png');
                .tree--tour-details & {
                    background-image: url('../../../images/tree/about/sections/Profile-0-min.png');
                }
            }
        }
        
        &--values {
            top: 36px;
            left: 49px;
            width: 182px;
            height: 133px;
            padding: 10px 0 0 38px;
            background-image: url('../../../images/tree/about/sections/Risk-min.png');
            &.tree-sub-item--base {
                background-image: url('../../../images/tree/about/sections/Risk-0-min.png');
            }
            &.tree-sub-item--middle {
                background-image: url('../../../images/tree/about/sections/Risk-10-min.png');
            }
            &.tree-sub-item--large {
                background-image: url('../../../images/tree/about/sections/Risk-50-min.png');
            }
            &.tree-sub-item--disabled,
            &.tree-sub-item--blocked {
                background-image: url('../../../images/tree/about/sections/Risk-not-active-min.png');
                .tree--tour-details & {
                    background-image: url('../../../images/tree/about/sections/Risk-0-min.png');
                }
            }
        }
        
        &--education {
            top: 99px;
            right: -4px;
            width: 174px;
            height: 134px;
            background-image: url('../../../images/tree/about/sections/Children-min.png');
            &.tree-sub-item--base {
                background-image: url('../../../images/tree/about/sections/Children-0-min.png');
            }
            &.tree-sub-item--middle {
                background-image: url('../../../images/tree/about/sections/Children-10-min.png');
            }
            &.tree-sub-item--large {
                background-image: url('../../../images/tree/about/sections/Children-50-min.png');
            }
            &.tree-sub-item--disabled,
            &.tree-sub-item--blocked {
                background-image: url('../../../images/tree/about/sections/Children-not-active-min.png');
                .tree--tour-details & {
                    background-image: url('../../../images/tree/about/sections/Children-0-min.png');
                }
            }
        }
        
        &--risk {
            top: 64px;
            left: 2px;
            width: 182px;
            height: 172px;
            padding: 0px 103px 50px 0;
            background-image: url('../../../images/tree/about/sections/Education-min.png');
            &.tree-sub-item--base {
                background-image: url('../../../images/tree/about/sections/Education-0-min.png');
            }
            &.tree-sub-item--middle {
                background-image: url('../../../images/tree/about/sections/Education-10-min.png');
            }
            &.tree-sub-item--large {
                background-image: url('../../../images/tree/about/sections/Education-50-min.png');
            }
            &.tree-sub-item--disabled,
            &.tree-sub-item--blocked {
                background-image: url('../../../images/tree/about/sections/Education-not-active-min.png');
                .tree--tour-details & {
                    background-image: url('../../../images/tree/about/sections/Education-0-min.png');
                }
            }
        }

        &--companies {
            top: 73px;
            left: 1px;
            width: 179px;
            height: 170px;
            padding: 52px 0 0 28px;
            background-image: url('../../../images/tree/about/sections/Company-min.png');
            &.tree-sub-item--base {
                background-image: url('../../../images/tree/about/sections/Company-0-min.png');
            }
            &.tree-sub-item--middle {
                background-image: url('../../../images/tree/about/sections/Company-10-min.png');
            }
            &.tree-sub-item--large {
                background-image: url('../../../images/tree/about/sections/Company-50-min.png');
            }
            &.tree-sub-item--disabled,
            &.tree-sub-item--blocked {
                background-image: url('../../../images/tree/about/sections/Company-not-active-min.png');
                .tree--tour-details & {
                    background-image: url('../../../images/tree/about/sections/Company-0-min.png');
                }
            }
        }
    
        &--profile {
            top: -5px;
            right: 87px;
            width: 155px;
            height: 93px;
            padding: 0 0 13px;
            background-image: url('../../../images/tree/about/sections/Values-min.png');
            &.tree-sub-item--base {
                background-image: url('../../../images/tree/about/sections/Values-0-min.png');
            }
            &.tree-sub-item--middle {
                background-image: url('../../../images/tree/about/sections/Values-10-min.png');
            }
            &.tree-sub-item--large {
                background-image: url('../../../images/tree/about/sections/Values-50-min.png');
            }
        }
    
        &--retirement {
            top: -7px;
            left: 0;
            width: 197px;
            height: 105px;
            padding: 0 0 0 10px;
            background-image: url('../../../images/tree/future/sections/Sabbatical-min.png');
            &.tree-sub-item--base {
                background-image: url('../../../images/tree/future/sections/Sabbatical-0-min.png');
            }
            &.tree-sub-item--middle {
                background-image: url('../../../images/tree/future/sections/Sabbatical-10-min.png');
            }
            &.tree-sub-item--large {
                background-image: url('../../../images/tree/future/sections/Sabbatical-50-min.png');
            }
            &.tree-sub-item--disabled {
                background-image: url('../../../images/tree/future/sections/Sabbatical-not-active-min.png');
            }
        }
        
        &--objectives {
            top: 45px;
            left: 97px;
            width: 177px;
            height: 120px;
            padding: 7px 0 0;
            background-image: url('../../../images/tree/future/sections/Objectives-min.png');
            &.tree-sub-item--base {
                background-image: url('../../../images/tree/future/sections/Objectives-0-min.png');
            }
            &.tree-sub-item--middle {
                background-image: url('../../../images/tree/future/sections/Objectives-10-min.png');
            }
            &.tree-sub-item--large {
                background-image: url('../../../images/tree/future/sections/Objectives-50-min.png');
            }
            &.tree-sub-item--disabled {
                background-image: url('../../../images/tree/future/sections/Objectives-not-active-min.png');
            }
        }
        
        &--future-children {
            top: 61px;
            left: -3px;
            width: 136px;
            height: 136px;
            padding: 3px 20px 0;
            background-image: url('../../../images/tree/future/sections/Future-education-min.png');
            &.tree-sub-item--base {
                background-image: url('../../../images/tree/future/sections/Future-education-0-min.png');
            }
            &.tree-sub-item--middle {
                background-image: url('../../../images/tree/future/sections/Future-education-10-min.png');
            }
            &.tree-sub-item--large {
                background-image: url('../../../images/tree/future/sections/Future-education-50-min.png');
            }
            &.tree-sub-item--disabled {
                background-image: url('../../../images/tree/future/sections/Future-education-not-active-min.png');
            }
        }
    
        &--future-education {
            top: 110px;
            left: 87px;
            width: 178px;
            height: 150px;
            padding: 0 29px 8px;
            background-image: url('../../../images/tree/future/sections/Retirement-min.png');
            &.tree-sub-item--base {
                background-image: url('../../../images/tree/future/sections/Retirement-0-min.png');
            }
            &.tree-sub-item--middle {
                background-image: url('../../../images/tree/future/sections/Retirement-10-min.png');
            }
            &.tree-sub-item--large {
                background-image: url('../../../images/tree/future/sections/Retirement-50-min.png');
            }
            &.tree-sub-item--disabled {
                background-image: url('../../../images/tree/future/sections/Retirement-not-active-min.png');
            }
        }
    
        &--sabbatical {
            top: 97px;
            right: -3px;
            width: 155px;
            height: 191px;
            padding: 7px 11px 0 42px;
            background-image: url('../../../images/tree/future/sections/Future-children-min.png');
            &.tree-sub-item--base {
                background-image: url('../../../images/tree/future/sections/Future-children-0-min.png');
            }
            &.tree-sub-item--middle {
                background-image: url('../../../images/tree/future/sections/Future-children-10-min.png');
            }
            &.tree-sub-item--large {
                background-image: url('../../../images/tree/future/sections/Future-children-50-min.png');
            }
            &.tree-sub-item--disabled {
                background-image: url('../../../images/tree/future/sections/Future-children-not-active-min.png');
            }
        }
    
        &--incremental-income {
            top: 79px;
            right: 1px;
            width: 142px;
            height: 125px;
            padding: 34px 0 0 4px;
            align-items: flex-start;
            background-image: url('../../../images/tree/finances/sections/Income-min.png');
            &.tree-sub-item--base {
                background-image: url('../../../images/tree/finances/sections/Income-0-min.png');
            }
            &.tree-sub-item--middle {
                background-image: url('../../../images/tree/finances/sections/Income-10-min.png');
            }
            &.tree-sub-item--large {
                background-image: url('../../../images/tree/finances/sections/Income-50-min.png');
            }
            &.tree-sub-item--disabled {
                background-image: url('../../../images/tree/finances/sections/Income-not-active-min.png');
            }
        }
        
        &--worth {
            top: 42px;
            right: 82px;
            width: 127px;
            height: 88px;
            background-image: url('../../../images/tree/finances/sections/Assets-min.png');
            &.tree-sub-item--base {
                background-image: url('../../../images/tree/finances/sections/Assets-0-min.png');
            }
            &.tree-sub-item--middle {
                background-image: url('../../../images/tree/finances/sections/Assets-10-min.png');
            }
            &.tree-sub-item--large {
                background-image: url('../../../images/tree/finances/sections/Assets-50-min.png');
            }
            &.tree-sub-item--disabled {
                background-image: url('../../../images/tree/finances/sections/Assets-not-active-min.png');
            }
        }
    
        &--assets {
            top: 95px;
            left: 97px;
            width: 157px;
            height: 136px;
            padding: 0 10px 3px 0;
            background-image: url('../../../images/tree/finances/sections/Expenses-min.png');
            &.tree-sub-item--base {
                background-image: url('../../../images/tree/finances/sections/Expenses-0-min.png');
            }
            &.tree-sub-item--middle {
                background-image: url('../../../images/tree/finances/sections/Expenses-10-min.png');
            }
            &.tree-sub-item--large {
                background-image: url('../../../images/tree/finances/sections/Expenses-50-min.png');
            }
            &.tree-sub-item--disabled {
                background-image: url('../../../images/tree/finances/sections/Expenses-not-active-min.png');
            }
        }
    
        &--expenses {
            top: 15px;
            left: -4px;
            width: 153px;
            height: 144px;
            padding: 24px 20px 0 0;
            background-image: url('../../../images/tree/finances/sections/Incremental-income-min.png');
            &.tree-sub-item--base {
                background-image: url('../../../images/tree/finances/sections/Incremental-income-0-min.png');
            }
            &.tree-sub-item--middle {
                background-image: url('../../../images/tree/finances/sections/Incremental-income-10-min.png');
            }
            &.tree-sub-item--large {
                background-image: url('../../../images/tree/finances/sections/Incremental-income-50-min.png');
            }
            &.tree-sub-item--disabled {
                background-image: url('../../../images/tree/finances/sections/Incremental-income-not-active-min.png');
            }
        }
        
        &--income {
            top: -2px;
            left: 41px;
            width: 125px;
            height: 75px;
            padding: 0 0 11px;
            background-image: url('../../../images/tree/finances/sections/Worth-min.png');
            &.tree-sub-item--base {
                background-image: url('../../../images/tree/finances/sections/Worth-0-min.png');
            }
            &.tree-sub-item--middle {
                background-image: url('../../../images/tree/finances/sections/Worth-10-min.png');
            }
            &.tree-sub-item--large {
                background-image: url('../../../images/tree/finances/sections/Worth-50-min.png');
            }
            &.tree-sub-item--disabled {
                background-image: url('../../../images/tree/finances/sections/Woth-not-active-min.png');
            }
        }
    
        &--stocks {
            top: 75px;
            right: 14px;
            width: 115px;
            height: 86px;
            background-image: url('../../../images/tree/finances/sections/Stocks-min.png');
            &.tree-sub-item--base {
                background-image: url('../../../images/tree/finances/sections/Stocks-0-min.png');
            }
            &.tree-sub-item--middle {
                background-image: url('../../../images/tree/finances/sections/Stocks-10-min.png');
            }
            &.tree-sub-item--large {
                background-image: url('../../../images/tree/finances/sections/Stocks-50-min.png');
            }
            &.tree-sub-item--disabled {
                background-image: url('../../../images/tree/finances/sections/Stocks-not-active-min.png');
            }
        }
        
        &--bequeath {
            top: 150px;
            left: 38px;
            width: 148px;
            height: 92px;
            padding: 10px 0 0;
            background-image: url('../../../images/tree/protection/sections/Estate-min.png');
            &.tree-sub-item--base {
                background-image: url('../../../images/tree/protection/sections/Estate-0-min.png');
            }
            &.tree-sub-item--middle {
                background-image: url('../../../images/tree/protection/sections/Estate-10-min.png');
            }
            &.tree-sub-item--large {
                background-image: url('../../../images/tree/protection/sections/Estate-50-min.png');
            }
            &.tree-sub-item--disabled {
                background-image: url('../../../images/tree/protection/sections/Estate-not-active-min.png');
            }
        }
        
        &--unemployment {
            top: 89px;
            left: 97px;
            width: 148px;
            height: 104px;
            align-items: flex-end;
            padding: 0 0 26px 10px;
            background-image: url('../../../images/tree/protection/sections/Life-min.png');
            &.tree-sub-item--base {
                background-image: url('../../../images/tree/protection/sections/Life-0-min.png');
            }
            &.tree-sub-item--middle {
                background-image: url('../../../images/tree/protection/sections/Life-10-min.png');
            }
            &.tree-sub-item--large {
                background-image: url('../../../images/tree/protection/sections/Life-50-min.png');
            }
            &.tree-sub-item--disabled {
                background-image: url('../../../images/tree/protection/sections/Life-not-active-min.png');
            }
    
            .tree-sub-item__label {
                display: flex;
                flex-direction: column-reverse;
                align-items: center;
            }
        }
        
        &--disability {
            top: 32px;
            right: -3px;
            width: 177px;
            height: 123px;
            padding: 32px 10px 0 0;
            background-image: url('../../../images/tree/protection/sections/Unemployment-min.png');
            &.tree-sub-item--base {
                background-image: url('../../../images/tree/protection/sections/Unemployment-0-min.png');
            }
            &.tree-sub-item--middle {
                background-image: url('../../../images/tree/protection/sections/Unemployment-10-min.png');
            }
            &.tree-sub-item--large {
                background-image: url('../../../images/tree/protection/sections/Unemployment-50-min.png');
            }
            &.tree-sub-item--disabled {
                background-image: url('../../../images/tree/protection/sections/Unemployment-not-active-min.png');
            }
        }
    
        &--insurance {
            top: 18px;
            left: -4px;
            width: 181px;
            height: 104px;
            padding: 0 32px 6px 0;
            background-image: url('../../../images/tree/protection/sections/Insurance-min.png');
            &.tree-sub-item--base {
                background-image: url('../../../images/tree/protection/sections/Insurance-0-min.png');
            }
            &.tree-sub-item--middle {
                background-image: url('../../../images/tree/protection/sections/Insurance-10-min.png');
            }
            &.tree-sub-item--large {
                background-image: url('../../../images/tree/protection/sections/Insurance-50-min.png');
            }
            &.tree-sub-item--disabled {
                background-image: url('../../../images/tree/protection/sections/Insurance-not-active-min.png');
            }
        }
        
        &--life {
            top: -4px;
            right: 52px;
            width: 175px;
            height: 99px;
            padding: 8px 0 0 10px;
            background-image: url('../../../images/tree/protection/sections/Disability-min.png');
            &.tree-sub-item--base {
                background-image: url('../../../images/tree/protection/sections/Disability-0-min.png');
            }
            &.tree-sub-item--middle {
                background-image: url('../../../images/tree/protection/sections/Disability-10-min.png');
            }
            &.tree-sub-item--large {
                background-image: url('../../../images/tree/protection/sections/Disability-50-min.png');
            }
            &.tree-sub-item--disabled {
                background-image: url('../../../images/tree/protection/sections/Disability-not-active-min.png');
            }
        }
        
        &--estate {
            top: 75px;
            left: -5px;
            width: 149px;
            height: 112px;
            padding: 20px 0 0;
            background-image: url('../../../images/tree/protection/sections/Bequeath-min.png');
            &.tree-sub-item--base {
                background-image: url('../../../images/tree/protection/sections/Bequeath-0-min.png');
            }
            &.tree-sub-item--middle {
                background-image: url('../../../images/tree/protection/sections/Bequeath-10-min.png');
            }
            &.tree-sub-item--large {
                background-image: url('../../../images/tree/protection/sections/Bequeath-50-min.png');
            }
            &.tree-sub-item--disabled {
                background-image: url('../../../images/tree/protection/sections/Bequeath-not-active-min.png');
            }
        }
    
        &--about-you {
            z-index: 2;
            top: 192px;
            left: 159px;
            width: 327px;
            height: 316px;
            padding: 0 22px 0 0;
            background-image: url('../../../images/tree/about/Profile/2_100-min.PNG');
            &.tree-sub-item--base {
                background-image: url('../../../images/tree/about/Profile/2_0-min.PNG');
            }
            &.tree-sub-item--middle {
                background-image: url('../../../images/tree/about/Profile/2_10-min.PNG');
            }
            &.tree-sub-item--large {
                background-image: url('../../../images/tree/about/Profile/2_50-min.PNG');
            }
            &.tree-sub-item--disabled {
                background-image: url('../../../images/tree/about/Profile/2_0-min.PNG');
            }
            @media (max-width: $breakpoint-large) {
                left: 259px;
            }
        }

        &--about-your-spouse {
            z-index: 3;
            top: 112px;
            left: 301px;
            width: 268px;
            height: 150px;
            padding: 0 10px 0 0;
            background-image: url('../../../images/tree/about/Profile/1_100-min.PNG');
            &.tree-sub-item--base {
                background-image: url('../../../images/tree/about/Profile/1_0-min.PNG');
            }
            &.tree-sub-item--middle {
                background-image: url('../../../images/tree/about/Profile/1_10-min.PNG');
            }
            &.tree-sub-item--large {
                background-image: url('../../../images/tree/about/Profile/1_50-min.PNG');
            }
            &.tree-sub-item--disabled {
                background-image: url('../../../images/tree/about/Profile/1_0-min.PNG');
            }
            @media (max-width: $breakpoint-large) {
                left: 399px;
            }
        }

        &--about-your-family {
            z-index: 1;
            top: 148px;
            left: 386px;
            width: 350px;
            height: 389px;
            padding: 15px 0 0 45px;
            background-image: url('../../../images/tree/about/Profile/3_100-min.PNG');
            &.tree-sub-item--base {
                background-image: url('../../../images/tree/about/Profile/3_0-min.PNG');
            }
            &.tree-sub-item--middle {
                background-image: url('../../../images/tree/about/Profile/3_10-min.PNG');
            }
            &.tree-sub-item--large {
                background-image: url('../../../images/tree/about/Profile/3_50-min.PNG');
            }
            &.tree-sub-item--disabled {
                background-image: url('../../../images/tree/about/Profile/3_0-min.PNG');
            }
            @media (max-width: $breakpoint-large) {
                left: 486px;
            }
        }
    
        &--children-1 {
            z-index: 10;
            top: 114px;
            left: 311px;
            width: 249px;
            height: 92px;
            padding: 0 0 29px;
            background-image: url('../../../images/tree/about/Children/1_100-min.png');
            &.tree-sub-item--base {
                background-image: url('../../../images/tree/about/Children/1_0-min.png');
            }
            &.tree-sub-item--middle {
                background-image: url('../../../images/tree/about/Children/1_10-min.png');
            }
            &.tree-sub-item--large {
                background-image: url('../../../images/tree/about/Children/1_50-min.png');
            }
            &.tree-sub-item--disabled {
                background-image: url('../../../images/tree/about/Children/1_0-min.png');
            }
            @media (max-width: $breakpoint-large) {
                left: 411px;
            }
        }
    
        &--children-2 {
            z-index: 8;
            top: 167px;
            left: 273px;
            width: 150px;
            height: 111px;
            padding: 28px 33px 0 0;
            background-image: url('../../../images/tree/about/Children/2_100-min.PNG');
            &.tree-sub-item--base {
                background-image: url('../../../images/tree/about/Children/2_0-min.PNG');
            }
            &.tree-sub-item--middle {
                background-image: url('../../../images/tree/about/Children/2_10-min.PNG');
            }
            &.tree-sub-item--large {
                background-image: url('../../../images/tree/about/Children/2_50-min.PNG');
            }
            &.tree-sub-item--disabled {
                background-image: url('../../../images/tree/about/Children/2_0-min.PNG');
            }
            @media (max-width: $breakpoint-large) {
                left: 373px;
            }
        }
    
        &--children-3 {
            z-index: 9;
            top: 124px;
            left: 404px;
            width: 310px;
            height: 156px;
            padding: 20px 10px 0;
            background-image: url('../../../images/tree/about/Children/3_100-min.PNG');
            &.tree-sub-item--base {
                background-image: url('../../../images/tree/about/Children/3_0-min.PNG');
            }
            &.tree-sub-item--middle {
                background-image: url('../../../images/tree/about/Children/3_10-min.PNG');
            }
            &.tree-sub-item--large {
                background-image: url('../../../images/tree/about/Children/3_50-min.PNG');
            }
            &.tree-sub-item--disabled {
                background-image: url('../../../images/tree/about/Children/3_0-min.PNG');
            }
            @media (max-width: $breakpoint-large) {
                left: 504px;
            }
        }
    
        &--children-4 {
            z-index: 6;
            top: 218px;
            left: 222px;
            width: 193px;
            height: 138px;
            padding: 20px 10px 0;
            background-image: url('../../../images/tree/about/Children/4_100-min.PNG');
            &.tree-sub-item--base {
                background-image: url('../../../images/tree/about/Children/4_0-min.PNG');
            }
            &.tree-sub-item--middle {
                background-image: url('../../../images/tree/about/Children/4_10-min.PNG');
            }
            &.tree-sub-item--large {
                background-image: url('../../../images/tree/about/Children/4_50-min.PNG');
            }
            &.tree-sub-item--disabled {
                background-image: url('../../../images/tree/about/Children/4_0-min.PNG');
            }
            @media (max-width: $breakpoint-large) {
                left: 322px;
            }
        }
    
        &--children-5 {
            z-index: 7;
            top: 201px;
            left: 375px;
            width: 349px;
            height: 150px;
            padding: 20px 10px 0;
            background-image: url('../../../images/tree/about/Children/5_100-min.PNG');
            &.tree-sub-item--base {
                background-image: url('../../../images/tree/about/Children/5_0-min.PNG');
            }
            &.tree-sub-item--middle {
                background-image: url('../../../images/tree/about/Children/5_10-min.PNG');
            }
            &.tree-sub-item--large {
                background-image: url('../../../images/tree/about/Children/5_50-min.PNG');
            }
            &.tree-sub-item--disabled {
                background-image: url('../../../images/tree/about/Children/5_0-min.PNG');
            }
            @media (max-width: $breakpoint-large) {
                left: 474px;
            }
        }
    
        &--children-6 {
            z-index: 5;
            top: 258px;
            left: 161px;
            width: 215px;
            height: 164px;
            padding: 45px 30px 0 0;
            background-image: url('../../../images/tree/about/Children/6_100-min.PNG');
            &.tree-sub-item--base {
                background-image: url('../../../images/tree/about/Children/6_0-min.PNG');
            }
            &.tree-sub-item--middle {
                background-image: url('../../../images/tree/about/Children/6_10-min.PNG');
            }
            &.tree-sub-item--large {
                background-image: url('../../../images/tree/about/Children/6_50-min.PNG');
            }
            &.tree-sub-item--disabled {
                background-image: url('../../../images/tree/about/Children/6_0-min.PNG');
            }
            @media (max-width: $breakpoint-large) {
                left: 261px;
            }
        }
    
        &--children-7 {
            z-index: 4;
            top: 272px;
            left: 361px;
            width: 219px;
            height: 131px;
            padding: 34px 0 0 29px;
            background-image: url('../../../images/tree/about/Children/7_100-min.PNG');
            &.tree-sub-item--base {
                background-image: url('../../../images/tree/about/Children/7_0-min.PNG');
            }
            &.tree-sub-item--middle {
                background-image: url('../../../images/tree/about/Children/7_10-min.PNG');
            }
            &.tree-sub-item--large {
                background-image: url('../../../images/tree/about/Children/7_50-min.PNG');
            }
            &.tree-sub-item--disabled {
                background-image: url('../../../images/tree/about/Children/7_0-min.PNG');
            }
            @media (max-width: $breakpoint-large) {
                left: 461px;
            }
        }
    
        &--children-8 {
            z-index: 3;
            top: 333px;
            left: 158px;
            width: 250px;
            height: 178px;
            padding: 45px 0 0;
            background-image: url('../../../images/tree/about/Children/8_100-min.PNG');
            &.tree-sub-item--base {
                background-image: url('../../../images/tree/about/Children/8_0-min.PNG');
            }
            &.tree-sub-item--middle {
                background-image: url('../../../images/tree/about/Children/8_10-min.PNG');
            }
            &.tree-sub-item--large {
                background-image: url('../../../images/tree/about/Children/8_50-min.PNG');
            }
            &.tree-sub-item--disabled {
                background-image: url('../../../images/tree/about/Children/8_0-min.PNG');
            }
            @media (max-width: $breakpoint-large) {
                left: 258px;
            }
        }
    
        &--children-9 {
            z-index: 2;
            top: 342px;
            left: 358px;
            width: 241px;
            height: 173px;
            padding: 45px 0 0;
            background-image: url('../../../images/tree/about/Children/9_100-min.PNG');
            &.tree-sub-item--base {
                background-image: url('../../../images/tree/about/Children/9_0-min.PNG');
            }
            &.tree-sub-item--middle {
                background-image: url('../../../images/tree/about/Children/9_10-min.PNG');
            }
            &.tree-sub-item--large {
                background-image: url('../../../images/tree/about/Children/9_50-min.PNG');
            }
            &.tree-sub-item--disabled {
                background-image: url('../../../images/tree/about/Children/9_0-min.PNG');
            }
            @media (max-width: $breakpoint-large) {
                left: 458px;
            }
        }
    
        &--children-10 {
            z-index: 1;
            top: 304px;
            left: 540px;
            width: 193px;
            height: 228px;
            padding: 69px 0 0;
            background-image: url('../../../images/tree/about/Children/10_100-min.PNG');
            &.tree-sub-item--base {
                background-image: url('../../../images/tree/about/Children/10_0-min.PNG');
            }
            &.tree-sub-item--middle {
                background-image: url('../../../images/tree/about/Children/10_10-min.PNG');
            }
            &.tree-sub-item--large {
                background-image: url('../../../images/tree/about/Children/10_50-min.PNG');
            }
            &.tree-sub-item--disabled {
                background-image: url('../../../images/tree/about/Children/10_0-min.PNG');
            }
            @media (max-width: $breakpoint-large) {
                left: 640px;
            }
        }
    
        &--education-item-1 {
            z-index: 10;
            top: 110px;
            left: 326px;
            width: 203px;
            height: 88px;
            background-image: url('../../../images/tree/about/Education/1_100-min.png');
            &.tree-sub-item--base {
                background-image: url('../../../images/tree/about/Education/1-0-min.png');
            }
            &.tree-sub-item--middle {
                background-image: url('../../../images/tree/about/Education/1-10-min.png');
            }
            &.tree-sub-item--large {
                background-image: url('../../../images/tree/about/Education/1_50-min.png');
            }
            &.tree-sub-item--disabled {
                background-image: url('../../../images/tree/about/Education/1_100-min.png');
            }
            @media (max-width: $breakpoint-large) {
                left: 426px;
            }
        }
    
        &--education-item-2 {
            z-index: 9;
            top: 145px;
            left: 237px;
            width: 235px;
            height: 129px;
            padding: 10px 0 0;
            background-image: url('../../../images/tree/about/Education/2_100-min.png');
            &.tree-sub-item--base {
                background-image: url('../../../images/tree/about/Education/2_0-min.png');
            }
            &.tree-sub-item--middle {
                background-image: url('../../../images/tree/about/Education/2_10-min.png');
            }
            &.tree-sub-item--large {
                background-image: url('../../../images/tree/about/Education/2_50-min.png');
            }
            &.tree-sub-item--disabled {
                background-image: url('../../../images/tree/about/Education/2_100-min.png');
            }
            @media (max-width: $breakpoint-large) {
                left: 337px;
            }
        }
    
        &--education-item-3 {
            z-index: 8;
            top: 131px;
            left: 421px;
            width: 188px;
            height: 117px;
            background-image: url('../../../images/tree/about/Education/3_100-min.png');
            &.tree-sub-item--base {
                background-image: url('../../../images/tree/about/Education/3_0-min.png');
            }
            &.tree-sub-item--middle {
                background-image: url('../../../images/tree/about/Education/3_10-min.png');
            }
            &.tree-sub-item--large {
                background-image: url('../../../images/tree/about/Education/3_50-min.png');
            }
            &.tree-sub-item--disabled {
                background-image: url('../../../images/tree/about/Education/3_100-min.png');
            }
            @media (max-width: $breakpoint-large) {
                left: 521px;
            }
        }
    
        &--education-item-4 {
            z-index: 7;
            top: 199px;
            left: 318px;
            width: 192px;
            height: 116px;
            padding: 27px 0 0 72px;
            background-image: url('../../../images/tree/about/Education/4_100-min.png');
            &.tree-sub-item--base {
                background-image: url('../../../images/tree/about/Education/4_0-min.png');
            }
            &.tree-sub-item--middle {
                background-image: url('../../../images/tree/about/Education/4_10-min.png');
            }
            &.tree-sub-item--large {
                background-image: url('../../../images/tree/about/Education/4_50-min.png');
            }
            &.tree-sub-item--disabled {
                background-image: url('../../../images/tree/about/Education/4_100-min.png');
            }
            @media (max-width: $breakpoint-large) {
                left: 418px;
            }
        }
    
        &--education-item-5 {
            z-index: 6;
            top: 164px;
            left: 544px;
            width: 168px;
            height: 108px;
            padding: 0 0 0 10px;
            background-image: url('../../../images/tree/about/Education/5_100-min.png');
            &.tree-sub-item--base {
                background-image: url('../../../images/tree/about/Education/5_0-min.png');
            }
            &.tree-sub-item--middle {
                background-image: url('../../../images/tree/about/Education/5_10-min.png');
            }
            &.tree-sub-item--large {
                background-image: url('../../../images/tree/about/Education/5_50-min.png');
            }
            &.tree-sub-item--disabled {
                background-image: url('../../../images/tree/about/Education/5_100-min.png');
            }
            @media (max-width: $breakpoint-large) {
                left: 644px;
            }
        }
    
        &--education-item-6 {
            z-index: 5;
            top: 247px;
            left: 168px;
            width: 222px;
            height: 135px;
            background-image: url('../../../images/tree/about/Education/7_100-min.png');
            &.tree-sub-item--base {
                background-image: url('../../../images/tree/about/Education/7_0-min.png');
            }
            &.tree-sub-item--middle {
                background-image: url('../../../images/tree/about/Education/7_10-min.png');
            }
            &.tree-sub-item--large {
                background-image: url('../../../images/tree/about/Education/7_50-min.png');
            }
            &.tree-sub-item--disabled {
                background-image: url('../../../images/tree/about/Education/7_100-min.png');
            }
            @media (max-width: $breakpoint-large) {
                left: 268px;
            }
        }
    
        &--education-item-7 {
            z-index: 4;
            top: 256px;
            left: 327px;
            width: 215px;
            height: 141px;
            padding: 16px 0 0;
            background-image: url('../../../images/tree/about/Education/8_100-min.png');
            &.tree-sub-item--base {
                background-image: url('../../../images/tree/about/Education/8_0-min.png');
            }
            &.tree-sub-item--middle {
                background-image: url('../../../images/tree/about/Education/8_10-min.png');
            }
            &.tree-sub-item--large {
                background-image: url('../../../images/tree/about/Education/8_50-min.png');
            }
            &.tree-sub-item--disabled {
                background-image: url('../../../images/tree/about/Education/8_100-min.png');
            }
            @media (max-width: $breakpoint-large) {
                left: 427px;
            }
        }
    
        &--education-item-8 {
            z-index: 3;
            top: 202px;
            left: 486px;
            width: 232px;
            height: 138px;
            padding: 28px 0 0;
            background-image: url('../../../images/tree/about/Education/6_100-min.png');
            &.tree-sub-item--base {
                background-image: url('../../../images/tree/about/Education/6_0-min.png');
            }
            &.tree-sub-item--middle {
                background-image: url('../../../images/tree/about/Education/6_10-min.png');
            }
            &.tree-sub-item--large {
                background-image: url('../../../images/tree/about/Education/6_50-min.png');
            }
            &.tree-sub-item--disabled {
                background-image: url('../../../images/tree/about/Education/6_100-min.png');
            }
            @media (max-width: $breakpoint-large) {
                left: 586px;
            }
        }
    
        &--education-item-9 {
            z-index: 2;
            top: 295px;
            left: 156px;
            width: 252px;
            height: 212px;
            padding: 28px 0 0;
            background-image: url('../../../images/tree/about/Education/9_100-min.png');
            &.tree-sub-item--base {
                background-image: url('../../../images/tree/about/Education/9_0-min.png');
            }
            &.tree-sub-item--middle {
                background-image: url('../../../images/tree/about/Education/9_10-min.png');
            }
            &.tree-sub-item--large {
                background-image: url('../../../images/tree/about/Education/9_50-min.png');
            }
            &.tree-sub-item--disabled {
                background-image: url('../../../images/tree/about/Education/9_100-min.png');
            }
            @media (max-width: $breakpoint-large) {
                left: 256px;
            }
        }
    
        &--education-item-10 {
            z-index: 1;
            top: 335px;
            left: 298px;
            width: 197px;
            height: 174px;
            padding: 28px 0 0 10px;
            background-image: url('../../../images/tree/about/Education/10_100-min.png');
            &.tree-sub-item--base {
                background-image: url('../../../images/tree/about/Education/10_0-min.png');
            }
            &.tree-sub-item--middle {
                background-image: url('../../../images/tree/about/Education/10_10-min.png');
            }
            &.tree-sub-item--large {
                background-image: url('../../../images/tree/about/Education/10_50-min.png');
            }
            &.tree-sub-item--disabled {
                background-image: url('../../../images/tree/about/Education/10_100-min.png');
            }
            @media (max-width: $breakpoint-large) {
                left: 398px;
            }
        }
        
        &--home {
            top: 103px;
            left: 337px;
            width: 328px;
            height: 182px;
            background-image: url('../../../images/tree/protection/Insurance/1_100-min.png');
            &.tree-sub-item--base {
                background-image: url('../../../images/tree/protection/Insurance/1_0-min.png');
            }
            &.tree-sub-item--middle {
                background-image: url('../../../images/tree/protection/Insurance/1_10-min.png');
            }
            &.tree-sub-item--large {
                background-image: url('../../../images/tree/protection/Insurance/1_50-min.png');
            }
            &.tree-sub-item--disabled {
                background-image: url('../../../images/tree/protection/Insurance/1_0-min.png');
            }
            @media (max-width: $breakpoint-large) {
                left: 437px;
            }
        }
    
        &--health {
            top: 139px;
            left: 116px;
            width: 356px;
            height: 320px;
            padding: 16px 10px 0 0;
            background-image: url('../../../images/tree/protection/Insurance/2_100-min.png');
            &.tree-sub-item--base {
                background-image: url('../../../images/tree/protection/Insurance/2_0-min.png');
            }
            &.tree-sub-item--middle {
                background-image: url('../../../images/tree/protection/Insurance/2_10-min.png');
            }
            &.tree-sub-item--large {
                background-image: url('../../../images/tree/protection/Insurance/2_50-min.png');
            }
            &.tree-sub-item--disabled {
                background-image: url('../../../images/tree/protection/Insurance/2_0-min.png');
            }
            @media (max-width: $breakpoint-large) {
                left: 215px;
            }
        }
    
        &--umbrella {
            top: 168px;
            left: 428px;
            width: 345px;
            height: 220px;
            padding: 58px 0 0;
            background-image: url('../../../images/tree/protection/Insurance/3_100-min.png');
            &.tree-sub-item--base {
                background-image: url('../../../images/tree/protection/Insurance/3_0-min.png');
            }
            &.tree-sub-item--middle {
                background-image: url('../../../images/tree/protection/Insurance/3_10-min.png');
            }
            &.tree-sub-item--large {
                background-image: url('../../../images/tree/protection/Insurance/3_50-min.png');
            }
            &.tree-sub-item--disabled {
                background-image: url('../../../images/tree/protection/Insurance/3_0-min.png');
            }
            @media (max-width: $breakpoint-large) {
                left: 528px;
            }
        }
        
        &--auto {
            top: 326px;
            left: 212px;
            width: 371px;
            height: 236px;
            padding: 72px 57px 0 0;
            background-image: url('../../../images/tree/protection/Insurance/4_100-min.png');
            &.tree-sub-item--base {
                background-image: url('../../../images/tree/protection/Insurance/4_0-min.png');
            }
            &.tree-sub-item--middle {
                background-image: url('../../../images/tree/protection/Insurance/4_10-min.png');
            }
            &.tree-sub-item--large {
                background-image: url('../../../images/tree/protection/Insurance/4_50-min.png');
            }
            &.tree-sub-item--disabled {
                background-image: url('../../../images/tree/protection/Insurance/4_0-min.png');
            }
            @media (max-width: $breakpoint-large) {
                left: 312px;
            }
        }
        
        &--sabbatical-your-spouse {
            z-index: 2;
            top: 98px;
            left: 167px;
            width: 425px;
            height: 364px;
            padding: 0 155px 85px 60px;
            background-image: url('../../../images/tree/future/Sabbatical/1_100-min.PNG');
            &.tree-sub-item--base {
                background-image: url('../../../images/tree/future/Sabbatical/1_0-min.PNG');
            }
            &.tree-sub-item--middle {
                background-image: url('../../../images/tree/future/Sabbatical/1_10-min.PNG');
            }
            &.tree-sub-item--large {
                background-image: url('../../../images/tree/future/Sabbatical/1_50-min.PNG');
            }
            &.tree-sub-item--disabled {
                background-image: url('../../../images/tree/future/Sabbatical/1_0-min.PNG');
            }
            @media (max-width: $breakpoint-large) {
                left: 266px;
            }
        }
    
        &--sabbatical-your {
            z-index: 1;
            top: 240px;
            left: 427px;
            width: 328px;
            height: 324px;
            padding: 15px 45px 0 80px;
            background-image: url('../../../images/tree/future/Sabbatical/2_100-min.PNG');
            &.tree-sub-item--base {
                background-image: url('../../../images/tree/future/Sabbatical/2_0-min.PNG');
            }
            &.tree-sub-item--middle {
                background-image: url('../../../images/tree/future/Sabbatical/2_10-min.PNG');
            }
            &.tree-sub-item--large {
                background-image: url('../../../images/tree/future/Sabbatical/2_50-min.PNG');
            }
            &.tree-sub-item--disabled {
                background-image: url('../../../images/tree/future/Sabbatical/2_0-min.PNG');
            }
            @media (max-width: $breakpoint-large) {
                left: 526px;
            }
        }
    
        &--future-child-1 {
            z-index: 10;
            top: 96px;
            left: 170px;
            width: 308px;
            height: 140px;
            padding: 0 0 38px;
            background-image: url('../../../images/tree/future/Children/1-100-min.png');
            &.tree-sub-item--base {
                background-image: url('../../../images/tree/future/Children/1-0-min.png');
            }
            &.tree-sub-item--middle {
                background-image: url('../../../images/tree/future/Children/1-10-min.png');
            }
            &.tree-sub-item--large {
                background-image: url('../../../images/tree/future/Children/1-50-min.png');
            }
            &.tree-sub-item--disabled {
                background-image: url('../../../images/tree/future/Children/1-0-min.png');
            }
            @media (max-width: $breakpoint-large) {
                left: 270px;
            }
        }
    
        &--future-child-2 {
            z-index: 9;
            top: 207px;
            left: 169px;
            width: 198px;
            height: 204px;
            padding: 0 0 0 20px;
            background-image: url('../../../images/tree/future/Children/2-100-min.png');
            &.tree-sub-item--base {
                background-image: url('../../../images/tree/future/Children/2-0-min.png');
            }
            &.tree-sub-item--middle {
                background-image: url('../../../images/tree/future/Children/2-10-min.png');
            }
            &.tree-sub-item--large {
                background-image: url('../../../images/tree/future/Children/2-50-min.png');
            }
            &.tree-sub-item--disabled {
                background-image: url('../../../images/tree/future/Children/2-0-min.png');
            }
            @media (max-width: $breakpoint-large) {
                left: 268px;
            }
        }
    
        &--future-child-3 {
            z-index: 8;
            top: 171px;
            left: 282px;
            width: 221px;
            height: 99px;
            padding: 20px 0 0;
            background-image: url('../../../images/tree/future/Children/3-100-min.png');
            &.tree-sub-item--base {
                background-image: url('../../../images/tree/future/Children/3-0-min.png');
            }
            &.tree-sub-item--middle {
                background-image: url('../../../images/tree/future/Children/3-10-min.png');
            }
            &.tree-sub-item--large {
                background-image: url('../../../images/tree/future/Children/3-50-min.png');
            }
            &.tree-sub-item--disabled {
                background-image: url('../../../images/tree/future/Children/3-0-min.png');
            }
            @media (max-width: $breakpoint-large) {
                left: 382px;
            }
        }
    
        &--future-child-4 {
            z-index: 7;
            top: 209px;
            left: 442px;
            width: 143px;
            height: 94px;
            padding: 0 0 0 26px;
            background-image: url('../../../images/tree/future/Children/4-100-min.png');
            &.tree-sub-item--base {
                background-image: url('../../../images/tree/future/Children/4-0-min.png');
            }
            &.tree-sub-item--middle {
                background-image: url('../../../images/tree/future/Children/4-10-min.png');
            }
            &.tree-sub-item--large {
                background-image: url('../../../images/tree/future/Children/4-50-min.png');
            }
            &.tree-sub-item--disabled {
                background-image: url('../../../images/tree/future/Children/4-0-min.png');
            }
            @media (max-width: $breakpoint-large) {
                left: 542px;
            }
        }
    
        &--future-child-5 {
            z-index: 6;
            top: 251px;
            left: 344px;
            width: 177px;
            height: 100px;
            padding: 0 28px 0 0;
            background-image: url('../../../images/tree/future/Children/5-100-min.png');
            &.tree-sub-item--base {
                background-image: url('../../../images/tree/future/Children/5-0-min.png');
            }
            &.tree-sub-item--middle {
                background-image: url('../../../images/tree/future/Children/5-10-min.png');
            }
            &.tree-sub-item--large {
                background-image: url('../../../images/tree/future/Children/5-50-min.png');
            }
            &.tree-sub-item--disabled {
                background-image: url('../../../images/tree/future/Children/5-0-min.png');
            }
            @media (max-width: $breakpoint-large) {
                left: 444px;
            }
        }
    
        &--future-child-6 {
            z-index: 5;
            top: 258px;
            left: 545px;
            width: 166px;
            height: 100px;
            background-image: url('../../../images/tree/future/Children/6-100-min.png');
            &.tree-sub-item--base {
                background-image: url('../../../images/tree/future/Children/6-0-min.png');
            }
            &.tree-sub-item--middle {
                background-image: url('../../../images/tree/future/Children/6-10-min.png');
            }
            &.tree-sub-item--large {
                background-image: url('../../../images/tree/future/Children/6-50-min.png');
            }
            &.tree-sub-item--disabled {
                background-image: url('../../../images/tree/future/Children/6-0-min.png');
            }
            @media (max-width: $breakpoint-large) {
                left: 645px;
            }
        }
    
        &--future-child-7 {
            z-index: 3;
            top: 321px;
            left: 317px;
            width: 171px;
            height: 140px;
            background-image: url('../../../images/tree/future/Children/7-100-min.png');
            &.tree-sub-item--base {
                background-image: url('../../../images/tree/future/Children/7-0-min.png');
            }
            &.tree-sub-item--middle {
                background-image: url('../../../images/tree/future/Children/7-10-min.png');
            }
            &.tree-sub-item--large {
                background-image: url('../../../images/tree/future/Children/7-50-min.png');
            }
            &.tree-sub-item--disabled {
                background-image: url('../../../images/tree/future/Children/7-0-min.png');
            }
            @media (max-width: $breakpoint-large) {
                left: 417px;
            }
        }
    
        &--future-child-8 {
            z-index: 4;
            top: 294px;
            left: 468px;
            width: 173px;
            height: 140px;
            padding: 0 33px 0 0;
            background-image: url('../../../images/tree/future/Children/8-100-min.png');
            &.tree-sub-item--base {
                background-image: url('../../../images/tree/future/Children/8-0-min.png');
            }
            &.tree-sub-item--middle {
                background-image: url('../../../images/tree/future/Children/8-10-min.png');
            }
            &.tree-sub-item--large {
                background-image: url('../../../images/tree/future/Children/8-50-min.png');
            }
            &.tree-sub-item--disabled {
                background-image: url('../../../images/tree/future/Children/8-0-min.png');
            }
            @media (max-width: $breakpoint-large) {
                left: 568px;
            }
        }
    
        &--future-child-9 {
            z-index: 2;
            top: 326px;
            left: 575px;
            width: 176px;
            height: 180px;
            padding: 0 0 0 20px;
            background-image: url('../../../images/tree/future/Children/9-100-min.png');
            &.tree-sub-item--base {
                background-image: url('../../../images/tree/future/Children/9-0-min.png');
            }
            &.tree-sub-item--middle {
                background-image: url('../../../images/tree/future/Children/9-10-min.png');
            }
            &.tree-sub-item--large {
                background-image: url('../../../images/tree/future/Children/9-50-min.png');
            }
            &.tree-sub-item--disabled {
                background-image: url('../../../images/tree/future/Children/9-0-min.png');
            }
            @media (max-width: $breakpoint-large) {
                left: 675px;
            }
        }
    
        &--future-child-10 {
            z-index: 1;
            top: 383px;
            left: 444px;
            width: 267px;
            height: 177px;
            padding: 0 51px 14px 0;
            background-image: url('../../../images/tree/future/Children/10-100-min.png');
            &.tree-sub-item--base {
                background-image: url('../../../images/tree/future/Children/10-0-min.png');
            }
            &.tree-sub-item--middle {
                background-image: url('../../../images/tree/future/Children/10-10-min.png');
            }
            &.tree-sub-item--large {
                background-image: url('../../../images/tree/future/Children/10-50-min.png');
            }
            &.tree-sub-item--disabled {
                background-image: url('../../../images/tree/future/Children/10-0-min.png');
            }
            @media (max-width: $breakpoint-large) {
                left: 544px;
            }
        }
    
        &--future-education-item-1 {
            z-index: 10;
            top: 100px;
            left: 203px;
            width: 222px;
            height: 108px;
            padding: 0 0 10px 20px;
            background-image: url('../../../images/tree/future/Education/1_100-min.png');
            &.tree-sub-item--base {
                background-image: url('../../../images/tree/future/Education/1_0-min.png');
            }
            &.tree-sub-item--middle {
                background-image: url('../../../images/tree/future/Education/1_10-min.png');
            }
            &.tree-sub-item--large {
                background-image: url('../../../images/tree/future/Education/1_50-min.png');
            }
            &.tree-sub-item--disabled {
                background-image: url('../../../images/tree/future/Education/1_0-min.png');
            }
            @media (max-width: $breakpoint-large) {
                left: 303px;
            }
        }
    
        &--future-education-item-2 {
            z-index: 9;
            top: 147px;
            left: 173px;
            width: 180px;
            height: 143px;
            padding: 0 30px 0 0;
            background-image: url('../../../images/tree/future/Education/2_100-min.png');
            &.tree-sub-item--base {
                background-image: url('../../../images/tree/future/Education/2_0-min.png');
            }
            &.tree-sub-item--middle {
                background-image: url('../../../images/tree/future/Education/2_10-min.png');
            }
            &.tree-sub-item--large {
                background-image: url('../../../images/tree/future/Education/2_50-min.png');
            }
            &.tree-sub-item--disabled {
                background-image: url('../../../images/tree/future/Education/2_0-min.png');
            }
            @media (max-width: $breakpoint-large) {
                left: 273px;
            }
        }
    
        &--future-education-item-3 {
            z-index: 8;
            top: 118px;
            left: 309px;
            width: 164px;
            height: 142px;
            padding: 29px 0 0 20px;
            background-image: url('../../../images/tree/future/Education/3_100-min.png');
            &.tree-sub-item--base {
                background-image: url('../../../images/tree/future/Education/3_0-min.png');
            }
            &.tree-sub-item--middle {
                background-image: url('../../../images/tree/future/Education/3_10-min.png');
            }
            &.tree-sub-item--large {
                background-image: url('../../../images/tree/future/Education/3_50-min.png');
            }
            &.tree-sub-item--disabled {
                background-image: url('../../../images/tree/future/Education/3_0-min.png');
            }
            @media (max-width: $breakpoint-large) {
                left: 409px;
            }
        }
    
        &--future-education-item-4 {
            z-index: 7;
            top: 197px;
            left: 169px;
            width: 184px;
            height: 213px;
            padding: 30px 0 0;
            background-image: url('../../../images/tree/future/Education/4_100-min.png');
            &.tree-sub-item--base {
                background-image: url('../../../images/tree/future/Education/4_0-min.png');
            }
            &.tree-sub-item--middle {
                background-image: url('../../../images/tree/future/Education/4_10-min.png');
            }
            &.tree-sub-item--large {
                background-image: url('../../../images/tree/future/Education/4_50-min.png');
            }
            &.tree-sub-item--disabled {
                background-image: url('../../../images/tree/future/Education/4_0-min.png');
            }
            @media (max-width: $breakpoint-large) {
                left: 269px;
            }
        }
    
        &--future-education-item-5 {
            z-index: 6;
            top: 232px;
            left: 346px;
            width: 174px;
            height: 108px;
            padding: 0 0 20px;
            background-image: url('../../../images/tree/future/Education/5_100-min.png');
            &.tree-sub-item--base {
                background-image: url('../../../images/tree/future/Education/5_0-min.png');
            }
            &.tree-sub-item--middle {
                background-image: url('../../../images/tree/future/Education/5_10-min.png');
            }
            &.tree-sub-item--large {
                background-image: url('../../../images/tree/future/Education/5_50-min.png');
            }
            &.tree-sub-item--disabled {
                background-image: url('../../../images/tree/future/Education/5_0-min.png');
            }
            @media (max-width: $breakpoint-large) {
                left: 446px;
            }
        }
    
        &--future-education-item-6 {
            z-index: 5;
            top: 200px;
            left: 425px;
            width: 162px;
            height: 94px;
            padding: 0 0 0 20px;
            background-image: url('../../../images/tree/future/Education/6_100-min.png');
            &.tree-sub-item--base {
                background-image: url('../../../images/tree/future/Education/6_0-min.png');
            }
            &.tree-sub-item--middle {
                background-image: url('../../../images/tree/future/Education/6_10-min.png');
            }
            &.tree-sub-item--large {
                background-image: url('../../../images/tree/future/Education/6_50-min.png');
            }
            &.tree-sub-item--disabled {
                background-image: url('../../../images/tree/future/Education/6_0-min.png');
            }
            @media (max-width: $breakpoint-large) {
                left: 525px;
            }
        }
    
        &--future-education-item-7 {
            z-index: 4;
            top: 256px;
            left: 502px;
            width: 208px;
            height: 112px;
            padding: 0 0 0 20px;
            background-image: url('../../../images/tree/future/Education/7_100-min.png');
            &.tree-sub-item--base {
                background-image: url('../../../images/tree/future/Education/7_0-min.png');
            }
            &.tree-sub-item--middle {
                background-image: url('../../../images/tree/future/Education/7_10-min.png');
            }
            &.tree-sub-item--large {
                background-image: url('../../../images/tree/future/Education/7_50-min.png');
            }
            &.tree-sub-item--disabled {
                background-image: url('../../../images/tree/future/Education/7_0-min.png');
            }
            @media (max-width: $breakpoint-large) {
                left: 602px;
            }
        }
    
        &--future-education-item-8 {
            z-index: 3;
            top: 319px;
            left: 558px;
            width: 168px;
            height: 112px;
            padding: 12px 0 0 10px;
            background-image: url('../../../images/tree/future/Education/8_100-min.png');
            &.tree-sub-item--base {
                background-image: url('../../../images/tree/future/Education/8_0-min.png');
            }
            &.tree-sub-item--middle {
                background-image: url('../../../images/tree/future/Education/8_10-min.png');
            }
            &.tree-sub-item--large {
                background-image: url('../../../images/tree/future/Education/8_50-min.png');
            }
            &.tree-sub-item--disabled {
                background-image: url('../../../images/tree/future/Education/8_0-min.png');
            }
            @media (max-width: $breakpoint-large) {
                left: 658px;
            }
        }
    
        &--future-education-item-9 {
            z-index: 2;
            top: 354px;
            left: 601px;
            width: 149px;
            height: 154px;
            padding: 38px 0 0 10px;
            background-image: url('../../../images/tree/future/Education/9_100-min.png');
            &.tree-sub-item--base {
                background-image: url('../../../images/tree/future/Education/9_0-min.png');
            }
            &.tree-sub-item--middle {
                background-image: url('../../../images/tree/future/Education/9_10-min.png');
            }
            &.tree-sub-item--large {
                background-image: url('../../../images/tree/future/Education/9_50-min.png');
            }
            &.tree-sub-item--disabled {
                background-image: url('../../../images/tree/future/Education/9_0-min.png');
            }
            @media (max-width: $breakpoint-large) {
                left: 701px;
            }
        }
    
        &--future-education-item-10 {
            z-index: 1;
            top: 390px;
            left: 571px;
            width: 142px;
            height: 170px;
            padding: 83px 0 0 20px;
            background-image: url('../../../images/tree/future/Education/10_100-min.png');
            &.tree-sub-item--base {
                background-image: url('../../../images/tree/future/Education/10_0-min.png');
            }
            &.tree-sub-item--middle {
                background-image: url('../../../images/tree/future/Education/10_10-min.png');
            }
            &.tree-sub-item--large {
                background-image: url('../../../images/tree/future/Education/10_50-min.png');
            }
            &.tree-sub-item--disabled {
                background-image: url('../../../images/tree/future/Education/10_0-min.png');
            }
            @media (max-width: $breakpoint-large) {
                left: 671px;
            }
        }
    
        &--objective-1 {
            z-index: 7;
            top: 96px;
            left: 169px;
            width: 308px;
            height: 136px;
            padding: 0 0 47px;
            background-image: url('../../../images/tree/future/Objectives/1-100-min.png');
            &.tree-sub-item--base {
                background-image: url('../../../images/tree/future/Objectives/1-0-min.png');
            }
            &.tree-sub-item--middle {
                background-image: url('../../../images/tree/future/Objectives/1-10-min.png');
            }
            &.tree-sub-item--large {
                background-image: url('../../../images/tree/future/Objectives/1-50-min.png');
            }
            &.tree-sub-item--disabled {
                background-image: url('../../../images/tree/future/Objectives/1-0-min.png');
            }
            @media (max-width: $breakpoint-large) {
                left: 272px;
            }
        }
    
        &--objective-2 {
            z-index: 6;
            top: 166px;
            left: 169px;
            width: 341px;
            height: 136px;
            padding: 10px 40px 0 0;
            background-image: url('../../../images/tree/future/Objectives/2-100-min.png');
            &.tree-sub-item--base {
                background-image: url('../../../images/tree/future/Objectives/2-0-min.png');
            }
            &.tree-sub-item--middle {
                background-image: url('../../../images/tree/future/Objectives/2-10-min.png');
            }
            &.tree-sub-item--large {
                background-image: url('../../../images/tree/future/Objectives/2-50-min.png');
            }
            &.tree-sub-item--disabled {
                background-image: url('../../../images/tree/future/Objectives/2-0-min.png');
            }
            @media (max-width: $breakpoint-large) {
                left: 272px;
            }
        }
    
        &--objective-3 {
            z-index: 5;
            top: 178px;
            left: 166px;
            width: 444px;
            height: 236px;
            padding: 63px 50px 0 0;
            background-image: url('../../../images/tree/future/Objectives/3-100-min.png');
            &.tree-sub-item--base {
                background-image: url('../../../images/tree/future/Objectives/3-0-min.png');
            }
            &.tree-sub-item--middle {
                background-image: url('../../../images/tree/future/Objectives/3-10-min.png');
            }
            &.tree-sub-item--large {
                background-image: url('../../../images/tree/future/Objectives/3-50-min.png');
            }
            &.tree-sub-item--disabled {
                background-image: url('../../../images/tree/future/Objectives/3-0-min.png');
            }
            @media (max-width: $breakpoint-large) {
                left: 265px;
            }
        }
    
        &--objective-4 {
            z-index: 4;
            top: 299px;
            left: 294px;
            width: 256px;
            height: 173px;
            padding: 67px 30px 0 10px;
            background-image: url('../../../images/tree/future/Objectives/4-100-min.png');
            &.tree-sub-item--base {
                background-image: url('../../../images/tree/future/Objectives/4-0-min.png');
            }
            &.tree-sub-item--middle {
                background-image: url('../../../images/tree/future/Objectives/4-10-min.png');
            }
            &.tree-sub-item--large {
                background-image: url('../../../images/tree/future/Objectives/4-50-min.png');
            }
            &.tree-sub-item--disabled {
                background-image: url('../../../images/tree/future/Objectives/4-0-min.png');
            }
            @media (max-width: $breakpoint-large) {
                left: 394px;
            }
        }
    
        &--objective-5 {
            z-index: 3;
            top: 254px;
            left: 492px;
            width: 221px;
            height: 160px;
            padding: 0 0 10px 105px;
            background-image: url('../../../images/tree/future/Objectives/5-100-min.png');
            &.tree-sub-item--base {
                background-image: url('../../../images/tree/future/Objectives/5-0-min.png');
            }
            &.tree-sub-item--middle {
                background-image: url('../../../images/tree/future/Objectives/5-10-min.png');
            }
            &.tree-sub-item--large {
                background-image: url('../../../images/tree/future/Objectives/5-50-min.png');
            }
            &.tree-sub-item--disabled {
                background-image: url('../../../images/tree/future/Objectives/5-0-min.png');
            }
            @media (max-width: $breakpoint-large) {
                left: 592px;
            }
        }
        
        &--objective-6 {
            z-index: 2;
            top: 343px;
            left: 445px;
            width: 267px;
            height: 169px;
            padding: 34px 30px 0;
            background-image: url('../../../images/tree/future/Objectives/6-100-min.png');
            &.tree-sub-item--base {
                background-image: url('../../../images/tree/future/Objectives/6-0-min.png');
            }
            &.tree-sub-item--middle {
                background-image: url('../../../images/tree/future/Objectives/6-10-min.png');
            }
            &.tree-sub-item--large {
                background-image: url('../../../images/tree/future/Objectives/6-50-min.png');
            }
            &.tree-sub-item--disabled {
                background-image: url('../../../images/tree/future/Objectives/6-0-min.png');
            }
            @media (max-width: $breakpoint-large) {
                left: 545px;
            }
        }
        
        &--objective-7 {
            z-index: 1;
            top: 339px;
            left: 577px;
            width: 176px;
            height: 221px;
            padding: 121px 30px 0;
            background-image: url('../../../images/tree/future/Objectives/7-100-min.png');
            &.tree-sub-item--base {
                background-image: url('../../../images/tree/future/Objectives/7-0-min.png');
            }
            &.tree-sub-item--middle {
                background-image: url('../../../images/tree/future/Objectives/7-10-min.png');
            }
            &.tree-sub-item--large {
                background-image: url('../../../images/tree/future/Objectives/7-50-min.png');
            }
            &.tree-sub-item--disabled {
                background-image: url('../../../images/tree/future/Objectives/7-0-min.png');
            }
            @media (max-width: $breakpoint-large) {
                left: 677px;
            }
        }
    
        &--retirement-your {
            z-index: 2;
            top: 99px;
            left: 168px;
            width: 422px;
            height: 365px;
            padding: 0 113px 16px;
            background-image: url('../../../images/tree/future/Retirement/1_100-min.png');
            &.tree-sub-item--base {
                background-image: url('../../../images/tree/future/Retirement/1_0-min.png');
            }
            &.tree-sub-item--middle {
                background-image: url('../../../images/tree/future/Retirement/1_10-min.png');
            }
            &.tree-sub-item--large {
                background-image: url('../../../images/tree/future/Retirement/1_50-min.png');
            }
            &.tree-sub-item--disabled {
                background-image: url('../../../images/tree/future/Retirement/1_0-min.png');
            }
            @media (max-width: $breakpoint-large) {
                left: 269px;
            }
        }
    
        &--retirement-spouse {
            z-index: 1;
            top: 257px;
            left: 436px;
            width: 315px;
            height: 306px;
            padding: 0 60px 0 122px;
            background-image: url('../../../images/tree/future/Retirement/2_100-min.png');
            &.tree-sub-item--base {
                background-image: url('../../../images/tree/future/Retirement/2_0-min.png');
            }
            &.tree-sub-item--middle {
                background-image: url('../../../images/tree/future/Retirement/2_10-min.png');
            }
            &.tree-sub-item--large {
                background-image: url('../../../images/tree/future/Retirement/2_50-min.png');
            }
            &.tree-sub-item--disabled {
                background-image: url('../../../images/tree/future/Retirement/2_0-min.png');
            }
            @media (max-width: $breakpoint-large) {
                left: 536px;
            }
        }
    
        &--bequeath-item-1 {
            z-index: 5;
            top: 148px;
            left: 132px;
            width: 277px;
            height: 181px;
            background-image: url('../../../images/tree/protection/Bequeath/1_100-min.png');
            &.tree-sub-item--base {
                background-image: url('../../../images/tree/protection/Bequeath/1_0-min.png');
            }
            &.tree-sub-item--middle {
                background-image: url('../../../images/tree/protection/Bequeath/1_10-min.png');
            }
            &.tree-sub-item--large {
                background-image: url('../../../images/tree/protection/Bequeath/1_50-min.png');
            }
            &.tree-sub-item--disabled {
                background-image: url('../../../images/tree/protection/Bequeath/1_0-min.png');
            }
            @media (max-width: $breakpoint-large) {
                left: 232px;
            }
        }
    
        &--bequeath-item-2 {
            z-index: 4;
            top: 105px;
            left: 343px;
            width: 321px;
            height: 168px;
            padding: 0 0 15px;
            background-image: url('../../../images/tree/protection/Bequeath/2_100-min.png');
            &.tree-sub-item--base {
                background-image: url('../../../images/tree/protection/Bequeath/2_0-min.png');
            }
            &.tree-sub-item--middle {
                background-image: url('../../../images/tree/protection/Bequeath/2_10-min.png');
            }
            &.tree-sub-item--large {
                background-image: url('../../../images/tree/protection/Bequeath/2_50-min.png');
            }
            &.tree-sub-item--disabled {
                background-image: url('../../../images/tree/protection/Bequeath/2_0-min.png');
            }
            @media (max-width: $breakpoint-large) {
                left: 443px;
            }
        }
    
        &--bequeath-item-3 {
            z-index: 1;
            top: 168px;
            left: 456px;
            width: 316px;
            height: 221px;
            padding: 1px 0 58px 136px;
            background-image: url('../../../images/tree/protection/Bequeath/5_100-min.png');
            &.tree-sub-item--base {
                background-image: url('../../../images/tree/protection/Bequeath/5_0-min.png');
            }
            &.tree-sub-item--middle {
                background-image: url('../../../images/tree/protection/Bequeath/5_10-min.png');
            }
            &.tree-sub-item--large {
                background-image: url('../../../images/tree/protection/Bequeath/5_50-min.png');
            }
            &.tree-sub-item--disabled {
                background-image: url('../../../images/tree/protection/Bequeath/5_0-min.png');
            }
            @media (max-width: $breakpoint-large) {
                left: 556px;
            }
        }
    
        &--bequeath-item-4 {
            z-index: 3;
            top: 244px;
            left: 125px;
            width: 269px;
            height: 211px;
            padding: 26px 57px 0 0;
            background-image: url('../../../images/tree/protection/Bequeath/3_100-min.png');
            &.tree-sub-item--base {
                background-image: url('../../../images/tree/protection/Bequeath/3_0-min.png');
            }
            &.tree-sub-item--middle {
                background-image: url('../../../images/tree/protection/Bequeath/3_10-min.png');
            }
            &.tree-sub-item--large {
                background-image: url('../../../images/tree/protection/Bequeath/3_50-min.png');
            }
            &.tree-sub-item--disabled {
                background-image: url('../../../images/tree/protection/Bequeath/3_0-min.png');
            }
            @media (max-width: $breakpoint-large) {
                left: 225px;
            }
        }
        
        &--bequeath-item-5 {
            z-index: 2;
            top: 258px;
            left: 208px;
            width: 379px;
            height: 305px;
            padding: 0 0 17px 44px;
            background-image: url('../../../images/tree/protection/Bequeath/4_100-min.png');
            &.tree-sub-item--base {
                background-image: url('../../../images/tree/protection/Bequeath/4_0-min.png');
            }
            &.tree-sub-item--middle {
                background-image: url('../../../images/tree/protection/Bequeath/4_10-min.png');
            }
            &.tree-sub-item--large {
                background-image: url('../../../images/tree/protection/Bequeath/4_50-min.png');
            }
            &.tree-sub-item--disabled {
                background-image: url('../../../images/tree/protection/Bequeath/4_0-min.png');
            }
            @media (max-width: $breakpoint-large) {
                left: 307px;
            }
        }
    
        &--incremental-item-1 {
            z-index: 2;
            top: 290px;
            left: 328px;
            width: 298px;
            height: 270px;
            padding: 0 41px 3px 20px;
            background-image: url('../../../images/tree/finances/Incremental/5_100-min.png');
            &.tree-sub-item--base {
                background-image: url('../../../images/tree/finances/Incremental/5_0-min.png');
            }
            &.tree-sub-item--middle {
                background-image: url('../../../images/tree/finances/Incremental/5_10-min.png');
            }
            &.tree-sub-item--large {
                background-image: url('../../../images/tree/finances/Incremental/5_50-min.png');
            }
            &.tree-sub-item--disabled {
                background-image: url('../../../images/tree/finances/Incremental/5_0-min.png');
            }
            @media (max-width: $breakpoint-large) {
                left: 428px;
            }
        }
    
        &--incremental-item-2 {
            z-index: 1;
            top: 257px;
            left: 501px;
            width: 299px;
            height: 261px;
            padding: 0 65px 50px 70px;
            background-image: url('../../../images/tree/finances/Incremental/6_100-min.png');
            &.tree-sub-item--base {
                background-image: url('../../../images/tree/finances/Incremental/6_0-min.png');
            }
            &.tree-sub-item--middle {
                background-image: url('../../../images/tree/finances/Incremental/6_10-min.png');
            }
            &.tree-sub-item--large {
                background-image: url('../../../images/tree/finances/Incremental/6_50-min.png');
            }
            &.tree-sub-item--disabled {
                background-image: url('../../../images/tree/finances/Incremental/6_0-min.png');
            }
            @media (max-width: $breakpoint-large) {
                left: 602px;
            }
        }
    
        &--incremental-item-3 {
            z-index: 4;
            top: 187px;
            left: 362px;
            width: 265px;
            height: 159px;
            padding: 0 30px 0 47px;
            background-image: url('../../../images/tree/finances/Incremental/3_100-min.png');
            &.tree-sub-item--base {
                background-image: url('../../../images/tree/finances/Incremental/3_0-min.png');
            }
            &.tree-sub-item--middle {
                background-image: url('../../../images/tree/finances/Incremental/3_10-min.png');
            }
            &.tree-sub-item--large {
                background-image: url('../../../images/tree/finances/Incremental/3_50-min.png');
            }
            &.tree-sub-item--disabled {
                background-image: url('../../../images/tree/finances/Incremental/3_0-min.png');
            }
            @media (max-width: $breakpoint-large) {
                left: 462px;
            }
        }
    
        &--incremental-item-4 {
            z-index: 3;
            top: 222px;
            left: 124px;
            width: 269px;
            height: 194px;
            padding: 40px 30px 0 0px;
            background-image: url('../../../images/tree/finances/Incremental/4_100-min.png');
            &.tree-sub-item--base {
                background-image: url('../../../images/tree/finances/Incremental/4_0-min.png');
            }
            &.tree-sub-item--middle {
                background-image: url('../../../images/tree/finances/Incremental/4_10-min.png');
            }
            &.tree-sub-item--large {
                background-image: url('../../../images/tree/finances/Incremental/4_50-min.png');
            }
            &.tree-sub-item--disabled {
                background-image: url('../../../images/tree/finances/Incremental/4_0-min.png');
            }
            @media (max-width: $breakpoint-large) {
                left: 224px;
            }
        }
    
        &--incremental-item-5 {
            z-index: 5;
            top: 140px;
            left: 134px;
            width: 235px;
            height: 173px;
            padding: 40px 0 0 10px;
            background-image: url('../../../images/tree/finances/Incremental/2_100-min.png');
            &.tree-sub-item--base {
                background-image: url('../../../images/tree/finances/Incremental/2_0-min.png');
            }
            &.tree-sub-item--middle {
                background-image: url('../../../images/tree/finances/Incremental/2_10-min.png');
            }
            &.tree-sub-item--large {
                background-image: url('../../../images/tree/finances/Incremental/2_50-min.png');
            }
            &.tree-sub-item--disabled {
                background-image: url('../../../images/tree/finances/Incremental/2_0-min.png');
            }
            @media (max-width: $breakpoint-large) {
                left: 233px;
            }
        }
    
        &--incremental-item-6 {
            z-index: 6;
            top: 103px;
            left: 205px;
            width: 254px;
            height: 146px;
            padding: 0 20px 26px;
            background-image: url('../../../images/tree/finances/Incremental/1_100-min.png');
            &.tree-sub-item--base {
                background-image: url('../../../images/tree/finances/Incremental/1_0-min.png');
            }
            &.tree-sub-item--middle {
                background-image: url('../../../images/tree/finances/Incremental/1_10-min.png');
            }
            &.tree-sub-item--large {
                background-image: url('../../../images/tree/finances/Incremental/1_50-min.png');
            }
            &.tree-sub-item--disabled {
                background-image: url('../../../images/tree/finances/Incremental/1_0-min.png');
            }
            @media (max-width: $breakpoint-large) {
                left: 304px;
            }
        }
        
        &--stock-item-1 {
            z-index: 6;
            top: 111px;
            left: 212px;
            width: 240px;
            height: 130px;
            padding: 0 20px 26px;
            background-image: url('../../../images/tree/finances/Stocks/1_100-min.png');
            &.tree-sub-item--base {
                background-image: url('../../../images/tree/finances/Stocks/1_0-min.png');
            }
            &.tree-sub-item--middle {
                background-image: url('../../../images/tree/finances/Stocks/1_10-min.png');
            }
            &.tree-sub-item--large {
                background-image: url('../../../images/tree/finances/Stocks/1_50-min.png');
            }
            &.tree-sub-item--disabled {
                background-image: url('../../../images/tree/finances/Stocks/1_0-min.png');
            }
            @media (max-width: $breakpoint-large) {
                left: 312px;
            }
        }
    
        &--stock-item-2 {
            z-index: 5;
            top: 141px;
            left: 144px;
            width: 226px;
            height: 160px;
            padding: 38px 10px 0;
            background-image: url('../../../images/tree/finances/Stocks/2_100-min.png');
            &.tree-sub-item--base {
                background-image: url('../../../images/tree/finances/Stocks/2_0-min.png');
            }
            &.tree-sub-item--middle {
                background-image: url('../../../images/tree/finances/Stocks/2_10-min.png');
            }
            &.tree-sub-item--large {
                background-image: url('../../../images/tree/finances/Stocks/2_50-min.png');
            }
            &.tree-sub-item--disabled {
                background-image: url('../../../images/tree/finances/Stocks/2_0-min.png');
            }
            @media (max-width: $breakpoint-large) {
                left: 244px;
            }
        }
    
        &--stock-item-3 {
            z-index: 4;
            top: 184px;
            left: 355px;
            width: 251px;
            height: 182px;
            padding: 0 20px;
            background-image: url('../../../images/tree/finances/Stocks/3_100-min.png');
            &.tree-sub-item--base {
                background-image: url('../../../images/tree/finances/Stocks/3_0-min.png');
            }
            &.tree-sub-item--middle {
                background-image: url('../../../images/tree/finances/Stocks/3_10-min.png');
            }
            &.tree-sub-item--large {
                background-image: url('../../../images/tree/finances/Stocks/3_50-min.png');
            }
            &.tree-sub-item--disabled {
                background-image: url('../../../images/tree/finances/Stocks/3_0-min.png');
            }
            @media (max-width: $breakpoint-large) {
                left: 455px;
            }
        }
    
        &--stock-item-4 {
            z-index: 3;
            top: 207px;
            left: 141px;
            width: 245px;
            height: 199px;
            padding: 56px 20px 0;
            background-image: url('../../../images/tree/finances/Stocks/4_100-min.png');
            &.tree-sub-item--base {
                background-image: url('../../../images/tree/finances/Stocks/4_0-min.png');
            }
            &.tree-sub-item--middle {
                background-image: url('../../../images/tree/finances/Stocks/4_10-min.png');
            }
            &.tree-sub-item--large {
                background-image: url('../../../images/tree/finances/Stocks/4_50-min.png');
            }
            &.tree-sub-item--disabled {
                background-image: url('../../../images/tree/finances/Stocks/4_0-min.png');
            }
            @media (max-width: $breakpoint-large) {
                left: 241px;
            }
        }
    
        &--stock-item-5 {
            z-index: 2;
            top: 268px;
            left: 326px;
            width: 299px;
            height: 267px;
            padding: 56px 20px 0;
            background-image: url('../../../images/tree/finances/Stocks/5_100-min.png');
            &.tree-sub-item--base {
                background-image: url('../../../images/tree/finances/Stocks/5_0-min.png');
            }
            &.tree-sub-item--middle {
                background-image: url('../../../images/tree/finances/Stocks/5_10-min.png');
            }
            &.tree-sub-item--large {
                background-image: url('../../../images/tree/finances/Stocks/5_50-min.png');
            }
            &.tree-sub-item--disabled {
                background-image: url('../../../images/tree/finances/Stocks/5_0-min.png');
            }
            @media (max-width: $breakpoint-large) {
                left: 426px;
            }
        }
    
        &--stock-item-6 {
            z-index: 1;
            top: 337px;
            left: 580px;
            width: 206px;
            height: 216px;
            padding: 56px 20px 0 30px;
            background-image: url('../../../images/tree/finances/Stocks/6_100-min.png');
            &.tree-sub-item--base {
                background-image: url('../../../images/tree/finances/Stocks/6_0-min.png');
            }
            &.tree-sub-item--middle {
                background-image: url('../../../images/tree/finances/Stocks/6_10-min.png');
            }
            &.tree-sub-item--large {
                background-image: url('../../../images/tree/finances/Stocks/6_50-min.png');
            }
            &.tree-sub-item--disabled {
                background-image: url('../../../images/tree/finances/Stocks/6_0-min.png');
            }
            @media (max-width: $breakpoint-large) {
                left: 680px;
            }
        }
    
        &--assets-item-1 {
            z-index: 5;
            top: 104px;
            left: 131px;
            width: 328px;
            height: 170px;
            padding: 0 20px 35px;
            background-image: url('../../../images/tree/finances/Assets/1_100-min.png');
            &.tree-sub-item--base {
                background-image: url('../../../images/tree/finances/Assets/1_0-min.png');
            }
            &.tree-sub-item--middle {
                background-image: url('../../../images/tree/finances/Assets/1_10-min.png');
            }
            &.tree-sub-item--large {
                background-image: url('../../../images/tree/finances/Assets/1_50-min.png');
            }
            &.tree-sub-item--disabled {
                background-image: url('../../../images/tree/finances/Assets/1_0-min.png');
            }
            @media (max-width: $breakpoint-large) {
                left: 230px;
            }
        }
    
        &--assets-item-2 {
            z-index: 4;
            top: 205px;
            left: 124px;
            width: 297px;
            height: 213px;
            padding: 11px 39px 0 0;
            background-image: url('../../../images/tree/finances/Assets/2_100-min.png');
            &.tree-sub-item--base {
                background-image: url('../../../images/tree/finances/Assets/2_0-min.png');
            }
            &.tree-sub-item--middle {
                background-image: url('../../../images/tree/finances/Assets/2_10-min.png');
            }
            &.tree-sub-item--large {
                background-image: url('../../../images/tree/finances/Assets/2_50-min.png');
            }
            &.tree-sub-item--disabled {
                background-image: url('../../../images/tree/finances/Assets/2_0-min.png');
            }
            @media (max-width: $breakpoint-large) {
                left: 224px;
            }
        }
    
        &--assets-item-3 {
            z-index: 3;
            top: 180px;
            left: 362px;
            width: 266px;
            height: 180px;
            padding: 0 0 0 15px;
            background-image: url('../../../images/tree/finances/Assets/3_100-min.png');
            &.tree-sub-item--base {
                background-image: url('../../../images/tree/finances/Assets/3_0-min.png');
            }
            &.tree-sub-item--middle {
                background-image: url('../../../images/tree/finances/Assets/3_10-min.png');
            }
            &.tree-sub-item--large {
                background-image: url('../../../images/tree/finances/Assets/3_50-min.png');
            }
            &.tree-sub-item--disabled {
                background-image: url('../../../images/tree/finances/Assets/3_0-min.png');
            }
            @media (max-width: $breakpoint-large) {
                left: 461px;
            }
        }
    
        &--assets-item-4 {
            z-index: 2;
            top: 299px;
            left: 325px;
            width: 319px;
            height: 263px;
            padding: 0;
            background-image: url('../../../images/tree/finances/Assets/4_100-min.png');
            &.tree-sub-item--base {
                background-image: url('../../../images/tree/finances/Assets/4_0-min.png');
            }
            &.tree-sub-item--middle {
                background-image: url('../../../images/tree/finances/Assets/4_10-min.png');
            }
            &.tree-sub-item--large {
                background-image: url('../../../images/tree/finances/Assets/4_50-min.png');
            }
            &.tree-sub-item--disabled {
                background-image: url('../../../images/tree/finances/Assets/4_0-min.png');
            }
            @media (max-width: $breakpoint-large) {
                left: 422px;
            }
        }
    
        &--assets-item-5 {
            z-index: 1;
            top: 257px;
            left: 501px;
            width: 299px;
            height: 261px;
            padding: 14px 0 0 21px;
            background-image: url('../../../images/tree/finances/Assets/5_100-min.png');
            &.tree-sub-item--base {
                background-image: url('../../../images/tree/finances/Assets/5_0-min.png');
            }
            &.tree-sub-item--middle {
                background-image: url('../../../images/tree/finances/Assets/5_10-min.png');
            }
            &.tree-sub-item--large {
                background-image: url('../../../images/tree/finances/Assets/5_50-min.png');
            }
            &.tree-sub-item--disabled {
                background-image: url('../../../images/tree/finances/Assets/5_0-min.png');
            }
            @media (max-width: $breakpoint-large) {
                left: 601px;
            }
        }
    
        &--your-assets {
            z-index: 2;
            top: 104px;
            left: 124px;
            width: 416px;
            height: 421px;
            padding: 0 103px 107px 0;
            background-image: url('../../../images/tree/finances/Worth/1_100-min.png');
            &.tree-sub-item--base {
                background-image: url('../../../images/tree/finances/Worth/1_0-min.png');
            }
            &.tree-sub-item--middle {
                background-image: url('../../../images/tree/finances/Worth/1_10-min.png');
            }
            &.tree-sub-item--large {
                background-image: url('../../../images/tree/finances/Worth/1_50-min.png');
            }
            &.tree-sub-item--disabled {
                background-image: url('../../../images/tree/finances/Worth/1_0-min.png');
            }
            @media (max-width: $breakpoint-large) {
                left: 224px;
            }
        }
    
        &--your-liabilities {
            z-index: 1;
            top: 179px;
            left: 381px;
            width: 427px;
            height: 387px;
            padding: 0 0 0 90px;
            background-image: url('../../../images/tree/finances/Worth/2_100-min.png');
            &.tree-sub-item--base {
                background-image: url('../../../images/tree/finances/Worth/2_0-min.png');
            }
            &.tree-sub-item--middle {
                background-image: url('../../../images/tree/finances/Worth/2_10-min.png');
            }
            &.tree-sub-item--large {
                background-image: url('../../../images/tree/finances/Worth/2_50-min.png');
            }
            &.tree-sub-item--disabled {
                background-image: url('../../../images/tree/finances/Worth/2_0-min.png');
            }
            @media (max-width: $breakpoint-large) {
                left: 478px;
            }
        }
    }
    
    @media (max-width: $breakpoint-medium-max) {
        color: $base-color;
        border-bottom: 1px solid $gray-600;
        padding: 19px 26px 19px 0;
        &.tree-sub-item--disabled {
            color: $gray-600;
            background: $gray-200;
            order: 99;
            &:hover {
                color: $gray-600;
            }
        }
        
        &--auto {
            order: 5;
        }
        
        &--umbrella {
            order: 6;
        }
    }
    
    &__icon {
        position: absolute;
        right: 8px;
        top: 50%;
        transform: translateY(-50%);
    }
    
    &__label {
        @media (max-width: $breakpoint-medium-max) {
            display: flex;
            align-items: center;
            .tree__count {
                margin-left: 10px;
            }
        }
    }
    
    &:hover,
    &--hover {
        z-index: 11;
        opacity: 1;
        text-decoration: none;
        
        @media (max-width: $breakpoint-medium-max) {
            //color: $success-color;
        }
    }
    
    &.tree-sub-item--disabled {
        .tree__count {
            display: none;
        
            .tree--tour-details & {
                display: inline-flex;
            }
        }
    }
    
    &.tree-sub-item--blocked {
        color: #7AA29E;
    
        @media (max-width: $breakpoint-medium-max) {
            color: $gray-600;
            background: $gray-200;
        }
        &:hover {
            z-index: 1;
        }
        
        .tree__count {
            display: none;
    
            .tree--tour-details & {
                display: inline-flex;
            }
        }
    
        .tree--tour-details & {
            color: #215E59;
        }
    }
}