.section {
    padding: 80px 0;
    @media (max-width: $breakpoint-small-max) {
        padding: 56px 0;
    }
    
    &__title {
        text-align: center;
        margin: 0 auto;
        max-width: 592px ;
    }
    
    &--gray {
        background: $gray-400;
    }
    
    &--bottom {
        .layout-main & {
            margin-bottom: -40px;
            @media (max-width: $breakpoint-small-max) {
                margin-bottom: -32px;
            }
        }
    }
    
    .layout-main & {
        margin-right: -32px;
        margin-left: -32px;
        padding-left: 32px;
        padding-right: 32px;
        
        @media (max-width: $breakpoint-medium-max) {
            margin-left: -16px;
            margin-right: -16px;
            padding-left: 16px;
            padding-right: 16px;
        }
    }
}