.choose-box {
    font-size: 16px;
    
    &__label {
        font-size: 14px;
        display: block;
        margin-bottom: 6px;
        .MuiIconButton-default {
            display: inline-block;
            width: auto;
            height: auto;
            margin-left: 5px;
            margin-right: -5px;
        }
    }
    
    &__holder {
        background: $gray-200;
        border-radius: 12px;
        display: flex;
        align-items: center;
        padding: 0 0 0 16px;
    }
    
    &__check {
        padding: 16px;
        border-left: 1px solid $gray-600;
    }
    
    .custom-select {
        .MuiSelect-select {
            padding-left: 8px;
        }
    }
}