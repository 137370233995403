.header-admin {
    padding: 25px 15px;
    width: 180px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    flex: 0 0 auto;
    position: relative;
    z-index: 5;
    background: $gray-300;
    @media (min-width: $breakpoint-medium) {
      height: 100%;
      min-height: 100vh;
      max-height: 940px;
    }
    
    @media (max-width: $breakpoint-medium-max) {
        padding: 24px 16px;
        flex-direction: row;
        height: auto;
        width: 100%;
        align-items: center;
        background: #fff;

        &--custom{
            padding: 10px 16px;
        }
    }
    
    &__logo-box {
        display: flex;
        align-items: center;
        justify-content: center;

        & .logo {
            @media (min-width: $breakpoint-medium) {
                width: 100%;
                height: 50px;
                overflow: hidden;
            }
        }
    }
    
    &__holder {
        flex: 1 1 auto;
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;
        padding: 20px 0 0;
        @media (max-width: $breakpoint-medium-max) {
            padding: 0;
        }
    }
    
    &__side {
        display: flex;
        justify-content: center;
        width: 100%;
        flex-direction: column;
        & > .MuiButton-root.MuiButton-outlined {
            background: transparent;
            @media (max-width: $breakpoint-medium-max) {
                max-width: 140px;
                margin-left: auto;
            }
        }
    }
    
    &__menu {
        font-size: 14px;
        display: flex;
        flex-direction: column;
        width: 100%;
        
        > li {
            position: relative;
            padding: 4px 0;
            @media (min-width: $breakpoint-medium) {
                &:hover .header-admin__submenu,
                &:focus-within .header-admin__submenu {
                    opacity: 1;
                    visibility: visible;    
                    height: auto;
                }
            }
        }
    }
    
    &__menu-link {
        color: $gray-800;
        padding: 6px 0;
        position: relative;
        display: flex;
        & svg {
            width: 14px;
            height: 14px;
            margin-right: 10px;
            & * {
                fill: $gray-800;
            }
        }
        @media (max-width: $breakpoint-medium-max) {
            padding: 10px 0;
        }
    
        &:hover {
            text-decoration: none;
            color: $accent-color;
            & svg * {
                fill: $accent-color;
            }
        }
        &--active {
            color: $accent-color;
            font-weight: 600;
            & svg * {
                fill: $accent-color;
            }
        }
    }

    &__footer{
        margin-top: 20px;
        li{
            display: flex;

            > button {
                color: $gray-800;
                font-size: 14px;
                background: transparent;
                border: none;
                padding: 0;
                cursor: pointer;
                &:hover{
                  color: $accent-color;
                }
            }
        }
        &-link{
            color: $gray-800;
            font-size: 14px;
            &:hover{
                text-decoration: none;
                color: $accent-color;
            }
        }
        @media (max-width: $breakpoint-medium-max) {
            display: none;
        }
    }

    &__submenu {
        position: absolute;
        top: 0;
        left: 152px;
        border-radius: 4px;
        visibility: hidden;
        opacity: 0;
        background-color: $gray-500;
        box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, .2);
        transition: all .2s ease;

        @media (max-width: $breakpoint-medium-max) {
            position: relative;
            height: 0;
            left: 0;

            &--hover {
                position: relative;
                opacity: 1;
                visibility: visible;    
                height: auto;
                box-shadow: none;
                background-color: $gray-200;
            }
        }
    }

    &__submenu-link {
        color: $gray-800;
        padding: 10px 20px;
        position: relative;
        display: block;

        &:hover {
            text-decoration: none;
            background-color: $gray-200;
            color: $base-color;
        }
        
        &--active {
            color: $base-color;
            font-weight: 600;
        }
    }
    
    &__profile {
        display: flex;
        flex-direction: column;
        align-items: center;

        @media (max-width: $breakpoint-medium-max) {
            display: none;
        }

        & > a {
            width: 56px;
            height: 56px;
            margin-bottom: 8px;
        }

        &--name{
            text-align: center;
            margin-bottom: 0;
        }
        &--email {
            font-size: 14px;
            color: $gray-800;
            margin-bottom: 16px;
            text-align: center;
        }
    }
    
    &__tour {
        display: flex;
        justify-content: center;
        
        @media (min-width: $breakpoint-large) {
            padding-right: 12px;
        }
    }
    
    &__content {
        @media (min-width: $breakpoint-medium) {
            flex: 1 1 auto;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            width: 100%;
        }
        @media (max-width: $breakpoint-medium-max) {
            text-align: center;
            position: fixed;
            left: 0;
            right: 0;
            bottom: 0;
            top: 70px;
            background: #fff url('../../images/decor.png') no-repeat top 0 right -858px;
            transform: translateX(-100%);
            background-size: 1008px 210px;
            transition: transform .2s;
            padding: 48px 24px 14px;
            
            .header-admin--open & {
                transform: translateX(0);
            }
        }
    }
    
    &__toggle {
        position: relative;
        padding: 0;
        border: 0;
        background: none;
        width: 24px;
        height: 16px;
        margin-left: 20px;
        outline: none;
        cursor: pointer;
        flex: 0 0 24px;
        span,
        &::before,
        &::after {
            overflow: hidden;
            text-indent: -9999px;
            position: absolute;
            top: 0;
            display: block;
            content:'';
            height: 2px;
            width: 24px;
            background: $base-color;
            transition: transform .2s, opacity .1s;
        }
        &::before {
            top: 6px;
            width: 16px;
        }
        &::after {
            top: 12px;
            width: 8px;
        }
        
        .header-admin--open & {
            span {
                opacity: 0;
            }
            &::before,
            &::after {
                top: 50%;
                border-radius: 4px;
                width: 16px;
                transform: rotate(45deg);
            }
            &::after {
                transform: rotate(-45deg);
            }
        }
    }
    
    &__admin-link {
        color: white;
        background: $accent-color;
        font-size: 16px;
        border-radius: 8px;
        height: 32px;
        &:hover {
            text-decoration: none;
            background: darken($accent-color, 5%);
        }
    }
    
    &--open {
        @media (max-width: $breakpoint-medium-max) {
            //position: fixed;
            top: 0;
            left: 0;
            right: 0;
        }
    }
    .MuiButton-label {
        white-space: nowrap;
    }
}