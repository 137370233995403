.logo {
    width: 126px;
    max-height: 50px;
    overflow: hidden;
    
    img {
        width: 100%;
        height: 100%;
        max-height: 50px;
        object-fit: contain;
        display: block;
        @media (max-width: $breakpoint-medium-max) {
          width: auto;
        }
    }
    &-custom{
        width: 126px;
        height: 65px;
        overflow: hidden;
        img{
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}