.verification {
    position: relative;
    padding: 8px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    text-align: center;
    background: url('../../images/bg-img.png') bottom no-repeat;
    background-position-y: 48vh;
    background-size: 1200px 500px;
    @media (max-width: $breakpoint-small-max) {
        justify-content: center;
        background-size: contain;
        background-position-y: bottom;
    }

    &__content {
        padding: 15% 8px 0;
        margin: 0 auto;
        max-width: 525px;

        h2 {
            margin-bottom: 15px;
        }

        @media (min-width: $breakpoint-large-max) {
            padding-top: 10%;
        }
        
        @media (max-width: $breakpoint-small-max) {
            padding-top: 0;
        }
        
        &--description {
            max-width: 385px;
            margin: 0 auto 15px;

            > p {
                text-align: left;
                margin-bottom: 15px;
            }

            .resend{
                font-size: 14px;
                color: $gray-800;
                text-align: left;
                margin-top: 5px;

                &> button {
                    color: $link-color;
                }
            }
        }

        &--buttons {
            display: flex;
            justify-content: space-evenly;
            max-width: 440px;
            margin: 0 auto;
            @media (max-width: $breakpoint-medium-max) {
                flex-direction: column;
            }

            & > button {
                margin-bottom: 15px;
            }
        }
    }
    
    &__logo {
        top: 25px;
        left: 25px;
        position: absolute;
    }
    
    &__icon {
        width: 48px;
        height: 48px;
        margin: 0 auto 25px;
        background: #F3D9DA;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}