.incomes-list {
    @media (min-width: $breakpoint-xsmall) {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -8px;
    }
    @media (min-width: $breakpoint-medium) {
        margin: 0 -16px;
    }
    
    &__item {
        display: flex;
        flex: 1 1 auto;
        flex-direction: column;
        @media (min-width: $breakpoint-xsmall) {
            width: 50%;
            padding: 0 8px;
            margin-bottom: 8px;
        }
        @media (min-width: $breakpoint-small) {
            width: 25%;
        }
        @media (min-width: $breakpoint-medium) {
            padding: 0 16px;
            margin-bottom: 32px;
        }
        @media (max-width: $breakpoint-xsmall-max) {
            &:not(:last-child) {
                border-bottom: 1px solid $gray-200;
                margin-bottom: 24px;
            }
        }
    }
    
    &__item-title {
        flex: 1 1 auto;
        display: flex;
        align-items: center;
        font-family: $font-family-heading;
        font-weight: 600;
        font-size: 14px;
        line-height: 1.3;
        margin-bottom: 16px;
        .MuiButtonBase-root {
            margin-left: 8px;
        }
    }
    
    &__item-content {
    
    }
}