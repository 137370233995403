.form-group {
    margin-bottom: 24px;
    @media (max-width: $breakpoint-medium-max) {
        margin-bottom: 22px;
    }
    
    &__label {
        font-size: 14px;
        line-height: 18px;
        display: block;
        margin-bottom: 6px;
        
        .MuiIconButton-default {
            display: inline-block;
            width: auto;
            height: auto;
            margin-left: 5px;
            margin-right: -5px;
            .MuiSvgIcon-root {
                font-size: 1.2rem;
            }
        }
        + .MuiFormGroup-root {
            padding-top: 10px
        }
        
        &--hide {
            @media (min-width: $breakpoint-xsmall) {
                display: none;
            }
        }
    }
    
    + .form__button {
        padding-top: 9px;
    
        @media (min-width: $breakpoint-medium) {
            padding-top: 16px;
        }
    }
}