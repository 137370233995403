.analysis {
    &__container {
        position: relative;
        &:not(:last-child) {
    
            @media (min-width: $breakpoint-small) {
                &::after {
                    display: block;
                    width: 100%;
                    height: 103px;
                    max-width: 992px;
                    background: url('../../../images/decor02.png') no-repeat 50% 50%;
                    background-size: 100% auto;
                    content: '';
                    margin: 24px auto;
                }
            }
        }
    }
    
    &__grid {
        @media (min-width: $breakpoint-small) {
            margin: 0 -8px;
            display: flex;
        }
        @media (min-width: $breakpoint-medium) {
            margin: 0 -16px;
        }
        
        > div {
            flex: 1 1 auto;
            padding: 0 0 16px;
            width: 100%;
            overflow: hidden;
            @media (min-width: $breakpoint-small) {
                padding: 0 8px;
                display: flex;
            }
            @media (min-width: $breakpoint-medium) {
                padding: 0 16px;
            }
        }
        
        &-2 {
            > div {
                @media (min-width: $breakpoint-small) {
                    width: 50%;
                }
            }
        }
    }
    
    &__grid-column {
        &-1-3 {
            @media (min-width: $breakpoint-small) {
                width: 33.33%;
                flex: 0 0 33.33%
            }
        }
    
        &-alt {
            @media (min-width: $breakpoint-small) {
                width: 42%;
                flex: 0 0 42%;
            }
        }
    
        &-flex {
            @media (min-width: $breakpoint-small) {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                > div {
                    height: 50%;
                    display: flex;
                }
            }
            > div {
                &:not(:last-child) {
                    margin-bottom: 16px;
                }
            }
        }
    }
    
    &__box {
        overflow: hidden;
        border-radius: 12px;
        border: 1px solid $gray-200;
        padding: 30px 24px 12px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1 1 auto;
        max-width: 100%;
        
        @media (max-width: $breakpoint-medium-max) {
            padding: 16px;
        }
        
        @media (max-width: $breakpoint-small-max) {
            padding: 16px 10px 10px;
        }
    }
    
    &__box-title {
        margin-bottom: 24px;
        font-size: 14px;
        @media (min-width: $breakpoint-small) {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
        }
        h6 {
            margin: 0;
        }
        &-tooltip {
            display: flex;
            align-items: center;
        }
    }
    
    &__box-subtitle {
        font-size: 14px;
        margin-bottom: 20px;
        
        &--center {
            margin: 0 auto 20px;
            max-width: 390px;
            text-align: center;
        }
    }
    
    &__box-content {
        display: flex;
        position: relative;
    
        &--alt {
            @media (min-width: $breakpoint-small) {
                padding-bottom: 22px;
            }
        }
    }
    
    &__box-legend {
        font-size: 14px;
        
        @media (min-width: $breakpoint-small) {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            justify-content: flex-end;
            > * {
                margin-left: 16px;
                @media (min-width: $breakpoint-medium) {
                    margin-left: 32px;
                }
            }
        }
    
        &-label {
            display: flex;
            align-items: center;
            @media (max-width: $breakpoint-small-max) {
                margin-top: 8px;
            }
        
            &::before {
                display: block;
                flex: 0 0 16px;
                margin: 0 8px 0 0;
                content: '';
                width: 16px;
                height: 16px;
                border-radius: 4px;
                background: $success-color;
            }
            &-color2 {
                &::before {
                    background: #FDB5A1;
                }
            }
            &-color3 {
                &::before {
                    background: #D4654D;
                }
            }
            &-color4 {
                &::before {
                    background: #E8E8E8;
                }
            }
        }
    }
    
    &__box-pie {
        margin: 0 auto;
        height: 176px;
        width: 176px;
    }
    
    &__box-slider {
        .slick-list {
            overflow: hidden;
        }
    }
    
    &__empty {
        font-size: 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 968px;
        margin: 0 auto;
        padding-top: 24px;
    
        @media (max-width: $breakpoint-xsmall-max) {
            flex-direction: column;
        }
    
        &-chart {
            @media (max-width: $breakpoint-xsmall-max) {
                order: -1;
                margin-bottom: 20px;
            }
        }
    }
}

//.chartjs-render-monitor {
//    width: 100% !important;
//}
