.range {
    &__label {
        font-size: 16px;
        color: $gray-800;
        margin-bottom: 2px;
    }
    
    &__bar {
        &.MuiSlider-root {
            height: 8px;
            &.Mui-disabled {
                opacity: .6;
            }
        }
        
        .MuiSlider-rail {
            height: 8px;
            border-radius: 100px;
            background: $gray-500;
            opacity: 1;
        }
        .MuiSlider-track {
            height: 8px;
            border-radius: 100px;
            background: $success-color;
        }
        .MuiSlider-thumb {
            width: 8px;
            height: 8px;
            margin-top: 0;
            margin-left: 0;
            box-shadow: 0 0 0 7px #fff;
            background: $success-color;
    
            &.Mui-disabled {
                margin-top: 0;
                margin-left: 0;
            }
            
            &::after {
                border: 1px solid $gray-200;
                background: none;
                box-shadow: $box-shadow;
                width: 20px;
                height: 20px;
            }
    
            &.Mui-focusVisible, &:hover {
                box-shadow: 0 0 0 7px #fff;
            }
        }
    }
}