.users-statistics{

    &__description{
        color: $gray-800;
        font-size: 16px;
        max-width: 490px;
        margin-bottom: 44px;
        @media (max-width: $breakpoint-small-max) { 
            margin-bottom: 20px;
        }
    }

    &__graph {
        max-width: 1000px;
        
        @media (max-width: $breakpoint-small-max) { 
            margin-bottom: 20px;
        }
    }
}
    