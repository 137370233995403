.incremental-card {
    display: flex;
    align-items: center;
    flex: 1 1 auto;
    border-radius: 12px;
    background: $gray-300;
    color: $base-color;
    min-height: 112px;
    padding: 24px;
    transition: background-color .2s, box-shadow .2s;
    &:hover {
        text-decoration: none;
        background: #fff;
        box-shadow: $box-shadow;
    }
    
    &__icon {
        flex: 0 0 40px;
        margin-right: 16px;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: rgba($link-color, .3);
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    
}