.footer {
    padding: 19px 32px;
    background: $primary-color;
    color: #fff;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    &--custom{
        padding: 11px 32px;
    }
    
    @media (max-width: $breakpoint-medium) {
        padding-left: 16px;
        padding-right: 16px;
    }
    
    &__logo {
        display: block;
        width: 90px;
        height: 17px;
        margin-top: -5px;
    
        @media (min-width: $breakpoint-medium) {
            width: 126px;
            height: 24px;
        }
        &--custom{
            height: 40px;
            overflow: hidden;
            img{
                height: 100%;
                width: 100%;
                object-fit: contain;
            }
        }
    
        img  {
            display: block;
        }
    }
    
    &__menu {
        display: flex;
        li {
            padding-left: 16px;
            @media (min-width: $breakpoint-medium) {
                padding-left: 40px;
            }
            button {
                color: currentColor;
                border: 0;
                background: none;
                padding: 0;
                margin: 0;
                outline: none;
                font-size: 14px;
                &:hover {
                    text-decoration: underline;
                    cursor: pointer;
                }
            }
        }
    }
    
    &__menu-link {
        color: #fff;
    }
}