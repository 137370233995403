.admin-filters {
    position: relative;
    margin-top: -8px;
    margin-bottom: 20px;
    font-size: 16px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;

    @media (max-width: $breakpoint-small-max) {
        display: block;
        margin-top: 0px;
    }

    .form__search-box {
        @media (max-width: $breakpoint-small-max) {
            width: 100%;
        }
    }
    

    .form__search-box .form__input {
        height: 36px;
        padding-top: 6px;
        padding-bottom: 6px;
        border-radius: 8px;

    }

    .MuiSelect-select.MuiSelect-select {
        height: 24px;
        padding-top: 0;
        padding-bottom: 0;
        padding-left: 5px;
    }

    .MuiButton-root.MuiButton-sizeSmall {
        padding: 7px 25px;
    }

    @media (max-width: $breakpoint-small-max) {
        margin-left: -8px;
        margin-bottom: 16px;
    }
    
    &__select {
        max-width: 100%;
        @media (max-width: $breakpoint-medium-max) {
            display: flex;
            > button {
                margin-right: 10px;
                background: none;
                border: 0;
                cursor: pointer;
                outline: none;
                border-radius: 8px;
            }
        }
    }
    
    &__button {
        position: absolute !important;
        top: 8px;
        right: 0;
    
        @media (max-width: $breakpoint-small-max) {
            position: sticky !important;
            top: auto;
            bottom: 24px;
            right: 16px;
            z-index: 2;
            margin-left: auto !important;
            display: flex !important;
            width: 48px;
            height: 48px;
            flex: 0 0 48px;
            border-radius: 8px;
            padding: 0 !important;
            min-width: 48px !important;
            .MuiButton-label {
                display: none;
            }
            &::before,
            &::after {
                position: absolute;
                left: 50%;
                top: 50%;
                width: 16px;
                height: 2px;
                border-radius: 2px;
                content:'';
                background: #fff;
                transform: translate(-50%,-50%);
            }
            &::after {
                transform: translate(-50%,-50%) rotate(90deg);
            }
        }
    }
    
    &__remove {
        position: relative;
        margin: 0;
        padding: 0;
        overflow: hidden;
        text-indent: -9999px;
        border-radius: 8px;
        background: $gray-200;
        width: 32px;
        height: 32px;
        color: $gray-800;
        border: 0;
        cursor: pointer;
    
        &::after,
        &::before {
            position: absolute;
            width: 16px;
            height: 2px;
            content:'';
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%) rotate(45deg);
            border-radius: 2px;
            background: currentColor;
        }
        &::after {
            transform: translate(-50%,-50%) rotate(-45deg);
        }
    
        &:hover {
            color: $base-color;
        }
    }
    
    &__no-results {
        margin: 40px auto;
        justify-content: center;
    }
}