.modal {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100vh;
    width: 100vw;
    padding: 8px;

    &__position {
        @media (max-width: 374px) {
            overflow: auto;
            align-items: flex-start;
        }
    }
    
    &__holder {
        position: relative;
        flex: 1 1 auto;
        background: #fff;
        max-width: 592px;
        width: 100%;
        margin: 0 auto;
        border-radius: 12px;
        padding: 56px 72px;
        @media (max-width: $breakpoint-small-max) {
            padding: 70px 16px 32px;
        }
        
        &:focus {
            outline: none;
        }
      &--fix-height{
        height: 100%;
        overflow-y: auto;
      }  
    }
    
    &__close {
        background: 0;
        margin: 0;
        padding: 0;
        position: absolute;
        top: 24px;
        right: 24px;
        overflow: hidden;
        text-indent: -9999px;
        border-radius: 8px;
        background: $gray-200;
        width: 32px;
        height: 32px;
        color: $gray-800;
        border: 0;
        cursor: pointer;
        &::after,
        &::before {
            position: absolute;
            width: 16px;
            height: 2px;
            content:'';
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%) rotate(45deg);
            border-radius: 2px;
            background: currentColor;
        }
        &::after {
            transform: translate(-50%,-50%) rotate(-45deg);
        }
        
        &:hover {
            color: $base-color;
        }
    }
    
    &__title {
        font-family: $font-family-heading;
        font-size: 22px;
        line-height: 1.3;
        font-weight: 600;
        margin-bottom: 32px;
        @media (max-width: $breakpoint-small-max) {
            font-size: 18px;
            margin-bottom: 22px;
        }
    }
    
    &__title-icon {
        max-width: 320px;
        margin: 0 auto;
        .icon-svg {
            margin-bottom: 22px;
        }
        img {
            width: 80px;
            margin-bottom: 22px;
        }
    }
    
    &__content {
        width: 100%;
        max-width: 384px;
        margin: 0 auto 48px;
        @media (max-width: $breakpoint-small-max) {
            margin-bottom: 32px;
        }
        
        .form-group {
            text-align: left;
        }

        &--text-grey-sm{
          font-size: 16px;
          color: $gray-800;
        }
        &--largeButtons {
          &__buttons {
            .MuiButton-root.MuiButtonBase-root {
              min-width: 260px;
            }
          }
        }
    }
    
    &__button {
        display: flex;
        justify-content: center;
    }
    
    &__buttons {
        > div {
            &:not(:last-child) {
                margin-bottom: 10px;
            }
        }
        .MuiButton-root.MuiButtonBase-root {
            min-width: 160px;
        }
        &--inline {
            justify-content: center;
            display: flex;
            > div {
                margin: 0 3px;
                .MuiButton-root.MuiButtonBase-root {
                    min-width: 130px;
                }
            }
        }
    
    
        &--alt {
            @media (min-width: $breakpoint-small) {
                margin-bottom: -40px;
            }
        }

        &--reverse {
            flex-flow: row-reverse;
        }

        &--large {
          .MuiButtonBase-root.MuiButton-root {
              min-width: 260px;

          }
        }
    }
    
    &--small {
        .modal {
            &__content {
                margin-bottom: 0;
            }
            &__holder {
                border-radius: 24px;
                max-width: 448px;
                padding: 80px 32px;

                .checkbox {
                    margin-bottom: -40px;
                }
                
                @media (max-width: $breakpoint-small-max) {
                    padding: 70px 24px 32px;

                    .checkbox {
                        margin-bottom: -25px;
                    }
                }
            }
            &__title {
                font-size: 28px;
                margin-bottom: 16px;
                @media (max-width: $breakpoint-small-max) {
                    font-size: 22px;
                }
            }
        }
    }
    
    &--edit {
        .modal__content {
            margin: 0 auto;
        }
        @media (max-width: $breakpoint-small-max) {
            padding: 0;
            .modal {
                &__holder {
                    max-width: 100%;
                    display: flex;
                    flex-direction: column;
                    border-radius: 0;
                    height: 100vh;
                    padding-top: 29px;
                }
                
                &__content {
                    flex: 1 1 auto;
                }
                
                &__buttons {
                    .MuiButton-root.MuiButtonBase-root {
                        width: 100%;
                    }
                }
                
                &__title {
                    text-align: left;
                    border-bottom: 1px solid $gray-600;
                    padding-bottom: 28px;
                }
            }
        }
    }
    
    .progress-circle {
        margin-bottom: 28px;
    }
  &__top-index {
    z-index: 1400 !important;
  }
}