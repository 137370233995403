.income-card {
    border-radius: 12px;
    background: $gray-400;
    padding: 24px;
    flex: 1 1 auto;
    font-size: 16px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    
    &__title {
        font-family: $font-family-heading;
        font-weight: 600;
        font-size: 14px;
        line-height: 1.3;
        margin-bottom: 10px;
        flex: 1 1 auto;
        display: flex;
        align-items: center;
    }
    
    &__content {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
    }
    
    &__amount {
        display: inline-flex;
        align-items: center;
        min-width: 107px;
        .icon-svg {
            margin-right: 8px;
        }
    }
    
    .MuiButton-root.MuiButton-sizeSmall {
        min-width: 56px;
    }
}