.drawer {
    font-size: 16px;
    .MuiDrawer-paper {
        padding: 80px 0 0;
        @media (min-width: $breakpoint-xsmall) {
            border-radius: 24px 0 0 24px;
            width: 448px;
            padding: 86px 0 0;
        }
        @media (max-width: $breakpoint-xsmall-max) {
            left: 0;
        }
    }
    
    .MuiButton-textGray {
        min-width: 112px;
    }
    
    .MuiRating-root {
        margin: 5px 5px 5px 0;
    }
    
    &__close {
        margin: 0;
        padding: 0;
        position: absolute;
        top: 24px;
        right: 32px;
        overflow: hidden;
        text-indent: -9999px;
        border-radius: 8px;
        background: $gray-200;
        width: 32px;
        height: 32px;
        color: $gray-800;
        border: 0;
        cursor: pointer;
    
        @media (max-width: $breakpoint-xsmall-max) {
            right: 16px;
        }
        
        &::after,
        &::before {
            position: absolute;
            width: 16px;
            height: 2px;
            content:'';
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%) rotate(45deg);
            border-radius: 2px;
            background: currentColor;
        }
        &::after {
            transform: translate(-50%,-50%) rotate(-45deg);
        }
    
        &:hover {
            color: $base-color;
        }
    }
    
    &__holder {
      overflow-y: auto;
      overflow-x: hidden;
       &::-webkit-scrollbar {
         width: 5px;
       }

       &::-webkit-scrollbar-track {
         -webkit-box-shadow: none;
       }

       &::-webkit-scrollbar-thumb {
         background-color: $link-color;
       }
        @media (min-width: $breakpoint-xsmall) {
          padding: 0 32px;
        }

        @media (max-width: $breakpoint-xsmall-max) {
          padding: 32px 16px 0;
          border-top: 1px solid $gray-600;
        }
        &--notification {
            flex: 1 1 auto;
            display: flex;
            flex-direction: column;
        }
    }

    &__info{
      position: absolute;
      top: 100px;
      left: 32px;
      width: calc(100% - 32px);
      @media (max-width: $breakpoint-xsmall-max) {
        left: 16px;
        width: calc(100% - 16px);
      }
    }
    
    &__top {
        font-size: 14px;
        @media (min-width: $breakpoint-xsmall) {
            margin-bottom: 33px;
        }
    }
    
    &__top-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 23px;
        @media (max-width: $breakpoint-xsmall-max) {
            margin-bottom: 21px;
        }
    }
    
    &__title {
        font-family: $font-family-heading;
        font-size: 22px;
        line-height: 1.3;
        font-weight: 600;
        margin-bottom: 24px;
        @media (max-width: $breakpoint-xsmall-max) {
            font-size: 18px;
        }
        &--small {
            font-size: 18px;
        }
        &--alt {
            @media (max-width: $breakpoint-xsmall-max) {
                position: absolute;
                left: 16px;
                top: 29px;
            }
        }
    }
    
    &__status {
        color: $gray-800;
        font-size: 14px;
        margin-bottom: 4px;
    }
    
    &__edit-label {
        display: inline-flex;
        align-items: center;
        .icon-svg {
            margin-right: 10px;
        }
    }
    
    &__category {
        color: $link-color;
        padding: 2px 9px;
        border-radius: 2px;
        background: rgba($link-color, .15);
    }

    &__description {

      p{
        margin-bottom: 0;
      }
    }
    
    &__buttons {
        position: sticky;
        bottom: 0;
        left: 0;
        right: 0;
        background: linear-gradient(to bottom, rgba(255,255,255,0) 0, rgba(255,255,255,0.2) 3%, rgba(255,255,255,0.9) 12%, #fff 100%);
        padding: 20px 0 10px;
        z-index: 10;
        &__wrap {
          display: flex;
        }
        &--alt{
          position: relative;
        }
        
        &.form__button{
          display: flex;
        }
    }
    
    &__buttons-list {
        display: flex;
        align-items: center;
        margin-left: auto;
        li {
            padding: 0 8px;
        }
    }
    
    &__back-button {
        position: absolute;
        top: 24px;
        left: 32px;
    
        @media (max-width: $breakpoint-xsmall-max) {
            left: 16px;
        }
    }
    
    &__revert {
        display: flex;
        align-items: center;
        
        button {
            color: $gray-800;
            font-size: 14px;
            font-weight: 600;
            background: none;
            border: 0;
            padding-left: 0;
            cursor: pointer;
            outline: none;
            &:hover {
                color: $accent-color;
            }
        }
    
        .MuiSvgIcon-root {
            font-size: 1.2rem;
        }
    }
}