* {
    box-sizing: border-box;
}

body {
    margin: 0;
    font: 18px/1.5 $font-family;
    -webkit-overflow-scrolling: touch;
    @media (max-width: $breakpoint-small-max) {
        font-size: 16px;
    }
}

img {
    max-width: 100%;
    height: auto;
}

a {
    color: $link-color;
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
}

button,
input,
optgroup,
select,
textarea {
    font-family: inherit;
    margin: 0;
    font-size: 16px;
    line-height: 1.5;
    -webkit-appearance: none;
}

ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

.container {
    margin: 0 auto;
    &__blog{
      margin: 0;
    }
    &--large {
        max-width: 1008px;
    }
    
    &--medium {
        max-width: 545px;
    }
    
    &--small {
        max-width: 384px;
    }
}

.spinner {
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -11px 0 0 -11px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 3px solid rgba(255,255,255,.3);
    border-bottom-color: #fff;
    animation: rotate 1.1s infinite linear;
    &--small {
        width: 18px;
        height: 18px;
        margin: -9px 0 0 -9px;
    }
    
    &--dark {
        border-bottom-color: $base-color;
    }
}

.not-found {
    margin: 0 auto;
    max-width: 400px;
    text-align: center;
    @media (min-width: $breakpoint-large) {
        padding-top: 80px;
    }
}

@-webkit-keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  75% {
    opacity: 0.5;
    transform: translateY(0px);
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeInSmall {
  0% {
    opacity: 0;
    transform: translateY(5px);
  }

  75% {
    opacity: 0.5;
    transform: translateY(0px);
  }

  100% {
    opacity: 1;
  }
}

.custom-switcher {
    & .MuiSwitch-switchBase {
      color: $gray-800;

      &:hover {
        color: $secondary-color;
        background: transparent;
      }
    }

    & .MuiSwitch-track {
      background-color: $gray-800;
    }

    & .MuiSwitch-colorPrimary.Mui-checked {
      color: $accent-color;

      &:hover {
        background-color: rgba(253, 138, 99, .04);
      }

      &+.MuiSwitch-track {
        background-color: $accent-color;
      }
    }
}