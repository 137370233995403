.calculators {
    &__title {
        align-items: center;
        justify-content: space-between;
        margin-bottom: 40px;
        @media (min-width: $breakpoint-small) {
            display: flex;
        }
        h1,
        h2,
        h3,
        h4 {
            margin: 0;
        }
    
        .MuiIconButton-root {
            width: 32px;
            height: 32px;
            border-radius: 8px;
            background: $gray-200 !important;
            margin-left: 10px;
        }
        
        > div {
            display: flex;
            align-items: center;
        }
        &-select {
            max-width: 300px;
            @media (max-width: $breakpoint-small-max) {
                margin-top: 16px;
            }
        }
        
        &--center {
            justify-content: center;
            > div {
                justify-content: center;
            }
        }
    }
    
    &__subtitle {
        margin-bottom: 32px;
        @media (max-width: $breakpoint-small-max) {
            display: none;
        }
    }
    
    &__top-panel {
        display: flex;
        align-items: center;
        &-tabs {
            flex: 1 1 auto;
            margin-right: 10px;
        }
        
        .add-button {
            flex: 0 0 auto;
        }

        @media (max-width: $breakpoint-xsmall-max) {
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: 40px;
        }
    }
    
    &__info {
        font-size: 16px;
        margin-bottom: 26px;
    }
    
    &__holder {
        display: flex;
        margin: 0 -16px;
        font-size: 16px;
        > div {
            width: 50%;
            padding: 0 16px;
        }
    
        @media (max-width: $breakpoint-small-max) {
            flex-wrap: wrap;
            > div {
                width: 100%;
            }
        }
    }
    
    &__preview {
        @media (max-width: $breakpoint-small-max) {
            order: -1;
            margin-bottom: 24px;
        }
    }
    
    &__preview-line {
        margin: 0 -24px;
        padding: 0 24px;
        &:not(:first-child) {
            padding-top: 24px;
            border-top: 1px solid $gray-200;
        }
        
        &-holder {
            display: flex;
            align-items: flex-start;
            padding-bottom: 18px;
    
            &--alt {
                @media (min-width: $breakpoint-small) {
                    padding-left: 99px;
                }
            }
        }
    }
    
    &__preview-progress {
        width: 77px;
        flex: 0 0 auto;
        margin-right: 22px;
    }
    
    &__preview-box {
        padding: 24px 24px 16px;
        border-radius: 12px;
        border: 1px solid $gray-200;
    
        @media (max-width: $breakpoint-small-max) {
            flex-wrap: wrap;
            > div {
                padding: 0;
                width: 100%;
            }
        }
        + div {
            margin-top: 24px;
        }
        
        &-text {
            margin-top: 20px;
            text-align: center;
            font-size: 14px
        }
    
        &-chart-title {
            display: flex;
            text-align: center;
            font-size: 12px;
            color: #8C8C8C;
            padding-left: 50px !important;
            margin-bottom: 20px;
            > div {
                padding: 0 10px;
                width: 50%;
            }
        }
    }
    
    &__preview-tabs {
        display: flex;
        background: $gray-200;
        border-radius: 12px 12px 0 0;
        border: 1px solid $gray-200;
        overflow: hidden;
        position: relative;
        li {
            &.active {
                background: #fff;
                button {
                    color: $base-color;
                    font-weight: bold;
                }
            }
        }
        
        button {
            font-family: $font-family;
            border: 0;
            background: none;
            padding: 13px 10px;
            margin: 0;
            color: $gray-800;
            outline: none;
            font-size: 14px;
            cursor: pointer;
            min-width: 100px;
        }
        
        &-holder {
            border-radius: 0 0 12px 12px;
            border: solid $gray-200;
            border-width: 0 1px 1px;
            padding: 30px 30px 0;
        }
        
        &-content {
            padding: 0;
        }
    }
    
    &__legend {
        font-size: 14px;
        margin-bottom: 20px;
    
        @media (min-width: $breakpoint-small) {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            > * {
                margin-right: 16px;
                @media (min-width: $breakpoint-medium) {
                    margin-right: 32px;
                }
            }
        }
    
        &-label {
            display: flex;
            align-items: center;
            @media (max-width: $breakpoint-small-max) {
                margin-top: 8px;
            }
        
            &::before {
                display: block;
                flex: 0 0 16px;
                margin: 0 8px 0 0;
                content: '';
                width: 16px;
                height: 16px;
                border-radius: 4px;
                background: $success-color;
            }
            &-color2 {
                &::before {
                    background: #FDB5A1;
                }
            }
            &-color3 {
                &::before {
                    background: #D4654D;
                }
            }
            &-color4 {
                &::before {
                    background: #E8E8E8;
                }
            }
        }
    }
    
    &__area {
        display: flex;
        align-items: center;
        margin-bottom: 13px;
        justify-content: space-between;
        @media (max-width: $breakpoint-xsmall-max) {
            display: block;
        }
        
        &-slider {
            flex: 1 1 auto;
            @media (min-width: $breakpoint-xsmall) {
                order: -1;
                padding-right: 20px;
                max-width: 350px;
            }
        }
    
        .form__area {
            @media (max-width: $breakpoint-xsmall-max) {
                margin-bottom: 10px;
            }
        }
    }
    
    &__area-label {
        display: flex;
        font-size: 16px;
        color: $gray-800;
        margin-bottom: 14px;
    }
    
    &__area-line {
        border-bottom: 1px solid $gray-500;
        padding-bottom: 10px;
        margin-bottom: 24px;
    }
    
    &__buttons {
        margin-top: 40px;
        > * {
            margin-right: 8px !important;
        }
    }
    
    &__cases {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 0 -16px;
        @media (max-width: $breakpoint-xsmall) {
            margin: 0;
        }
        > div {
            flex: 0 0 33.33%;
            text-align: center;
            padding: 0 16px 26px;
            @media (max-width: $breakpoint-xsmall) {
                flex: 0 0 50%;
            }
        }
        .speedometer {
            margin: 0 auto;
        }
    }
    
    &__case-title {
        text-align: center;
        color: $gray-800;
        font-size: 12px;
    }
    
    &__speed-box {
        font-size: 12px;
        display: flex;
        align-items: center;
        h6 {
            margin-bottom: 20px;
        }
        p {
            color: $gray-800;
            margin: 0 0 8px;
        }
    }
    
    &__speed {
        flex: 0 0 auto;
        margin-right: 24px;
        @media (min-width: $breakpoint-medium) {
            margin-right: 50px;
        }
    }
    
    &__total {
        strong {
            font-size: 22px;
            font-weight: 600;
            font-family: $font-family-heading;
        }
    }
    
    &__speedometer {
        position: relative;
        width: 288px;
        margin: 10px auto;
        height: 200px;
        
        &-label {
            position: absolute;
            bottom: 25px;
            left: 0;
            color: #fff;
            font-size: 12px;
            line-height: 16px;
            font-weight: 600;
            padding: 3px 5px;
            background: $gray-700;
            border-radius: 8px;
            
            &.label2 {
                bottom: auto;
                top: 25px;
            }
            &.label3 {
                left: auto;
                bottom: auto;
                top: 25px;
                right: 0;
            }
            &.label4 {
                left: auto;
                right: 0;
            }
            
            &.active {
                background: $success-color;
            }
        }
        
        &-circle {
            width: 198px;
            height: 198px;
            border-radius: 50%;
            margin: 0 auto;
            border: 8px solid #F3F3F3;
            position: relative;
            padding: 12px 0 0;
        }
        
        &-rules {
            left: 10px;
            position: relative;
        }
        
        &-arrow {
            position: absolute;
            top: 33.5%;
            left: 22px;
            transform: rotate(-53deg);
            transform-origin: 69% 37%;
            transition: transform .2s;
        }
    }
    
    &__text {
        font-size: 16px;
        
        &-title {
            font-size: 18px;
            margin-bottom: 40px;
            text-align: center;
        }
        
        ul {
            margin-bottom: 32px;
            li {
                position: relative;
                padding-left: 8px;
                &::before {
                    position: absolute;
                    top: .7em;
                    left: 0;
                    width: 4px;
                    height: 4px;
                    border-radius: 50%;
                    content:'';
                    background: $base-color;
                }
            }
        }
    }
    
    &__error {
        margin-bottom: 16px;
        font-size: 12px;
        color: $error-color;
    }
    
    &--medium {
        margin: 0 auto;
        max-width: 695px;
    }
}