h1,
h2,
h3,
h4,
h5,
h6 {
    line-height: 1.3;
    font-weight: 600;
    font-family: $font-family-heading;
    margin: 0;
}

h1 {
    font-size: 44px;
    @media (max-width: $breakpoint-small-max) {
        font-size: 22px;
    }
}

h2 {
    font-size: 36px;
    margin-bottom: 20px;
    @media (max-width: $breakpoint-small-max) {
        font-size: 22px;
    }
}

h3 {
    font-size: 28px;
    margin-bottom: 16px;
    @media (max-width: $breakpoint-small-max) {
        font-size: 18px;
    }
}

h4 {
    font-size: 22px;
    margin-bottom: 24px;
    @media (max-width: $breakpoint-small-max) {
        font-size: 18px;
    }
}

h5 {
    font-size: 18px;
    margin-bottom: 16px;
    @media (max-width: $breakpoint-medium-max) {
        font-size: 14px;
    }
}

h6 {
    font-size: 14px;
    margin-bottom: 8px;
}

p {
    margin: 0 0 32px;
    @media (max-width: $breakpoint-small-max) {
        margin-bottom: 24px;
    }
}

.typography {
    &__caption {
        font-size: 14px;
    }

    &__bold {
        font-weight: 700;
    }

    &__semibold {
        font-weight: 600;
    }

    &_gutter_bottom {
        margin-bottom: .5em;

        &_sm {
            margin-bottom: .25em;
        }

        &_lg {
            margin-bottom: 1em;
        }
    }
}