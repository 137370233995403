.upload-photo {
    position: relative;
    width: 96px;
    height: 96px;
    border-radius: 50%;
    color: $gray-800;
    @media (max-width: $breakpoint-small-max) {
        width: 72px;
        height: 72px;
    }
    
    &__placeholder {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        border-radius: 50%;
        background: $gray-200;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid $gray-600;
    }
    
    &__input {
        display: none;
    }
    
    &__image {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        overflow: hidden;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    
    &__button {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #1752F1;
        border: 0;
        margin: 0;
        border-radius: 50%;
        outline: none;
        @media (max-width: $breakpoint-small-max) {
            right: -8px;
        }
    }
    
    .MuiSvgIcon-root {
        font-size: 3rem;
    }
    
    &--loading {
        pointer-events: none;
        opacity: .5;
    }

    &--error {
        margin: 0;
        font-size: 14px;
        color: $secondary-color;
    }
}