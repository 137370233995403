.progress-bar {
    border-radius: 12px;
    background: #fff;
    padding: 24px 16px;
    box-shadow: $box-shadow;
    @media (max-width: $breakpoint-small-max) {
        padding: 16px;
    }
    
    &__holder {
        background: $gray-500;
        border-radius: 10px;
        height: 8px;
        position: relative;
        &:hover {
            .progress-bar__dot {
                background-color: #262453;
            }
            .progress-bar__dot-text {
                opacity: 1;
            }
        }
    }
    
    &__line {
        height: 100%;
        border-radius: 20px;
        transition: width .2s;
    }
    
    &__dot {
        position: absolute;
        top: 0;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        transition: left .2s, background-color .2s;
        box-shadow: 0 0 0 3px #fff;
    
        &--show {
            @media (min-width: $breakpoint-small) {
                background-color: #262453;
            }
        }
    }
    
    &__dot-text {
        position: absolute;
        bottom: 2px;
        left: 50%;
        transform: translateX(-50%);
        font-size: 14px;
        font-weight: 600;
        font-family: $font-family-heading;
        padding-bottom: 40px;
        opacity: 0;
        transition: opacity .2s;
        
        .progress-bar__dot--show & {
            @media (min-width: $breakpoint-small) {
                opacity: 1;
            }
        }
        
        &::before {
            position: absolute;
            bottom: 37px;
            left: 50%;
            transform: translateX(-50%);
            width: 8px;
            height: 2px;
            background: #000;
            content:'';
        }
        &::after {
            height: 39px;
            width: 2px;
            background: #000;
            content:'';
            position: absolute;
            left: 50%;
            bottom: 0;
            margin-left: -1px;
        }
    }
}