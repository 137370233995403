.expense {
    
    &-card {
        position: relative;
        border-radius: 12px;
        border: 1px solid $gray-200;
        display: flex;
        align-items: center;
        padding: 16px 24px;
        margin-bottom: 8px;
        transition: opacity .3s;
        @media (max-width: $breakpoint-small-max) {
            padding: 16px;
        }
        &--animate {
            animation: leave 0.3s forwards;
        }
        
        &__icon {
            width: 40px;
            height: 40px;
            margin-right: 16px;
            flex: 0 0 40px;
            background: rgba($link-color,.3);
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    
        &__content {
            display: flex;
            flex: 1 1 auto;
            justify-content: space-between;
            align-items: center;
            @media (max-width: $breakpoint-small-max) {
                display: block;
            }
        }
    
        &__title {
            font-weight: 700;
            font-size: 14px;
        }
    
        &__options {
            @media (max-width: $breakpoint-xsmall-max) {
                position: absolute;
                right: 0;
                top: 70%;
                width: 120px;
                background: $gray-200;
                padding: 2px 8px;
                transition: opacity .2s;
                border-radius: 6px;
                opacity: 0;
                visibility: hidden;
                box-shadow: $box-shadow;
                z-index: 3;
                &::after {
                    position: absolute;
                    top: -16px;
                    right: 16px;
                    content:'';
                    border: 8px solid transparent;
                    border-bottom-color: $gray-200;
                }
                &--open {
                    opacity: 1;
                    visibility: visible;
                }
            }
            
            ul {
                @media (min-width: $breakpoint-xsmall) {
                    display: flex;
                    align-items: center;
                    li {
                        display: flex;
                        align-items: center;
                        margin-left: 27px;
                    }
                }
                @media (max-width: $breakpoint-xsmall-max) {
                    li {
                        padding: 5px 0;
                        &:not(:last-child) {
                            border-bottom: 1px solid $gray-600;
                        }
                    }
                }
            }
            
            button {
                display: inline-flex;
                align-items: center;
                color: $base-color;
                font-size: 14px;
                font-weight: 700;
                border: 0;
                background: none;
                padding: 0;
                cursor: pointer;
                outline: none;
                &:hover {
                    text-decoration: underline;
                }
                .icon-svg {
                    margin-right: 7px;
                }
    
                @media (max-width: $breakpoint-xsmall-max) {
                    font-weight: normal;
                    .icon-svg {
                        display: none;
                    }
                }
            }
        }
        
        &__amount {
            font-size: 16px;
        }
    
        &__toggle {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            background: none;
            padding: 0 10px;
            outline: none;
            border: 0;
            @media (min-width: $breakpoint-xsmall) {
                display: none !important;
            }
            path {
                stroke: #8c8c8c;
            }
            &:hover {
                path {
                    stroke: $base-color;
                }
            }
        }
    }
    
    &__modal-title {
        font-weight: 700;
        font-size: 14px;
        margin: 0 0 20px;
        .icon-svg {
            width: 40px;
            height: 40px;
            display: flex;
            border-radius: 50%;
            margin: 0 auto 14px;
            align-items: center;
            justify-content: center;
            background: rgba($link-color, .3);
        }
        
        &-icon {
            width: 40px;
            height: 40px;
            display: flex;
            border-radius: 50%;
            margin: 0 auto 14px;
            align-items: center;
            justify-content: center;
            background: rgba($link-color, .3);
        }
    }
    
    &__button {
        @media (min-width: $breakpoint-small) {
            order: -1;
            margin-bottom: 32px;
        }
    }
    
    &__holder {
        @media (min-width: $breakpoint-small) {
            display: flex;
            flex-direction: column;
        }
    }
}

@-webkit-keyframes leave {
    0% { opacity: 1 }
    100% { opacity: 0 }
}

@keyframes leave {
    0% { opacity: 1 }
    100% { opacity: 0 }
}