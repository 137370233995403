.tour {
    &__button {
        display: flex;
        align-items: center;
        
        .MuiButton-root.MuiButton-sizeSmall {
            font-size: 16px;
            //padding-left: 10px;
        }
        &.animate {
            .MuiSvgIcon-root {
                animation: animateColor 4s infinite;
            }
            .MuiButton-label {
                animation: animateButton 4s infinite;
            }
        }
    }
}

.__floater__body {
    button[aria-label='Finish'] {
        width: 100%;
    }
}

.__floater__arrow {
    > span {
        &::before {
            position: absolute;
            margin: 17px 3px 0;
            border-radius: 50%;
            width: 8px;
            height: 8px;
            content:'';
            background: #FF665C;
            border: 8px solid #FD8A63;
            box-shadow: 0 0 0 8px rgba(#FD8A63,.4);
            animation: pulse 1s ease-in-out infinite;
    
            .__floater[x-placement="bottom"] &,
            .__floater[x-placement="bottom-start"] &,
            .__floater[x-placement="bottom-end"] &,
            .__floater[x-placement="bottom-end-start"] &,
            .__floater[x-placement="bottom-end-end"] &,
            .__floater[x-placement="bottom-start-start"] &,
            .__floater[x-placement="bottom-start-end"] & {
                margin: -25px 3px 0;
            }
            
            .__floater[x-placement="right"] &,
            .__floater[x-placement="right-start"] &,
            .__floater[x-placement="right-end"] & {
                margin: 3px 0 3px -25px;
            }
            
            .__floater[x-placement="left"] &,
            .__floater[x-placement="left-start"] &,
            .__floater[x-placement="left-end"] & {
                margin: 3px 0 3px 20px;
            }
        }
    }
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }

    55% {
        transform: scale(1.1);
    }
}

@keyframes animateButton {
    0% {
        text-shadow: none;
        color: $gray-800;
    }
    25% {
        text-shadow: 0 0 5px $accent-color;
        color: $accent-color;
    }
    50% {
        text-shadow: none;
        color: $gray-800;
    }
    75% {
        text-shadow: 0 0 5px $accent-color;
        color: $accent-color;
    }
}

@keyframes animateColor {
    0% {
        color: $gray-800;
    }
    25% {
        color: $accent-color;
    }
    50% {
        color: $gray-800;
    }
    75% {
        color: $accent-color;
    }
}

