.address {
    &__list {
        background: $gray-200;
        border-radius: 12px;
        padding: 0;
        margin: 0;
        list-style: none;
        font-size: 16px;
        overflow: hidden;
    
        [data-reach-combobox-option] {
            padding: 5px 16px;
            &[aria-selected="true"] {
                background: $gray-600;
            }
        }
        
        [data-user-value="true"] {
            font-weight: 600;
        }
        
        [data-suggested-value] {
            font-weight: normal;
        }
    }
    
    &__logo {
        padding: 5px 16px;
        text-align: right;
    }
}

[data-reach-combobox-popover] {
    border: 0;
    z-index: 6;
}