.react-date-picker {
    width: 100%;
    font-size: 16px;
    
    &--open {
        z-index: 10;
    }
    
    &__wrapper {
        background: $gray-200;
        height: 56px;
        padding: 16px;
        border-radius: 12px;
        width: 100%;
        border: 1px solid $gray-200;
        outline: none;
        @media (max-width: $breakpoint-small-max) {
            height: 48px;
            padding-top: 12px;
            padding-bottom: 12px;
            border-radius: 8px;
        }
    
        .react-date-picker--open & {
            color: $base-color;
            background: #fff;
            border-color: $gray-800;
        }
        .react-date-picker--error & {
            box-shadow: 0 0 0 1px $error-color;
            background: #fff;
        }
    }
    
    &__inputGroup {
        &__input {
            &::placeholder {
                color: $gray-800
            }
            &:invalid {
                background: none;
            }
        }
        &__divider {
            color: $gray-800;
        }
    }
    
    &__clear-button {
        display: none;
    }
    
    &__calendar-button {
        padding: 0;
    }
    
    &__calendar {
        max-width: 100%;
        width: auto;
        right: 0 !important;
        left: auto !important;
    }
    .react-calendar {
        font-family: $font-family;
        background: $gray-200;
        border: 0;
        padding: 16px;
        border-radius: 12px;
        box-shadow: $box-shadow;
        z-index: 10;
        @media (max-width: $breakpoint-xsmall-max) {
            padding: 8px;
        }
    
        &__navigation {
            margin-bottom: 10px;
            height: 32px;
            button {
                min-width: 33px;
            }
        }
        
        &__month-view__days__day--weekend {
            color: $error-color;
            &.react-calendar__tile--active {
                color: #fff;
            }
        }

        &__tile {
            padding: 8px 0;
            border-radius: 4px;
            &--active {
                background: $accent-color;
                .react-calendar__month-view__days__day--weekend {
                    color: #fff;
                }
                &:enabled:hover,
                &:enabled:focus {
                    background: $accent-color;
                }
            }
    
            &--now {
                background: $primary-color;
                color: #fff;
                &:enabled:hover,
                &:enabled:focus {
                    background: $primary-color;
                    color: #fff;
                }
            }
        }
    }
}