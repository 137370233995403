.values-card {
    border-radius: 12px;
    padding: 24px;
    font-size: 16px;
    background: $gray-300;
    display: flex;
    align-items: flex-start;
    width: 100%;
    outline: none;
    
    &__title {
        font-family: $font-family-heading;
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 8px;
        word-break: break-all;
    }
    
    &__info {
        font-size: 16px;
    }
    
    &__content {
        margin-bottom: 12px;
    }
    
    &__label {
        color: #fff;
        font-size: 14px;
        border-radius: 4px;
        padding: 2px 5px;
        font-weight: 700;
        background: $success-color;
        &--alt {
            background: $error-color;
        }
    }
    
    &__icon {
        width: 56px;
        height: 56px;
        flex: 0 0 56px;
        margin-right: 16px;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}