$base-color:           #000;
$body-bg:              #f5f5f5;
$link-color:           #0F9AFF;

$font-family:          'Source Sans Pro', sans-serif;
$font-family-heading:  'Sora', sans-serif;

$primary-color:        #262453;
$secondary-color:      #E33544;
$secondary2-color:     #F3D9DA;
$secondary3-color:     #E3A45A;
$accent-color:         #FD8A63;

$error-color:          #FF665C;
$success-color:        #215E59;

// gray colors
$gray-200:             #F3F3F3;
$gray-300:             #F9F9F9;
$gray-400:             rgba(#F3F3F3, .5);
$gray-500:             #E8E8E8;
$gray-600:             #DDDDDD;
$gray-700:             #D1D1D1;
$gray-800:             #8C8C8C;

$box-shadow:           0 100px 80px rgba(135, 138, 154, 0.07), 0 41.7776px 33.4221px rgba(135, 138, 154, 0.0503198), 0 22.3363px 17.869px rgba(135, 138, 154, 0.0417275), 0 12.5216px 10.0172px rgba(135, 138, 154, 0.035), 0 6.6501px 5.32008px rgba(135, 138, 154, 0.0282725), 0 2.76726px 2.21381px rgba(135, 138, 154, 0.0196802);


// Breakpoints
$breakpoint-xsmall:         480px;
$breakpoint-small:          760px;
$breakpoint-medium:         1000px;
$breakpoint-large:          1200px;
$breakpoint-xlarge:         1600px;

$breakpoint-xsmall-max:     ($breakpoint-xsmall - 1);
$breakpoint-small-max:      ($breakpoint-small - 1);
$breakpoint-medium-max:     ($breakpoint-medium - 1);
$breakpoint-large-max:      ($breakpoint-large - 1);
$breakpoint-xlarge-max:     ($breakpoint-xlarge - 1);