.layout-main {
    overflow: hidden;
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    
    &__title {
        display: flex;
        align-items: center;
        margin-bottom: 24px;
        
        h4 {
            margin: 0;
        }
        
        .MuiIconButton-root {
            width: 32px;
            height: 32px;
            border-radius: 8px;
            background: $gray-200 !important;
            margin-left: 10px;
        }
    }
    
    &__content {
        flex: 1 1 auto;
        padding: 40px 32px;
        position: relative;
        @media (max-width: $breakpoint-medium-max) {
            padding-left: 16px;
            padding-right: 16px;
        }
        @media (max-width: $breakpoint-small-max) {
            padding-top: 32px;
            padding-bottom: 32px;
        }
    }
    
    &__form {
        max-width: 384px;
        margin: 0 auto;
    }
    
    &--decor {
        .layout-main__content {
            padding-top: 150px;
            background: url('../../images/decor.png') no-repeat 50% 0;
            background-size: 1008px 210px;
            @media (max-width: $breakpoint-medium-max) {
                padding-top: 96px;
                background-position: top 0 right -858px;
            }
        }
        
        &-profile {
            .layout-main__content {
                @media (max-width: $breakpoint-small-max) {
                    padding-top: 32px;
                }
            }
        }
    }
    
    &--tree {
        @media (max-width: $breakpoint-medium-max) {
            overflow: visible;
        }
        
        @media (min-width: $breakpoint-medium) {
            background: #FFF3EF;
        }
        .layout-main__content {
            padding-top: 0;
            display: flex;
            flex-direction: column;
            @media (min-width: $breakpoint-medium) {
                overflow: hidden;
            }
            @media (max-width: $breakpoint-medium-max) {
                background: url('../../images/decor.png') no-repeat;
                background-size: 1008px 210px;
                padding-top: 96px;
                background-position: top 0 right -858px;
            }
        }
    }
    
    &--sticky {
        @media (max-width: $breakpoint-small-max) {
            overflow: visible;
        }
    }
    
    &--search {
        @media (max-width: $breakpoint-medium-max) {
            .header {
                display: none;
            }
            
            .layout-main__content {
                padding-top: 0;
            }
        }
    }
    
    &--admin {
        overflow: visible;
        .layout-main__content {
            padding: 0;
            display: flex;
    
            @media (max-width: $breakpoint-small-max) {
                flex-direction: column;
            }
    
        }
    }
    
    &--event-none {
        pointer-events: none;
    }
    
    &--disabled {
        opacity: .3;
        pointer-events: none;
    }
}