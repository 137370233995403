.MuiButton-root {
    .MuiTouchRipple-root {
        display: none !important;
    }
    &.MuiButtonBase-root {
        color: $base-color;
        font-size: 14px;
        line-height: 1.5;
        font-weight: 700;
        font-family: $font-family;
        border-radius: 10px;
        text-transform: none;
        padding: 10px 8px;
        min-width: 112px;
        cursor: pointer;
        white-space: nowrap;
    }
    
    &.MuiButton-contained {
        box-shadow: none;
        &:hover {
            box-shadow: none;
        }
    }
    
    // primary
    &.MuiButton-containedPrimary {
        color: #fff !important;
        background: $accent-color;
        &:hover {
            background: darken($accent-color, 5%);
        }
        &.Mui-disabled {
          background: $gray-400;
          color: $gray-700;
        }
    }
    
    // blue
    &.MuiButton-containedBlue {
        color: white;
        background: $link-color;
        &:hover {
            background: darken($link-color, 5%);
        }
    }
    
    // gray
    &.MuiButton-textGray {
        color: $gray-800;
        &:hover {
          background: $gray-200;
        }
    }
    
    
    // disabled
    &.Mui-disabled {
        background: $gray-400;
        color: $gray-700;
    }
    
    // size
    &.MuiButton-sizeSmall {
        font-size: 14px;
        border-radius: 8px;
        padding: 6px 10px;
        min-width: 96px;
        font-weight: 600;
    }
    
    &.MuiButton-sizeLarge {
        border-radius: 12px;
        font-size: 16px;
        padding: 16px 10px;
        @media (min-width: $breakpoint-small) {
            min-width: 176px;
        }
        @media (max-width: $breakpoint-small-max) {
          padding: 14px 20px;
          white-space: nowrap;
        }
    }

    &.tree-bar {
        padding: 10px 40px;
    }
    
    &.medium {
        font-size: 16px;
        border-radius: 12px;
        padding: 12px;
    }
    
    
    // outlined
    &.MuiButton-outlined {
        //border-radius: 8px;
        border-color: $gray-700;
        padding: 9px 10px;
        font-weight: 600;
        background: #fff;
        transition: background-color .2s, color .1s, border-color .1s;
        
        &:hover {
            border-color: darken($accent-color, 5%);
            color: darken($accent-color, 5%);
            background: #fff;
        }
        
        &.invert {
            background: none;
            color: #fff;
            border-color: #fff;
            &:hover {
                background-color: rgba(255,255,255,.2);
            }
        }
        
        &.MuiButton-sizeSmall {
            padding-top: 5px;
            padding-bottom: 5px;
        }
        &.MuiButton-sizeLarge {
            padding-top: 15px;
            padding-bottom: 15px;
        }
        &.medium {
            padding-top: 11px;
            padding-bottom: 11px;
        }
        &.MuiButton-outlinedPrimary {
            border-color: $accent-color;
            color: $accent-color;
    
            &:hover {
                border-color: darken($accent-color, 5%);
                color: #fff;
                background: darken($accent-color, 5%);
            }
        }
        &.Mui-disabled {
            background: $gray-700;
            color: $gray-400;
            border-color: $gray-700;
        }
    }
    
    &.MuiButton-minimal {
        font-weight: normal;
        color: $gray-800;
        padding: 0;
        &:hover {
            background: none;
            color: $base-color;
        }
    }
    
    // link
    &.link {
        padding: 0;
        margin-left: 5px;
        color: $link-color;
        &:hover {
            background: none;
            color: darken($link-color, 10%)
        }
    }
    
    // back button
    &.back-button {
        padding: 0;
        margin: 0;
        font-size: 14px !important;
        font-weight: normal;
        &:hover {
            background: none;
            opacity: .8;
            
            .back-button__icon {
                background: $gray-600;
            }
        }
        
        .back-button__icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 32px;
            height: 32px;
            border-radius: 8px;
            background: $gray-400;
            margin-right: 16px;
            color: $gray-800;
            transition: background-color .2s;
        }
        
        &--position {
            position: absolute;
            left: 32px;
            top: 40px;
            z-index: 4;
        }
    }
    
}

// Button Back
.back-button,
.delete-button {
    font-size: 14px;
    color: $base-color;
    display: inline-flex;
    align-items: center;
    background: none;
    border: 0;
    padding: 0;
    cursor: pointer;
    margin-bottom: 40px;
    outline: none;
    @media (max-width: $breakpoint-medium-max) {
        margin-bottom: 32px;
    }
    &:hover {
        text-decoration: none;
        opacity: .8;
    
        .back-button__icon {
            background: $gray-600;
        }
    }

    &--company {
        margin: 20px 0 0;
    }
    
    &__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        border-radius: 8px;
        background: #F3F3F3;
        margin-right: 16px;
        color: $gray-800;
        transition: background-color .2s;
        
        .layout-main--tree & {
            @media (min-width: $breakpoint-medium) {
                background: #fff;
            }
        }
    }
    
    &--position {
        position: absolute;
        left: 32px;
        top: 40px;
        z-index: 4;
        @media (max-width: $breakpoint-medium-max) {
            left: 16px;
        }
        @media (max-width: $breakpoint-small-max) {
            top: 32px;
        }
    }
    
    .layout-form & {
        position: absolute;
        left: 0;
        top: 0;
    }
}

// Button Delete
.delete-button {
  &--position {
      left: auto;
      right: 32px;
      @media (max-width: $breakpoint-medium-max) {
          right: 16px;
      }
  }

  .layout-form & {
      left: auto;
      right: 0;
  }
}

// Add button
.add-button {
    border: 0;
    background: none;
    padding: 0;
    margin: 0;
    display: inline-flex;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
    font-family: $font-family;
    color: $base-color;
    cursor: pointer;
    outline: none;
    
    &__icon {
        position: relative;
        width: 32px;
        height: 32px;
        flex: 0 0 32px;
        border-radius: 8px;
        background: $accent-color;
        @media (max-width: $breakpoint-xsmall-max) {
            width: 48px;
            height: 48px;
            flex: 0 0 48px;
            border-radius: 12px;
        }
    
        @media (max-width: $breakpoint-small-max) {
            .add-button--small-mobile & {
                margin: 0;
                width: 48px;
                height: 48px;
                flex: 0 0 48px;
                border-radius: 12px;
            }
        }
        
        &::before,
        &::after {
            position: absolute;
            left: 50%;
            top: 50%;
            width: 16px;
            height: 2px;
            border-radius: 2px;
            content:'';
            background: #fff;
            transform: translate(-50%,-50%);
        }
        &::after {
            transform: translate(-50%,-50%) rotate(90deg);
        }
    }
    
    &__text {
        margin-left: 16px;
    }

    &__text-moblie {
        @media (max-width: $breakpoint-xsmall-max) {
            display: none;
        }

        .add-button--small-mobile & {
            @media (max-width: $breakpoint-small-max) {
                display: none;
            }
        }
    }
    
    &--full {
        background: $gray-200;
        border-radius: 12px;
        padding: 24px;
        justify-content: center;
        width: 100%;
        @media (max-width: $breakpoint-small-max) {
            background: none;
            width: auto;
            padding: 0;
        }
    }
}

.button {
    &__mobile-sticky {
        @media (max-width: $breakpoint-small-max) {
            padding-top: 16px;
            position: sticky;
            text-align: right;
            width: max-content;
            margin-left: auto;
            bottom: 16px;
            right: 16px;
        }
    }
}