.progress-circle {
    width: 200px;
    height: 200px;
    padding: 24px;
    position: relative;
    margin: 0 auto;
    
    &__svg {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        transform: rotate(-90deg);
    }
    
    &__circle {
        r: calc(50% - 12px);
        stroke-width: 24px;
        fill: none;
        transition: stroke-dasharray .4s;
    }
    
    &__holder {
        width: 100%;
        height: 100%;
        border: 7px solid $gray-500;
        background: $gray-300;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    &__title {
        font-size: 14px;
        text-align: center;
        strong {
            font-size: 16px;
            display: block;
        }
    }
    
    &--small {
        width: 77px;
        height: 77px;
        padding: 10px;
        .progress-circle {
            &__circle {
                r: calc(50% - 5px);
                stroke-width: 10px;
            }
            &__holder {
                border-width: 4px;
            }
            &__title {
                line-height: 1.3;
            }
        }
    }
}
