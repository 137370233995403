.steps {
    font-size: 14px;
    padding: 0;
    margin: 0 0 40px;
    display: flex;
    list-style: none;
    counter-reset: item;
    
    &__item {
        counter-increment: item;
        margin-right: 20px;
        position: relative;
        padding-left: 30px;
        color: $gray-800;
        &::before {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            font-weight: bold;
            content: counter(item);
            width: 22px;
            height: 22px;
            line-height: 21px;
            text-align: center;
            border: 1px solid $gray-600;
            border-radius: 50%;
        }
        
        
        &--active {
            color: $link-color;
            font-weight: 600;
            &::before {
                border-color: $link-color;
                background: $link-color;
                color: #fff;
            }
        }
        
        &--done {
            color: $success-color;
            &::before {
                border-color: $success-color;
                content: '';
                background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxNiAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE0LjcwOTkgMS4yMUMxNC42MTcgMS4xMTYyNyAxNC41MDY0IDEuMDQxODggMTQuMzg0NSAwLjk5MTEwN0MxNC4yNjI3IDAuOTQwMzM4IDE0LjEzMiAwLjkxNDIgMTMuOTk5OSAwLjkxNDJDMTMuODY3OSAwLjkxNDIgMTMuNzM3MiAwLjk0MDMzOCAxMy42MTU0IDAuOTkxMTA3QzEzLjQ5MzUgMS4wNDE4OCAxMy4zODI5IDEuMTE2MjcgMTMuMjkgMS4yMUw1LjgzOTk1IDguNjdMMi43MDk5NSA1LjUzQzIuNjEzNDMgNS40MzY3NiAyLjQ5OTQ5IDUuMzYzNDUgMi4zNzQ2MyA1LjMxNDI0QzIuMjQ5NzggNS4yNjUwNCAyLjExNjQ1IDUuMjQwOTEgMS45ODIyNyA1LjI0MzIzQzEuODQ4MDkgNS4yNDU1NSAxLjcxNTY4IDUuMjc0MjggMS41OTI2IDUuMzI3NzdDMS40Njk1MyA1LjM4MTI2IDEuMzU4MTkgNS40NTg0OCAxLjI2NDk1IDUuNTU1QzEuMTcxNzEgNS42NTE1MiAxLjA5ODQgNS43NjU0NiAxLjA0OTE5IDUuODkwMzJDMC45OTk5ODkgNi4wMTUxNyAwLjk3NTg1OSA2LjE0ODUgMC45NzgxOCA2LjI4MjY4QzAuOTgwNTAyIDYuNDE2ODYgMS4wMDkyMyA2LjU0OTI3IDEuMDYyNzIgNi42NzIzNEMxLjExNjIyIDYuNzk1NDIgMS4xOTM0MyA2LjkwNjc2IDEuMjg5OTUgN0w1LjEyOTk1IDEwLjg0QzUuMjIyOTEgMTAuOTMzNyA1LjMzMzUxIDExLjAwODEgNS40NTUzNyAxMS4wNTg5QzUuNTc3MjMgMTEuMTA5NyA1LjcwNzk0IDExLjEzNTggNS44Mzk5NSAxMS4xMzU4QzUuOTcxOTYgMTEuMTM1OCA2LjEwMjY3IDExLjEwOTcgNi4yMjQ1MyAxMS4wNTg5QzYuMzQ2MzkgMTEuMDA4MSA2LjQ1Njk5IDEwLjkzMzcgNi41NDk5NSAxMC44NEwxNC43MDk5IDIuNjhDMTQuODExNSAyLjU4NjM2IDE0Ljg5MjUgMi40NzI3IDE0Ljk0NzkgMi4zNDYyQzE1LjAwMzMgMi4yMTk3MSAxNS4wMzE5IDIuMDgzMSAxNS4wMzE5IDEuOTQ1QzE1LjAzMTkgMS44MDY5IDE1LjAwMzMgMS42NzAyOSAxNC45NDc5IDEuNTQzNzlDMTQuODkyNSAxLjQxNzI5IDE0LjgxMTUgMS4zMDM2NCAxNC43MDk5IDEuMjFaIiBmaWxsPSIjMjE1RTU5Ii8+Cjwvc3ZnPgo=') no-repeat 50% 50%;
                background-size: 14px 14px;
            }
        }
    }
}