.mb {
    &--small {
        margin-bottom: 24px;
    }

    &--medium {
        margin-bottom: 40px;

        @media (max-width: $breakpoint-small-max) {
            margin-bottom: 20px;
        }
    }

    &--large {
        margin-bottom: 56px;
    }
}

.mt {
    &--small {
        margin-top: 24px;
    }

    &--medium {
        margin-top: 40px;

        @media (max-width: $breakpoint-xsmall-max) {
            margin-top: 24px;
        }
    }

    &--large {
        margin-top: 56px;
    }
}

.mr-0{
    margin: 0;
}

.align-items {
    &__start {
        align-items: flex-start;
    }
}

.mobile {
    &-visible {
        @media (min-width: $breakpoint-xsmall) {
            display: none !important;
        }
    }
    &-hidden {
        @media (max-width: $breakpoint-xsmall-max) {
            display: none !important;
        }
    }
}

.mobile-large {
    &-visible {
        @media (min-width: $breakpoint-small) {
            display: none !important;
        }
        > button {
          border-radius: 8px !important;
          padding: 8px 10px !important;
        }
    }
    &-hidden {
        @media (max-width: $breakpoint-small-max) {
            display: none !important;
        }
        > button {
          border-radius: 8px !important;
          padding: 6px 10px !important;
        }
    }
}

.tablet {
    &-visible {
        @media (min-width: $breakpoint-medium) {
            display: none !important;
        }
    }

    &-hidden {
        @media (max-width: $breakpoint-medium-max) {
            display: none !important;
        }
    }
}

.desktop {
    &-visible {
        @media (max-width: $breakpoint-large-max) {
            display: none !important;
        }
    }
}

.text {
    &-muted {
        color: $gray-800;
    }

    &-base {
        color: $base-color;
    }

    &-link {
        color: $link-color;
    }

    &-success {
        color: $success-color;
    }

    &-accent {
        color: $accent-color;
    }

    &-center {
        text-align: center;
    }

    &-left {
        text-align: left;
    }

    &-nowrap {
      white-space: nowrap;
    }
}

.fs {
    &__14 {
        font-size: 14px;
    }

    &__16 {
        font-size: 16px;
    }
}

.grid {
    @media (min-width: $breakpoint-xsmall) {
        margin: 0 -8px;
        display: flex;
        flex-wrap: wrap;
    }

    @media (min-width: $breakpoint-medium) {
        margin: 0 -16px;
    }

    > div {
        padding: 0 0 8px;
        @media (min-width: $breakpoint-xsmall) {
            display: flex;
            padding: 0 8px 16px;
        }
        @media (min-width: $breakpoint-medium) {
            padding: 0 16px 32px;
        }

        &.column-1-3 {
            @media (min-width: $breakpoint-small) {
                width: 33.33%;
            }
        }

        &.column-2-3 {
            @media (min-width: $breakpoint-small) {
                width: 66.33%;
            }
        }
    }

    &-2 {
        > div {
            @media (min-width: $breakpoint-xsmall) {
                width: 50%;
            }
        }
    }

    &-3 {
        > div {
            @media (min-width: $breakpoint-xsmall) {
                width: 50%;
            }
            @media (min-width: $breakpoint-small) {
                width: 33.33%;
            }
        }
    }

    &-3-large {
        > div {
            @media (min-width: $breakpoint-xsmall) {
                width: 50%;
            }
            @media (min-width: $breakpoint-medium) {
                width: 33.33%;
            }
            @media (min-width: $breakpoint-xlarge) {
                width: 25%;
            }
        }
    }

    &-4 {
        > div {
            @media (min-width: $breakpoint-xsmall) {
                width: 50%;
            }
            @media (min-width: $breakpoint-small) {
                width: 33.33%;
            }
            @media (min-width: $breakpoint-medium) {
                width: 25%;
            }
        }
    }

    &-4-large {
        > div {
            @media (min-width: $breakpoint-xsmall) {
                width: 50%;
            }
            @media (min-width: $breakpoint-small) {
                width: 33.33%;
            }
            @media (min-width: $breakpoint-large) {
                width: 25%;
            }
        }
    }

    &-button {
        display: flex;
        align-items: center;

        &--sticky {
            @media (max-width: $breakpoint-xsmall-max) {
                position: sticky;
                bottom: 16px;
                right: 16px;
                justify-content: flex-end;
            }
        }
    }
}
