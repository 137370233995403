.faq {
    padding-top: 29px;
    @media (min-width: $breakpoint-small) {
        padding-top: 47px;
    }
    
    &__page-title {
        text-align: center;
        margin: 0 auto 28px;
        @media (min-width: $breakpoint-small) {
            margin-bottom: 75px;
        }
    }
    
    &__content {
        border-radius: 12px;
        background: $gray-300;
        padding: 80px 16px;
        position: relative;
        &::before {
            position: absolute;
            top: -112px;
            left: -32px;
            right: -32px;
            content: '';
            height: 210px;
            background: url('../../images/bg-img.png') 50% 50% no-repeat;
            background-size: 1200px 500px;
            @media (max-width: $breakpoint-small-max) {
                background-size: 552px 230px;
                height: 100px;
                top: -50px;
            }
        }
    
        @media (min-width: $breakpoint-small) {
            display: flex;
            justify-content: space-between;
            padding: 120px 8.6% 145px;
        }
    }
    
    &__title {
        @media (min-width: $breakpoint-small) {
            padding-top: 30px;
        }
    }
    
    &__list {
        font-size: 14px;
        line-height: 1.375;
        @media (min-width: $breakpoint-small) {
            max-width: 59%;
            font-size: 16px;
            width: 100%;
        }
    
        .MuiAccordion-root {
            box-shadow: none;
            background: none;
            margin: 0;
            border-bottom: 1px solid $gray-600;
            &::before {
                background: none
            }
            &.MuiAccordion-rounded {
                border-radius: 0;
            }
    
            &.Mui-expanded {
                margin: 0;
                border-bottom-color: $base-color;
            }
    
            .MuiAccordionSummary-root {
                padding: 17px 0;
                margin: 0;
                min-height: 70px;
                font-size: 14px;
                font-weight: 600;
                line-height: 1.286;
                font-family: $font-family-heading;
                @media (min-width: $breakpoint-small) {
                    min-height: 84px;
                }
                
                &.Mui-expanded {
                    margin: 0;
                }
    
                .MuiAccordionSummary-content {
                    margin: 0;
                }
    
                .MuiIconButton-root {
                    background: none !important;
                    margin-right: -6px;
                    .MuiSvgIcon-root {
                        color: $base-color;
                    }
                }
            }
            
            .MuiAccordionDetails-root {
                padding: 0 0 25px;
            }
        }
    }
}