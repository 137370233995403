.react-select {
    &:focus {
        outline: none;
    }
    .react-select {
        &__control {
            border: 1px solid $gray-200;
            background: $gray-200;
            border-radius: 12px;
            min-height: 56px;
            box-shadow: none;
            &:hover {
                border-color: $gray-200;
            }
            &:focus {
                background: #fff;
                border-color: $gray-800;
            }
        }
    
        &__placeholder {
            margin: 0;
            + div {
                margin: 0;
            }
        }
        
        &__single-value {
            + div {
                margin: 0;
            }
        }
    
        &__value-container {
            font-size: 16px;
            padding: 0 0 0 16px;
        }
    
        &__dropdown-indicator {
            padding: 10px 16px 10px 8px;
        }
        
        &__indicator-separator {
            display: none;
        }
    
        &__clear-indicator {
            padding: 0;
        }
        
        &__menu {
            margin: 0;
            border-radius: 12px;
            border: 0;
            font-size: 16px;
            padding: 5px 16px;
            z-index: 10;
        }
        
        &__option {
            padding: 10px 0;
            border-bottom: 1px solid $gray-600;
            &:hover,
            &--is-focused {
                background: $gray-300;
            }
            
            &--is-selected {
                background: none;
                color: $accent-color;
                font-weight: 600;
            }
        }
    }
    
    &.react-select--error {
        .react-select {
            &__control {
                border-color: $error-color;
            }
        }
    }
}

