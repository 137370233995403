.speedometer {
    position: relative;
    width: 104px;
    flex: 0 0 104px;
    text-align: center;
    
    &__rulers {
        position: absolute;
        left: 11px;
        top: 10px;
    }
    
    &__arrow {
        position: absolute;
        top: 30px;
        left: 18px;
        transform: rotate(-53deg);
        transform-origin: 68% 59%;
    }
    
    &__label {
        border-radius: 8px;
        background: $success-color;
        color: #fff;
        margin: 10px auto 14px;
        display: inline-block;
        min-width: 32px;
        padding: 2px 5px;
        font-size: 12px;
        font-weight: 600;
        text-align: center;
        &--limit {
            background: $error-color;
        }
    }
    &__description {
        color: #8C8C8C;
    }
    
    &--large {
        @media (min-width: $breakpoint-large) {
            width: 138px;
            flex: 0 0 138px;
            .speedometer {
                &__svg {
                    width: 138px;
                    height: 132px;
                    margin-bottom: -19px;
                }
                
                &__rulers {
                    width: 109px;
                    height: 81px;
                    left: 15px;
                    top: 27px;
                }
                
                &__arrow {
                    top: 52px;
                    left: 25px;
                    width: 65px;
                    height: 52px;
                }
            }
        }
    }
}