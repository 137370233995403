.terms-section {
    max-width: 800px;
    font-size: 16px;
    margin: 0 auto 30px;
    
    &--center {
        text-align: center;
    }

    &--list{
      list-style-type: disc;
      padding-inline-start: 40px;
      margin-bottom: 20px;
    }
}