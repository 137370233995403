.analysis-filter{

  .filter-title,
  .filter-subtitle{
    text-align: left;
    max-width: 555px;
  }
  .filter-title{
    margin-bottom: 10px;
    @media (min-width: $breakpoint-medium) {
      display: none;
    }
  }
  .Mui-disabled {
    background: rgba(227, 227, 227, 0.15);
  }
  .filter-divider{
    width: 16px;
    height: 2px;
    background: $gray-600;
    margin: 0 6px;
  }
  .custom-select{
    font-size: 16px;
    border-radius: 8px !important;
    height: 40px;
    text-align: left;
  }

  &-button{
    width: 100%;
    margin: 10px 0 20px !important;
    @media (min-width: $breakpoint-xsmall) {
      max-width: 270px;
  }
  }

  &__wrap{
    display: flex;
    gap: 24px;
    max-width: 1300px;
    @media (max-width: $breakpoint-medium-max) {
      flex-wrap: wrap;
    }
  }

  &__item {
    margin-bottom: 10px;
    @media (max-width: $breakpoint-medium-max) {

      .range__label{
        text-align: left;
      }
    }
  }

  &__slide{
    display: flex;
    gap: 24px;

    .range__bar{
      padding: 16px 0;
    }

    @media (max-width: $breakpoint-medium-max) {
      flex-direction: column;
      .MuiBox-root{
        width: 100%;
      }
    }
  }

  &__location{
    min-width: 250px;
    flex: 1 0 250px;
  }

  &__status{
    max-width: 400px;
    min-width: 160px;
    flex: 1 0 160px;
  }

  &__control{
    display: flex;
    gap: 16px;
    margin: 20px 0 30px;
    .MuiButton-root.MuiButtonBase-root {
      min-width: 170px;
      height: 46px;
    }
    @media (max-width: $breakpoint-medium-max) {
      flex-direction: column;
    }
  }

  .filter-range-inputs{
    display: flex;
    align-items: center;

    .form__area.form__area--small{
      max-width: 135px;

      @media (max-width: $breakpoint-medium-max) {
        max-width: 100%;
      }
    }
  }
}