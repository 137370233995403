.slick-slider {
    width: 100%;
    //max-width: 100%;
    position: relative;
    display: block;
    box-sizing: border-box;
    
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
    
    &.widget__list {
        @media (max-width: 640px) {
            margin: 0 -8px;
            display: block;
            > div {
                width: 100%;
                padding: 0;
            }
        }
    }
}

.slick-list {
    position: relative;
    display: block;
    //overflow: hidden;
    margin: 0;
    padding: 0;
    &:focus {
        outline: none;
    }
    &.dragging {
        cursor: hand;
    }
}

.slick-slide {
    display: none;
    //float: left;
    //height: 100%;
    min-height: 1px;
    width: 100%;
    padding: 0 8px;
    .slick-track,
    .slick-list {
        -webkit-transform: translate3d(0, 0, 0);
        -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
        -o-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
    
    img {
        display: block;
    }
    
    &.slick-loading {
        visibility: hidden;
        img {
            display: none;
        }
    }
    
    &.dragging {
        img {
            pointer-events: none;
        }
    }
    
    .slick-initialized & {
        display: flex;
        max-width: 100%;
    }
    
    > div {
        width: 100%;
        > div {
            outline: none;
        }
    }
}

.slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    
    &::before,
    &::after {
        display: table;
        content: '';
    }
    &::after {
        clear: both;
    }
    
    .slick-loading & {
        visibility: hidden;
    }
}

.slick-dots {
    display: flex !important;
    justify-content: center;
    margin-top: 16px;
    li {
        padding: 0 3px;
        font-size: 0;
        button {
            overflow: hidden;
            font-size: 0;
            line-height: 0;
            padding: 0;
            border: 0;
            width: 8px;
            height: 3px;
            border-radius: 15% / 50%;
            background: $gray-600;
            outline: none;
            cursor: pointer;
            transition: width .2s;
        }
        
        &.slick-active {
            button {
                width: 16px;
                background: $primary-color;
            }
        }
    }
    &.custom-dots {
      position: absolute;
      bottom: 45px;
      left: 50%;
      margin: 0;
      transform: translate(-50%, 0);
       @media (max-width: $breakpoint-small-max) {
         bottom: 10px;
       }
      & li button {
        border-radius: 3px;
        background: $secondary2-color;
        width: 35px;
      }
      & .slick-active {
        button {
          background: $primary-color;
          width: 60px;
        }
      }
    }
}

.slick-arrow {
    width: 32px;
    height: 32px;
    border-radius: 8px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: $gray-200;
    border: 0;
    cursor: pointer;
    z-index: 2;
    font-size: 0;
    line-height: 0;
    &:not(.slick-disabled) {
        &:hover {
            background: $gray-300;
        }
    }
    
    &::after {
        position: absolute;
        left: 50%;
        top: 50%;
        content:'';
        transform: translate(-50%, -50%) rotate(-135deg);
        width: 6px;
        height: 6px;
        border: solid $gray-800;
        border-width: 2px 2px 0 0;
        margin-left: 2px;
    }
    
    &.slick-prev {
        left: 0;
    }
    
    &.slick-next {
        right: 0;
        &::after {
            transform: translate(-50%, -50%) rotate(45deg);
            margin-left: -2px;
        }
    }
    
    &.slick-disabled {
        cursor: default;
        background: $gray-600;
    }
}