.calculator-card {
    flex: 1 1 auto;
    margin-top: 38px;
    position: relative;
    border-radius: 12px;
    background: $gray-400;
    text-align: center;
    padding: 44px 24px 57px;
    @media (min-width: $breakpoint-small) {
        padding: 44px 38px 71px;
        margin-top: 28px;
    }
    
    &--active {
        z-index: 4;
    }
    
    &__icon {
        width: 56px;
        height: 56px;
        position: absolute;
        overflow: hidden;
        left: 50%;
        top: -28px;
        transform: translateX(-50%);
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    
    &__title {
        max-width: 230px;
        margin: 0 auto 16px;
    }
    
    &__info {
        display: flex;
        justify-content: center;
        position: absolute;
        bottom: 24px;
        left: 50%;
        transform: translateX(-50%);
    
        @media (min-width: $breakpoint-small) {
            bottom: 38px;
        }
    
    
        > * {
            margin: 0 6px !important;
        }
    }
}