.icon-svg {
    display: flex;
    align-items: center;
    justify-content: center;
    > div {
        line-height: 1;
        display: inline-flex;
        align-items: center;
        justify-content: center;
    }
    &:not(.icon-svg--default) {
        svg {
            path {
                fill: currentColor;
            }
        }
    }
}