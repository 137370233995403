.MuiFormGroup-root {
    &.radio-list-inline {
        flex-direction: row;
        margin-bottom: -16px;
        
        .MuiFormControlLabel-root {
            margin-right: 20px;
            margin-bottom: 16px;
            min-width: 80px;
            .MuiFormControlLabel-label {
                color: $gray-800;
            }
        }
    }
    
    &.radio-list-slider {
        @media (max-width: 740px) {
            display: block;
        }
    }
    
    .MuiFormControlLabel-root {
        margin: 0 0 22px;
        align-items: flex-start;
    
        .MuiFormControlLabel-label {
            font-size: 16px;
            //color: $gray-800;
            font-family: $font-family;
            font-weight: 400;
            
        }
    }
    
    .MuiButtonBase-root {
        &.MuiRadio-root {
            background: none;
            color: $gray-700;
            padding: 0;
            margin: 2px 10px 0 0;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            flex: 0 0 20px;
            position: relative;
            &::after {
                position: absolute;
                left: 50%;
                top: 50%;
                content:'';
                transform: translate(-50%, -50%);
                border-radius: 50%;
                width: 6px;
                height: 6px;
                opacity: 0;
                background: #fff;
            }
            &:hover {
                background: none;
            }
            
            &.Mui-checked {
                color: $success-color;
                background: $success-color;
                &::after {
                    opacity: 1;
                }
                
                .MuiIconButton-label {
                    > div {
                        display: none;
                    }
                }
                
                + .MuiFormControlLabel-label {
                    color: $base-color;
                }
            }
        }
    }
}

.radio-slider {
    margin: 30px -12px 64px;
    width: auto;
    @media (max-width: $breakpoint-medium-max) {
        margin-top: 16px;
        margin-bottom: 50px;
    }
    
    @media (min-width: 741px) {
        display: flex;
    }
    
    .slick-list {
        margin-right: 56px;
    }
    
    .slick-slide {
        padding: 0 12px;
        position: relative;
        &:not(.slick-active) {
            opacity: .3;
            pointer-events: none;
        }
    }
    
    .MuiFormControlLabel-root {
        display: flex !important;
        margin: 0;
        outline: none;
        @media (min-width: 741px) {
            padding: 0 12px;
            flex: 1;
        }
        .MuiFormControlLabel-label {
            flex: 1 1 auto;
        }
    }
}

.radio {
    &__label {
        margin-bottom: 16px;
    }
    
    &__image {
        overflow: hidden;
        margin-left: -30px;
        width: calc(100% + 30px);
        @media (max-width: 740px) {
            img {
                width: 100%;
            }
        }
    }
}