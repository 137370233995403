.table {
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;

    &__container {
        width: 100%;
        overflow-x: auto;
    }

    &__row {
        color: inherit;
        outline: 0;
        vertical-align: middle;
    }

    &__cell {
        background-color: #ffffff;

        &__head {
            padding: 0 16px;
            font-size: 12px;
            font-weight: 600;
            color: $gray-800;

            @media (max-width: $breakpoint-small) {
                padding: 2px 30px 10px 50px;

                &:not(:last-child) {
                    @media (max-width: $breakpoint-small) {
                        border-right: 1px solid $gray-200;
                    }
                }
            }
        }

        &__body {
            padding: 16px;
            font-size: 14px;
            font-weight: 400;
            border-bottom: 1px solid $gray-200;

            @media (max-width: $breakpoint-small) {
                padding: 16px 30px 16px 50px;
            }

            &:not(:last-child) {
                @media (max-width: $breakpoint-small) {
                    border-right: 1px solid $gray-200;
                }
            }
        }

        &__sort {
            display: flex;
            align-items: center;
            cursor: pointer;

            svg {
                fill: #0F9AFF;
                font-size: 16px;
                margin: 1px 0 0 4px;

                @media (max-width: $breakpoint-small) {
                    font-size: 16px;
                }
            }
        }

        &-sticky {
            position: sticky;
            left: 0;
        }

        &-nowrap {
            white-space: nowrap;
        }

        &-offset-left {
            padding-left: 0;
        }

        &-offset-right {
            padding-right: 0;
        }
    }
}