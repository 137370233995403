.MuiRating-root {
    font-size: 1rem !important;
    .MuiRating-icon {
        line-height: 1;
        &.MuiRating-iconFilled {
            .icon-svg {
                color: currentColor;
                path {
                    fill: currentColor;
                }
            }
        }
    }
    > span {
        padding: 0 7px 0 0;
    }
    
    &.rating--large {
        > span {
            padding-right: 9px;
        }
        .icon-svg {
            svg {
                width: 10px;
                height: 12px;
            }
        }
    }
}