.admin-tasks{
    display: flex;
    flex: 1 1 auto;
    @media (max-width: $breakpoint-small-max) {
        flex-direction: column;
    }
    &__content{
        flex: 1 1 auto;
        max-width: 600px;
        @media (max-width: $breakpoint-small-max) {
            max-width: 100%;
        }
    }
    &__side{
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
        max-width: 350px;
        margin-left: 50px;
        margin-top: 35px;
        @media (max-width: $breakpoint-small-max) {
            margin-left: 0;
            max-width: 100%;
        }
        &--mobile{
            display: none;
            @media (max-width: $breakpoint-small-max) {
                display: flex;
            }
        }
        &--desktop{
            @media (max-width: $breakpoint-small-max) {
                display: none;
            }
        }
    }
    
}
.admin {
    &__title {
        display: flex;
        align-items: center;
        margin-bottom: 25px;
        justify-content: space-between;
        h1,h2,h3 {
            margin: 0;
        }
    }

    &__form-holder {
        display: flex;
        border-bottom: 1px solid $gray-500;
        justify-content: space-between;
        padding-bottom: 20px;
        margin-bottom: 40px;
        @media (min-width: $breakpoint-xsmall) {
            padding-bottom: 40px;
            margin-bottom: 60px;
        }
        @media (max-width: $breakpoint-xsmall-max) {
            flex-direction: column;
        }

      &--noline{
        border-bottom: none;
        padding-bottom: 0;
        margin-bottom: 0;
      }  
    }

    &__form-column {
        width: 38%;
        @media (max-width: $breakpoint-medium-max) {
            width: 46%;
        }
        @media (max-width: $breakpoint-xsmall-max) {
            width: 100%;
        }
        + .admin__form-column {
            width: 48.4%;
            @media (max-width: $breakpoint-xsmall-max) {
                width: 100%;
                order: -1;
                margin-bottom: 20px;
            }
        }
    }

    &__form-button {
        position: absolute;
        right: 32px;
        top: 42px;
        @media (max-width: $breakpoint-medium-max) {
          position: relative;
          right: 0;
          top: 0;
          margin-top: 20px;
          text-align: center;
        }
    }

    &__side {
        width: 100%;
        max-width: 250px;
        border: solid $gray-200;
        border-width: 0 1px 0 0;
        @media (max-width: $breakpoint-medium-max) {
            display: none;
        }

        @media (max-width: $breakpoint-small-max) {
            width: 100%;
            padding: 20px 16px 16px;
            border: 0;
            order: -1;
            z-index: 3;
        }

        &--alt {
            border-width: 0 0 0 1px;
            width: 300px;
            display: flex;
            flex-direction: column;

            @media (max-width: $breakpoint-small-max) {
                width: 100%;
                border-bottom: 1px solid $gray-200;
            }
        }

        &--back-button {
            @media (max-width: $breakpoint-small-max) {
                padding-top: 78px;
            }
        }

        &--mobile-hide {
            @media (max-width: $breakpoint-medium-max) {
                width: 0;
                padding: 0;
                border: 0;
            }
        }
    }

    &__side-sticky {
        background: #fff;

        @media (min-width: $breakpoint-small) {
            position: sticky;
            top: 0;
            z-index: 3;
            margin-top: -20px;
            padding: 20px 0 14px;
        }

        h6 {
            margin-bottom: 20px;
        }
    }

    &__side-holder {
        flex: 1 1 auto;
        position: relative;
        @media (max-width: $breakpoint-small-max) {
            max-height: 195px;
            overflow: auto;
            border-radius: 8px;
            background: $gray-400;
        }
    }

    &__side-inner {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        overflow: auto;
        @media (max-width: $breakpoint-small-max) {
            position: relative;
        }
        &::-webkit-scrollbar {
          width: 6px;
        }

        &::-webkit-scrollbar-thumb {
          background-color: $accent-color;
        }
    }

    &__side-nav {
        font-size: 12px;
        @media (max-width: $breakpoint-small-max) {
            display: flex;
            overflow: auto;
            font-size: 14px;
            padding: 8px 0 16px;
            border-bottom: 1px solid $gray-600;
        }

        > li {
            padding-bottom: 14px;
            @media (max-width: $breakpoint-small-max) {
                padding: 0 24px 0 0;
                white-space: nowrap;
            }
        }

        &-item {
            cursor: pointer;
            color: $base-color;
            transition: color .2s;

            &.active,
            &:hover {
                text-decoration: none;
                color: $accent-color;
            }

            @media (max-width: $breakpoint-small-max) {
                color: $gray-800;

                &.active,
                &:hover {
                    color: $base-color;
                    font-weight: bold;
                }
            }
        }
    }

    &__content {
        flex: 1 1 auto;
        padding: 0 15px 0 40px;
        min-height: calc(100vh - 50px);
        @media (max-width: $breakpoint-small-max) {
            padding: 20px 0;
        }

        &-holder {
            margin: 0 auto;
            width: 100%;
            &--large {
                max-width: 480px;
            }
            @media (max-width: $breakpoint-small-max) {
                max-width: 100%;
            }
        }

        &-section {
            &:not(:last-child) {
                padding: 0 0 48px;
                margin-bottom: 48px;
                border-bottom: 1px solid $gray-200;
                @media (max-width: $breakpoint-small-max) {
                    padding-bottom: 24px;
                    margin-bottom: 24px;
                }
            }

            h3 {
                margin-bottom: 40px;
            }

            h5 {
                margin-bottom: 24px;
            }
        }

        &--back-button {
            @media (max-width: $breakpoint-medium-max) {
                padding-top: 88px;
            }
            @media (max-width: $breakpoint-small-max) {
                padding-top: 20px;
            }
        }
    }

    &__search-box {
        position: relative;

        @media (max-width: $breakpoint-small-max) {
            margin-bottom: 4px;
        }

        &__input {
            width: 100%;
            border: 0;
            border-radius: 8px;
            background: $gray-200;
            padding: 0 0 0 32px;
            outline: none;
            font-size: 14px;
            line-height: 30px;
            color: #8C8C8C;
            height: 32px;
        }
        .icon-svg {
            position: absolute;
            left: 10px;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    &__welcome {
        text-align: center;
        padding-top: 80px;
    }

    &-notifications{
        margin-top: 40px;
        .form-group{
            max-width: 380px;
            @media (max-width: $breakpoint-small-max) {
                max-width: 100%;
            }
        }
    }

    &__plans {
        &__title {
            margin-bottom: 4px;
            font-size: 28px;
        }

        &__description {
            color: $gray-800;
            font-size: 16px;
            max-width: 624px;
            margin-bottom: 44px;
        }

        &__subtitle {
            font-size: 18px;
            margin-bottom: 16px;

            @media (min-width: $breakpoint-small) {
                margin-bottom: 24px;
            }
        }

        &__box {
            width: 100%;
            padding: 24px;
            margin-bottom: 30px;

            @media (min-width: $breakpoint-small) {
                margin-bottom: 0;
            }

            &-name-input {
                font-size: 20px;
            }

            &-description-input {
                pointer-events: none;
                touch-action: none;
            }

            &-textarea-wrap {
                textarea::-webkit-scrollbar {
                    width: 3px;
                }

                textarea::-webkit-scrollbar-track {
                    -webkit-box-shadow: none;
                }

                textarea::-webkit-scrollbar-thumb {
                    background-color: $link-color;
                }
            }

            &-label {
                font-size: 14px;
                margin-bottom: 12px;
            }
        }
        &__buttons{
          display: flex;
          justify-content: space-around;
          max-width: 380px;
          margin: 0 auto;
        }
    }
}
