.custom-select {
    width: 100%;
    background: $gray-200;
    border-radius: 12px !important;
    backface-visibility: hidden;
    
    .MuiSelect-icon,
    &::before {
        display: none !important;
    }
    
        &::after {
            position: absolute;
            content: "";
            left: auto;
            right: 14px;
            top: 50%;
            border: 0;
            transform: translateY(-50%);
            width: 12px;
            height: 8px;
            background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDEyIDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xMC45OTk5IDEuMTdDMTAuODEyNiAwLjk4Mzc1MyAxMC41NTkxIDAuODc5MjExIDEwLjI5NDkgMC44NzkyMTFDMTAuMDMwOCAwLjg3OTIxMSA5Ljc3NzMxIDAuOTgzNzUzIDkuNTg5OTUgMS4xN0w1Ljk5OTk1IDQuNzFMMi40NTk5NSAxLjE3QzIuMjcyNTkgMC45ODM3NTMgMi4wMTkxMyAwLjg3OTIxMSAxLjc1NDk1IDAuODc5MjExQzEuNDkwNzYgMC44NzkyMTEgMS4yMzczMSAwLjk4Mzc1MyAxLjA0OTk1IDEuMTdDMC45NTYyMiAxLjI2Mjk3IDAuODgxODI2IDEuMzczNTcgMC44MzEwNTcgMS40OTU0M0MwLjc4MDI4OCAxLjYxNzI5IDAuNzU0MTUgMS43NDc5OSAwLjc1NDE1IDEuODhDMC43NTQxNSAyLjAxMjAyIDAuNzgwMjg4IDIuMTQyNzIgMC44MzEwNTcgMi4yNjQ1OEMwLjg4MTgyNiAyLjM4NjQ0IDAuOTU2MjIgMi40OTcwNCAxLjA0OTk1IDIuNTlMNS4yODk5NSA2LjgzQzUuMzgyOTEgNi45MjM3MyA1LjQ5MzUxIDYuOTk4MTMgNS42MTUzNyA3LjA0ODg5QzUuNzM3MjMgNy4wOTk2NiA1Ljg2Nzk0IDcuMTI1OCA1Ljk5OTk1IDcuMTI1OEM2LjEzMTk2IDcuMTI1OCA2LjI2MjY3IDcuMDk5NjYgNi4zODQ1MyA3LjA0ODg5QzYuNTA2MzggNi45OTgxMyA2LjYxNjk5IDYuOTIzNzMgNi43MDk5NSA2LjgzTDEwLjk5OTkgMi41OUMxMS4wOTM3IDIuNDk3MDQgMTEuMTY4MSAyLjM4NjQ0IDExLjIxODggMi4yNjQ1OEMxMS4yNjk2IDIuMTQyNzIgMTEuMjk1NyAyLjAxMjAyIDExLjI5NTcgMS44OEMxMS4yOTU3IDEuNzQ3OTkgMTEuMjY5NiAxLjYxNzI5IDExLjIxODggMS40OTU0M0MxMS4xNjgxIDEuMzczNTcgMTEuMDkzNyAxLjI2Mjk3IDEwLjk5OTkgMS4xN1oiIGZpbGw9IiM4QzhDOEMiLz4KPC9zdmc+Cg==') no-repeat;
            background-size: cover;
            z-index: 1;
        }
        
        &.Mui-focused {
            &::after {
                transform: translateY(-50%);
            }
        }

    .MuiOutlinedInput-notchedOutline {
      border: none;
    }
    
    .MuiSelect-select {
        height: 56px;
        box-sizing: border-box;
        border-radius: 12px;
        padding: 16px;
        font-family: $font-family;
        font-size: 16px;
        line-height: 1.5;
        z-index: 2;
        @media (max-width: $breakpoint-small-max) {
            height: 48px;
            padding-top: 12px;
            padding-bottom: 12px;
            border-radius: 8px;
        }
        
        &:focus {
            border-radius: 8px;
        }
        &.MuiSelect-select {
            padding-right: 40px !important;
        }
    }
    
    // small size
    &--small {
        .MuiSelect-select {
            height: 40px;
            font-size: 14px;
            padding: 11px 16px;
            border-radius: 6px;
        }
    }
    
    // medium size
    &--medium {
        .MuiSelect-select {
            height: 48px;
            padding: 12px 16px;
            border-radius: 8px;
        }
    }
    
    &--auto {
        width: auto;
    }
    
    &--small {
        .MuiSelect-select {
            &:focus {
                border-radius: 8px;
            }
        }
    }

    &--color{
      color: $gray-800 !important;
    }
    
    &.custom-select--error {
        .MuiSelect-select {
            box-shadow: 0 0 0 1px $error-color;
        }
    }
}

.MuiMenu-list {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    .MuiMenuItem-root {
        font-family: $font-family;
        white-space: normal;
        display: flex;
        justify-content: flex-start;
        padding: 6px 16px;
        color: $gray-800;
    }
    .MuiFormControlLabel-root .MuiCheckbox-root{
        padding: 0;
    }
}
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
  border-radius: 12px;
  backface-visibility:hidden;
}