.overview {
    @media (max-width: $breakpoint-medium) {
        > div:last-child {
            margin-bottom: 0;
        }
    }
    
    &__title {
        margin: 15px 0 38px;
        @media (max-width: $breakpoint-medium-max) {
            margin: 0 0 20px;
        }
    }
    
    &__columns {
        display: flex;
        margin: 0 -16px 72px;
    
        @media (max-width: $breakpoint-medium) {
            margin: 0 -8px 32px;
        }
        
        @media (max-width: $breakpoint-small-max) {
            display: block;
            > div:not(:last-child) {
                padding-bottom: 32px;
            }
        }
    }
    
    &__column {
        flex: 1 1 auto;
        padding: 0 16px;
        display: flex;
        flex-direction: column;
        max-width: 100%;
    
        @media (max-width: $breakpoint-medium) {
            padding: 0 8px;
        }
        @media (min-width: $breakpoint-small) {
            flex: 1 1 66.66%;
            width: 66.66%;
        }
    
        &--small {
            flex: 0 0 33.33%;
        }
        &--slide {
            flex: 0 0 33.33%;
            @media (min-width: $breakpoint-small) {
                width: 33.33%;
            }
            & .slick-slider, 
              .slick-list {
                height: 100%;
            }
            & .slick-initialized .slick-slide {
                position: relative;
                padding: 0;
            }
            & .slick-track{
                height: 100%;
                @media (min-width: $breakpoint-small) {
                    min-height: 700px;
                }
                @media (min-width: $breakpoint-medium) {
                    min-height: 800px;
                }
                @media (min-width: $breakpoint-large) {
                    min-height: 900px;
                }
            }
        }
        > div {
            &:not(:last-child) {
                margin-bottom: 56px;
    
                @media (max-width: $breakpoint-medium) {
                    margin-bottom: 32px;
                }
            }
        }
    }
}