.author-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-radius: 8px;
    border: 1px solid $gray-200;
    padding: 10px 12px;
    
    &__title {
        font-size: 16px;
        font-weight: 600;
    }
    
    &__options {
        display: flex;
        align-items: center;
        > button {
            margin-left: 10px;
            border: 0;
            cursor: pointer;
            outline: none;
            width: 30px;
            height: 30px;
            background: $gray-200;
            border-radius: 8px;
        }
    }
}