.statistics {
    &__table {
        &_user {
            display: flex;
            align-items: center;
            white-space: nowrap;

            .user-avatar {
                margin-right: 12px;
            }

            &_name {
                font-weight: 600;
            }
        }

        &_sort {
            display: flex;
            align-items: center;
            cursor: pointer;

            svg {
                fill: #0F9AFF
            }
        }
    }

    &__title {
        font-size: 22px;

        @media (min-width: $breakpoint-small) {
            font-size: 28px;
        }
    }
}