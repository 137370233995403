.MuiTabs-root {
    margin-bottom: 30px;
    .MuiTabs-scroller {
        border-bottom: 1px solid $gray-200;
    }
    .MuiTabs-flexContainer {
        
        .MuiTab-root {
            flex: 0 0 auto;
            color: $gray-800;
            font-size: 16px;
            text-transform: none;
            font-family: $font-family;
            min-width: 40px;
            padding: 10px;
            margin-right: 24px;
            @media (max-width: $breakpoint-small-max) {
                font-size: 14px;
                margin-right: 32px;
                padding-right: 0;
            }
            
            &:hover {
                text-decoration: none;
                color: $base-color;
            }
            
            &.Mui-selected {
                color: $base-color;
                font-weight: 600;
            }
        }
    }
    
    .MuiTabs-indicator {
        background: $base-color;
        height: 1px;
    }
    
    .MuiTabScrollButton-root {
        display: none !important;
    }
    
    &.tabs-center {
        .MuiTabs-flexContainer {
            @media (min-width: $breakpoint-xsmall) {
                justify-content: center;
            }
        }
        .MuiTab-root {
            margin: 0 32px 0 0;
            padding-right: 0;
            @media (min-width: $breakpoint-xsmall) {
                margin: 0 26px;
            }
        }
    }

    &.MuiTabs-vertical{

        .MuiTabs-scroller {
            border-bottom: none;
        }
        .MuiTabs-flexContainer {
            align-items: flex-start;
            .MuiTab-root {
                flex: 0 0 auto;
                color: $gray-800;
                font-size: 14px;
                text-transform: none;
                font-family: $font-family;
                text-align: left;
                min-height: auto;
                padding: 6px 12px;
                margin-right: 0;
                @media (max-width: $breakpoint-small-max) {
                    font-size: 14px;
                    margin-right: 32px;
                    padding-right: 0;
                }
                
                &:hover {
                    text-decoration: none;
                    color: $accent-color;
                }
                
                &.Mui-selected {
                    color: $accent-color;
                    font-weight: 700;
                }
            }
        }
        .MuiTabs-indicator {
            display: none;
        }
    }
}

.tab__modal-wrap{
    height: 320px;
    margin-bottom: 30px;
    overflow: auto;
    .MuiTabs-root.MuiTabs-vertical .MuiTabs-flexContainer .MuiTab-root{
        margin: 0;
        padding: 8px 12px;
        width: 100%;
        &.Mui-selected{
            background-color: #FFEFEA;
        }
    }
    .MuiTab-wrapper{
        align-items: flex-start;
    }
}

.tabs-holder {
    position: relative;
}