.blog {
    &__list {
        margin-bottom: -8px;
        
        @media (min-width: $breakpoint-xsmall) {
            margin-bottom: -16px;
        }
        
        @media (min-width: $breakpoint-medium) {
            margin-bottom: -32px;
        }
    
        @media (max-width: $breakpoint-small-max) {
            > div {
                width: 50%;
            }
        }
    
        @media (max-width: $breakpoint-xsmall-max) {
            > div {
                width: 100%;
                padding: 20px 0;
                
                &:not(:last-child) {
                    border-bottom: 1px solid $gray-600;
                }
            }
        }
    }
    
    &__add-list {
        @media (max-width: $breakpoint-xsmall-max) {
            > div {
                width: 100%;
                padding: 20px 0;
                border-top: 1px solid $gray-600;
            }
        }
    }
    
    &__tags {
        margin: 0 -16px 32px;
        font-size: 16px;
        display: flex;
        overflow: auto;
        padding: 0 16px 16px;
         &::-webkit-scrollbar {
           height: 6px;
         }

         &::-webkit-scrollbar-thumb {
           background-color: $link-color;
         }
        @media (max-width: $breakpoint-xsmall-max) {
            font-size: 14px;
            margin-bottom: 26px;
        }
        
        &-item {
            margin-right: 8px;
            color: $link-color;
            display: inline-flex;
            white-space: nowrap;
            padding: 8px 16px;
            border-radius: 8px;
            background: $gray-200;
            cursor: pointer;
            
            &--selected {
                background: $link-color;
                color: #fff;
            }
        }
    }
    
    &__grid {
        @media (min-width: $breakpoint-small) {
            margin-bottom: 80px;
        }
    
        @media (max-width: $breakpoint-small-max) {
            > div {
                margin-bottom: 28px;
            }
        }
    
        @media (max-width: $breakpoint-xsmall-max) {
            > div {
                margin-bottom: 0;
            }
        }
    }
    
    &__slider {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: auto;
        margin: 0 -8px 60px;
        @media (max-width: $breakpoint-small-max) {
            display: none;
        }
        @media (min-width: $breakpoint-medium) {
            margin: 0 -16px 110px;
        }
        
        .slick-slide {
            @media (min-width: $breakpoint-medium) {
                padding: 0 16px;
            }
    
            > div {
                display: flex;
                > div {
                    display: flex !important;
                }
            }
        }
        
        .slick-list  {
            order: -1;
            width: 100%;
            margin-bottom: 60px;
            overflow: hidden;
        }
        
        .slick-arrow {
            position: relative;
            left: auto;
            right: auto;
            top: auto;
            border: 1px solid $gray-800;
            background: none;
            transform: translate(0);
            &.slick-prev {
                order: 2;
                margin-right: 4px;
            }
            &.slick-next {
                order: 4;
                margin-left: 4px;
            }
        }
        
        .slick-dots {
            margin: 0;
            order: 3;
            li {
                button {
                    height: 32px;
                    width: 32px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 50%;
                    color: $base-color;
                    background: none;
                    font-size: 16px;
                }
                
                &.slick-active {
                    button {
                        font-weight: 600;
                        background: $link-color;
                        color: white;
                    }
                }
            }
        }
    }
    
    &__button {
        padding: 30px 0 50px;
        text-align: center;
    }
    
    &__no-post {
        text-align: center;
        padding: 30px 0;
    }
    
    &__add-nav {
        position: absolute;
        right: 32px;
        top: 40px;
        display: flex;
        @media (max-width: $breakpoint-medium-max) {
            right: 16px;
        }
        @media (max-width: $breakpoint-small-max) {
            top: 32px;
        }
        > button {
            margin-left: 16px;
        }
    }
}