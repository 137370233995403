.profile {
    text-align: center;
    padding: 26px 0 60px;
    @media (max-width: $breakpoint-medium-max) {
        padding-bottom: 30px;
    }
    
    @media (max-width: $breakpoint-small-max) {
        padding-top: 39px;
    }
    
    &__title {
        font-size: 28px;
        line-height: 1.3;
        font-weight: 600;
        font-family: $font-family-heading;
        margin: 0 0 13px;
        @media (max-width: $breakpoint-small-max) {
            font-size: 22px;
        }
    }
    
    &__avatar {
        margin: 0 auto 23px;
        width: 96px;
        height: 96px;
        border-radius: 50%;
        @media (max-width: $breakpoint-small-max) {
            width: 72px;
            height: 72px;
            margin-bottom: 18px;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__h1 {
        font-size: 28px;
        margin-bottom: 4px;

        @media (min-width: $breakpoint-medium) {
            margin-bottom: 16px;
            font-size: 44px;
        }
    }

    &__subtitle {
        font-size: 18px;
        margin-bottom: 4px;

        @media (min-width: $breakpoint-medium) {
            margin-bottom: 8px;
        }
    }

    &__description {
        margin-bottom: 44px;

        @media (min-width: $breakpoint-medium) {
            margin-bottom: 56px;
        }
    }

    &__caption {
        font-size: 14px;
        margin-bottom: 24px;

        @media (min-width: $breakpoint-medium) {
            font-size: 16px;
        }
    }

    &__submit {
        width: 100%;
        margin-bottom: 30px !important;

        @media (min-width: $breakpoint-medium) {
            width: auto;
            margin-bottom: 82px !important;
        }
    }
    
    &__email {
        color: $gray-800;
    }
    
    &__tabs {
        @media (min-width: $breakpoint-medium) {
            .MuiTabs-root {
                margin-bottom: 47px;
            }
        }
    }

    &__task-plan {
        margin-top: 44px;

        @media (min-width: $breakpoint-medium) {
            margin-top: 76px;
        }
    }

    &__schedule {
        &-list {
            margin: 18px auto 32px auto;
            max-width: 1140px;

            @media (min-width: $breakpoint-medium) {
                margin-bottom: 44px;
            }
        }

        &-item {
            display: flex;
            flex-direction: column;
            height: 100%;
            padding: 24px;
            font-size: 14px;
            line-height: 1.3;
            color: #8C8C8C;
            background: #F9F9F9;
            border-radius: 12px;
            transition: background-color .2s, box-shadow .2s;

            &_selected {
                background-color: #ffffff;
                box-shadow: $box-shadow;
            }

            $this: &;

            &__title {
                display: inline-block;
                color: #ffffff;
                font-size: 20px;
                font-weight: 600;
                padding: 8px 14px;
                margin-bottom: 16px;
                background-color: $link-color;
                border-radius: 9px;

                #{$this}_accent & {
                    background-color: $accent-color;
                }

                @media (min-width: $breakpoint-medium) {
                    margin-bottom: 12px;
                }
            }

            &__subtitle {
                font-weight: 400;
                font-size: 14px;
                margin-bottom: 30px;
                color: $link-color;

                #{$this}_accent & {
                    color: $accent-color;
                }

                @media (min-width: $breakpoint-medium) {
                    margin-bottom: 20px;
                }
            }

            &__content {
                display: flex;
                flex-direction: column;
                flex-grow: 1;
            }

            &__description {
                margin-bottom: 20px;

                @media (min-width: $breakpoint-medium) {
                    margin-bottom: 15px;
                }
            }

            &__numbers {
                color: $base-color;
                margin-top: auto;
            }

            &__button-wrap {
                position: relative;
                padding-top: 20px;
                margin-top: 20px;

                @media (min-width: $breakpoint-medium) {
                    padding-top: 40px;
                    margin-top: 26px;
                }

                > span {
                    display: flex;
                    align-items: center;
                    margin-bottom: 10px;

                    @media (min-width: $breakpoint-medium) {
                        position: absolute;
                        top: 0;
                        left: 0;
                    }
                }
            }

            &__button {
                background-color: transparent !important;
                color: $gray-800 !important;

                #{$this}_selected & {
                    color: $base-color !important;
                }
            }
        }
    }
}