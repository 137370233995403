.MuiIconButton-root {
    &.MuiButtonBase-root {
        padding: 0;
        border-radius: 8px;
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $gray-200;
        color: $gray-800;
        line-height: 1;
        &:hover {
            background: $gray-600;
            color: $base-color;
        }
        
        &.form__input-icon {
            width: auto;
            height: auto;
            background: none;
            &:hover {
                background: none;
            }
        }
    }
    
    &.MuiIconButton-default {
        background: none;
        &:hover {
            background: none;
        }
    }
    
    &.MuiIconButton-sizeSmall {
        &.MuiButtonBase-root {
            //width: 16px;
            //height: 16px;
            .MuiSvgIcon-root {
                font-size: 1.2rem;
            }
        }
    }
    
    &.icon-new {
        position: relative;
        &::before {
            top: -1px;
            right: -5px;
            position: absolute;
            width: 10px;
            height: 10px;
            background: $success-color;
            border: 2px solid #fff;
            border-radius: 50%;
            content:'';
        }
    }
}