.term {
    position: relative;
    font-size: 16px;
    
    &__content {
        width: 100%;
        padding: 100px 16px 20px;
        @media (min-width: $breakpoint-medium) {
            display: flex;
        }

        .back-button { 
            top: 110px;
    
            @media (max-width: $breakpoint-medium-max) {
                position: absolute;
                left: 16px;
                top: 100px;
                .back-button__text {
                    line-height: 0;
                    font-size: 0;
                }
            }
        }
    }
}