.permission{

    &__modal{
        .modal__content{
            margin: 15px auto 25px;
            max-width: 420px;
        }
        .modal__buttons .MuiButton-root.MuiButtonBase-root {
            min-width: 220px;
        }
        p {
            font-size: 16px;
            color: $gray-800;
        }
    }
}
    