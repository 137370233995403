.splash {
    flex-direction: column;
    display: flex;
    position: relative;
    min-height: 100vh;
    padding: 80px 16px;
    @media (min-width: $breakpoint-xsmall) {
        padding-bottom: 20px;
    }
    @media (min-width: $breakpoint-small) {
        padding: 100px 20px 60px;
        text-align: center;
        justify-content: center;
    }
    
    &__logo {
        top: 25px;
        left: 16px;
        position: absolute;
        @media (min-width: $breakpoint-small) {
            left: 25px;
        }
    }
    
    &__content {
        max-width: 780px;
        margin: 0 auto;
    }
    
    &__title {
        max-width: 600px;
        @media (min-width: $breakpoint-small) {
            margin: 0 auto 48px;
        }
        &--plan{
            text-align: left;
        }
    }
    
    &__items {
        margin-bottom: 24px;
        > div {
            padding-bottom: 8px;
        }
    
        @media (min-width: $breakpoint-small) {
            display: flex;
            flex-wrap: wrap;
            margin: 0 -8px 24px;
            > div {
                width: 33.33%;
                padding: 0 8px 16px;
            }
        }
        @media (min-width: $breakpoint-medium) {
            > div {
                width: 25%;
            }
        }
    }

    &__plan-items {
        margin-bottom: 24px;
        > div {
            padding-bottom: 8px;
        }
    
        @media (min-width: $breakpoint-small) {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            margin: 0 -8px 24px;
            > div {
                width: 45%;
                padding: 0 8px 16px;
            }
        }
    }
    
    &__item {
        text-align: left;
        cursor: pointer;
        border-radius: 12px;
        background: $gray-300;
        color: $gray-800;
        font-weight: 500;
        font-size: 14px;
        line-height: 1.3;
        font-family: $font-family-heading;
        display: flex;
        align-items: center;
        width: 100%;
        padding: 14px;
        transition: background-color .2s, box-shadow .2s;
    
        @media (min-width: $breakpoint-small) {
            padding: 21px 12px;
        }
        
        &--selected {
            background: #fff;
            color: $base-color;
            box-shadow: $box-shadow;
            -webkit-tap-highlight-color: transparent;
        }
    }
    
    &__item-icon {
        margin-right: 16px;
        width: 40px;
        flex: 0 0 40px;
    }
    
    &__buttons {

        &--settings{
            text-align: center;
        }

        > div {
            padding-bottom: 16px;
        }
        @media (max-width: $breakpoint-small-max) {
            display: flex;
            justify-content: center;
            > div {
                padding: 0 4px;
            }
        }
    
        @media (max-width: $breakpoint-xsmall-max) {
            position: fixed;
            left: 0;
            right: 0;
            bottom: 0;
            padding: 24px 12px;
            background: linear-gradient(to bottom, transparent 0%, rgba(255, 255, 255, .4) 10%, #ffffff 100%);

            &--settings{
                position: relative;
            }
            > div {
                flex: 1 1 auto;
                button {
                    width: 100%;
                }
            }
        }
    }
}