.task-card {
    position: relative;
    border-radius: 12px;
    background: $gray-400;
    transition: background-color .2s, box-shadow .1s;
    display: flex;
    flex-direction: column;
    width: 100%;
    font-size: 14px;
    &:hover {
        cursor: pointer;
        background: #fff;
        box-shadow: $box-shadow;
        @media (min-width: $breakpoint-medium) {
            .task-card__priority-text {
                opacity: 1;
                min-width: 75px;
            }
            .MuiRating-root {
                display: none;
            }
        }
    }

    &--default {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        background: transparent;
        width: auto;

        & .task-card__menu {
            top: 0;
            right: 0;
        }

        & .task-card__toggler {
            padding: 1px 6px;
        }
    }
    
    &__inner {
        padding: 20px 16px;
        display: flex;
        flex-direction: column;
        color: $base-color;
        @media (min-width: $breakpoint-medium) {
            padding: 20px 24px;
            min-height: 224px;
        }
        &:hover {
            text-decoration: none;
        }
    }
    
    &__top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 12px;
        @media (max-width: $breakpoint-medium-max) {
            margin-bottom: 20px;
        }
    }
    
    &__holder {
        position: relative;
        @media (min-width: $breakpoint-medium) {
            padding: 4px 0 0 72px;
            &--alt {
                padding-top: 21px;
            }
        }
    }
    
    &__status {
        display: inline-flex;
        align-items: center;
        font-size: 14px;
        line-height: 1;
        min-height: 16px;
        &::before {
            margin-right: 8px;
            width: 16px;
            height: 16px;
            content:'';
            display: block;
            border-radius: 4px;
            background: $success-color;
        }
        &--edited {
            &::before {
                background: $link-color;
            }
        }
        &--closed {
            &::before {
                background: #E33544;
            }
        }
        &--updated {
            &::before {
                background: $accent-color;
            }
        }
        &--viewed {
            &::before {
                display: none;
            }
        }
    }
    
    &__substatus {
        color: $gray-800;
        font-size: 12px;
        margin-bottom: 4px;
    }
    
    &__info {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 7px;
    }
    
    &__category {
        color: $link-color;
        padding: 2px 9px;
        border-radius: 2px;
        background: rgba($link-color, .15);
        margin: 5px 20px 5px 0;
    }
    
    &__title {
        margin-bottom: 7px;
        display: flex;
        align-items: center;
        @media (max-width: $breakpoint-medium-max) {
            margin-bottom: 11px;
            align-items: flex-start;
        }
        h5 {
            overflow: hidden;
            margin: 0;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            @media (max-width: $breakpoint-medium-max) {
                -webkit-line-clamp: 2;
            }
        }
    }
    
    &__image {
        width: 56px;
        height: 56px;
        flex: 0 0 56px;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 16px;
        @media (min-width: $breakpoint-medium) {
            position: absolute;
            left: 0;
            top: 0;
        }
        @media (max-width: $breakpoint-medium-max) {
            width: 40px;
            height: 40px;
            flex: 0 0 40px;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    
    &__side {
        max-width: 100%;
        flex: 1 1 auto;
    }
    
    &__content {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        max-height: 44px;
        p{
          margin-bottom: 0;
        }
    }
    
    &__priority-text {
        display: inline-flex;
        align-items: center;
        font-weight: 600;
        opacity: 0;
        height: 0;
        .icon-svg {
            margin-right: 8px;
        }
    }
    
    &__menu {
        position: absolute;
        top: 16px;
        right: 25px;
        z-index: 2;
        &--active{
          z-index: 3;
        }
        button {
            cursor: pointer;
            border: 0;
            background: none;
            padding: 0;
            color: $gray-800;
            outline: none;
            path {
                stroke: currentColor;
            }
            &:focus {
                color: $base-color;
            }
        }
    }
    
    &__menu-drop {
        position: absolute;
        right: -12px;
        top: 100%;
        width: 120px;
        background: $gray-200;
        padding: 2px 8px;
        transition: opacity .2s;
        border-radius: 6px;
        opacity: 0;
        visibility: hidden;
        box-shadow: $box-shadow;
        &::after {
            position: absolute;
            top: -16px;
            right: 16px;
            content:'';
            border: 8px solid transparent;
            border-bottom-color: $gray-200;
        }
        &--open {
            opacity: 1;
            visibility: visible;
            z-index: 1;
        }
        ul {
            li {
                padding: 5px 0;
                &:not(:last-child) {
                    border-bottom: 1px solid $gray-600;
                }
            }
        }
        button {
            display: block;
            width: 100%;
            font-size: 14px;
            text-align: left;
            color: $base-color;
            padding: 4px 8px;
            &:hover {
                text-decoration: underline;
            }
        }
    }
    
    &--small {
        padding-top: 25px;
    }

    &--switch{
       & .task-card {
            @media (min-width: $breakpoint-medium) {
                &__inner{
                    padding: 10px 60px 15px 15px;
                } 
                &__image {
                    width: 50px;
                    height: 50px;
                    flex: 0 0 50px;
                    margin-right: 10px;
                } 
                &__holder {
                    padding: 4px 0 0 64px;
                }
                &__content {
                  -webkit-line-clamp: 3;
                }
            }   
            @media (max-width: $breakpoint-medium-max) {
                &__inner{
                    padding: 10px 60px 15px 15px;
                }
            }
       }  
    }
    &--simple {
        cursor: default;
        &:hover {
            box-shadow: none;
            background: $gray-400;
        }

        .task-card__title h5 {
            display: block;
        }
    }
}