.pdf-download {
    position: relative;
    @media (max-width: $breakpoint-medium-max) {
        display: inline-block;
    }
    
    &__drop {
        position: absolute;
        right: 0;
        top: calc(100% + 10px);
        width: 184px;
        background: $gray-200;
        padding: 2px 8px;
        transition: opacity .2s;
        border-radius: 6px;
        opacity: 0;
        visibility: hidden;
        box-shadow: $box-shadow;
        
        @media (max-width: $breakpoint-medium-max) {
            top: auto;
            bottom: calc(100% + 15px);
            box-shadow: none;
        }
        
        &::after {
            position: absolute;
            top: -16px;
            right: 16px;
            content: '';
            border: 8px solid transparent;
            border-bottom-color: $gray-200;
            
            @media (max-width: $breakpoint-medium-max) {
                top: auto;
                border-bottom-color: transparent;
                border-top-color: $gray-200;
            }
        }
        
        &--open {
            opacity: 1;
            visibility: visible;
        }
        
        ul {
            li {
                padding: 5px 0;
            
                &:not(:last-child) {
                    border-bottom: 1px solid $gray-600;
                }
            }
        }
        
        button {
            display: block;
            width: 100%;
            font-size: 14px;
            text-align: left;
            color: $base-color;
            padding: 4px 8px;
            border: 0;
            background: none;
        
            &:hover {
                text-decoration: underline;
                cursor: pointer;
            }
        }
    }
}