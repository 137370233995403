.variable-card {
    font-size: 16px;
    padding: 10px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $gray-200;
    
    @media (max-width: $breakpoint-small-max) {
        padding: 8px 0 8px 8px;
    }
    
    &__title {
        color: $accent-color;
        background: none !important;
        padding: 0;
        border: 0;
        height: 20px;
        resize: none;
        outline: none;
        flex: 1 1 auto;
        font-size: 13px;
    }
    
    &__buttons {
        position: relative;
        display: flex;
        align-items: center;
        > * {
            margin-left: 10px !important;
        }
    
        .MuiSvgIcon-root {
            font-size: 1.2rem;
        }
    
        button {
            border: 0;
            background: none;
            padding: 0;
            cursor: pointer;
        }
    }
    
    &__copy {
        font-size: 12px;
        position: absolute;
        right: 30px;
        top: -10px;
        font-style: italic;
    }
}