.password-strength {
    font-size: 14px;
    @media (min-width: $breakpoint-medium) {
        margin-bottom: 20px;
    }
    
    &__title {
        font-weight: 600;
        margin-bottom: 11px;
    }
    
    &__list {
        li {
            padding-bottom: 12px;
            display: flex;
            align-items: center;
            svg {
                margin-right: 12px;
                width: 17px;
                height: 17px;
                color: $gray-700;
            }
            &.done {
                svg {
                    color: $success-color;
                }
            }
        }
    }
}

.form-group + .password-strength {
    margin-top: -12px;
}