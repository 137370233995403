.loading {
    height: 300px;
    width: 100% !important;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (min-width: $breakpoint-medium) {
        height: 600px;
    }
    
    &--small {
        height: 250px;
    }
    
    &--xsmall {
        height: 50px;
    }
    
    &--position {
        position: absolute;
        left: 50%;
        top: 50%;
        z-index: 10;
        transform: translate(-50%, -50%);
    }
    
    .MuiCircularProgress-colorPrimary {
        color: $accent-color;
    }
}