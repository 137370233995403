.blog-card {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    flex: 1 1 auto;
    transition: all .3s ease;
    @media (max-width: $breakpoint-xsmall-max) {
        &:not(.blog-card--large) {
            flex-direction: row;
            
            .blog-card {
                &__image {
                    height: auto;
                    flex: 0 0 36%;
                    margin: 0 16px 0 0;
                }
                
                &__button {
                    display: none;
                }
    
                &__content {
                    padding: 0;
                }
            }
        }
    }
    
    &__image {
        //background: $gray-600;
        border-radius: 12px;
        margin-bottom: 24px;
        overflow: hidden;
        height: 280px;
        
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    
    &__content {
        position: relative;
        padding-bottom: 27px;
        flex: 1 1 auto;
    }
    
    &__title {
        display: flex;
        color: $base-color;
        font-family: $font-family-heading;
        font-weight: 600;
        margin: 0 0 8px;
        font-size: 14px;
        line-height: 1.3;
        @media (min-width: $breakpoint-medium) {
            font-size: 18px;
        }
        &:hover {
            text-decoration: none;
            color: $gray-800;
        }
    }
    
    &__category {
        background: $link-color;
        color: #fff;
        border-radius: 4px;
        padding: 2px 8px;
        display: inline-flex;
        font-size: 14px;
        margin-bottom: 16px;
        @media (max-width: $breakpoint-xsmall-max) {
            font-size: 12px;
            margin-bottom: 10px;
        }
    }
    
    &__button {
        position: absolute;
        bottom: 0;
        left: 0;
        color: $gray-800;
        font-weight: 600;
        @media (max-width: $breakpoint-small-max) {
            font-size: 14px;
        }
        &:hover {
            color: $accent-color;
            text-decoration: none;
        }
    }
    
    &__drop-button {
        position: absolute;
        bottom: 0;
        right: 6px;
        z-index: 2;
        cursor: pointer;
        border: 0;
        background: none;
        padding: 0;
        color: $gray-800;
        outline: none;
        path {
            stroke: currentColor;
        }
        &:focus {
            color: $base-color;
        }
    }
    
    &__drop {
        position: absolute;
        right: -6px;
        top: calc(100% + 10px);
        z-index: 3;
        width: 120px;
        background: $gray-200;
        padding: 2px 8px;
        transition: opacity .2s;
        border-radius: 6px;
        opacity: 0;
        visibility: hidden;
        box-shadow: $box-shadow;
        &::after {
            position: absolute;
            top: -16px;
            right: 16px;
            content:'';
            border: 8px solid transparent;
            border-bottom-color: $gray-200;
        }
        &--open {
            opacity: 1;
            visibility: visible;
        }
        ul {
            li {
                padding: 5px 0;
                &:not(:last-child) {
                    border-bottom: 1px solid $gray-600;
                }
            }
        }
        button {
            cursor: pointer;
            display: block;
            width: 100%;
            font-size: 14px;
            text-align: left;
            color: $base-color;
            padding: 4px 8px;
            background: none;
            border: 0;
            &:hover {
                text-decoration: underline;
            }
        }
    }
    
    &--small {
        flex-direction: row;
        max-height: 240px;
        font-size: 16px;
        @media (max-width: $breakpoint-medium-max) {
          font-size: 14px;
        }
        .blog-card {
            @media (max-width: $breakpoint-xsmall-max) {
                align-items: center;
            }
            
            &__image {
                margin: 0 16px 0 0;
                flex: 0 0 33%;
                height: auto;
    
                @media (min-width: $breakpoint-small) {
                    flex: 0 0 46%;
                }
    
                @media (min-width: $breakpoint-large) {
                    margin-right: 32px;
                }
            }
            
            &__title {
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
            
            &__description {
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
            }
            
            &__content {
                @media (max-width: $breakpoint-xsmall-max) {
                    padding: 0;
                }
            }
            
            &__button {
                @media (max-width: $breakpoint-xsmall-max) {
                    display: none;
                }
            }
        }
    }
    
    &--large {
        .blog-card {
            &__content {
                padding-bottom: 60px;
            }
            
            &__image {
                height: 232px;
                @media (min-width: $breakpoint-small) {
                    height: 312px;
                }
            }
            
            &__title {
                font-size: 18px;
                margin-bottom: 17px;
                @media (min-width: $breakpoint-small) {
                    font-size: 22px;
                }
            }
            
            &__button {
                border-radius: 12px;
                color: white;
                background: $accent-color;
                padding: 12px 27px;
                @media (max-width: $breakpoint-xsmall-max) {
                    padding: 10px 20px;
                }
                
                &:hover {
                    background: darken($accent-color, 5%);
                }
            }
        }
    }
    &--hide {
        background-color: $gray-200;
        border-radius: 12px;
        .blog-card {
            &__title,
            &__category,
            &__description,
            &__button{
                opacity: .4;
                pointer-events: none;
            }
            &__image{
                opacity: .4;
                pointer-events: none;
            }
        }
    }
}