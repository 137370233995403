.dropzone {
    border: 1px dashed $gray-500;
    border-radius: 12px;
    padding: 16px;
    
    &__holder {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 328px;
        border-radius: 8px;
        background: linear-gradient(148.27deg, #F9F9F9 19.11%, rgba(249, 249, 249, 0) 80.48%);
        outline: none;
        @media (max-width: $breakpoint-small-max) {
            height: 270px;
        }

        &--error{
          position: absolute;
          bottom: 10px;
          color: $secondary-color;
        }
    }
    
    &__preview {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        overflow: hidden;
        border-radius: 8px;
        background: #fff;
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    
    .icon-svg {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: rgba($link-color, .3);
        margin: 0 0 20px;
    }
}