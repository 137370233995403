.company {
    display: flex;
    flex-direction: column;

    &__table{
        display: flex;
    }

    &__title{
        margin: 40px 0 0;
    }

    &__back-button {
        margin: 0;
    }

    &__subtitle{
        margin: 8px 0 25px;
        color: $gray-800;
        font-size: 14px;
        line-height: 1.2;
        max-width: 360px;
        &--success{
            font-size: 14px;
            font-weight: 600;
            color: $link-color;
            margin: -5px 0 10px;
        }
        &--error{
          font-size: 14px;
          font-weight: 600;
          color: $secondary-color;
          margin: -5px 0 10px;
        }
    }

    .search .search__title {
        @media (max-width: $breakpoint-medium-max) {
            border-bottom: none;
            padding: 0;
        }
    }

    &-column__name {
        width: 100%;
        max-width: 350px;
        min-width: 180px;
        & .hover {
          background: $gray-400;
        }
    }

    &-column__other {
        width: 100%;
        @media (max-width: $breakpoint-medium-max) {
            overflow-x: auto;
            overflow-y: hidden;
        }
        & .hover {
          background: $gray-400;
        }
    }

    &__modal{
        margin-bottom: 35px;
        >p{
           color: $gray-800; 
           font-size: 16px;
        }
        &-icon{
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto 10px;
            height: 45px;
            width: 45px;
            border-radius: 50%;
            overflow: hidden;
            background: $gray-200;
            > img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
        &--filter{
            > h2 {
                margin-bottom: 0;
            }
            .filter-panel{
                display: flex;
                gap: 10px;

                &__tab {
                    flex: 0 0 50%;
                    > p {
                        color: $gray-800; 
                    }
                  & .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
                    display: none;
                  }
                }
            }
            .MuiFormGroup-root .MuiFormControlLabel-root{
                position: relative;
                display: flex;
                align-items: center;
                height: 55px;
                margin: 0;
                color: $gray-800;
                .MuiIconButton-label {
                    display: none;
                }
            }
            .MuiFormGroup-root .MuiButtonBase-root.MuiRadio-root{
                width: 0;
                height: 0;
                flex: none;
                margin: 0;
                padding: 0 0 0 15px;
                &.Mui-checked{
                    width: 100%;
                    height: 55px;
                    position: absolute;
                    left: 0;
                    border-radius: 8px;
                    background: #E9F6FF;
                    & + .MuiFormControlLabel-label {
                        color: $link-color;
                        padding: 0 0 0 15px;
                        z-index: 1;
                    }
                }
            }
            .MuiFormGroup-root .MuiButtonBase-root.MuiRadio-root.Mui-checked::after{
                opacity: 0;
                visibility: hidden;
            }
        }
        &--search{
            >h3{
                margin-bottom: 0;
            }
            >p{
                color: $gray-800; 
                font-size: 16px;
             }
             .search-user{
                 &__input{
                     height: 42px;
                     font-size: 14px;
                 }
                &__box > .icon-svg{
                    top: 14px;
                    right: 12px;
                } 
                &__result{
                    width: 100%;
                    margin-bottom: 20px;
                }
                &__result-item{
                    max-width: 100%;
                    padding: 4px 10px;
                    &:hover{
                        background: #E9F6FF;
                    }

                    button{
                        font-size: 16px;
                    }
                    .result-item__logo{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 30px;
                        height: 30px;
                        margin-right: 12px;
                    }
                }
             }    
        }
    }
    

    .column__name {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 600;
        height: 60px;
        padding: 8px 5px;
        &:not(:first-child) {
          animation: fadeIn 0.4s linear;
          animation-fill-mode: both;
        }

        > a {
            color: inherit;
            text-decoration: none;
            display: flex;
            align-items: center;
            line-height: 1;
        }
        &:not(:last-child) {
            border-bottom: 1px solid $gray-200;
        }
        @media (max-width: $breakpoint-medium-max) {
            border-right: 1px solid $gray-200;
        }

        &-logo{
            flex: 0 0 auto;
            height: 38px;
            width: 38px;
            border-radius: 50%;
            overflow: hidden;
            margin-right: 10px;
            >img{
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }

    .column__other {
        display: flex;
        height: 60px;
        min-width: 620px;
        font-size: 14px;
        &:not(:first-child){
          animation: fadeIn 0.4s linear;
          animation-fill-mode: both;
        }

        &:not(:last-child) {
            border-bottom: 1px solid $gray-200;
        }

        & > div {
            display: flex;
            align-items: center;
            padding: 8px 5px;
            line-height: 1;
            @media (max-width: $breakpoint-medium-max) {
                border-right: 1px solid $gray-200;
            }
        }

        &--date {
            flex: 1 0 210px;
        }    
        &--owner {
            flex: 1 0 210px;
            cursor: default;
        }
        &--status {
            flex: 1 0 100px;
            color: $gray-800;
            &.status-blue{
                color: $link-color;
            }
        }
        &--control {
            flex: 1 0 100px;
            justify-content: right;
             > button{
                margin-left: 10px;
                border: 0;
                cursor: pointer;
                outline: none;
                width: 30px;
                height: 30px;
                background: $gray-200;
                border-radius: 8px;

                &:disabled {
                  cursor: not-allowed;
                  opacity: .4;
                }
            }
        }
    }

    & .titles {
        font-size: 14px;
        color: $gray-800;
        min-height: auto;
        height: 40px;
        border: none !important;
    }
    & .titles-button{
        display: flex;
        align-items: center;
        position: relative;
        font-size: 14px;
        color: $gray-800;
        min-height: auto;
        height: 40px;
        border: none !important;
        background: transparent;
        cursor: pointer;
        text-align: left;
        &:disabled .title-arrow{
            display: none;
        }
    }

    & .title-arrow {
        margin-left: 5px;
        width: 12px;
        height: 9px;
        background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDEyIDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xMC45OTk5IDEuMTdDMTAuODEyNiAwLjk4Mzc1MyAxMC41NTkxIDAuODc5MjExIDEwLjI5NDkgMC44NzkyMTFDMTAuMDMwOCAwLjg3OTIxMSA5Ljc3NzMxIDAuOTgzNzUzIDkuNTg5OTUgMS4xN0w1Ljk5OTk1IDQuNzFMMi40NTk5NSAxLjE3QzIuMjcyNTkgMC45ODM3NTMgMi4wMTkxMyAwLjg3OTIxMSAxLjc1NDk1IDAuODc5MjExQzEuNDkwNzYgMC44NzkyMTEgMS4yMzczMSAwLjk4Mzc1MyAxLjA0OTk1IDEuMTdDMC45NTYyMiAxLjI2Mjk3IDAuODgxODI2IDEuMzczNTcgMC44MzEwNTcgMS40OTU0M0MwLjc4MDI4OCAxLjYxNzI5IDAuNzU0MTUgMS43NDc5OSAwLjc1NDE1IDEuODhDMC43NTQxNSAyLjAxMjAyIDAuNzgwMjg4IDIuMTQyNzIgMC44MzEwNTcgMi4yNjQ1OEMwLjg4MTgyNiAyLjM4NjQ0IDAuOTU2MjIgMi40OTcwNCAxLjA0OTk1IDIuNTlMNS4yODk5NSA2LjgzQzUuMzgyOTEgNi45MjM3MyA1LjQ5MzUxIDYuOTk4MTMgNS42MTUzNyA3LjA0ODg5QzUuNzM3MjMgNy4wOTk2NiA1Ljg2Nzk0IDcuMTI1OCA1Ljk5OTk1IDcuMTI1OEM2LjEzMTk2IDcuMTI1OCA2LjI2MjY3IDcuMDk5NjYgNi4zODQ1MyA3LjA0ODg5QzYuNTA2MzggNi45OTgxMyA2LjYxNjk5IDYuOTIzNzMgNi43MDk5NSA2LjgzTDEwLjk5OTkgMi41OUMxMS4wOTM3IDIuNDk3MDQgMTEuMTY4MSAyLjM4NjQ0IDExLjIxODggMi4yNjQ1OEMxMS4yNjk2IDIuMTQyNzIgMTEuMjk1NyAyLjAxMjAyIDExLjI5NTcgMS44OEMxMS4yOTU3IDEuNzQ3OTkgMTEuMjY5NiAxLjYxNzI5IDExLjIxODggMS40OTU0M0MxMS4xNjgxIDEuMzczNTcgMTEuMDkzNyAxLjI2Mjk3IDEwLjk5OTkgMS4xN1oiIGZpbGw9IiMwRjlBRkYiLz4KPC9zdmc+Cg==') no-repeat;
        transition: all .2s ease;

        &--rotate{
          transform: rotate(180deg);
        }
    }

    &-step{
        position: relative;
        display: flex;
        justify-content: space-between;
        max-width: 450px;
        margin-bottom: 45px;
        &::before{
            content: "";
            position: absolute;
            top: 16px;
            left: 32px;
            width: 75%;
            height: 1px;
            background: $gray-200;
            z-index: 0;
            @media (max-width: $breakpoint-small-max) {
                width: 80%;
            }
            @media (max-width: $breakpoint-xsmall-max) {
                width: 75%;
            }
        }

        &--admin{
            margin-bottom: 0px;
        }

        &__block{
            display: flex;
            flex-direction: column;
            align-items: center;
            @media (max-width: $breakpoint-small-max) {
                max-width: 100px;
            }
        }
        &__number{
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 32px;
            height: 32px;
            border: none;
            border-radius: 50%;
            font-size: 14px;
            font-weight: 600;
            color: $gray-800;
            background-color: $gray-200;
            z-index: 1;
            &.step-active{
                color: #fff;
                background-color: $link-color; 
                & + p{
                    color: $link-color;
                    font-weight: 600;
                }
            }
        }
        &__title{
            text-align: center;
            font-size: 14px;
            color: $gray-800;
            margin: 8px 0 0;
        }
    }

    .comapny__form {
        display: flex;
        justify-content: space-between;
        max-width: 760px;
        flex-flow: wrap;  
        &.comapny__logo{
            align-items: center;
            justify-content: flex-start;
            max-width: 100%;
            flex-wrap: nowrap;
            @media (max-width: $breakpoint-small-max) {
                align-items: flex-start;
            }
            
            &--details{
                align-items: center;
                .comapny__form--avatar{
                    @media (max-width: $breakpoint-small-max) {
                        width: 70px;
                        height: 70px;
                    }
                }
            }
        }
        &--avatar{
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 0 0 auto;
            width: 99px;
            height: 99px;
            padding: 0;
            margin: 0;
            border: none;
            border-radius: 50%;
            overflow: hidden;
            background: $gray-200;
            cursor: pointer;
            & img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
        
        &--avatar-btn{   
            .logo-buttons {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                gap: 10px;

                > div span {
                    font-size: 14px;
                    color: $gray-800;
                    margin: 0 8px;
                }
                > div .delete-btn {
                    margin-left: 10px;
                }
            }
            &.details {
                margin: 0 0 0 20px;
                @media (max-width: $breakpoint-small-max) {
                    margin: 0 0 0 15px;
                }
            }

            *{
                margin: 0;
            }
            > p {
                font-size: 14px;
                color: $gray-800;
                margin-bottom: 8px;
            }
        }
        .form-group{
            max-width: 360px;
            width: 100%;
            @media (max-width: $breakpoint-small-max) {
                max-width: 100%;
            }
        }
        .form__input[disabled], 
        .form__textarea[disabled] {
            color: $gray-800;
        }
        .MuiFormControl-root{
            max-width: 360px;
            width: 100%;
            @media (max-width: $breakpoint-small-max) {
                max-width: 100%;
            }
        }
        label + .MuiInput-formControl {
            margin-top: 0;
        }
    }
    & .switcher__wrap{
        max-width: 530px;
        padding: 20px 30px;
        border: 1px solid $gray-600;
        border-radius: 16px;
        &--top{
            display: flex;
            justify-content: space-between;
            align-items: baseline;
        }
        &--content{
            display: flex;
            flex-direction: column;
            & .MuiFormControlLabel-root{
                justify-content: space-between;
            }
        }
    }
}

.upload-modal__content{
    .modal__content{
        max-width: 400px;
        .comapny__form--modal{
            > h3 {
                margin-bottom: 10px;
            }
            > p {
                font-size: 14px;
                color: $gray-800;  
            }
            .dropzone {
                position: relative;
                margin-bottom: 30px;
                .icon-svg{
                    height: auto;
                    width: 24px;
                    background: none;
                    margin: 0;
                }
                > .dropzone__holder{
                    height: 190px;
                    p {
                        font-size: 14px; 
                        margin: 10px;
                    }
                }  
                .dropzone__prev{
                    display: flex;
                    flex-direction: column;
                  &-image{
                      position: relative;
                      height: 272px;
                      @media (max-width: $breakpoint-xsmall-max) {
                        height: 230px;
                    }
                  }  
                  &-control{
                    .range__bar .MuiSlider-thumb {
                      background: $accent-color;
                    }
                    .range__bar .MuiSlider-track{
                      background: $accent-color;
                    }
                  }
               } 
            }
                 
        }
    }
}
.delete-btn {
    border: 0;
    cursor: pointer;
    outline: none;
    width: 30px;
    height: 30px;
    background: #F3F3F3;
    border-radius: 8px;
    vertical-align: middle;
    margin-left: auto;
}

.custom-switcher {
    & .MuiSwitch-switchBase {
      color: $gray-800;
        &:hover{
            color: $secondary-color;
            background: transparent;
        }
    }
    & .MuiSwitch-track{
        background-color: $gray-800;
    }
    & .MuiSwitch-colorPrimary.Mui-checked {
        color: $accent-color;
        &:hover{
          background-color: rgba(253, 138, 99, .04);
        }
    & + .MuiSwitch-track {
        background-color: $accent-color;
    }
    }

    &--task {
        &.MuiSwitch-root {
            position: absolute;
            top: 15px;
            left: -15px;
            @media (max-width: $breakpoint-small-max) {
              top: 25px;
            }
        }
    }
}
