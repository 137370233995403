.alert {
    background: $link-color;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    position: relative;
    padding: 16px 80px;
    @media (max-width: $breakpoint-small-max) {
        font-size: 12px;
        font-weight: 400;
    }
    @media (max-width: $breakpoint-xsmall-max) {
        text-align: left;
        padding: 16px 40px 16px 16px;
    }
    
    .layout-main__content & {
        margin: -40px -32px 80px;
        @media (max-width: $breakpoint-medium-max) {
            margin-left: -16px;
            margin-right: -16px;
            margin-bottom: 30px;
        }
        @media (max-width: $breakpoint-small-max) {
            margin-top: -32px;
        }
    }
    
    &__content {
        margin: 0 auto;
        max-width: 999px;
        & * {
            margin: 0;
            line-height: 110%;
        }
        & a {
            color: #ffffff;
            text-decoration: underline;
        }
    }
    
    &__icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 32px;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 12px;
        background: rgba(255, 255, 255, 0.2);
        border: 1px solid rgba(255, 255, 255, 0.5);
        @media (max-width: $breakpoint-medium-max) {
            left: 16px;
        }
        @media (max-width: $breakpoint-xsmall-max) {
            display: none;
        }
    
    }
    
    &__close {
        position: absolute;
        top: 50%;
        right: 28px;
        width: 24px;
        height: 24px;
        transform: translateY(-50%);
        text-indent: -9999px;
        overflow: hidden;
        background: none;
        border: 0;
        padding: 0;
        cursor: pointer;
        color: #fff;
    
        @media (max-width: $breakpoint-medium-max) {
            right: 10px;
        }
        
        &::after,
        &::before {
            position: absolute;
            width: 16px;
            height: 2px;
            content:'';
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%) rotate(45deg);
            border-radius: 2px;
            background: currentColor;
        }
        &::after {
            transform: translate(-50%,-50%) rotate(-45deg);
        }
    }
    
    &--animate {
        animation: leave 0.5s forwards;
    }
    
    &--expand {
        .alert {
            &__content {
                @media (max-width: $breakpoint-medium) {
                    > div {
                        margin-bottom: 10px;
                    }
                }
                @media (min-width: $breakpoint-medium) {
                    margin-left: 144px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    max-width: 100%;
                    text-align: left;
                    > div {
                        max-width: 580px;
                    }
                }
            }
        }
    }
}

@keyframes leave {
    0% { opacity: 1 }
    100% { opacity: 0 }
}
