.layout-form {
    position: relative;
    &__content {
        flex: 1 1 auto;
        padding-top: 40px;
    
        @media (max-width: $breakpoint-small-max) {
            padding-top: 56px;
        }
    }
    
    &__content-holder {
        .layout-form__content--small & {
            max-width: 384px;
            margin: 0 auto;
        }
        .layout-form__content--medium & {
            max-width: 592px;
            @media (max-width: $breakpoint-medium-max) {
                margin: 0 auto;
            }
        }
    }
    
    &__image {
        position: relative;
        flex: 0 0 40%;
        max-width: 504px;
        margin: -40px 0 -40px -32px;
        
        img {
            position: absolute;
            right: 0;
            top: 0;
        }
    
        @media (max-width: $breakpoint-medium-max) {
            display: none;
        }
    }
    
    &__grid {
        > div {
            padding-bottom: 8px;
            > * {
                &:not(:first-child) {
                    margin-top: 8px;
                }
            }
        }
        @media (min-width: $breakpoint-small) {
            display: flex;
            flex-wrap: wrap;
            margin: 0 -8px;
            > div {
                //display: flex;
                flex: 1 1 50%;
                padding: 0 8px 16px;
                > * {
                    &:not(:first-child) {
                        margin-top: 16px;
                    }
                }
            }
        }
    
        @media (min-width: $breakpoint-medium) {
            margin: 0 -16px;
            > div {
                padding: 0 16px 32px;
                > div {
                    &:not(:first-child) {
                        margin-top: 32px;
                    }
                }
            }
        }
        
        &--flex {
            @media (min-width: $breakpoint-xsmall) {
                > div {
                    display: flex;
                }
            }
    
        }
        
        &--alt {
            display: block;
    
            @media (min-width: $breakpoint-small) {
                overflow: hidden;
                > div {
                    float: left;
                    width: 50%;
        
                    &:nth-child(2n) {
                        float: right;
                    }
                }
            }
        }
    }
    
    &--with-image {
        @media (min-width: $breakpoint-medium) {
            display: flex;
            .layout-form__content {
                padding-left: 14%;
                &--medium {
                    padding-left: 5%;
                }
            }
            .layout-form__content-holder {
                margin: 0;
            }
        }
    }
}