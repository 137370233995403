.users-statistics{

  .Mui-disabled {
    background: rgba(227, 227, 227, 0.15);
  }

  &__wrap{
    display: flex;
    gap: 24px;
    max-width: 700px;
    @media (max-width: $breakpoint-medium-max) {
      flex-wrap: wrap;
    }
  }

  &__input-wrap {
    display: flex;
    gap: 20px;
    & .form__input--small {
        font-size: 14px;
        height: 43px;
        border-radius: 12px;
    }
  }

  &__item {
    margin-bottom: 10px;
    @media (max-width: $breakpoint-medium-max) {

      .range__label{
        text-align: left;
      }
    }
  }

  &__slide{
    display: flex;
    gap: 24px;

    .range__bar{
      padding: 16px 0;
    }

    @media (max-width: $breakpoint-medium-max) {
      flex-direction: column;
      .MuiBox-root{
        width: 100%;
      }
    }
  }

  &__location{
    min-width: 250px;
    flex: 1 0 250px;
  }

  &__status{
    max-width: 400px;
    min-width: 160px;
    flex: 1 0 160px;
  }

  &__control{
    display: flex;
    gap: 16px;
    margin: 20px 0 30px;
    .MuiButton-root.MuiButtonBase-root {
      min-width: 170px;
      height: 46px;
    }
    @media (max-width: $breakpoint-small-max) {
      flex-direction: column;
    }
  }
}