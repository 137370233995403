.form {
    &__area {
        position: relative;

        &--small {
            max-width: 131px;
        }

        &--large {
            @media (min-width: $breakpoint-small) {
                min-width: 240px;
            }
        }
    }

    &__icon {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);

        &--dollar {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    &__input,
    &__textarea {
        background: $gray-200;
        height: 56px;
        padding: 16px;
        border-radius: 12px;
        width: 100%;
        border: 1px solid $gray-200;
        outline: none;
        @media (max-width: $breakpoint-small-max) {
            height: 48px;
            padding-top: 12px;
            padding-bottom: 12px;
            border-radius: 8px;
        }

        &::placeholder {
            color: $gray-800
        }

        &-prefix{
            position: absolute;
            top: 11px;
            left: 15px;
            font-size: 15px;
            color: $gray-800;
        }

        &:focus,
        &--focus {
            color: $base-color;
            background: #fff;
            border-color: $gray-800;
        }
        &[disabled] {
            color: $gray-600;
            + .icon-svg {
                opacity: .6;
            }
        }
    
        &[type=number],
        &[type=number] {
          appearance: textfield;
          
          &::-webkit-inner-spin-button,
          &::-webkit-outer-spin-button {
            appearance: none;
            margin: 0;
          }
        }

        &--alt {
            padding-right: 40px;
            .form__area--small & {
                padding-right: 27px;
            }
        }

        &--lpad {
            padding-left: 20px;
            .form__area--small & {
                padding-left: 20px;
            }
        }

        &--small {
            height: 40px;
            padding-top: 10px;
            padding-bottom: 10px;
            border-radius: 8px;
            font-size: 16px;
        }

        &--xsmall {
            height: 36px;
            padding: 6px 12px;
            border-radius: 8px;
            font-size: 16px;
        }

        &.form__input--error {
            color: $base-color;
            background: #fff;
            border-color: $error-color;
            display: block;
        }
        
    }
    &__input--core{
        border: 1px solid $success-color;
    }
    &__textarea {
        height: auto;
        min-height: 176px;
        max-width: 100%;
        &::placeholder {
            color: $gray-800
        }

        .form-group & {
            margin-top: 20px;
        }

        &--alt {
            .form-group & {
                margin-top: 0;
            }
        }

        &--resize {
            min-height: auto;
        }

        &--error {
            color: $base-color;
            background: #fff;
            border-color: $error-color;
            display: block;
        }
    }

    &__msg-error {
        font-size: 14px;
        color: $error-color;
        .form__comment-box & {
            display: block;
            text-align: left;
        }
    }

    &__msg-core {
        font-size: 14px;
        color: $success-color;
    }
    
    &__icon {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        > *,
        > .MuiButtonBase-root {
            color: $gray-800;
        }
    }

    &__input-icon {
        position: absolute !important;
        right: 15px;
        top: 50%;
        z-index: 2;
        transform: translateY(-50%);
        background: red;
        @media (max-width: $breakpoint-small-max) {
            //top: 14px;
            right: 10px;
        }

        &:hover {
            background: none !important;
        }
        .MuiSvgIcon-root {
            font-size: 1.2rem;
        }
    }

    &__input-colored{
        position: relative;
        &::after{
            position: absolute;
            content: "";
            top: 16px;
            right: 16px;
            width: 24px;
            height: 24px;
            border-radius: 4px;
            @media (max-width: $breakpoint-small-max) {
                top: 12px;
                right: 12px;
            }
        }
        &--green::after{
            background-color: $success-color;
        }
        &--light-green::after{
            background-color: #268A82;
        }
        &--yellow::after{
            background-color: #F7D069;
        }
        &--dark-yellow::after{
            background-color: #FAC63F;
        }
        &--pink::after{
            background-color: #FB8792;
        }
        &--red::after{
            background-color: #E43544;
        }
        &--dark-red::after{
            background-color: #B00817;
        }
    }
    
    &__button {
        padding-top: 40px;

        @media (max-width: $breakpoint-small-max) {
            button {
                width: 100%;
            }
        }

        &--center {
            text-align: center;
            > * {
                margin: 0 6px !important;

                @media (max-width: $breakpoint-small-max) {
                    margin: 0 !important;
                    &:not(:first-child) {
                        margin-top: 10px !important;
                    }
                }
            }
        }

        &--no-padding {
            padding: 0;
        }

        &--inline {
            display: flex;
            flex-wrap: wrap;
        }
    }

    &__columns {
        margin: 0 -20px;
        display: flex;
        &--small {
            margin: 0 -8px;
            @media (max-width: $breakpoint-xsmall-max) {
                flex-wrap: wrap;
            }

            > div {
                padding: 0 8px;
            }
        }
    }

    &__column {
        padding: 0 20px;
        &--flex {
            flex: 1 1 auto;
        }
        &--small {
            @media (min-width: $breakpoint-xsmall) {
                max-width: 40%;
            }
        }
    }

    &__msg {
        text-align: center;
        font-size: 16px;
    }

    &__comment-box {
        font-size: 16px;

        @media (min-width: $breakpoint-xsmall) {
            text-align: center;
            max-width: 384px;
            margin-left: auto;
            margin-right: auto;
        }

        h5 {
            font-size: 18px;
        }
    }

    &__add-button {
        padding: 0;
        border: 0;
        background: none;
        display: flex;
        align-items: center;
        font-weight: 700;
        outline: none;
        cursor: pointer;
        color: $base-color;
        &-icon {
            position: relative;
            width: 32px;
            height: 32px;
            flex: 0 0 32px;
            margin-right: 16px;
            border-radius: 8px;
            background: $accent-color;
            &::before,
            &::after {
                position: absolute;
                left: 50%;
                top: 50%;
                width: 16px;
                height: 2px;
                border-radius: 2px;
                content:'';
                background: #fff;
                transform: translate(-50%,-50%);
            }
            &::after {
                transform: translate(-50%,-50%) rotate(90deg);
            }
        }
    }

    &__label {
        display: flex;
        align-items: center;
        margin-bottom: 24px;
        h5 {
            margin: 0;
        }
    }

    &__search-box {
        position: relative;

        .form__input {
            height: 48px;
            padding-left: 35px;
        }

        .icon-svg {
            position: absolute;
            top: 50%;
            right: 5px;
            z-index: 1;
            transform: translateY(-50%);
            width: 25px;
            height: 25px;
        }
    }

    &--email {
        margin: 0 auto;
        max-width: 568px;
        @media (min-width: $breakpoint-small) {
            display: flex;
        }

        .form-group {
            flex:  1 1 auto;
            margin: 0;

            + .form__button {
                @media (min-width: $breakpoint-small) {
                    padding: 0 0 0 8px;
                }
            }
        }
    }

    &__main-title{
        max-width: 370px;
    }
    
    &__flow-wrap{
        display: flex;
        max-width: 760px;
        flex-flow: wrap;
        @media (max-width: $breakpoint-medium-max) {
            max-width: 100%;
        }
        .form-group{ 
            min-width: 200px;
            width: 48%;
            @media (max-width: $breakpoint-xsmall-max) {
               width: 100%; 
            }
            &:nth-child(odd){
                margin-right: auto;
            }
        }
        &--alerts{
            flex-direction: column;
            max-height: 450px;
            @media (max-width: $breakpoint-xsmall-max) {
                flex-direction: row; 
                max-height: 100%;
            }
        }
        &--column {
          @media (min-width: $breakpoint-xsmall) {
            flex-direction: column;
            &.umbrella-h {
              max-height: 1120px;
            }
            &.mortgage-h{
              max-height: 950px;
            }
          }    
        }
    }

    &__priority {
        display: flex;
        position: relative;

        &__item {
            display: flex;
            align-items: center;
            flex-grow: 1;
            background-color: $gray-200;
        }

        &__left {
            max-width: 58%;
            padding: 10px 16px;
            font-size: 16px;
            border-right: 1px solid $gray-600;
            border-radius: 12px 0 0 12px;
        }

        &__right {
            position: relative;
            max-width: 42%;
            border-radius: 0 12px 12px 0;
        }

        .form__msg-error {
            position: absolute;
            top: 100%;
            right: 0;
        }
    }
}

.react-date-picker__calendar-button.react-date-picker__button{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 16px;

  & .icon-svg{
    justify-content: flex-end;
  }
}