.notifications {
    font-size: 14px;
    flex: 1 1 auto;
    display: flex;
    
    &__empty {
        flex: 1 1 auto;
        display: flex;
        align-items: center;
        width: 100%;
        text-align: center;
        flex-direction: column;
        justify-content: center;
        img {
            max-width: 257px;
            margin: 0 auto 20px;
        }
    }
    
    &__list {
        margin-bottom: 20px;
        > div {
            display: flex;
            position: relative;
            color: $gray-800;
            padding: 20px 0;
            &:not(:last-child) {
                border-bottom: 1px solid $gray-600;
            }
        }
    }
    
    &__list-title {
        &--new {
            &::before {
                display: inline-block;
                width: 8px;
                height: 8px;
                content: '';
                background: $link-color;
                margin-right: 8px;
                border-radius: 50%;
            }
        }
    }
    
    &__card {
        position: relative;
        display: flex;
        padding-right: 30px;
        flex:  1 1 auto;
        
        &-icon {
            flex: 0 0 32px;
            width: 32px;
            height: 32px;
            border-radius: 8px;
            margin-right: 16px;
            background: rgba($link-color, .2);
            display: flex;
            align-items: center;
            justify-content: center;
            
            path {
                fill: $link-color;
            }
            
            &--complete {
                background: rgba($success-color, .2);
    
                path {
                    fill: $success-color;
                }
            }
            
            &--dropbox {
                background: $link-color;
    
                path {
                    fill: white;
                }
            }
        }
        
        &-close {
            cursor: pointer;
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            display: flex;
            align-items: center;
            justify-content: center;
            width: 12px;
            height: 12px;
            overflow: hidden;
            font-size: 0;
            line-height: 0;
            border: 0;
            background: none;
            
            &:hover {
                opacity: 0.7;
            }
            
            &::before,
            &::after {
                position: absolute;
                left: 0;
                right: 0;
                height: 2px;
                border-radius: 4px;
                content: '';
                background: $gray-800;
                transform: rotate(45deg);
            }
            &::after {
                transform: rotate(-45deg);
            }
        }
    
        &-title {
            color: $base-color;
            font-weight: 600;
            font-size: 12px;
            font-family: $font-family-heading;
        }
    }
}