.check-email {
    position: relative;
    padding: 8px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    background: url('../../images/bg-img.png') bottom no-repeat;
    background-size: 1200px 500px;
    @media (max-width: $breakpoint-xsmall-max) {
        background-size: contain;
    }
    
    &__logo {
        top: 25px;
        left: 25px;
        position: absolute;
    }
    
    &__icon {
        width: 48px;
        height: 48px;
        margin: 0 auto 33px;
        background: #F3D9DA;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    & .success-button__wrap{
        display: flex;
        justify-content: space-around;
        max-width: 290px;
        margin: 0 auto;
    }
}