.MuiFormControlLabel-root {
    margin-left: 0 !important;
    
    .MuiCheckbox-root {
        color: $gray-700;
        padding: 0;
        
        &.MuiIconButton-root.MuiButtonBase-root {
            padding: 0;
            margin: 0 9px 0 0;
            background: none;
            width: auto;
            height: auto;
        }
        
        + .MuiTypography-body1 {
            color: $gray-800;
        }
        
        &.Mui-checked {
            color: $success-color;
            
            + .MuiTypography-body1 {
                color: $base-color;
            }
        }
    }

    .MuiTypography-body1 {
        font-size: 16px;
        font-family: $font-family;
    }
    
    &--small {
        .MuiTypography-body1 {
            font-size: 14px;
        }
    }
    
    &.MuiFormControlLabel-labelPlacementStart {
        margin-right: 0 !important;
        
        .MuiCheckbox-root.MuiIconButton-root.MuiButtonBase-root {
            margin-right: 0;
            margin-left: 8px;
        }
    }
}

.checkbox {
    &__list {
        li {
            display: flex;
            margin-bottom: 16px;
            align-items: center;
        }
        .MuiFormControlLabel-root {
            margin-right: 10px;
            //align-items: flex-start;
        }
    }
}