.box {
    border-radius: 8px;
    background: $gray-400;
    padding: 32px 40px 90px;
    font-size: 16px;
    position: relative;
    flex: 1 1 auto;
    @media (max-width: $breakpoint-medium-max) {
        padding: 16px 16px 80px;
        font-size: 14px;
    }

    &__holder {
        @media (min-width: 640px) {
            display: flex;
            margin-right: -60px;
            > div {
                flex: 1 1 50%;
                padding-right: 60px;
            }
        }
        @media (min-width:  $breakpoint-medium) {
            margin-right: -72px;
            > div {
                padding-right: 72px;
                flex: 1 1 50%;
            }
        }
    }

    &__row {
        color: $gray-800;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 16px;
        min-width: 200px;

        &-option {
            color: $base-color;
            font-weight: 600;
            text-align: right;
            min-width: 120px;
        }
    }

    &__button {
        position: absolute;
        bottom: 40px;
        left: 40px;
        @media (max-width: $breakpoint-medium-max) {
            bottom: 32px;
            left: 16px;
        }
    }
}
