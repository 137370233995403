.user-avatar {
    display: block;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    overflow: hidden;
    
    &__placeholder {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background: $gray-200;
        color: $gray-800;
        display: flex;
        align-items: center;
        justify-content: center;
        //border: 1px solid $gray-600;
        > svg {
            font-size: 1em;
        }
    }
    
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &__name {
        margin-left: 14px;
    }

    &--md {
        width: 48px;
        height: 48px;
    }
}