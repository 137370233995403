.page-title {
    max-width: 592px;
    margin: 0 auto 40px;
    text-align: center;
    @media (max-width: $breakpoint-xsmall-max) {
        text-align: left;
        margin-bottom: 32px;
    }
    
    .layout-form--with-image & {
        max-width: 486px;
        @media (min-width: $breakpoint-medium) {
            margin-left: 0;
            text-align: left;
        }
    }
    
    &__top {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        justify-content: center;
        @media (min-width: $breakpoint-medium) {
            .layout-form--with-image & {
                justify-content: flex-start;
            }
        }
        @media (max-width: $breakpoint-xsmall-max) {
            margin-bottom: 10px;
            justify-content: flex-start;
        }
        h3 {
            margin: 0;
            
            .page-title--small & {
                font-size: 22px;
                @media (max-width: $breakpoint-small-max) {
                    font-size: 18px;
                }
            }
        }
        .MuiIconButton-root.MuiIconButton-default {
            margin-left: 10px;
            height: auto;
            @media (max-width: $breakpoint-small-max) {
                margin-left: 4px;
                .MuiSvgIcon-root {
                    font-size: 1.2rem;
                }
            }
        }
    }
    
    &__status {
        margin-left: 10px;
        padding: 9px 12px;
        color: #fff;
        border-radius: 8px;
        font-size: 14px;
        font-weight: 500;
        font-family: $font-family-heading;
    }
    
    &__subtitle {
        font-style: italic;
        color: $gray-800;
        font-size: 14px;
    }
}