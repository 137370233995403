.widget-placeholder {
    border-radius: 12px;
    background: $gray-300;
    text-align: center;
    padding: 32px;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    .widget--tasks & {
        @media (min-width: $breakpoint-medium) {
            min-height: 480px;
        }
    }
    
    &__content {
        max-width: 496px;
        > * {
            &:not(:last-child) {
                margin-bottom: 10px;
            }
        }
    }
    
    &__image {
        max-width: 368px;
        margin-bottom: 10px;
    
        .widget--others & {
            max-width: 90px;
        }
    }
}