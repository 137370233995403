.member-comapny{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 25px;
    @media (max-width: $breakpoint-medium-max) {
      position: relative;
      align-items: flex-start;
      flex-direction: column;
    }
    &--top{
        align-items: flex-start;
        @media (max-width: $breakpoint-medium-max) {
            .member-comapny__buttons{
                display: block;
                flex: 0 0 auto;
                margin: 20px auto 0 0;
                text-align: left;
            }
        }
    }
    .more-menu{
      display: none;
      @media (max-width: $breakpoint-medium-max) {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        background: transparent;
        border: none;
      }
    }

    &__logo {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 70px;
        height: 70px;
        padding: 0;
        margin: 0 15px 0 0;
        border: none;
        border-radius: 50%;
        overflow: hidden;
        background: $gray-200;
    }

    &__buttons {
        margin-left: auto;
        flex: 0 1 354px;
        text-align: right;
        @media (max-width: $breakpoint-medium-max) {
            display: none;
        }
        > button{
            padding-left: 20px !important;
            padding-right: 20px !important;

            &:not(:first-child){
                margin-left: 16px;
            }
        }

        &--wrap{
            display: flex;
            width: 100%;
            gap: 5px;
        }

        &--sort{
            position: relative;
            display: flex;
            align-items: center;
            min-width: 210px;
            height: 34px;
            margin-left: auto;
            border: 1px solid rgba(221, 221, 221, 0.5);
            border-radius: 8px;
            padding: 2px 2px 2px 10px;
            color: #8C8C8C;
            font-size: 14px;
            @media (max-width: $breakpoint-xsmall) {
                font-size: 12px;
                margin-left: 0;
            }
            .member-comapny__sort{
                margin-right: 0;
                background: transparent;
                min-width: 72px;
                cursor: pointer;
            }
        }
        &--size {
          position: relative;
          display: flex;
          align-items: center;
          height: 34px;
          margin-left: 5px;
          border: 1px solid rgba(221, 221, 221, 0.5);
          border-radius: 8px;
          padding: 2px 2px 2px 10px;
          color: #8C8C8C;
          font-size: 14px;

          .custom-select{
            height: 100%;
            background: transparent;
            padding: 0;

            & .MuiSelect-select.MuiSelect-select {
                padding-right: 32px !important;
                padding-left: 5px;
                @media (max-width: $breakpoint-xsmall) {
                    font-size: 14px;
                  }
            }

            &::after{
              background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDEyIDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xMC45OTk5IDEuMTdDMTAuODEyNiAwLjk4Mzc1MyAxMC41NTkxIDAuODc5MjExIDEwLjI5NDkgMC44NzkyMTFDMTAuMDMwOCAwLjg3OTIxMSA5Ljc3NzMxIDAuOTgzNzUzIDkuNTg5OTUgMS4xN0w1Ljk5OTk1IDQuNzFMMi40NTk5NSAxLjE3QzIuMjcyNTkgMC45ODM3NTMgMi4wMTkxMyAwLjg3OTIxMSAxLjc1NDk1IDAuODc5MjExQzEuNDkwNzYgMC44NzkyMTEgMS4yMzczMSAwLjk4Mzc1MyAxLjA0OTk1IDEuMTdDMC45NTYyMiAxLjI2Mjk3IDAuODgxODI2IDEuMzczNTcgMC44MzEwNTcgMS40OTU0M0MwLjc4MDI4OCAxLjYxNzI5IDAuNzU0MTUgMS43NDc5OSAwLjc1NDE1IDEuODhDMC43NTQxNSAyLjAxMjAyIDAuNzgwMjg4IDIuMTQyNzIgMC44MzEwNTcgMi4yNjQ1OEMwLjg4MTgyNiAyLjM4NjQ0IDAuOTU2MjIgMi40OTcwNCAxLjA0OTk1IDIuNTlMNS4yODk5NSA2LjgzQzUuMzgyOTEgNi45MjM3MyA1LjQ5MzUxIDYuOTk4MTMgNS42MTUzNyA3LjA0ODg5QzUuNzM3MjMgNy4wOTk2NiA1Ljg2Nzk0IDcuMTI1OCA1Ljk5OTk1IDcuMTI1OEM2LjEzMTk2IDcuMTI1OCA2LjI2MjY3IDcuMDk5NjYgNi4zODQ1MyA3LjA0ODg5QzYuNTA2MzggNi45OTgxMyA2LjYxNjk5IDYuOTIzNzMgNi43MDk5NSA2LjgzTDEwLjk5OTkgMi41OUMxMS4wOTM3IDIuNDk3MDQgMTEuMTY4MSAyLjM4NjQ0IDExLjIxODggMi4yNjQ1OEMxMS4yNjk2IDIuMTQyNzIgMTEuMjk1NyAyLjAxMjAyIDExLjI5NTcgMS44OEMxMS4yOTU3IDEuNzQ3OTkgMTEuMjY5NiAxLjYxNzI5IDExLjIxODggMS40OTU0M0MxMS4xNjgxIDEuMzczNTcgMTEuMDkzNyAxLjI2Mjk3IDEwLjk5OTkgMS4xN1oiIGZpbGw9IiMwRjlBRkYiLz4KPC9zdmc+Cg==') no-repeat;
            }
          }

          @media (max-width: $breakpoint-xsmall) {
            font-size: 12px;
            margin-left: 0;
          }
        }
        &--desc{
            font-size: 12px;
            color: $gray-800;
            margin: 8px 0 0;
            padding: 0 0 0 10px;
            @media (max-width: $breakpoint-medium-max) {
                padding: 0;
            }
        }
    }
    &__info{
      display: flex;
      align-items: center;
      flex: 0 0 auto;
      @media (max-width: $breakpoint-medium-max) {
        display: flex;
        align-items: center;
      }
    }
    .mobile-feedback{
      font-size: 14px;
      font-weight: 600;
      color: $gray-800;
      margin-top: 15px;

      >span {
        font-weight: 400;
      }
    }
    .reset-filter{
        position: relative;
        top: 0;
        right: 0;
        border: none;
        width: 30px;
        height: 30px;
        flex-shrink: 0;
        border-radius: 8px;
        background: $gray-200;
    }

    &__title {
        flex-direction: row;
        align-items: center;
        @media (max-width: $breakpoint-xsmall) {
            flex-wrap: wrap;
            flex-direction: column;
            align-items: flex-start;
        }
        h3, h4, p{
            margin: 0;
        }
        > p {
            font-size: 14px;
            font-weight: 600;
            color: $gray-800;

            > span {
                font-weight: 400;
            }
        }
    }

    &__sort{
        display: flex;
        align-items: center;
        height: 34px;
        border: none;
        color: #8C8C8C;
        font-size: 14px;
        max-width: 600px;
        @media (max-width: $breakpoint-xsmall) {
            font-size: 12px;
            flex-grow: 1;
        }
        .custom-select .MuiSelect-select {
            color: $gray-800;
            background: transparent;

            &.MuiSelect-select{
                min-width: 100px;
                padding: 6px 35px 6px 10px;
                font-size: 14px;
                height: auto;
            }
        }
        .custom-select.MuiInput-underline::after {
            background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDEyIDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xMC45OTk5IDEuMTdDMTAuODEyNiAwLjk4Mzc1MyAxMC41NTkxIDAuODc5MjExIDEwLjI5NDkgMC44NzkyMTFDMTAuMDMwOCAwLjg3OTIxMSA5Ljc3NzMxIDAuOTgzNzUzIDkuNTg5OTUgMS4xN0w1Ljk5OTk1IDQuNzFMMi40NTk5NSAxLjE3QzIuMjcyNTkgMC45ODM3NTMgMi4wMTkxMyAwLjg3OTIxMSAxLjc1NDk1IDAuODc5MjExQzEuNDkwNzYgMC44NzkyMTEgMS4yMzczMSAwLjk4Mzc1MyAxLjA0OTk1IDEuMTdDMC45NTYyMiAxLjI2Mjk3IDAuODgxODI2IDEuMzczNTcgMC44MzEwNTcgMS40OTU0M0MwLjc4MDI4OCAxLjYxNzI5IDAuNzU0MTUgMS43NDc5OSAwLjc1NDE1IDEuODhDMC43NTQxNSAyLjAxMjAyIDAuNzgwMjg4IDIuMTQyNzIgMC44MzEwNTcgMi4yNjQ1OEMwLjg4MTgyNiAyLjM4NjQ0IDAuOTU2MjIgMi40OTcwNCAxLjA0OTk1IDIuNTlMNS4yODk5NSA2LjgzQzUuMzgyOTEgNi45MjM3MyA1LjQ5MzUxIDYuOTk4MTMgNS42MTUzNyA3LjA0ODg5QzUuNzM3MjMgNy4wOTk2NiA1Ljg2Nzk0IDcuMTI1OCA1Ljk5OTk1IDcuMTI1OEM2LjEzMTk2IDcuMTI1OCA2LjI2MjY3IDcuMDk5NjYgNi4zODQ1MyA3LjA0ODg5QzYuNTA2MzggNi45OTgxMyA2LjYxNjk5IDYuOTIzNzMgNi43MDk5NSA2LjgzTDEwLjk5OTkgMi41OUMxMS4wOTM3IDIuNDk3MDQgMTEuMTY4MSAyLjM4NjQ0IDExLjIxODggMi4yNjQ1OEMxMS4yNjk2IDIuMTQyNzIgMTEuMjk1NyAyLjAxMjAyIDExLjI5NTcgMS44OEMxMS4yOTU3IDEuNzQ3OTkgMTEuMjY5NiAxLjYxNzI5IDExLjIxODggMS40OTU0M0MxMS4xNjgxIDEuMzczNTcgMTEuMDkzNyAxLjI2Mjk3IDEwLjk5OTkgMS4xN1oiIGZpbGw9IiMwRjlBRkYiLz4KPC9zdmc+Cg==') no-repeat;
        }
        .sort-title{
            position: relative;
            margin-left: 5px;
            
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            &--blue {
                color: $link-color;
            }
            &--arrow{
                padding-right: 20px;
                &::after{
                    content: "";
                    top: 7px;
                    right: 0;
                    width: 13px;
                    height: 8px;
                    position: absolute;
                    background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDEyIDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xMC45OTk5IDEuMTdDMTAuODEyNiAwLjk4Mzc1MyAxMC41NTkxIDAuODc5MjExIDEwLjI5NDkgMC44NzkyMTFDMTAuMDMwOCAwLjg3OTIxMSA5Ljc3NzMxIDAuOTgzNzUzIDkuNTg5OTUgMS4xN0w1Ljk5OTk1IDQuNzFMMi40NTk5NSAxLjE3QzIuMjcyNTkgMC45ODM3NTMgMi4wMTkxMyAwLjg3OTIxMSAxLjc1NDk1IDAuODc5MjExQzEuNDkwNzYgMC44NzkyMTEgMS4yMzczMSAwLjk4Mzc1MyAxLjA0OTk1IDEuMTdDMC45NTYyMiAxLjI2Mjk3IDAuODgxODI2IDEuMzczNTcgMC44MzEwNTcgMS40OTU0M0MwLjc4MDI4OCAxLjYxNzI5IDAuNzU0MTUgMS43NDc5OSAwLjc1NDE1IDEuODhDMC43NTQxNSAyLjAxMjAyIDAuNzgwMjg4IDIuMTQyNzIgMC44MzEwNTcgMi4yNjQ1OEMwLjg4MTgyNiAyLjM4NjQ0IDAuOTU2MjIgMi40OTcwNCAxLjA0OTk1IDIuNTlMNS4yODk5NSA2LjgzQzUuMzgyOTEgNi45MjM3MyA1LjQ5MzUxIDYuOTk4MTMgNS42MTUzNyA3LjA0ODg5QzUuNzM3MjMgNy4wOTk2NiA1Ljg2Nzk0IDcuMTI1OCA1Ljk5OTk1IDcuMTI1OEM2LjEzMTk2IDcuMTI1OCA2LjI2MjY3IDcuMDk5NjYgNi4zODQ1MyA3LjA0ODg5QzYuNTA2MzggNi45OTgxMyA2LjYxNjk5IDYuOTIzNzMgNi43MDk5NSA2LjgzTDEwLjk5OTkgMi41OUMxMS4wOTM3IDIuNDk3MDQgMTEuMTY4MSAyLjM4NjQ0IDExLjIxODggMi4yNjQ1OEMxMS4yNjk2IDIuMTQyNzIgMTEuMjk1NyAyLjAxMjAyIDExLjI5NTcgMS44OEMxMS4yOTU3IDEuNzQ3OTkgMTEuMjY5NiAxLjYxNzI5IDExLjIxODggMS40OTU0M0MxMS4xNjgxIDEuMzczNTcgMTEuMDkzNyAxLjI2Mjk3IDEwLjk5OTkgMS4xN1oiIGZpbGw9IiMwRjlBRkYiLz4KPC9zdmc+Cg==') no-repeat;
                }
            }
        }
    }

    &__license{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 24px;
        margin-bottom: 15px;
        border-radius: 12px;
        border: 1px solid $gray-200;
        @media (max-width: $breakpoint-medium-max) {
            flex-direction: column;
            button{
              min-width: 250px !important;
            }
        }
        .license-counts {
            display: flex;
            @media (max-width: $breakpoint-medium-max) {
                min-width: 250px;
                margin-bottom: 25px;
            }
            &__item{
                h4{
                    margin-bottom: 18px;
                }
                p {
                  font-size: 14px;
                  margin: 0;
                }
                &:not(:last-child){
                    margin-right: 24px;
                }
            }
        }
        .license-buttons{
            @media (max-width: $breakpoint-medium-max) {
                display: flex;
                flex-direction: column;
                align-items: center;
            }
            button:not(:last-child) {
                margin-right: 16px;
                @media (max-width: $breakpoint-medium-max) {
                    margin: 0 0 15px;
                }
            }
        }

        .license-plan {
            display: flex;
            @media (max-width: $breakpoint-medium-max) {
                flex-direction: column;
                align-items: center;
            }
            &__icon{
                display: flex;
                justify-content: center;
                align-items: center;
                width: 36px;
                height: 36px;
                background: $accent-color;
                border-radius: 50%;
                margin-right: 18px;
                @media (max-width: $breakpoint-medium-max) {
                    margin: 0 auto 10px;
                }
            }
            &__desc {

                @media (max-width: $breakpoint-medium-max) {
                    text-align: center;
                    margin-bottom: 10px;
                }
                h6{
                    margin: 0;
                }
                p{
                    margin: 0;
                    font-size: 14px;
                    line-height: 1;
                    color: $gray-800;
                }
            }
        }

        &--full{
            border-color: transparent;
            background: rgba(255, 235, 0, .25);
            .license-plan {
                &__icon{
                    background: #ebd90a;
                }
                &__desc {
                    p{
                        color: #b19300;
                    }
                }
            }
            > button {
                border-color: $link-color !important;
                color: #fff !important;
                background-color: $link-color !important;
              &:hover{
                background-color: #007EE5 !important;
              }
            }
        }
        &--overfull{
            border-color: transparent;
            background: rgba(255, 103, 92, 0.15);
            .license-plan {
                &__icon{
                    background: #FF665C;
                }
                &__desc {
                    p{
                        color: #FF665C;
                    }
                }
            }
            > button {
                border-color: #FF665C !important;
                color: #fff !important;
                background-color: #FF665C !important;
              &:hover{
                background-color: #fc584d !important;
              }
            }
        }
    }
    &__search{
        .search__empty-text{
            max-width: 100%;
            h5{
                margin-bottom: 0;
            }
            p{
                margin-bottom: 20px;
            }
        }
    }

    &__filter {
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
        width: 100%;

        &--item {
            flex: 1 1 18px;
            @media (max-width: $breakpoint-small-max) {
                flex: 1 1 100%;
                width: 100%;
            }
            .label{
                font-size: 14px;
            }
            .input {
                display: block;
                color: $gray-800;
                height: 48px;
                width: 100%;
                max-width: 400px;
                border: 1px solid $gray-600;
                border-radius: 8px;
                padding: 8px 15px;
                margin: 0;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                cursor: default;
                @media (max-width: $breakpoint-small-max) {
                    max-width: unset;
                }
            }
            .age {
                display: flex;
                gap: 10px;
                min-width: 200px;
            }
            .married {
                text-transform: capitalize;
            }
        }
        &-total {
            font-weight: 700;
            margin: 25px 0;
            & > span {
                color: $accent-color;
            }
        }
    }
}

.members {
    display: flex;
    flex-direction: column;

    &__table{
        display: flex;
    }

    &-column__name {
        width: 100%;
        max-width: 400px;
        min-width: 180px;
        padding-bottom: 20px;
        font-weight: 600;
        & .hover {
          background: $gray-400;
        }
        @media (max-width: $breakpoint-xlarge-max) {
            max-width: 340px;
        }
    }

    &-column__other {
        position: relative;
        width: 100%;
        padding-bottom: 20px;
        z-index: 0;
         @media (max-width: $breakpoint-large-max) {
           overflow-x: auto;
           overflow-y: hidden;
         }
        & .hover{
           background: $gray-400;
         }
    }

    .column__name {
        display: flex;
        align-items: center;
        font-size: 14px;
        height: 60px;
        padding: 8px 5px;
        &:not(:first-child) {
          animation: fadeIn 0.4s linear;
          animation-fill-mode: both;
        }

        > div {
            color: inherit;
            text-decoration: none;
            display: flex;
            align-items: center;
            line-height: 1;
        }
        &:not(:last-child) {
            border-bottom: 1px solid $gray-200;
        }
        @media (max-width: $breakpoint-large-max) {
            border-right: 1px solid $gray-200;
        }

        &-logo{
            flex: 0 0 auto;
            height: 38px;
            width: 38px;
            border-radius: 50%;
            overflow: hidden;
            margin-right: 10px;
            >img, div {
                width: 100%;
                height: 100%;
                object-fit: cover;
                background: $gray-500;
            }
        }
    }

    .column__other {
        position: relative;
        display: flex;
        height: 60px;
        min-width: 590px;
        font-size: 14px;
        &:not(:first-child) {
          animation: fadeIn 0.4s linear;
          animation-fill-mode: both;
        }

        &:not(:last-child) {
            border-bottom: 1px solid $gray-200;
        }

        & > div {
            display: flex;
            align-items: center;
            padding: 8px 5px;
            line-height: 1;
            @media (max-width: $breakpoint-large-max) {
                border-right: 1px solid $gray-200;
            }
        }

        &--date {
            flex: 1 0 90px;
        }
        &--role {
            flex: 1 0 150px;
            cursor: default;
        }
        &--planner{
          position: relative;
          flex: 1 0 70px;
            > .planner-logo__wrap {
                position: relative;
                z-index: 20;
                .column__name-logo--dashed{
                  border: 1px dashed $gray-800;
                  & div {
                    background: transparent;
                  }
                }
                .planner-name{
                    position: absolute;
                    visibility: hidden;
                    opacity: 0;
                    padding: 6px 8px;
                    color: $base-color;
                    background: $gray-200;
                    border-radius: 6px;
                    white-space: nowrap;
                    top: 30px;
                    left: 50%;
                    transform: translateX(-50%);
                    transition: all .2s ease-in-out;
                    z-index: 50;
                }
                &:hover {
                    .planner-name{
                        visibility: visible;
                        opacity: 1;
                    }
                }
            }
        }
        &--status {
            flex: 1 0 50px;
            color: $gray-800;
            &.status-blue{
                color: $link-color;
            }
            &.titles-button {
                @media (max-width: $breakpoint-large-max) {
                    margin-right: 12px;
                }
            }
        }
        &--control {
            flex: 1 0 175px;
            justify-content: right;
            > button{
                white-space: nowrap;
                &:first-child{
                  @media (min-width: $breakpoint-large) {
                    margin-right: 10px;
                  }
                }
                &:nth-child(2) {
                  margin-left: auto;
                  @media (max-width: $breakpoint-large-max) {
                    margin-left: 10px;
                  }
                }
            }
             > .control-button {
                margin-left: 10px;
                border: 0;
                cursor: pointer;
                outline: none;
                width: 30px;
                height: 30px;
                background: $gray-200;
                border-radius: 8px;
                &:disabled{
                    cursor: not-allowed;
                    opacity: .4;
                }
            }
        }
    }

    & .titles {
        font-size: 14px;
        color: $gray-800;
        min-height: auto;
        height: 40px;
        border: none !important;
    }
    & .titles-button{
        display: flex;
        align-items: center;
        position: relative;
        font-size: 14px;
        color: $gray-800;
        min-height: auto;
        height: 40px;
        border: none !important;
        background: transparent;
        cursor: pointer;
        text-align: left;
        &:disabled .title-arrow{
            display: none;
        }
        @media (max-width: $breakpoint-large-max) {
            white-space: nowrap;
            padding: 0;
        }
    }

    & .title-arrow {
        margin-left: 5px;
        width: 12px;
        height: 9px;
        background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDEyIDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xMC45OTk5IDEuMTdDMTAuODEyNiAwLjk4Mzc1MyAxMC41NTkxIDAuODc5MjExIDEwLjI5NDkgMC44NzkyMTFDMTAuMDMwOCAwLjg3OTIxMSA5Ljc3NzMxIDAuOTgzNzUzIDkuNTg5OTUgMS4xN0w1Ljk5OTk1IDQuNzFMMi40NTk5NSAxLjE3QzIuMjcyNTkgMC45ODM3NTMgMi4wMTkxMyAwLjg3OTIxMSAxLjc1NDk1IDAuODc5MjExQzEuNDkwNzYgMC44NzkyMTEgMS4yMzczMSAwLjk4Mzc1MyAxLjA0OTk1IDEuMTdDMC45NTYyMiAxLjI2Mjk3IDAuODgxODI2IDEuMzczNTcgMC44MzEwNTcgMS40OTU0M0MwLjc4MDI4OCAxLjYxNzI5IDAuNzU0MTUgMS43NDc5OSAwLjc1NDE1IDEuODhDMC43NTQxNSAyLjAxMjAyIDAuNzgwMjg4IDIuMTQyNzIgMC44MzEwNTcgMi4yNjQ1OEMwLjg4MTgyNiAyLjM4NjQ0IDAuOTU2MjIgMi40OTcwNCAxLjA0OTk1IDIuNTlMNS4yODk5NSA2LjgzQzUuMzgyOTEgNi45MjM3MyA1LjQ5MzUxIDYuOTk4MTMgNS42MTUzNyA3LjA0ODg5QzUuNzM3MjMgNy4wOTk2NiA1Ljg2Nzk0IDcuMTI1OCA1Ljk5OTk1IDcuMTI1OEM2LjEzMTk2IDcuMTI1OCA2LjI2MjY3IDcuMDk5NjYgNi4zODQ1MyA3LjA0ODg5QzYuNTA2MzggNi45OTgxMyA2LjYxNjk5IDYuOTIzNzMgNi43MDk5NSA2LjgzTDEwLjk5OTkgMi41OUMxMS4wOTM3IDIuNDk3MDQgMTEuMTY4MSAyLjM4NjQ0IDExLjIxODggMi4yNjQ1OEMxMS4yNjk2IDIuMTQyNzIgMTEuMjk1NyAyLjAxMjAyIDExLjI5NTcgMS44OEMxMS4yOTU3IDEuNzQ3OTkgMTEuMjY5NiAxLjYxNzI5IDExLjIxODggMS40OTU0M0MxMS4xNjgxIDEuMzczNTcgMTEuMDkzNyAxLjI2Mjk3IDEwLjk5OTkgMS4xN1oiIGZpbGw9IiMwRjlBRkYiLz4KPC9zdmc+Cg==') no-repeat;
        transition: all .2s ease;

        &--rotate{
          transform: rotate(180deg);
        }
    }
}

.logo__delete,
.logo__add {
    margin: 0;
    padding: 0;
    position: absolute;
    overflow: hidden;
    text-indent: -9999px;
    border-radius: 50%;
    background: $gray-800;
    width: 16px;
    height: 16px;
    color: #fff;
    border: 0;
    cursor: pointer;
    &::after,
    &::before {
        position: absolute;
        width: 8px;
        height: 2px;
        content:'';
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%) rotate(45deg);
        border-radius: 2px;
        background: currentColor;
    }
    &::after {
        transform: translate(-50%,-50%) rotate(-45deg);
    }

    &:hover {
        color: $base-color;
    }
    &:disabled{
        display: none;
    }
}
.logo__delete{
    top: 0;
    left: 25px;
}

.logo__add {
    bottom: 0;
    left: 25px;
    &::after{
        transform: translate(-50%,-50%) rotate(-90deg);
    }
    &::before {
        transform: translate(-50%,-50%) rotate(0deg);
    }
}

.search-user {
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 210px;
  height: 240px;
  padding: 16px;
  background: #fff;
  border: 1px solid $gray-300;
  border-radius: 12px;
  box-shadow: $box-shadow;
  z-index: 100;
  &--open {
    visibility: visible;
  }

  &__box{
    position: relative;
    > .icon-svg{
      position: absolute;
      top: 9px;
      right: 6px;
    }
  }

  &__input {
        height: 32px;
        width: 100%;
        font-size: 12px;
        border: 1px solid transparent;
        border-radius: 8px;
        background: $gray-300;
        padding: 0 10px;
        &:focus-within{
          outline: 1px solid $gray-500;
        }
  }

  &__result {
    display: flex;
    flex-direction: column;
    height: 174px;
    width: 193px;
    padding: 10px 0 0;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $link-color;
    }

    &-item{
      animation: fadeInSmall 0.4s linear;
      animation-fill-mode: both;
      transition: background .4s ease;
      border-radius: 8px;
      max-width: 176px;
      &:hover{
        background: $gray-400;
      }
      >button {
        display: flex;
        align-items: center;
        width: 100%;
        border: none;
        background: transparent;
        padding: 5px 0;
        font-size: 12px;
        cursor: pointer;

        .result-item__logo{
          width: 22px;
          height: 22px;
          border: none;
          border-radius: 50%;
          background: $gray-500;
          margin-right: 8px;
          overflow: hidden;
          > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
      &--target{
        background: $gray-400;
      }
    }
  }
}
.admin-search {
    .modal__content{
        margin-bottom: 0;
    }
    &__modal{
        > p {
            color: #8C8C8C;
            font-size: 16px;
            margin-bottom: 0;
        }
    }
}

.modal-content{
  display: flex;
  flex-direction: column;
  align-items: center;
  &__button{
    border: none;
    background: #fff;
    height: 46px;
    max-width: 340px;
    color: $accent-color;
    font-weight: 600;
    width: 100%;
    border-radius: 12px;
    &:not(:last-child){
      margin-bottom: 16px;
    }
    &:last-child{
        margin-top: 10px;
    }
  }
}
