.auth {
    position: relative;
    padding: 8px;
    min-height: 100vh;
    font-size: 16px;
    @media (min-width: $breakpoint-medium) {
        display: flex;
    }
    
    &__logo {
        z-index: 2;
        top: 25px;
        left: 25px;
        position: absolute;
        @media (max-width: $breakpoint-medium-max) {
            left: 16px;
            &--center {
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }
    
    &__back {
        margin-bottom: 54px;
        
        @media (max-width: $breakpoint-medium-max) {
            position: absolute;
            left: 16px;
            top: 17px;
            .back-button__text {
                line-height: 0;
                font-size: 0;
            }
        }
    }
    
    &__close {
        z-index: 2;
        position: fixed;
        right: 30px;
        top: 30px;
        width: 32px;
        height: 32px;
        overflow: hidden;
        text-indent: -9999px;
        border-radius: 8px;
        background: #fff;
        @media (max-width: $breakpoint-medium-max) {
            right: 12px;
            top: 20px;
            position: absolute;
        }
        &::after,
        &::before {
            position: absolute;
            width: 16px;
            height: 2px;
            content:'';
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%) rotate(45deg);
            border-radius: 2px;
            background: $gray-800;
            @media (max-width: $breakpoint-medium-max) {
                background: $base-color;
            }
        }
        &::after {
            transform: translate(-50%,-50%) rotate(-45deg);
        }
    }
    
    &__content {
        width: 100%;
        @media (min-width: $breakpoint-medium) {
            display: flex;
        }
    }
    
    &__side {
        //flex: 1 1 50%;
        padding: 96px 24px;
        display: flex;
        align-items: center;
        min-height: 100%;
        @media (min-width: $breakpoint-medium) {
            width: 50%;
        }
    
        @media (max-width: $breakpoint-medium-max) {
            min-height: 97vh;
            padding: 80px 8px 20px;
        }

        &--top{
            align-items: flex-start;
            padding: 200px 8px 20px;
            @media (max-width: $breakpoint-medium-max) {
                padding: 120px 8px 10px;
            }
        }
    }
    
    &__image {
        position: fixed;
        right: 8px;
        top: 8px;
        bottom: 8px;
        width: 50%;
        text-align: center;
        background: $gray-300;
        border-radius: 8px;
        overflow: hidden;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        
        @media (max-width: $breakpoint-medium-max) {
            display: none;
        }
    }
    
    &__form {
        max-width: 384px;
        margin: 0 auto;
        width: 100%;

        & .form__textarea{
            margin-top: 0 !important;
        }

        & .form__input[disabled] {
            color: $gray-800;
        }
    }

    &__type-button {
        text-align: left;
        cursor: pointer;
        border-radius: 12px;
        border: 1px solid $gray-200;
        background: #fff;
        color: $base-color;
        font-weight: 500;
        font-size: 14px;
        line-height: 1.3;
        font-family: $font-family-heading;
        display: flex;
        align-items: center;
        width: 100%;
        padding: 14px;
        margin: 0 0 15px;
        box-shadow: 4px 4px 12px 0 rgba(0, 0, 0, .03);
        transition: background-color .2s, box-shadow .2s;

        &:hover {
            text-decoration: none;
            box-shadow: 4px 4px 12px 0 rgba(0, 0, 0, .08);
        }

        &:active {
            box-shadow: none;
        }

        @media (max-width: $breakpoint-small-max) {
            font-weight: 400;
        }

        @media (min-width: $breakpoint-small) {
            padding: 12px 14px;
        }
    }

    &__type-icon {
        display: flex;
        margin-right: 16px;
        width: 32px;
        flex: 0 0 32px;

        &--arrow{
            display: flex;
            margin-left: auto;
        }
    }

    &__form-title{
        margin-bottom: 0;
    }

    &__form-suptitle{
        font-size: 14px;
        color: $gray-800;
    }
    
    &__form-info {
        margin-top: 50px;
        font-size: 14px;
        @media (min-width: $breakpoint-medium) {
            position: absolute;
            bottom: 35px;
        }
        
        &-link {
            margin-left: 8px;
            font-size: 14px;
            font-weight: bold;
            display: inline-flex;
            align-items: center;
            .MuiSvgIcon-root {
                margin-left: 5px;
                font-size: 1.4em;
            }
        }

        &-button {
            margin-left: 8px;
            font-size: 14px;
            font-weight: bold;
            display: inline-flex;
            align-items: center;
            border: none;
            background: transparent;
            color: $link-color;
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
            .MuiSvgIcon-root {
                margin-left: 5px;
                font-size: 1.4em;
            }
        }
    }
    
    &__form-link {
        font-size: 14px;
        color: $gray-800;
        display: flex;
        margin-top: -5px;
    }
    
    &__form-option {
        margin-top: 40px;
        @media (min-width: $breakpoint-medium) {
            position: absolute;
            margin: 0;
            bottom: 0;
        }
    }
}