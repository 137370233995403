.article {
    margin-bottom: 40px;
    @media (min-width: $breakpoint-medium) {
        margin-bottom: 88px;
    }
    &__preview {
        @media (min-width: $breakpoint-xsmall) {
            padding: 0 0 40px;
            display: flex;
            align-items: center;
        }
        @media (min-width: $breakpoint-medium) {
            padding: 0 0 80px;
        }
    }
    
    &__text {
        padding-right: 20px;
        flex: 1 1 auto;
    }
    
    &__image {
        flex: 0 0 48%;
        border-radius: 12px;
        background: #fff;
        overflow: hidden;
        position: relative;
        height: 360px;
        @media (max-width: $breakpoint-xsmall-max) {
            border-radius: 0;
            margin: 0 -16px;
            height: 216px;
        }
        
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            @media (max-width: $breakpoint-xsmall-max) {
                object-fit: cover;
            }
        }
    }
    
    &__author {
        font-size: 14px;
        display: flex;
        align-items: center;
        @media (max-width: $breakpoint-xsmall-max) {
            font-size: 12px;
            margin-bottom: 36px;
        }
        
        &-avatar {
            width: 24px;
            height: 24px;
            margin-right: 8px;
            flex: 0 0 24px;
            background: $gray-600;
            border-radius: 50%;
            object-fit: cover;
        }
        
        button {
            padding: 0;
            background: none;
            border: 0;
            color: $base-color;
            cursor: pointer;
            font-size: 14px;
            @media (max-width: $breakpoint-xsmall-max) {
                font-size: 12px;
            }
        }
    }
    
    &__content {
        padding: 32px 0 12px;
        border: solid $gray-500;
        border-width: 1px 0;
        @media (min-width: $breakpoint-medium) {
            padding: 89px 10% 60px;
        }
    }
    
    &__author-box {
        font-size: 14px;
        &-avatar {
            width: 64px;
            height: 64px;
            overflow: hidden;
            border-radius: 50%;
            margin: 0 auto 24px;
            background: $gray-600;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        
        &-name {
            font-family: $font-family-heading;
            font-weight: 600;
            margin-bottom: 10px;
        }
        
        &-position {
            color: $gray-800;
            margin-bottom: 20px;
        }
        
        &-description {
            text-align: left;
        }
        
        &-mail {
            border-radius: 8px;
            padding: 0 10px;
            height: 48px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            font-size: 16px;
            background: $gray-200;
            min-width: 240px;
            margin-bottom: 24px;
        }
        
        &-linkedin {
            margin: 0 auto 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background: $primary-color;
        }
    }
    
    &__slider {
        @media (min-width: $breakpoint-xsmall) {
            padding: 0 36px;
        }
        .slick-list {
            overflow: hidden;
        }
        .slick-track {
            margin: 0;
        }
    
        .slick-slide {
            @media (min-width: $breakpoint-medium) {
                padding: 0 16px;
            }
            > div {
                display: flex;
                > div {
                    display: flex !important;
                }
            }
        }
        
    }
}