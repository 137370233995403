.widget {
    font-size: 16px;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    @media (max-width: $breakpoint-medium-max) {
        font-size: 14px;
    }
    
    &__top {
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
        @media (max-width: $breakpoint-medium-max) {
            margin-bottom: 16px;
        }
    }
    
    &__heading {
        font-size: 14px;
        line-height: 1.3;
        font-weight: 600;
        font-family: $font-family-heading;
        
        //.widget--analysis &,
        //.widget--others & {
        //    font-size: 22px;
        //    @media (max-width: $breakpoint-medium-max) {
        //        font-size: 18px;
        //    }
        //}
    }
    
    &__link {
        color: $gray-800;
        font-weight: 600;
        line-height: 1;
    }
    
    &__content {
        flex: 1 1 auto;
        display: flex;
    }
    
    &__list {
        flex: 1 1 auto;
        > div {
            padding-bottom: 8px;
            display: flex;
        }
        @media (min-width: $breakpoint-xsmall) {
            margin: 0 -8px -16px;
            display: flex;
            flex-wrap: wrap;
            > div {
                width: 50%;
                padding: 0 8px 16px;
            }
        }
        @media (min-width: $breakpoint-medium) {
            margin: 0 -16px -32px;
            > div {
                padding: 0 16px 32px;
            }
        }
    }
    
    &--progress {
        text-align: center;
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
        @media (max-width: $breakpoint-medium-max) {
            font-size: 16px;
        }
        
        .widget {
            &__content {
                overflow: hidden;
                position: relative;
                flex: 1 1 auto;
                display: block;
                background: #FDF1ED;
                border-radius: 12px;
                @media (max-width: $breakpoint-small-max) {
                    //padding: 40px 16px;
                }
                @media (min-width: $breakpoint-small) {
                    min-height: 700px;
                }
                @media (min-width: $breakpoint-medium) {
                    min-height: 800px;
                }
                @media (min-width: $breakpoint-large) {
                    min-height: 900px;
                }
            }
            
            &__info {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                padding: 88px 24px 56px;
                background: linear-gradient(to bottom, rgba(#FDF1ED, 0) 0, rgba(#FDF1ED, .7) 5%, rgba(#FDF1ED, .9) 8%, #FDF1ED 12%, #FDF1ED 100%);
                @media (max-width: $breakpoint-medium-max) {
                    padding: 80px 16px 50px;
                }
                @media (max-width: $breakpoint-small-max) {
                    padding: 20px 8px 40px;
                    position: relative;
                }
    
                .MuiButton-root {
                    min-width: 176px;
                }

                &--slide {
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: space-between;
                  min-height: 480px;
                  bottom: 40px;
                  @media (max-width: $breakpoint-small-max) {
                    min-height: 320px;
                    bottom: 0;
                  }
                   @media (max-width: $breakpoint-xsmall-max) {
                     min-height: 360px;
                   }
                }
            }
            
            &__image {
                img {
                    margin: 0 auto;
                }
                @media (max-width: $breakpoint-small-max) {
                    height: 280px;
                    margin: 0 auto -20px;
                    position: relative;
                    img {
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                    }
                }
                &--acorn {
                  @media (max-width: $breakpoint-medium-max) {
                    height: 280px;

                    img {
                      height: 100%;
                    }
                  }
                }
            }
        }
    }
    
    &--dropbox {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        background: rgba($link-color, .15);
        border-radius: 12px;
        padding: 24px 26px;
        flex-shrink: 0;
    
        @media (max-width: $breakpoint-xsmall-max) {
            flex-direction: column;
            justify-content: center;
            text-align: center;
            padding: 24px 15px;
        }
        
        .widget {
            &__title {
                align-items: center;
                display: flex;
                @media (max-width: $breakpoint-xsmall-max) {
                    margin: 0 0 16px;
                    display: block;
                    h6 {
                        margin-bottom: 10px;
                    }
                }
            }
            &__icon {
                margin-right: 18px;
                @media (max-width: $breakpoint-xsmall-max) {
                    margin: 0 auto 16px;
                }
            }
        }
    }
    
    &--analysis {
        .widget {
            &__link {
                line-height: 40px;
            }
        }
    }
    
    &--others {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
        .widget {
            &__content {
                display: flex;
                flex: 1 1 auto;
            }
        }
    }
}