.MuiPagination-root {
    display: flex;
    justify-content: center;
    margin: 60px 0 40px;
    @media (max-width: $breakpoint-small-max) {
        margin: 24px 0;
    }
    .MuiPagination-ul {
        li {
            &:last-child,
            &:first-child {
                margin-right: 2px;
                .MuiPaginationItem-page {
                    border: 1px solid $gray-800;
                    border-radius: 8px;
                    svg {
                        color: $gray-800;
                    }
                }
            }
            &:last-child {
                margin: 0 0 0 2px;
            }
        }
    }

    .MuiPaginationItem-root {
        font-family: inherit;
        font-size: 16px;
        margin: 0;
    }
    
    .MuiPaginationItem-page {
        color: $base-color;
        
        &.Mui-selected {
            background: $link-color;
            color: #fff;
            
            &:hover,
            &.Mui-focusVisible {
                background: $link-color;
            }
        }
    }
}