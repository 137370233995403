.tree-item {
    position: relative;
    color: $success-color;
    z-index: 2;
    display: flex;
    align-items: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    transition: color .2s, background-image .2s, opacity .2s;
    
    @media (min-width: $breakpoint-medium) {
        position: absolute;
        justify-content: center;
        
        .tree--active & {
            &:not(.tree-item--hover) {
                color: #7AA29E;
            }
        }
        
        &--about {
            top: 129px;
            left: 213px;
            width: 335px;
            height: 230px;
            background-image: url('../../../images/tree/about/About-Me.png');
            //artem
            
            //artem
        
            .tree--active & {
                &:not(.tree-item--hover) {
                    background-image: url('../../../images/tree/about/About-Me-hover.png');
                }
            }
        
            &.tree-item--base {
                background-image: url('../../../images/tree/about/About-Me-0.png');
            
                .tree--active & {
                    &:not(.tree-item--hover) {
                        background-image: url('../../../images/tree/about/About-Me-0-hover.png');
                    }
                }
            }
        
            &.tree-item--middle {
                background-image: url('../../../images/tree/about/About-Me-10.png');
            
                .tree--active & {
                    &:not(.tree-item--hover) {
                        background-image: url('../../../images/tree/about/About-Me-10-hover.png');
                    }
                }
            }
        
            &.tree-item--middle {
                background-image: url('../../../images/tree/about/About-Me-50.png');
            
                .tree--active & {
                    &:not(.tree-item--hover) {
                        background-image: url('../../../images/tree/about/About-Me-50-hover.png');
                    }
                }
            }
            
            .tree--tour-details & {
                .tree-item__holder {
                    opacity: 1;
                }
            }
        }
        &--protection {
            top: 265px;
            left: 42px;
            width: 336px;
            height: 240px;
            padding: 0 67px 0 0;
            background-image: url('../../../images/tree/protection/Protection.png');
        
            .tree--active & {
                &:not(.tree-item--hover) {
                    background-image: url('../../../images/tree/protection/Protection-hover.png');
                }
            }
        
            &.tree-item--base {
                background-image: url('../../../images/tree/protection/Protection-0.png');
            
                .tree--active & {
                    &:not(.tree-item--hover) {
                        background-image: url('../../../images/tree/protection/Protection-0-hover.png');
                    }
                }
            }
        
            &.tree-item--middle {
                background-image: url('../../../images/tree/protection/Protection-10.png');
            
                .tree--active & {
                    &:not(.tree-item--hover) {
                        background-image: url('../../../images/tree/protection/Protection-10-hover.png');
                    }
                }
            }
        
            &.tree-item--middle {
                background-image: url('../../../images/tree/protection/Protection-50.png');
            
                .tree--active & {
                    &:not(.tree-item--hover) {
                        background-image: url('../../../images/tree/protection/Protection-50-hover.png');
                    }
                }
            }
            
            &.tree-item--disabled {
                background-image: url('../../../images/tree/protection/Protection-0-hover.png') !important;
                .tree--tour:not(.tree--tour-details) & {
                    background-image: url('../../../images/tree/protection/Protection-0.png') !important;
                }
            }
        }
        &--future {
            top: 87px;
            right: 139px;
            width: 360px;
            height: 286px;
            padding: 0 0 2px 57px;
            background-image: url('../../../images/tree/future/Future.png');
        
            .tree--active & {
                &:not(.tree-item--hover) {
                    background-image: url('../../../images/tree/future/Future-hover.png');
                }
            }
        
            &.tree-item--base {
                background-image: url('../../../images/tree/future/Future-0.png');
            
                .tree--active & {
                    &:not(.tree-item--hover) {
                        background-image: url('../../../images/tree/future/Future-0-hover.png');
                    }
                }
            }
        
            &.tree-item--middle {
                background-image: url('../../../images/tree/future/Future-10.png');
            
                .tree--active & {
                    &:not(.tree-item--hover) {
                        background-image: url('../../../images/tree/future/Future-10-hover.png');
                    }
                }
            }
        
            &.tree-item--middle {
                background-image: url('../../../images/tree/future/Future-50.png');
            
                .tree--active & {
                    &:not(.tree-item--hover) {
                        background-image: url('../../../images/tree/future/Future-50-hover.png');
                    }
                }
            }
    
            &.tree-item--disabled {
                background-image: url('../../../images/tree/future/Future-0-hover.png') !important;
                .tree--tour:not(.tree--tour-details) & {
                    background-image: url('../../../images/tree/future/Future-0.png') !important;
                }
            }
        }
        &--finance {
            top: 287px;
            right: 22px;
            width: 329px;
            height: 228px;
            padding: 39px 0 0 60px;
            background-image: url('../../../images/tree/finances/Finances.png');
        
            .tree--active & {
                &:not(.tree-item--hover) {
                    background-image: url('../../../images/tree/finances/Finances-hover.png');
                }
            }
        
            &.tree-item--base {
                background-image: url('../../../images/tree/finances/Finances-0.png');
            
                .tree--active & {
                    &:not(.tree-item--hover) {
                        background-image: url('../../../images/tree/finances/Finances-0-hover.png');
                    }
                }
            }
        
            &.tree-item--middle {
                background-image: url('../../../images/tree/finances/Finances-10.png');
            
                .tree--active & {
                    &:not(.tree-item--hover) {
                        background-image: url('../../../images/tree/finances/Finances-10-hover.png');
                    }
                }
            }
        
            &.tree-item--middle {
                background-image: url('../../../images/tree/finances/Finances-50.png');
            
                .tree--active & {
                    &:not(.tree-item--hover) {
                        background-image: url('../../../images/tree/finances/Finances-50-hover.png');
                    }
                }
            }
    
            &.tree-item--disabled {
                background-image: url('../../../images/tree/finances/Finances-0-hover.png') !important;
                .tree--tour:not(.tree--tour-details) & {
                    background-image: url('../../../images/tree/finances/Finances-0.png') !important;
                }
            }
        }
    }
    @media (max-width: $breakpoint-medium-max) {
        border-bottom: 1px solid $gray-600;
        padding: 16px 26px 16px 0;
        &::before {
            display: block;
            flex: 0 0 80px;
            width: 80px;
            height: 80px;
            margin: 0 16px 0 0;
            content: '';
            background-size: 100% 100%;
        }
        &:hover {
            text-decoration: none;
            opacity: .8;
        }
    
        &--about {
            order: 1;
            &::before {
                background-image: url('../../../images/tree/Icon-AboutMe.png');
            }
        }
    
        &--protection {
            order: 99;
            &::before {
                background-image: url('../../../images/tree/Icon-Protection.png');
            }
        }
    
        &--finance {
            order: 2;
            &::before {
                background-image: url('../../../images/tree/Icon-Finances.png');
            }
        }
    
        &--future {
            order: 3;
            &::before {
                background-image: url('../../../images/tree/Icon-Future.png');
            }
        }
    }
    
    &__icon {
        position: absolute;
        right: 8px;
        top: 50%;
        transform: translateY(-50%);
    }
    
    &--hover {
        z-index: 3;
        .tree__label {
            opacity: 0;
        }
        
        .tree-item__holder {
            opacity: 1;
        }
        
        &.tree-item--disabled {
            z-index: 1;
            .tree-item__holder {
                opacity: 0;
            }
            .tree__label {
                opacity: 1;
            }
        }
    }
    
    &__holder {
        position: absolute;
        opacity: 0;
        left: 0;
        top: 0;
        right: 0;
        transition: opacity .3s ease-in-out;
    }
    
    &.tree-item--disabled {
        color: #7AA29E;
        pointer-events: none;
        &::before {
            opacity: .4;
        }
        .tree-item__holder {
            display: none;
        }
        
        .tree--tour:not(.tree--tour-details) & {
            color: #215E59;
            &::before {
                opacity: 1;
            }
        }
    }
}