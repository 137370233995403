.search {
    &__empty {
        text-align: center;
    }
    
    &__empty-image {
        max-width: 324px;
        margin: 0 auto -26px;
    }
    
    &__empty-text {
        font-size: 16px;
        max-width: 240px;
        margin: 0 auto;
    }
    
    &__form {
        margin-bottom: 32px;
    }
    
    &__title {
        position: relative;
        margin-bottom: 32px;
        @media (max-width: $breakpoint-medium-max) {
            border-bottom: 1px solid $gray-600;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 25px 0;
            margin-bottom: 24px;
        }
        h4,h5 {
            margin: 0;
        }
    }
    
    &__close {
        display: block;
        margin: 0;
        padding: 0;
        overflow: hidden;
        text-indent: -9999px;
        border-radius: 8px;
        background: $gray-200;
        width: 32px;
        height: 32px;
        color: $gray-800;
        border: 0;
        cursor: pointer;
        position: relative;
    
        @media (min-width: $breakpoint-medium) {
            display: none;
        }
        
        &::after,
        &::before {
            position: absolute;
            width: 16px;
            height: 2px;
            content:'';
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%) rotate(45deg);
            border-radius: 2px;
            background: currentColor;
        }
        &::after {
            transform: translate(-50%,-50%) rotate(-45deg);
        }
    
        &:hover {
            color: $base-color;
        }
    }
    
    &__list {
        margin-bottom: 20px;
        @media (max-width: $breakpoint-xsmall-max) {
            > div {
                padding: 16px 0;
                border-top: 1px solid $gray-600;
                
                &:first-child {
                    border: 0;
                    padding-top: 0;
                }
            }
        }
    }
    
    &-box {
        position: relative;
        height: 32px;
        &__area {
            position: absolute;
            right: 0;
            top: 0;
            width: 30px;
            background: #fff;
            border-radius: 8px;
            border: 1px solid $gray-800;
            display: flex;
            opacity: 0;
            overflow: hidden;
            transition: width .2s, opacity .1s;
        }
        
        &__input {
            flex: 1 1 auto;
            border: 0;
            background: none;
            padding: 0;
            outline: none;
            margin-left: 10px;
            font-size: 14px;
            line-height: 30px;
        }
        
        &__drop {
            font-size: 14px;
            width: 100%;
            position: absolute;
            top: 40px;
            right: 0;
            background: #fff;
            opacity: 0;
            transition: opacity .4s;
            border-radius: 8px;
            box-shadow: $box-shadow;
            border: 1px solid $gray-300;
            text-align: center;
        }
        
        &--open {
            .search-box {
                &__area {
                    padding: 0 8px;
                    width: 224px;
                    z-index: 3;
                    opacity: 1;
                    transition: width .2s;
                }
                
                &__drop {
                    opacity: 1;
                    width: 224px;
                    padding: 7px 16px;
                }
            }
        }
        
        &__case {
            text-align: left;
            border-bottom: 1px solid $gray-500;
            padding-bottom: 8px;
            margin-bottom: 8px;
        }
        
        &__title {
            font-size: 12px;
            font-weight: 600;
        }
        
        &__card {
            display: flex;
            align-items: center;
            padding: 8px 0;
            color: $base-color;
        }
        
        &__image {
            width: 40px;
            height: 40px;
            flex: 0 0 40px;
            margin-right: 8px;
            background: #C4C4C4;
            border-radius: 8px;
            overflow: hidden;
            .search-box__card--tasks & {
                border-radius: 50%;
            }
        }
        
        &__content {
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
        
        &__link {
            font-weight: 600;
        }
    }
}