.blog-list {
    font-size: 16px;
    margin-bottom: -24px;
    @media (max-width: $breakpoint-small-max) {
        font-size: 14px;
        margin-bottom: -16px;
    }
    
    &__item {
        padding-bottom: 24px;
        display: flex;
        @media (max-width: $breakpoint-small-max) {
            padding-bottom: 16px;
            align-items: center;
        }
        h5 {
            margin: 0;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }
    }
    
    &__item-holder {
        display: flex;
        flex-direction: column;
    }
    
    &__item-text {
        flex: 1 1 auto;
    }
    
    &__item-content {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        padding: 5px 0 0;
        margin-bottom: 10px;
        @media (max-width: $breakpoint-small-max) {
            margin: 0;
        }
    }
    
    &__item-image {
        flex: 0 0 25%;
        margin: 0 24px 0 0;
        border-radius: 12px;
        overflow: hidden;
        @media (max-width: $breakpoint-small-max) {
            border-radius: 8px;
            margin-right: 16px;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            display: block;
        }
    }
    
    &__item-author {
        font-size: 14px;
        color: $gray-800;
        display: flex;
        align-items: center;
        @media (max-width: $breakpoint-small-max) {
            display: none;
        }
    }
    
    &__item-avatar {
        flex: 0 0 24px;
        height: 24px;
        width: 24px;
        margin: 0 8px 0 0;
        border-radius: 50%;
        overflow: hidden;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    
    a {
        color: $base-color;
    }
}