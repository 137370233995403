.task-analysis-card {
    display: flex;
    align-items: center;
    color: $base-color;
    border-radius: 12px;
    background: #fff;
    border: 1px solid $gray-200;
    transition: box-shadow .2s;
    flex: 1 1 auto;
    padding: 20px 24px;
    @media (max-width: $breakpoint-medium-max) {
        padding: 16px 10px;
    }
    @media (max-width: $breakpoint-small-max) {
        padding: 16px 24px;
    }
    
    &__mark {
        display: flex;
        align-items: center;
        .icon-svg {
            margin: 0 12px 0 5px;
        }
    }
    
    &__content {
        padding-right: 10px;
        flex: 1 1 auto;
    }
    
    &--hover {
        border: 1px solid $gray-400;
    
        &:hover {
            border-color: #fff;
            text-decoration: none;
            box-shadow: $box-shadow;
        }
    }
}